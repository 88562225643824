/**
 * Copyright 2025 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';

import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';

import { REPLENISHMENT_METRICS, REPLENISHMENT_STATUS_CDS } from '@inbound/constants/constants';
import { replenishmentService } from '@inbound/services/replenishmentService';

export const useGetReplenishmentMetricsOver24Hours = (isEnabled = true) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const payload = {
    searchCriteria: {
      statusCds: [
        REPLENISHMENT_STATUS_CDS.NEW,
        REPLENISHMENT_STATUS_CDS.ASSIGNED,
        REPLENISHMENT_STATUS_CDS.SKIPPED,
        REPLENISHMENT_STATUS_CDS.UNASSIGNED,
        REPLENISHMENT_STATUS_CDS.IN_PROGRESS,
      ],
      effectiveTs: new Date(new Date().setHours(new Date().getHours() - 24)).toISOString(),
    },
    metric: [REPLENISHMENT_METRICS.METRIC_BY_EFFECTIVE_TS],
  };

  const {
    data: replenishmentDashboardMetricsOver24HoursData,
    isLoading,
    isError,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['replenishmentDashboardMetricsOver24Hours'],
    queryFn: () => replenishmentService.getReplenishmentDashboardMetrics(payload),
    enabled: isEnabled,
    cacheTime: 0,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (isError) {
      handleNotification(
        NOTIFICATION_TYPES.ERROR,
        t('Notification.ReplenishmentDashboardMetricsError')
      );
    }
  }, [isError, handleNotification, t]);

  return { replenishmentDashboardMetricsOver24HoursData, isLoading, isError, refetch, isFetching };
};
