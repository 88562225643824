/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';
import { userService } from '@shared/services/userService.ts';

export const useGetUserByUserId = (
  params: { userId: string; entityAssociations?: string },
  isEnabled = true
) => {
  const {
    data: userData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['user', params],
    queryFn: () =>
      userService.getUserByUserId(params.userId, {
        entityAssociations: params.entityAssociations,
      }),
    enabled: isEnabled,
    cacheTime: 0,
  });

  return { userData, isLoading, isError };
};
