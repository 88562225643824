/**
 * Copyright 2025 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

// Utility function to calculate total locations
export const calculateLocations = (
  totalFpsLayoutCountExpected: number | null | undefined,
  totalRsrvLayoutCountExpected: number | null | undefined
) => {
  let fpsLayoutCount = 0;
  let rsrvLayoutCount = 0;

  if (totalFpsLayoutCountExpected && totalFpsLayoutCountExpected !== null) {
    fpsLayoutCount = Number(totalFpsLayoutCountExpected);
  }

  if (totalRsrvLayoutCountExpected && totalRsrvLayoutCountExpected !== null) {
    rsrvLayoutCount = Number(totalRsrvLayoutCountExpected);
  }
  return fpsLayoutCount + rsrvLayoutCount;
};

/**
 * Checks if at least one of the provided values is non-zero.
 * @param {number | null | undefined} counted
 * @param {number | null | undefined} expected
 * @returns {boolean} `true` if either value is non-zero; otherwise, `false`
 */
export const hasValidValues = (
  counted: number | null | undefined,
  expected: number | null | undefined
): boolean => {
  return (counted ?? 0) !== 0 || (expected ?? 0) !== 0;
};
