/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text, Button } from '@az/starc-ui';
import { SetChildErrorType } from '@ofm/types/types';
import { Footer } from '@shared/components/Footer/Footer';
import { PAGE_URLS } from '@shared/constants/routes';
import dayjs from 'dayjs';
import { useNavigate, useOutletContext } from 'react-router-dom';
import s from '@shared/components/ItemLayout/ItemLayout.module.scss';
import { MOCK_DC_ORDERS } from '@ofm/constants/dataConstants';
import { CheckBoxCard } from '@shared/components/CheckBoxCard/CheckBoxCard';

export const ReadyToCreate = () => {
  const { waveDueDate } = useOutletContext<{
    waveId?: string;
    waveDueDate?: string;
    setChildError?: SetChildErrorType;
  }>();

  const navigate = useNavigate();

  return (
    <>
      <View padding={6} height="100%" className="scrollable-section">
        <Text variant="display-3">{`Upcoming - ${dayjs(waveDueDate).format('MM/DD/YY')}`}</Text>
        <View gap={4} className={s['list']} direction="row" padding={[5, 0, 0, 0]}>
          {MOCK_DC_ORDERS.map((dc) => {
            return (
              <View.Item key={dc.name} className={s['list__item']}>
                <CheckBoxCard
                  title={dc.name}
                  isChecked={dc.isChecked}
                  isCardDisabled={dc.isDisabled}
                />
              </View.Item>
            );
          })}
        </View>
      </View>
      <Footer
        hasBackButton={true}
        backButtonOnClick={() => navigate(PAGE_URLS.ORDER_REQUEST_BILLING)}
      >
        <Button>CREATE X ORDERS</Button>
      </Footer>
    </>
  );
};
