/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const InboundOrderLineProductSchema = z.object({
  productId: z.string(),
  localeCd: z.string(),
  productLocaleDesc: z.string().nullable(),
});

export const ReceivedOrderLineSchema = z.object({
  receivedOrderLineKey: z
    .string()
    .optional()
    .nullable() /* todo: Need to revisit again to remove optional & nullable */,
  trailerOrderKey: z.string().nullable(),
  productId: z.string(),
  layoutDistinctName: z.string(),
  receivedByUserId: z.string(),
  receivedTs: z.string(),
  partNbrId: z.string().nullable(),
  expiryDate: z.string().nullable(),
  receivedQty: z.number(),
  tourLbl: z.string().nullable(),
  tourLblStartTs: z.string().nullable(),
  tourLblEndTs: z.string().nullable(),
  receiptId: z.string().nullable(),
  receiptLabel: z.string().nullable(),
  rowNumber: z.number().nullable(),
  colNumber: z.number().nullable(),
  toteId: z.string().nullable(),
  operationCd: z.string().nullable(),
  statusCd: z.string().nullable(),
  isDeleted: z.boolean().nullable(),
});

export const InboundOrderLineLocationSchema = z.object({
  inboundOrderLineLocationKey: z.string(),
  productId: z.string(),
  layoutDistinctName: z.string(),
  layoutName: z.string().nullable(),
  layoutAttribNameType: z.string().nullable(),
  parentLayoutDistinctName: z.string().nullable(),
  totalReceivedQty: z.number(),
  receivedQtyAdj: z.number().nullable(),
  adjReasonCd: z.string().nullable(),
  totalCurrReceivedQty: z.number().nullable(),
  receiptLabel: z.string().nullable(),
  operationCd: z.string().nullable(),
  statusCd: z.string().nullable(),
  isDeleted: z.boolean().nullable(),
  receivedOrderLines: z.array(ReceivedOrderLineSchema).nullable(),
});

export const InboundOrderLineSchema = z.object({
  inboundOrderLineKey: z.string(),
  productId: z.string(),
  srcLineNbr: z.string().nullable(),
  partNbrId: z.string().nullable(),
  statusCd: z.string().nullable(),
  commodityTypeCd: z.string().nullable(),
  totalOrderedQty: z.number(),
  unitPerCaseQty: z.number().nullable(),
  unitPerTierQty: z.number().nullable(),
  tiersPerPalletQty: z.number().nullable(),
  totalCurrReceivedQty: z.number().nullable(),
  totalReceivedQty: z.number(),
  totalReceivedQtyAdj: z.number().nullable(),
  comment: z.string().nullable(),
  fpsLayoutName: z.string().nullable(),
  product: InboundOrderLineProductSchema.nullable(),
  inboundOrderLineLocations: z.array(InboundOrderLineLocationSchema).nullable(),
});
