/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Card, View, Text, classNames } from '@az/starc-ui';
import styles from './ContentCard.module.scss';

// TODO: Change username prop to general User type once BFF is set up
export type ContentCardProps = {
  title: string;
  startContent?: string;
  endContent?: string;
  variant: 'primary' | 'disabled' | 'secondary';
  borderVariant?: 'success';
};

export const ContentCard = ({
  title,
  startContent,
  endContent,
  variant,
  borderVariant,
}: ContentCardProps) => {
  return (
    <Card
      className={classNames(
        styles['card'],
        styles[`card-${variant}`],
        styles[`card__border-${borderVariant}`]
      )}
      hasHoverEffect={false}
    >
      <View>
        <Text color="500" variant="main-body" align="start">
          {title}
        </Text>
      </View>
      <View direction="row" align="center" justify="space-between">
        <Text variant="display-5">{startContent}</Text>
        <Text variant="display-5">{endContent}</Text>
      </View>
    </Card>
  );
};
