/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';
import { replenishmentService } from '../replenishmentService';

export const useGetPriority = (isChecked: boolean, groupBy: string, isEnabled = true) => {
  const {
    data: priorityData,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['priorityQueryKey', isChecked],
    queryFn: () => replenishmentService.getUnassignedReplenishment(groupBy),
    enabled: isChecked && isEnabled,
  });

  return { priorityData, isLoading, isFetching, isError, refetch };
};
