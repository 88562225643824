/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { ROUTES } from '@shared/constants/routes';

type CycleCountDetailsTabsData = {
  label: string;
  value: string;
};

export const CycleCountDetailsTabsData: CycleCountDetailsTabsData[] = [
  {
    label: 'CycleCount.CyclePhaseTabs.Overview',
    value: ROUTES.OVERVIEW,
  },
  {
    label: 'CycleCount.CyclePhaseTabs.SKUswithVariances',
    value: ROUTES.SKUS_WITH_VARIANCES,
  },
  {
    label: 'CycleCount.CyclePhaseTabs.Subzones',
    value: ROUTES.SUBZONES,
  },
];
