/**
 * Copyright 2025 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Text, View } from '@az/starc-ui';
import styles from './ReplenishmentDashboard.module.scss';
import { useEffect, useState } from 'react';
import { useGetUnassignedReplenishmentCountByPriority } from '@inbound/services/hooks/useGetUnassignedReplenishmentCountByPriority';
import { useGetUnassignedReplenishmentCountOver24Hours } from '@inbound/services/hooks/useGetUnassignedReplenishmentCountOver24Hrs';
import { Table } from '@shared/components/Table/Table';
import { TableStylingVariants } from '@shared/components/Table/tableConstants';
import { REPLENISHMENT_DASHBOARD_COUNT_BY_PRIORITY_TABLE_COLUMNS } from '@inbound/constants/tableConstants';
import { useSessionStorage } from '@shared/hooks/useStorage';
import { SESSION_DC_ID_KEY } from '@shared/constants/storageConstants';
import { useTranslation } from 'react-i18next';
import { generateDateString } from '@ofm/utils/utils';
import { EMPTY_VALUE } from '@shared/constants/constants';

type CountByPriorityItem = {
  key: string;
  value: string | number;
};
type CountByPriorityTableProps = {
  countByPrioritySelected: boolean;
};

export const CountByPriorityTable = ({ countByPrioritySelected }: CountByPriorityTableProps) => {
  const [countByPriorityData, setCountByPriorityData] = useState<CountByPriorityItem[]>([]);
  const [selectedDC] = useSessionStorage<string>(SESSION_DC_ID_KEY);
  const { t } = useTranslation();

  const { unassignedReplenishmentCountByPriorityData } =
    useGetUnassignedReplenishmentCountByPriority(new Date().toISOString());

  const { unassignedReplenishmentCountOver24oursData, isLoading } =
    useGetUnassignedReplenishmentCountOver24Hours();

  const processOver24HrsData = (data: CountByPriorityItem[]) => {
    return (
      data
        ?.map((item) => {
          if (item?.key === 'OverEffectiveHrs') {
            return { key: 'Over 24 Hours', value: item.value };
          }
          if (item?.key === 'Oldest') {
            return {
              key: 'Oldest Replenishment over 24 Hours',
              value:
                item.value != 'null'
                  ? generateDateString(new Date(item.value), t('DateFormat.LongTimeSeconds'))
                  : EMPTY_VALUE,
            };
          }
          return item;
        })
        ?.sort((a, _) => (a.key === 'Oldest Replenishment over 24 Hours' ? -1 : 1)) ?? []
    );
  };

  const sortDataByKey = (data: CountByPriorityItem[]) => {
    return data.sort((a, b) => Number(a.key) - Number(b.key));
  };

  const calculateTotal = (priorityData: CountByPriorityItem[]) => {
    return priorityData.reduce((sum, item) => sum + Number(item.value), 0);
  };
  useEffect(() => {
    if (
      countByPrioritySelected &&
      unassignedReplenishmentCountByPriorityData &&
      unassignedReplenishmentCountOver24oursData
    ) {
      const priorityData = unassignedReplenishmentCountByPriorityData.countByPriority ?? [];
      const over24HrsData = processOver24HrsData(
        unassignedReplenishmentCountOver24oursData.metricByEffectiveTs ?? []
      );

      const sortedPriorityData = sortDataByKey(priorityData);
      const totalPriorityValue = calculateTotal(priorityData);

      setCountByPriorityData([
        ...sortedPriorityData,
        { key: 'Total', value: totalPriorityValue },
        ...over24HrsData,
      ]);
    }
  }, [
    countByPrioritySelected,
    unassignedReplenishmentCountByPriorityData,
    unassignedReplenishmentCountOver24oursData,
  ]);

  return (
    <View>
      <Text as="h2" size="175" weight="bold" className={styles['dashboard__table-header']}>
        {t('ReplenishmentDashboard.Unassigned.Header', {
          dcNumber: selectedDC,
        })}
      </Text>
      <View direction="row" padding={[4, 6]} className={styles['dashboard__table-wrapper']}>
        <Table
          columns={REPLENISHMENT_DASHBOARD_COUNT_BY_PRIORITY_TABLE_COLUMNS}
          rows={countByPriorityData.map((item) => ({
            id: item.key,
            cells: [{ value: item.key }, { value: item.value }],
          }))}
          isApiLoadedData={true}
          isLoading={isLoading}
          styleVariant={TableStylingVariants.DETAILS}
          isPaginated={false}
          pageSize={0}
          defaultPage={0}
          isCheckboxTable={false}
          isCreditItem={false}
          totalPages={0}
          onSort={() => ''}
        />
      </View>
    </View>
  );
};
