/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState } from 'react';

import { useAtom } from 'jotai';

import { Text, View, Checkbox, Icon, Button, Actionable, Loader } from '@az/starc-ui';

import { Accordion } from '@shared/components/Accordion/Accordion';
import {
  selectedOrdersAtom,
  laneConfirmationItemAtom,
  ltdGroupedOrdersAtom,
  selectedRootReleaseOrdersAtom,
} from '@outbound/atoms/releaseOrder/releaseOrderAtom';

import { Props } from './ReleaseGroupAccordion.types.tsx';
import Styles from './ReleaseGroupAccordion.module.scss';
import { ChevronDown, ChevronUp } from '@az/starc-ui-icons';
import {
  RELEASE_GROUPS_GROUP_TYPE,
  RELEASE_GROUPS_ORDER_TYPE,
  RELEASE_ORDER_FIELD,
  RELEASE_ORDER_STATUS,
  RELEASE_ORDER_STATUS_COLOR_CODE,
} from '@outbound/constants/constants.ts';
import { ActionMenu } from '@mdm/components/ActionMenu/ActionMenu.tsx';
import { useGetReleaseGroups } from '@outbound/services/hooks/useGetReleaseGroups.tsx';
import { DEFAULT_PAGE, MAX_PAGE_SIZE, NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { IntermediateReleaseGroupAccordion } from '@outbound/components/ReleaseGroupAccordion/IntermediateReleaseGroupAccordion.tsx';
import { ReleaseGroupList } from '@outbound/components/ReleaseGroupAccordion/ReleaseGroupList.tsx';
import { StatusBadge } from '@shared/components/StatusBadge/StatusBadge.tsx';
import { handleReleaseOrderCheck } from '@outbound/utils/util.ts';
import { getAttributeValue } from '@shared/utils/commonUtils.ts';
import { useMutateOrderReplenishment } from '@outbound/services/hooks/useRunReplenishment.tsx';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useTranslation } from 'react-i18next';

export const RootReleaseGroupAccordion = ({
  releaseGroup,
  orderType = RELEASE_GROUPS_ORDER_TYPE.DIF_WORK_ORDER,
}: Props) => {
  /*Atoms*/
  const [selectedOrders, setSelectedOrders] = useAtom(selectedOrdersAtom);
  const [selectedRootReleaseOrders, setSelectedRootReleaseOrders] = useAtom(
    selectedRootReleaseOrdersAtom
  );
  const [selectedItem] = useAtom(laneConfirmationItemAtom);
  const [groupedOrders] = useAtom(ltdGroupedOrdersAtom);

  /* State variables */
  const [selectedGroupType, setSelectedGroupType] = useState<string>('');

  /* variables */
  const { attributes, groupId, groupName, status } = releaseGroup;

  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const queryClient = useQueryClient();
  const isTransferOrderTab = orderType === RELEASE_GROUPS_ORDER_TYPE.TRANSFER;
  const { releaseGroupsData, isFetching: isReleaseGroupLoading } = useGetReleaseGroups(
    {
      currentPage: DEFAULT_PAGE - 1,
      pageSize: MAX_PAGE_SIZE,
      orderType,
      parentReleaseGroupId: groupId,
      groupTypes: [selectedGroupType],
    },
    !!selectedGroupType
  );

  /* State variables */
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  /* Functions */
  const handleCheckAll = (state: boolean) => {
    const releaseOrders = (releaseGroupsData?.content || []).map(({ groupId }) => groupId);
    setSelectAllChecked(state);
    if (state) {
      setSelectedRootReleaseOrders((prev) => [...new Set([...prev, groupId])]);
      if (selectedGroupType && releaseOrders.length) {
        setSelectedOrders(state ? [...new Set([...releaseOrders, groupId])] : []);
      } else {
        setSelectedOrders((prev) => [...new Set([...prev, groupId])]);
      }
    } else {
      setSelectedRootReleaseOrders((prev) => prev.filter((item) => item !== groupId));
      setSelectedOrders((prev) =>
        prev.filter((item) => item !== groupId && !releaseOrders.includes(item))
      );
    }
  };

  const handleAccordionClick = () => {
    setIsAccordionOpen((isOpen) => !isOpen);
    setSelectedGroupType(
      isTransferOrderTab ? RELEASE_GROUPS_GROUP_TYPE.BY_ORDER : RELEASE_GROUPS_GROUP_TYPE.BY_DC
    );
  };

  /* Replenishment Hook */
  const { mutateReleaseGroups, isLoading: isRunReplishmentUpdating } =
    useMutateOrderReplenishment();

  /* Function to handle replenishment */
  const handleRunReplenishment = () => {
    mutateReleaseGroups(
      {
        releaseOrderReplenish: { groupIds: [releaseGroup.groupId] },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['releaseGroups']);
          handleNotification(
            NOTIFICATION_TYPES.SUCCESS,
            t('Success.Action.RunReplenishment.Updated')
          );
          setSelectedOrders([]);
          setSelectedRootReleaseOrders([]);
        },
      }
    );
  };

  return (
    <>
      <View
        className={[Styles['order-release-accordion'], Styles['order-release-accordion__root']]}
      >
        <Accordion
          open={isAccordionOpen}
          header={{
            label: (
              <View direction="row" align="center">
                <Checkbox
                  name="select-all"
                  value={releaseGroup.groupId}
                  onChange={(event) => handleCheckAll(event.target.checked)}
                  checked={selectAllChecked}
                  disabled={releaseGroup.status === RELEASE_ORDER_STATUS.RELEASED}
                  className={Styles['order-release-accordion__checkbox']}
                />
                <View
                  direction="row"
                  align="center"
                  className={Styles['order-release-accordion__left']}
                >
                  <View
                    direction="row"
                    align="center"
                    padding={[4, 22, 4, 0]}
                    className={Styles['order-release-accordion__left--label']}
                  >
                    <Text size="100" weight="bold">
                      {groupName}
                    </Text>
                  </View>
                  <View padding={[4, 10, 4, 2]}>
                    <Text size="100" weight="regular">
                      {isTransferOrderTab
                        ? t('OutboundMatrix.OrderRelease.OrderCount', {
                            count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.ORDER_COUNT),
                          })
                        : t('OutboundMatrix.OrderRelease.DCs', {
                            count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.DC_COUNT),
                          })}
                    </Text>
                  </View>
                  <View
                    padding={[4, 10, 4, 2]}
                    className={Styles['order-release-accordion__left--label__pieces']}
                  >
                    <Text size="100" weight="regular">
                      {t('OutboundMatrix.OrderRelease.Pallet', {
                        count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.PALLETS),
                      })}
                    </Text>
                  </View>
                  <View padding={[4, 10, 4, 2]}>
                    <Text size="100" weight="regular">
                      {t('OutboundMatrix.OrderRelease.Pieces', {
                        count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.PIECES),
                      })}
                    </Text>
                  </View>
                </View>
              </View>
            ),
            auxiliaryLabel: (
              <View
                direction="row"
                align="center"
                className={Styles['order-release-accordion__right']}
              >
                <View gap={2}>
                  <View direction="row" gap={2}>
                    {isTransferOrderTab ? (
                      <Checkbox
                        label={t('ViewByOrder')}
                        name={RELEASE_GROUPS_GROUP_TYPE.BY_ORDER}
                        value={RELEASE_GROUPS_GROUP_TYPE.BY_ORDER}
                        checked={selectedGroupType === RELEASE_GROUPS_GROUP_TYPE.BY_ORDER}
                        onChange={(event) => {
                          setSelectedGroupType(
                            event.target.checked ? RELEASE_GROUPS_GROUP_TYPE.BY_ORDER : ''
                          );
                          setIsAccordionOpen(event.target.checked);
                        }}
                      />
                    ) : (
                      <Checkbox
                        label={t('ViewByDC')}
                        name={RELEASE_GROUPS_GROUP_TYPE.BY_DC}
                        value={RELEASE_GROUPS_GROUP_TYPE.BY_DC}
                        checked={selectedGroupType === RELEASE_GROUPS_GROUP_TYPE.BY_DC}
                        onChange={(event) => {
                          setSelectedGroupType(
                            event.target.checked ? RELEASE_GROUPS_GROUP_TYPE.BY_DC : ''
                          );
                          setIsAccordionOpen(event.target.checked);
                        }}
                      />
                    )}

                    <Checkbox
                      label={t('ViewByArea')}
                      name={RELEASE_GROUPS_GROUP_TYPE.BY_AREA}
                      value={RELEASE_GROUPS_GROUP_TYPE.BY_AREA}
                      checked={selectedGroupType === RELEASE_GROUPS_GROUP_TYPE.BY_AREA}
                      onChange={(event) => {
                        setSelectedGroupType(
                          event.target.checked ? RELEASE_GROUPS_GROUP_TYPE.BY_AREA : ''
                        );
                        setIsAccordionOpen(event.target.checked);
                      }}
                    />
                  </View>
                </View>
                {status === RELEASE_ORDER_STATUS.CREATED && (
                  <View height="100%">
                    <Button
                      variant="secondary"
                      loading={isRunReplishmentUpdating}
                      onClick={handleRunReplenishment}
                    >
                      <Text size="100">{t('OutboundMatrix.OrderRelease.RunReplenishment')}</Text>
                    </Button>
                  </View>
                )}
                {status !== RELEASE_ORDER_STATUS.CREATED && (
                  <View height="100%">
                    <StatusBadge
                      variant={
                        RELEASE_ORDER_STATUS_COLOR_CODE[
                          status as keyof typeof RELEASE_ORDER_STATUS_COLOR_CODE
                        ]
                      }
                      text={status.replace(/_/g, ' ')}
                    />
                  </View>
                )}
                <View height="100%">
                  <ActionMenu
                    menus={[
                      {
                        label: t('OutboundMatrix.MenuOptions.ReleaseToPaper'),
                        clickHandler: () => ({}),
                      },
                      {
                        label: t('OutboundMatrix.MenuOptions.CloseToPaper'),
                        clickHandler: () => ({}),
                      },
                    ]}
                  />
                </View>
                <Actionable onClick={handleAccordionClick}>
                  <Icon size={6} svg={isAccordionOpen ? ChevronUp : ChevronDown} />
                </Actionable>
              </View>
            ),
          }}
        >
          <Checkbox.Group
            name="storeOrders"
            value={selectedOrders}
            onChange={(e) =>
              handleReleaseOrderCheck(
                e,
                selectedOrders,
                setSelectedOrders,
                groupedOrders,
                selectedItem,
                groupId,
                selectedRootReleaseOrders,
                setSelectedRootReleaseOrders,
                groupId || ''
              )
            }
          >
            {isReleaseGroupLoading && <Loader color="primary" />}
            {selectedGroupType === RELEASE_GROUPS_GROUP_TYPE.BY_AREA && (
              <ReleaseGroupList
                releaseGroup={releaseGroup}
                orderType={orderType}
                rootGroupId={groupId}
                groupTypes={[selectedGroupType]}
              />
            )}
            {[RELEASE_GROUPS_GROUP_TYPE.BY_ORDER, RELEASE_GROUPS_GROUP_TYPE.BY_DC].includes(
              selectedGroupType
            ) &&
              (releaseGroupsData?.content || []).map((releaseGroup) => (
                <IntermediateReleaseGroupAccordion
                  releaseGroup={releaseGroup}
                  rootGroupId={groupId}
                  orderType={orderType}
                />
              ))}
          </Checkbox.Group>
        </Accordion>
      </View>
    </>
  );
};
