/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useSessionStorage } from '@shared/hooks/useStorage.ts';
import { SESSION_DC_ID_KEY } from '@shared/constants/storageConstants.ts';
import { z } from 'zod';
import { LocationSchema } from '@mdm/schemas/locationSchema.ts';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { getFormFieldIndexByKey } from '@mdm/utils/form/formUtils.tsx';
import {
  Checkbox,
  FormControl,
  Link,
  Loader,
  Radio,
  Select,
  Text,
  TextField,
  View,
} from '@az/starc-ui';
import styles from '@mdm/pages/RDM/LocationManager/Location/Detail/LocationDetail.module.scss';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import { displayAttributeFieldValidationMessage } from '@mdm/utils/form/validationUtils.tsx';
import {
  DEFAULT_DIMENSION_UNIT,
  DEFAULT_WEIGHT_UNIT,
  FIELD_EVENT,
  LAYOUT_ENTITY_CODE,
  LOCATION_FIELD,
  LOCATION_FLAG_DEDICATED,
  LOCATION_FLAG_DYNAMIC,
  LOCATION_TYPE,
  MAX_LENGTH_FIELD,
  SUBZONE_FIELD,
  SUBZONE_TYPE,
} from '@mdm/constants/constants.ts';
import { useCallback, useEffect, useState } from 'react';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig.ts';
import { LAYOUT, PRODUCT_ID_TYPE, RDM } from '@shared/constants/constants.ts';
import { getFormInputError } from '@ofm/utils/utils.ts';
import { useGetWarehouseProducts } from '@shared/services/hooks/useGetWarehouseProducts.ts';
import { LayoutListRowType } from '@mdm/types/schema.type.ts';
import { subzoneService } from '@mdm/services/subzoneService.ts';
import { useCallbackState } from '@shared/hooks/useCallbackState.ts';
import { normalizeDistinctName } from '@mdm/utils/utils.ts';

type Props = {
  locationId?: string;
  selectedSubzoneId?: string;
  setSkuValid: (isSkuValid: boolean) => void;
  setIsSubzoneForwardCompatible: (isSkuValid: boolean) => void;
  setIsSubzoneReserveCompatible: (isSkuValid: boolean) => void;
  isSubzoneListLoading: boolean;
  isSubzoneForwardCompatible: boolean;
  isSubzoneReserveCompatible: boolean;
  subzonesData?: LayoutListRowType[];
};

export const LocationDetailForm = ({
  locationId,
  selectedSubzoneId,
  setSkuValid,
  subzonesData,
  isSubzoneListLoading,
  isSubzoneForwardCompatible,
  isSubzoneReserveCompatible,
  setIsSubzoneReserveCompatible,
  setIsSubzoneForwardCompatible,
}: Props) => {
  /* State variables */
  const [disabledMinMaxAttribute, setDisabledMinMaxAttribute] = useState<boolean>(false);
  const [selectedDC] = useSessionStorage<string>(SESSION_DC_ID_KEY);
  const [locationTypeOptions, setLocationTypeOptions] = useState<SelectOption[]>([]);
  const [locationFlagOptions, setLocationFlagOptions] = useState<SelectOption[]>([]);
  const [isInvalidSku, setIsInvalidSku] = useState<boolean>(false);
  const [selectedLocationType, setSelectedLocationType] = useCallbackState<string>('');
  const [selectedLocationFlag, setSelectedLocationFlag] = useCallbackState<string>('');

  /* Constants */
  type FormData = z.infer<typeof LocationSchema>;
  const { t } = useTranslation();

  const { configsData, isLoading: isConfigDataLoading } = useGetFacilityConfig({
    domainCd: RDM,
    subDomainCd: LAYOUT,
  });

  const {
    control,
    register,
    formState: { errors },
    getValues,
    watch,
    setValue,
    trigger,
  } = useFormContext<FormData>();

  const parentLayoutDistinctName = watch('parentLayoutDistinctName');
  const { fields: attributeFields } = useFieldArray({
    control,
    name: 'attributes',
  });

  const revalidateAttributeField = (attributeName: string) => {
    const attributeIndex = attributeFields.findIndex((field) => field.name === attributeName);
    if (attributeIndex !== -1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      trigger(`attributes[attribute-${attributeIndex}]`);
    }
  };

  const productSku = watch('products.0.productId');
  const { productsSearchData } = useGetWarehouseProducts(
    {
      productIdType: PRODUCT_ID_TYPE.PRODUCT_ID,
      products: [productSku.trim() || ''],
      currentPage: 0,
      pageSize: 1,
      sortBy: 'productId',
      direction: 'ASC',
    },
    !!productSku.trim()
  );

  /* Function */
  const updateDimensionFields = useCallback(
    (subzoneData: LayoutListRowType) => {
      const foundSubzone = subzoneData ? subzoneData : null;
      if (foundSubzone) {
        const attributes = getValues()['attributes'];
        const subzoneLength = (foundSubzone.layout.attributes || []).find(
          (attribute) => attribute.name === SUBZONE_FIELD.LENGTH
        );
        const locationLengthIndex = (attributes || []).findIndex(
          (attribute) => attribute.name === LOCATION_FIELD.LENGTH
        );
        if (locationLengthIndex !== -1) {
          if (subzoneLength) {
            setValue(`attributes.${locationLengthIndex}.value`, subzoneLength.value);
          } else {
            setValue(`attributes.${locationLengthIndex}.value`, '0');
          }
        }

        const subzoneWidth = (foundSubzone.layout.attributes || []).find(
          (attribute) => attribute.name === SUBZONE_FIELD.WIDTH
        );
        const locationWidthIndex = (attributes || []).findIndex(
          (attribute) => attribute.name === LOCATION_FIELD.WIDTH
        );
        if (locationWidthIndex !== -1) {
          if (subzoneWidth) {
            setValue(`attributes.${locationWidthIndex}.value`, subzoneWidth.value);
          } else {
            setValue(`attributes.${locationWidthIndex}.value`, '0');
          }
        }

        const subzoneHeight = (foundSubzone.layout.attributes || []).find(
          (attribute) => attribute.name === SUBZONE_FIELD.HEIGHT
        );
        const locationHeightIndex = (attributes || []).findIndex(
          (attribute) => attribute.name === LOCATION_FIELD.HEIGHT
        );
        if (locationHeightIndex !== -1) {
          if (subzoneHeight) {
            setValue(`attributes.${locationHeightIndex}.value`, subzoneHeight.value);
          } else {
            setValue(`attributes.${locationHeightIndex}.value`, '0');
          }
        }

        const subzoneWeight = (foundSubzone.layout.attributes || []).find(
          (attribute) => attribute.name === SUBZONE_FIELD.WEIGHT
        );
        const locationWeightIndex = (attributes || []).findIndex(
          (attribute) => attribute.name === LOCATION_FIELD.WEIGHT
        );
        if (locationWeightIndex !== -1) {
          if (subzoneWeight) {
            setValue(`attributes.${locationWeightIndex}.value`, subzoneWeight.value);
          } else {
            setValue(`attributes.${locationWeightIndex}.value`, '0');
          }
        }
      }
    },
    [getValues, setValue]
  );

  /* Hooks */
  const updateProductDataOnLocationTypeChange = (
    locationTypeValue: string,
    locationFlagValue: string
  ) => {
    const newLocationTypeValue = locationTypeValue || selectedLocationType;
    const newLocationFlagValue = locationFlagValue || selectedLocationFlag;
    const attributes = getValues()['attributes'];
    const locationTypeAttributeIndex = getFormFieldIndexByKey(
      attributes,
      LOCATION_FIELD.LOCATION_TYPE
    );
    const products = getValues()['products'] ? getValues()['products'] : [];

    if (locationTypeAttributeIndex !== -1 && attributes) {
      for (let i = 0; i < (products || []).length; i++) {
        if (
          newLocationFlagValue === LOCATION_FLAG_DEDICATED &&
          newLocationTypeValue === LOCATION_TYPE.SLOT
        ) {
          setValue(
            `products.${i}.productId`,
            products?.[i].productId ? products[i].productId.trim() : ''
          );
        } else {
          setValue(`products.${i}.productId`, ' ');
        }

        if (newLocationTypeValue === LOCATION_TYPE.RESERVE) {
          setValue(`products.${i}.min`, '0');
          setValue(`products.${i}.max`, '0');
        }

        trigger(`products.${i}.productId`);
        trigger(`products.${i}.min`);
        trigger(`products.${i}.max`);
      }
    }
  };

  const populateDropdownOptions = useCallback(() => {
    const locationTypes = (configsData || [])
      .filter((config) => config.configCd === LOCATION_FIELD.LOCATION_TYPE)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));
    setLocationTypeOptions(locationTypes);

    const locationFlags = (configsData || [])
      .filter((config) => config.configCd === LOCATION_FIELD.LOCATION_FLAG)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));

    setLocationFlagOptions(locationFlags);
  }, [configsData]);

  useEffect(() => {
    if (productSku && productsSearchData) {
      const attributes = getValues()['attributes'] || [];
      const locationTypeAttribute = attributes.find(
        (attribute) => attribute.name === LOCATION_FIELD.LOCATION_TYPE
      );
      const foundProduct = productsSearchData?.content.find(
        (product) => product.productId === productSku
      );
      if (!foundProduct && productSku && locationTypeAttribute?.value !== LOCATION_TYPE.RESERVE) {
        setIsInvalidSku(true);
        setSkuValid(false);
      } else {
        setIsInvalidSku(false);
        setSkuValid(true);
      }
    }
  }, [getValues, productSku, productsSearchData, setSkuValid]);

  useEffect(() => {
    const attributes = getValues()['attributes'] || [];
    const locationTypeAttribute = attributes.find(
      (attribute) => attribute.name === LOCATION_FIELD.LOCATION_TYPE
    );

    const locationFlagAttribute = attributes.find(
      (attribute) => attribute.name === LOCATION_FIELD.LOCATION_FLAG
    );

    if (locationTypeAttribute && locationTypeAttribute.value && attributes) {
      setSelectedLocationType(locationTypeAttribute.value);
    }

    if (locationFlagAttribute && locationFlagAttribute.value && attributes) {
      setSelectedLocationFlag(locationFlagAttribute.value);
    }
  }, [getValues, setSelectedLocationFlag, setSelectedLocationType]);

  useEffect(() => {
    const attributes = getValues()['attributes'];
    const subscription = watch(async (_value, { name, type }) => {
      const locationFlagAttributeIndex = getFormFieldIndexByKey(
        attributes,
        LOCATION_FIELD.LOCATION_FLAG
      );

      const locationTypeAttributeIndex = getFormFieldIndexByKey(
        attributes,
        LOCATION_FIELD.LOCATION_TYPE
      );

      if (
        locationFlagAttributeIndex !== -1 &&
        name === `attributes.${locationFlagAttributeIndex}.value` &&
        type === FIELD_EVENT.CHANGE
      ) {
        if (attributes && attributes[locationFlagAttributeIndex].value === LOCATION_FLAG_DYNAMIC) {
          setDisabledMinMaxAttribute(true);
          const products = getValues()['products'] ? getValues()['products'] : [];
          for (let i = 0; i < (products || []).length; i++) {
            setValue(`products.${i}.min`, '0');
            setValue(`products.${i}.max`, '0');
            trigger(`products.${i}.min`);
            trigger(`products.${i}.max`);
          }
        } else {
          setDisabledMinMaxAttribute(false);
        }
      }

      if (
        (name === 'parentLayoutDistinctName' ||
          (locationTypeAttributeIndex !== -1 &&
            name === `attributes.${locationTypeAttributeIndex}.value`)) &&
        type === FIELD_EVENT.CHANGE &&
        _value.parentLayoutDistinctName
      ) {
        // we have use service directly because there is no way for wait still react query completes and update that value
        const subzoneData = await subzoneService.getSubzoneById(_value.parentLayoutDistinctName);
        if (subzoneData?.content[0]) {
          updateDimensionFields(subzoneData?.content[0]);

          const subzoneAttributes = subzoneData?.content[0].layout.attributes || [];
          const subzoneTypeAttributesIndex = getFormFieldIndexByKey(
            subzoneAttributes,
            SUBZONE_FIELD.SUBZONE_TYPE
          );

          if (
            subzoneAttributes &&
            attributes &&
            subzoneTypeAttributesIndex !== -1 &&
            locationTypeAttributeIndex !== -1
          ) {
            if (
              attributes[locationTypeAttributeIndex].value === LOCATION_TYPE.SLOT &&
              subzoneAttributes[subzoneTypeAttributesIndex].value !== SUBZONE_TYPE.FORWARD_PICK
            ) {
              setIsSubzoneReserveCompatible(false);
            } else {
              setIsSubzoneReserveCompatible(true);
            }

            if (
              attributes[locationTypeAttributeIndex].value === LOCATION_TYPE.RESERVE &&
              subzoneAttributes[subzoneTypeAttributesIndex].value !== SUBZONE_TYPE.RESERVE
            ) {
              setIsSubzoneForwardCompatible(false);
            } else {
              setIsSubzoneForwardCompatible(true);
            }
          }
        }
      }

      if (name && name.includes('products.') && type === FIELD_EVENT.CHANGE) {
        const products = getValues()['products'] ? getValues()['products'] : [];
        for (let i = 0; i < (products || []).length; i++) {
          trigger(`products.${i}.min`);
          trigger(`products.${i}.max`);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [
    getValues,
    updateDimensionFields,
    setValue,
    trigger,
    watch,
    setIsSubzoneReserveCompatible,
    setIsSubzoneForwardCompatible,
  ]);

  // set subzone for edit view
  useEffect(() => {
    if (selectedSubzoneId) {
      setValue('parentLayoutDistinctName', selectedSubzoneId);
    }
  }, [selectedSubzoneId, setValue]);

  // populate dropdown options
  useEffect(() => {
    populateDropdownOptions();
  }, [configsData, populateDropdownOptions]);

  return (
    <View>
      <form>
        <View direction="row" gap={4}>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('Warehouse')} isRequired={true} />
              <FormControl>
                <Select
                  attributes={{
                    'data-testid': 'warehouse-id',
                  }}
                  label={t('Warehouse')}
                  name="warehouse"
                  variant="no-label"
                  multiSelect={false}
                  defaultValue={{
                    label: 'DC ' + selectedDC?.toString(),
                    value: 'DC ' + selectedDC?.toString(),
                  }}
                  options={[]}
                  disabled
                />
              </FormControl>
              <Text weight="regular" color="600" size="087">
                {t('LocationDetails.WarehouseInstruction')}
              </Text>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View
              attributes={{
                'data-testid': 'subzone-name',
              }}
              gap={2}
            >
              <FormLabel text={t('LocationDetails.SubzoneName.Label')} isRequired={true} />
              <FormControl hasError={!!errors.parentLayoutDistinctName}>
                <Controller
                  control={control}
                  name="parentLayoutDistinctName"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      {isSubzoneListLoading ? (
                        <Loader variant="spinner" color="primary" size="small" />
                      ) : (
                        <Select
                          label={t('LocationDetails.SubzoneName.Placeholder')}
                          variant="no-label"
                          options={(subzonesData || []).map((row) => ({
                            label: row.layout.layoutName,
                            value: row.layout.layoutDistinctName.toString(),
                          }))}
                          value={{
                            label:
                              (subzonesData || []).find(
                                (option) => option.layout.layoutDistinctName === value
                              )?.layout.layoutName || '',
                            value: value || '',
                          }}
                          disabled={!!selectedSubzoneId}
                          inputAttributes={{ onBlur }}
                          onValueChange={(subzone) => onChange(subzone?.value)}
                        />
                      )}
                    </>
                  )}
                />

                {errors.parentLayoutDistinctName && (
                  <View direction="row" justify="space-between">
                    <FormControl.Error>
                      {getFormInputError(errors.parentLayoutDistinctName.type)}
                    </FormControl.Error>
                  </View>
                )}
              </FormControl>
            </View>
          </View.Item>
        </View>

        <View className={[styles['form__field-row']]} direction="row" gap={4}>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.Location.Label')} isRequired={true} />
              <FormControl hasError={!!errors.layoutName}>
                <TextField
                  attributes={{
                    'data-testid': 'name',
                  }}
                  inputAttributes={{
                    placeholder: t('LocationDetails.Location.Placeholder'),
                    ...register('layoutName'),
                    maxLength: MAX_LENGTH_FIELD.LOCATION_NAME,
                  }}
                  defaultValue=""
                  disabled={!!locationId}
                />

                {errors.layoutName && (
                  <View direction="row" justify="space-between">
                    <FormControl.Error>{errors.layoutName.message}</FormControl.Error>
                  </View>
                )}
              </FormControl>
            </View>
          </View.Item>

          <View.Item columns={{ s: 12, l: 3 }}>
            <View
              attributes={{
                'data-testid': 'location-type',
              }}
              gap={2}
            >
              <FormLabel text={t('LocationDetails.LocationType.Label')} isRequired={true} />
              <FormControl
                hasError={
                  !!(
                    (errors.attributes &&
                      displayAttributeFieldValidationMessage(
                        errors,
                        getFormFieldIndexByKey(
                          getValues()['attributes'],
                          LOCATION_FIELD.LOCATION_TYPE
                        )
                      )) ||
                    !isSubzoneForwardCompatible ||
                    !isSubzoneReserveCompatible
                  )
                }
              >
                <Controller
                  control={control}
                  name={`attributes.${getFormFieldIndexByKey(
                    getValues()['attributes'],
                    LOCATION_FIELD.LOCATION_TYPE
                  )}.value`}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      {isConfigDataLoading ? (
                        <Loader variant="spinner" color="primary" size="small" />
                      ) : (
                        <Select
                          label={t('LocationDetails.LocationType.Placeholder')}
                          variant="no-label"
                          options={locationTypeOptions}
                          value={{
                            label:
                              locationTypeOptions.find((option) => option.value === value)?.label ||
                              '',
                            value: value || '',
                          }}
                          inputAttributes={{ onBlur }}
                          onValueChange={(LOCATION_TYPE) => {
                            onChange(LOCATION_TYPE?.value);
                            setSelectedLocationType(LOCATION_TYPE?.value || '', (locationType) => {
                              updateProductDataOnLocationTypeChange(locationType, '');
                              revalidateAttributeField(LOCATION_FIELD.LOCATION_TYPE);
                              revalidateAttributeField(LOCATION_FIELD.LOCATION_FLAG);
                            });
                          }}
                        />
                      )}
                    </>
                  )}
                />

                <FormControl.Error>
                  {!isSubzoneForwardCompatible &&
                    t('ValidationError.Location.SubzoneTypeAndLocationTypeCompatibility.Forward', {
                      subzoneId: normalizeDistinctName(
                        parentLayoutDistinctName,
                        LAYOUT_ENTITY_CODE.SUBZONE
                      ),
                    })}
                  {!isSubzoneReserveCompatible &&
                    t('ValidationError.Location.SubzoneTypeAndLocationTypeCompatibility.Reserve', {
                      subzoneId: normalizeDistinctName(
                        parentLayoutDistinctName,
                        LAYOUT_ENTITY_CODE.SUBZONE
                      ),
                    })}
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.LOCATION_TYPE
                      )
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>

          <View.Item columns={{ s: 12, l: 3 }}>
            <View
              attributes={{
                'data-testid': 'location-flag',
              }}
              gap={2}
            >
              <FormLabel text={`${t('LocationDetails.LocationFlag.Label')}`} isRequired={true} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.LOCATION_FLAG
                      )
                    )
                  )
                }
              >
                <Controller
                  control={control}
                  name={`attributes.${getFormFieldIndexByKey(
                    getValues()['attributes'],
                    LOCATION_FIELD.LOCATION_FLAG
                  )}.value`}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      {isConfigDataLoading ? (
                        <Loader variant="spinner" color="primary" size="small" />
                      ) : (
                        <Select
                          label={t('LocationDetails.LocationFlag.Placeholder')}
                          variant="no-label"
                          options={locationFlagOptions}
                          value={{
                            label:
                              locationFlagOptions.find((option) => option.value === value)?.label ||
                              '',

                            value: value || '',
                          }}
                          inputAttributes={{ onBlur }}
                          onValueChange={(LOCATION_FLAG) => {
                            onChange(LOCATION_FLAG?.value);
                            setSelectedLocationFlag(LOCATION_FLAG?.value || '', (locationFlag) => {
                              updateProductDataOnLocationTypeChange('', locationFlag);
                              revalidateAttributeField(LOCATION_FIELD.LOCATION_FLAG);
                            });
                          }}
                        />
                      )}
                    </>
                  )}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.LOCATION_FLAG
                      )
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
        </View>

        {(getValues()['products'] || []).map((_product, index) => {
          return (
            <View className={[styles['form__field-row']]} direction="row" gap={4}>
              <View.Item columns={{ s: 12, l: 3 }}>
                <View gap={2}>
                  <FormLabel
                    text={t('LocationDetails.Sku.Label')}
                    isRequired={
                      selectedLocationType === LOCATION_TYPE.SLOT &&
                      selectedLocationFlag === LOCATION_FLAG_DEDICATED
                    }
                  />
                  <FormControl
                    hasError={
                      !!(
                        (errors.products && errors.products?.[index]?.productId) ||
                        (isInvalidSku && productSku)
                      )
                    }
                  >
                    <TextField
                      attributes={{
                        'data-testid': 'sku',
                      }}
                      placeholder={t('LocationDetails.Sku.Placeholder')}
                      defaultValue=""
                      inputAttributes={{
                        placeholder: t('LocationDetails.Sku.Placeholder'),
                        ...register(`products.${index}.productId`),
                        maxLength: MAX_LENGTH_FIELD.LOCATION_SKU,
                      }}
                      disabled={
                        selectedLocationType || selectedLocationFlag
                          ? !(
                              selectedLocationType === LOCATION_TYPE.SLOT &&
                              selectedLocationFlag === LOCATION_FLAG_DEDICATED
                            )
                          : false
                      }
                    />

                    {isInvalidSku && productSku && (
                      <FormControl.Error>{t('ValidationError.Location.SKU')}</FormControl.Error>
                    )}
                    <FormControl.Error>
                      {errors.products && errors.products?.[index]?.productId?.message}
                    </FormControl.Error>
                  </FormControl>
                </View>
              </View.Item>
              <View.Item columns={{ s: 12, l: 3 }}>
                <View gap={2}>
                  <FormLabel
                    text={t('LocationDetails.Minimum.Label')}
                    isRequired={selectedLocationType !== LOCATION_TYPE.RESERVE}
                  />
                  <FormControl hasError={!!(errors.products && errors.products?.[index]?.min)}>
                    <TextField
                      attributes={{
                        'data-testid': 'minimum',
                      }}
                      defaultValue=""
                      disabled={
                        (getValues()['attributes']
                          ? getValues()['attributes']?.[
                              getFormFieldIndexByKey(
                                getValues()['attributes'],
                                LOCATION_FIELD.LOCATION_FLAG
                              )
                            ]['value'] === LOCATION_FLAG_DYNAMIC
                          : '') ||
                        disabledMinMaxAttribute ||
                        selectedLocationType === LOCATION_TYPE.RESERVE
                      }
                      inputAttributes={{
                        placeholder: t('LocationDetails.Minimum.Placeholder'),
                        ...register(`products.${index}.min`),
                        maxLength: MAX_LENGTH_FIELD.LOCATION_MINIMUM,
                      }}
                    />
                    <FormControl.Error>
                      {errors.products && errors.products?.[index]?.min?.message}
                    </FormControl.Error>
                  </FormControl>
                </View>
              </View.Item>

              <View.Item columns={{ s: 12, l: 3 }}>
                <View gap={2}>
                  <FormLabel
                    text={t('LocationDetails.Maximum.Label')}
                    isRequired={selectedLocationType !== LOCATION_TYPE.RESERVE}
                  />
                  <FormControl hasError={!!(errors.products && errors.products?.[index]?.max)}>
                    <TextField
                      attributes={{
                        'data-testid': 'maximum',
                      }}
                      defaultValue=""
                      disabled={
                        (getValues()['attributes']
                          ? getValues()['attributes']?.[
                              getFormFieldIndexByKey(
                                getValues()['attributes'],
                                LOCATION_FIELD.LOCATION_FLAG
                              )
                            ]['value'] === LOCATION_FLAG_DYNAMIC
                          : '') ||
                        disabledMinMaxAttribute ||
                        selectedLocationType === LOCATION_TYPE.RESERVE
                      }
                      inputAttributes={{
                        placeholder: t('LocationDetails.Maximum.Placeholder'),
                        ...register(`products.${index}.max`),
                        maxLength: MAX_LENGTH_FIELD.LOCATION_MAXIMUM,
                      }}
                    />
                    <FormControl.Error>
                      {errors.products && errors.products?.[index]?.max?.message}
                    </FormControl.Error>
                  </FormControl>
                </View>
              </View.Item>
            </View>
          );
        })}

        <View className={[styles['form__field-row']]} direction="row" gap={4}>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.QuantityUnits.Label')} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.QUANTITY_UNITS
                      )
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'quantity-units',
                  }}
                  defaultValue=""
                  disabled={true}
                  inputAttributes={{
                    placeholder: t('LocationDetails.QuantityUnits.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.QUANTITY_UNITS
                      )}.value`
                    ),
                  }}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.QUANTITY_UNITS
                      )
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>

          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.QuantityReserved.Label')} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.QUANTITY_RESERVED
                      )
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'quantity-reserved',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.QuantityUnits.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.QUANTITY_RESERVED
                      )}.value`
                    ),
                    onChange: (e) => {
                      setValue(
                        `attributes.${getFormFieldIndexByKey(
                          getValues()['attributes'],
                          LOCATION_FIELD.QUANTITY_RESERVED
                        )}.value`,
                        e.target.value
                      );
                      revalidateAttributeField(LOCATION_FIELD.QUANTITY_RESERVED);
                    },
                  }}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.QUANTITY_RESERVED
                      )
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>

          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.CheckDigit.Label')} isRequired={true} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.CHECK_DIGIT)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'check-digit',
                  }}
                  placeholder={t('LocationDetails.CheckDigit.Placeholder')}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.CheckDigit.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.CHECK_DIGIT
                      )}.value`
                    ),
                    onChange: (e) => {
                      setValue(
                        `attributes.${getFormFieldIndexByKey(
                          getValues()['attributes'],
                          LOCATION_FIELD.CHECK_DIGIT
                        )}.value`,
                        e.target.value
                      );
                      revalidateAttributeField(LOCATION_FIELD.CHECK_DIGIT);
                    },
                    maxLength: MAX_LENGTH_FIELD.CHECK_DIGIT,
                  }}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.CHECK_DIGIT)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
        </View>

        <View className={[styles['form__field-row']]} direction="row" gap={4}>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.Length.Label')} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.LENGTH)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'length',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.Length.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.LENGTH
                      )}.value`
                    ),
                    onChange: (e) => {
                      setValue(
                        `attributes.${getFormFieldIndexByKey(
                          getValues()['attributes'],
                          LOCATION_FIELD.LENGTH
                        )}.value`,
                        e.target.value
                      );
                      revalidateAttributeField(LOCATION_FIELD.LENGTH);
                    },
                    maxLength: MAX_LENGTH_FIELD.LOCATION_LENGTH,
                  }}
                  endElement={<Text color="400">{DEFAULT_DIMENSION_UNIT}</Text>}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.LENGTH)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.Width.Label')} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.WIDTH)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'width',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.Width.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.WIDTH
                      )}.value`
                    ),
                    onChange: (e) => {
                      setValue(
                        `attributes.${getFormFieldIndexByKey(
                          getValues()['attributes'],
                          LOCATION_FIELD.WIDTH
                        )}.value`,
                        e.target.value
                      );
                      revalidateAttributeField(LOCATION_FIELD.WIDTH);
                    },
                    maxLength: MAX_LENGTH_FIELD.LOCATION_WIDTH,
                  }}
                  endElement={<Text color="400">{DEFAULT_DIMENSION_UNIT}</Text>}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.WIDTH)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.Height.Label')} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.HEIGHT)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'height',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.Height.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.HEIGHT
                      )}.value`
                    ),
                    onChange: (e) => {
                      setValue(
                        `attributes.${getFormFieldIndexByKey(
                          getValues()['attributes'],
                          LOCATION_FIELD.HEIGHT
                        )}.value`,
                        e.target.value
                      );
                      revalidateAttributeField(LOCATION_FIELD.HEIGHT);
                    },
                    maxLength: MAX_LENGTH_FIELD.LOCATION_HEIGHT,
                  }}
                  endElement={<Text color="400">{DEFAULT_DIMENSION_UNIT}</Text>}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.HEIGHT)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.Weight.Label')} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.WEIGHT)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'weight',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.Weight.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.WEIGHT
                      )}.value`
                    ),
                    onChange: (e) => {
                      setValue(
                        `attributes.${getFormFieldIndexByKey(
                          getValues()['attributes'],
                          LOCATION_FIELD.WEIGHT
                        )}.value`,
                        e.target.value
                      );
                      revalidateAttributeField(LOCATION_FIELD.WEIGHT);
                    },
                    maxLength: MAX_LENGTH_FIELD.LOCATION_WEIGHT,
                  }}
                  endElement={<Text color="400">{DEFAULT_WEIGHT_UNIT}</Text>}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.WEIGHT)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
        </View>

        <View className={[styles['form__field-row']]} direction="row" gap={4}>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.SlotFronts.Label')} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.SLOT_FRONTS)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'slot-fronts',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.SlotFronts.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.SLOT_FRONTS
                      )}.value`
                    ),
                    maxLength: MAX_LENGTH_FIELD.LOCATION_SLOT_FRONT,
                  }}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.SLOT_FRONTS)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.SlotLayers.Label')} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.SLOT_LAYERS)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'slot-layers',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.SlotLayers.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        'SLOT_LAYERS'
                      )}.value`
                    ),
                    maxLength: MAX_LENGTH_FIELD.LOCATION_SLOT_LAYER,
                  }}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.SLOT_LAYERS)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('LocationDetails.SlotDeeps.Label')} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.SLOT_DEEPS)
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'slot-deeps',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    placeholder: t('LocationDetails.SlotDeeps.Placeholder'),
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.SLOT_DEEPS
                      )}.value`
                    ),
                    maxLength: MAX_LENGTH_FIELD.LOCATION_SLOT_DEEP,
                  }}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.SLOT_DEEPS)
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>

          <View.Item columns={{ s: 12, l: 4 }}>
            <View
              attributes={{
                'data-testid': 'location-lock',
              }}
              gap={2}
            >
              <FormLabel text={`${t('LocationDetails.LocationLock.Label')} (${t('Optional')})`} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.LOCATION_LOCK
                      )
                    )
                  )
                }
              >
                <Controller
                  control={control}
                  name={`attributes.${getFormFieldIndexByKey(
                    getValues()['attributes'],
                    LOCATION_FIELD.LOCATION_LOCK
                  )}.value`}
                  render={({ field: { onChange, value } }) => (
                    <View direction="row" gap={4}>
                      <Radio
                        value="true"
                        label={t('LocationDetails.Locked')}
                        checked={value === 'true'}
                        onChange={(isMultiplePartsAllowedWithinLocation) =>
                          onChange(isMultiplePartsAllowedWithinLocation)
                        }
                      />
                      <Radio
                        value="false"
                        label={t('LocationDetails.Unlocked')}
                        checked={value === 'false'}
                        onChange={(isMultiplePartsAllowedWithinLocation) =>
                          onChange(isMultiplePartsAllowedWithinLocation)
                        }
                      />

                      <View>
                        <Link onClick={() => onChange('false')}>{t('Clear')}</Link>
                      </View>
                    </View>
                  )}
                />

                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        LOCATION_FIELD.LOCATION_LOCK
                      )
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
        </View>

        <View
          attributes={{
            'data-testid': 'full-pallet',
          }}
          className={styles['form__field-row']}
        >
          <FormControl
            hasError={
              !!(
                errors.attributes &&
                errors.attributes[
                  getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.FULL_PALLET)
                ]
              )
            }
          >
            <Controller
              control={control}
              name={`attributes.${getFormFieldIndexByKey(
                getValues()['attributes'],
                LOCATION_FIELD.FULL_PALLET
              )}.value`}
              render={({ field: { onChange, value, ref } }) => (
                <Checkbox
                  name={`attributes.${getFormFieldIndexByKey(
                    getValues()['attributes'],
                    LOCATION_FIELD.FULL_PALLET
                  )}.value`}
                  value={value}
                  checked={value && value.toString() === 'true'}
                  inputAttributes={{ ref }}
                  label={t('LocationDetails.FullPallet.Label')}
                  onChange={(event) => onChange(event)}
                />
              )}
            />
            {errors.attributes &&
              errors.attributes[
                getFormFieldIndexByKey(getValues()['attributes'], LOCATION_FIELD.FULL_PALLET)
              ] && (
                <View direction="row" justify="space-between">
                  <FormControl.Error>
                    {
                      errors.attributes[
                        getFormFieldIndexByKey(
                          getValues()['attributes'],
                          LOCATION_FIELD.FULL_PALLET
                        )
                      ]?.message
                    }
                  </FormControl.Error>
                </View>
              )}
          </FormControl>
        </View>
      </form>
    </View>
  );
};
