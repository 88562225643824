/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { View, Text } from '@az/starc-ui';
import { t } from 'i18next';

import { TOP_VARIANCE_CATEGORY_SECTION } from '@inventory/constants/constants';
import { usePreparedFilterOptions } from '@inventory/hooks/usePreparedFilterOptions';
import { checkCountTypeCd } from '@inventory/utils/utils';

import { TopVariancesSection } from '@inventory/pages/CycleCounts/components/TopVariancesSection/TopVariancesSection';
import {
  CycleCountFilter,
  FilterOption,
} from '@inventory/pages/CycleCounts/components/CycleCountFilter';

import { CombinedPillTabs } from '@shared/components/CombinedPillTabs/CombinedPillTabs';

import { MODAL_TABLE_PAGE_SIZE, SORT_BY } from '@shared/constants/constants';
import { DEFAULT_PAGE, DESC } from '@inbound/constants/constants';

import * as T from '../CycleCountDetailsOverview.types';
import styles from '../CycleCountDetailsOverview.module.scss';

export const TopVariancesCard = ({ isRBCCFlow = false }: T.Props) => {
  const { countTypeCd } = useParams();
  const countTypeCdvalue = checkCountTypeCd(countTypeCd || '');
  const { defaultFilter, defaultOptions } = usePreparedFilterOptions({
    countTypeCd: countTypeCdvalue,
  });

  const [selectedFilter, setSelectedFilter] = useState<FilterOption>(defaultFilter);

  const commonApiPayload = {
    searchPage: {
      page: DEFAULT_PAGE,
      size: MODAL_TABLE_PAGE_SIZE,
      sort: [{ sortBy: SORT_BY.VALUE, direction: DESC }],
    },
    searchCriteria: {
      countTypeCd: countTypeCdvalue,
    },
  };

  const varianceSections = [
    {
      title: t('TopVariances.BySKUs'),
      dollarPayload: {
        payload: {
          ...commonApiPayload,
          searchCriteria: {
            ...commonApiPayload.searchCriteria,
            type: TOP_VARIANCE_CATEGORY_SECTION.DOLLAR_VARIANCE_BY_SKU,
            fromTs: selectedFilter.fromTs,
            toTs: selectedFilter.toTs,
          },
        },
      },
      piecePayload: {
        payload: {
          ...commonApiPayload,
          searchCriteria: {
            ...commonApiPayload.searchCriteria,
            type: TOP_VARIANCE_CATEGORY_SECTION.PIECE_ADJUSTMENTS_BY_SKU,
            fromTs: selectedFilter.fromTs,
            toTs: selectedFilter.toTs,
          },
        },
      },
    },
    {
      title: t('TopVariances.BySubZone'),
      dollarPayload: {
        payload: {
          ...commonApiPayload,
          searchCriteria: {
            ...commonApiPayload.searchCriteria,
            type: TOP_VARIANCE_CATEGORY_SECTION.DOLLAR_VARIANCE_BY_SUBZONE,
            fromTs: selectedFilter.fromTs,
            toTs: selectedFilter.toTs,
          },
        },
      },
      piecePayload: {
        payload: {
          ...commonApiPayload,
          searchCriteria: {
            ...commonApiPayload.searchCriteria,
            type: TOP_VARIANCE_CATEGORY_SECTION.PIECE_ADJUSTMENTS_BY_SUBZONE,
            fromTs: selectedFilter.fromTs,
            toTs: selectedFilter.toTs,
          },
        },
      },
    },
    {
      title: t('TopVariances.ByProductCategory'),
      dollarPayload: {
        payload: {
          ...commonApiPayload,
          searchCriteria: {
            ...commonApiPayload.searchCriteria,
            type: TOP_VARIANCE_CATEGORY_SECTION.DOLLAR_VARIANCE_BY_PRODUCT_CATEGORY,
            fromTs: selectedFilter.fromTs,
            toTs: selectedFilter.toTs,
          },
        },
      },
      piecePayload: {
        payload: {
          ...commonApiPayload,
          searchCriteria: {
            ...commonApiPayload.searchCriteria,
            type: TOP_VARIANCE_CATEGORY_SECTION.PIECE_ADJUSTMENTS_BY_PRODUCT_CATEGORY,
            fromTs: selectedFilter.fromTs,
            toTs: selectedFilter.toTs,
          },
        },
      },
    },
    {
      title: t('TopVariances.ByVendor'),
      dollarPayload: {
        payload: {
          ...commonApiPayload,
          searchCriteria: {
            ...commonApiPayload.searchCriteria,
            type: TOP_VARIANCE_CATEGORY_SECTION.DOLLAR_VARIANCE_BY_VENDOR,
            fromTs: selectedFilter.fromTs,
            toTs: selectedFilter.toTs,
          },
        },
      },
      piecePayload: {
        payload: {
          ...commonApiPayload,
          searchCriteria: {
            ...commonApiPayload.searchCriteria,
            type: TOP_VARIANCE_CATEGORY_SECTION.PIECE_ADJUSTMENTS_BY_VENDOR,
            fromTs: selectedFilter.fromTs,
            toTs: selectedFilter.toTs,
          },
        },
      },
    },
  ];

  return (
    <View direction="row">
      <View width="100%" gap={4} className={styles['cycle-count-card']} padding={2}>
        <View gap={4}>
          <View.Item>
            <Text weight="bold" size="125">
              {t(`TopVariances.TitlePlural`)}
            </Text>
          </View.Item>
          <View.Item>
            <View gap={4}>
              <View.Item>
                {isRBCCFlow ? (
                  <CombinedPillTabs<FilterOption>
                    TabsFilterData={defaultOptions}
                    onTabSelect={setSelectedFilter}
                    selectedValue={selectedFilter}
                  />
                ) : (
                  <CycleCountFilter
                    countTypeCd={countTypeCdvalue}
                    selectedFilter={selectedFilter}
                    setSelectedFilter={setSelectedFilter}
                  />
                )}
              </View.Item>
              <View direction="row" width="100%">
                {varianceSections.map((section, index) => (
                  <View
                    direction="column"
                    key={index}
                    width="24%"
                    gap={4}
                    attributes={{ style: { marginRight: 16 } }}
                  >
                    <Text weight="bold" size="100">
                      {section.title}
                    </Text>
                    <View direction="column">
                      <TopVariancesSection
                        dollarPayload={section.dollarPayload}
                        piecePayload={section.piecePayload}
                        selectedFilter={selectedFilter}
                        isRBCCFlow={isRBCCFlow}
                      />
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View.Item>
        </View>
      </View>
    </View>
  );
};
