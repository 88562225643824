/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Dropdown, View, Text, SearchBar, Actionable } from '@az/starc-ui';

import { KEY } from '@shared/constants/keyConstants';
import { Avatar } from '@shared/components/Avatar/Avatar';
import { UserSearchSchemaType } from '@shared/types/schema.type';

import * as T from './AssigneeSelect.types';
import styles from './AssigneeSelect.module.scss';
import { useUsersSearch } from '@shared/services/hooks/useUsersSearch';
import { DEPARTMENTS, FIRST_NAME, MAX_PAGE_SIZE } from '@shared/constants/constants';
import { ASC } from '@inbound/constants/constants';
import { DetailsTableSkeleton } from '@shared/components/Skeletons/DetailsTableSkeleton';

export const AssigneeSelect = ({
  label,
  selectedAssignee,
  setSelectedAssignee,
  onSelect,
}: T.Props) => {
  /* State variables */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const [value, setValue] = useState<string>(
    selectedAssignee ? `${selectedAssignee?.firstName} ${selectedAssignee?.lastName}` : ''
  );
  const [usersList, setUsersList] = useState<UserSearchSchemaType[]>([]);

  /* Constants */
  const { t } = useTranslation();
  const maxRecommendedAssignee = 5;

  /* Queries */
  const { refetch: getUserList, isLoading: isUserLoading } = useUsersSearch({
    searchPage: {
      page: 0,
      size: MAX_PAGE_SIZE,
      sortBy: FIRST_NAME,
      direction: ASC,
    },
    searchCriteria: {
      deptId: DEPARTMENTS.INBOUND,
    },
  });

  /* Functions */
  const toggleDropdown = (
    event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const onAssigneeSelect = (assignee: UserSearchSchemaType) => {
    setIsOpen(false);
    onSelect(assignee);
    setValue(`${assignee?.firstName} ${assignee?.lastName}`);
  };

  const onValueChange = (value: string) => {
    setValue(value);
    setSearchValue(value);
    setIsOpen(false);

    setTimeout(() => {
      setIsOpen(true);
    }, 1);
  };

  const isSuggested = (userId: string) => {
    return usersList?.slice(0, maxRecommendedAssignee).find((item) => item.userId === userId);
  };

  const filteredOptions = useMemo(() => {
    return usersList.filter((option) =>
      `${option.firstName} ${option.lastName}`
        .toLocaleLowerCase()
        .includes(searchValue.toLocaleLowerCase())
    );
  }, [searchValue, usersList]);

  /* Hooks */
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      const target: Node = ((event as MouseEvent).target ||
        (event as TouchEvent).targetTouches[0]) as Node;
      if (
        dropdownRef.current &&
        buttonRef.current &&
        !dropdownRef.current.contains(target) &&
        !buttonRef.current.contains(target) &&
        isOpen
      ) {
        setIsOpen(false);
      }
    };

    const handleScroll = (event: Event) => {
      const target: Node = event.target as Node;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    document.addEventListener('scroll', handleScroll, true);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === KEY.ESCAPE && isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  useEffect(() => {
    const loadUsers = async () => {
      const users = await getUserList();
      if (users?.data) {
        setUsersList(users.data.content);
      }
    };
    if (usersList.length <= 0) {
      loadUsers();
    }
  }, [getUserList, usersList.length]);

  return (
    <View direction="column" gap={1}>
      <View.Item>
        <Text weight="bold" size="087">
          {t('ReplenishmentDashboard.AddReplenishment.Assignee.Label')}
        </Text>
      </View.Item>
      <Dropdown open={isOpen} contentGap={0} className={styles['dropdown-select-menu']}>
        <View attributes={{ ref: buttonRef }}>
          <Dropdown.Button
            label={label}
            onClick={toggleDropdown}
            className={styles['dropdown-select-menu__button']}
          >
            <View direction="row" wrap={false} align="center">
              {selectedAssignee && value && (
                <Avatar
                  name={`${selectedAssignee?.firstName} ${selectedAssignee?.lastName}`}
                  size="small"
                />
              )}

              <SearchBar
                className={styles['dropdown-select-menu__search-bar']}
                suggestions={[]}
                label={t('ReplenishmentDashboard.AddReplenishment.Assignee.Placeholder')}
                variant="pro"
                value={value}
                onValueChange={onValueChange}
                onValueClear={() => setSelectedAssignee(null)}
              />
            </View>
          </Dropdown.Button>
        </View>
        <Dropdown.Content>
          <View
            attributes={{
              ref: dropdownRef,
            }}
            gap={1}
            padding={[2, 0]}
          >
            <View direction="column" className={styles['dropdown-select-menu__scrollable-section']}>
              {isUserLoading ? (
                <DetailsTableSkeleton />
              ) : filteredOptions.length === 0 ? (
                <Text size="087" weight="medium" variant="text-link" align="center">
                  {t('PODashboard.NoMatch')}
                </Text>
              ) : (
                filteredOptions.map((user) => (
                  <Actionable
                    className={styles['dropdown-select-menu__scrollable-section__option']}
                    onClick={() => onAssigneeSelect(user)}
                  >
                    <View direction="row" justify="center" align="center" padding={[3, 4]}>
                      <View.Item grow>
                        <Avatar
                          name={`${user.firstName} ${user.lastName}`}
                          size="large"
                          variant="assignee"
                          showText={true}
                        />
                      </View.Item>

                      {isSuggested(user.userId) && (
                        <View.Item>
                          <View
                            direction="row"
                            justify="center"
                            align="center"
                            min-height="var(--st-unit-6)"
                            width="fit-content"
                            borderRadius="small"
                            className={
                              styles[`dropdown-select-menu__scrollable-section__option__badge`]
                            }
                          >
                            <Text
                              size="075"
                              weight="bold"
                              className={styles[`assignees-suggestion__item__badge--text`]}
                            >
                              {t('PODashboard.Assignees.Suggested')}
                            </Text>
                          </View>
                        </View.Item>
                      )}
                    </View>
                  </Actionable>
                ))
              )}
            </View>
          </View>
        </Dropdown.Content>
      </Dropdown>
    </View>
  );
};
