/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export enum CreateCountType {
  WALL_TO_WALL_COUNT = 'WALL_TO_WALL',
  RISK_BASED_COUNT = 'RISK_BASED',
  INVENTORY_COUNT = 'INVENTORY',
  CUSTOM_COUNT = 'CUSTOM',
}

type CreateDropdownContent = {
  label: string;
  type: CreateCountType;
  disabled: boolean;
};

export const CreateDropdownContent: CreateDropdownContent[] = [
  {
    label: 'CycleCount.CreateCountTypes.WallToWallCount',
    type: CreateCountType.WALL_TO_WALL_COUNT,
    disabled: false,
  },
  {
    label: 'CycleCount.CreateCountTypes.RiskBasedCount',
    type: CreateCountType.RISK_BASED_COUNT,
    disabled: false,
  },
  {
    label: 'CycleCount.CreateCountTypes.InventoryCount',
    type: CreateCountType.INVENTORY_COUNT,
    disabled: false,
  },
  {
    label: 'CycleCount.CreateCountTypes.CustomCount',
    type: CreateCountType.CUSTOM_COUNT,
    disabled: false,
  },
];

export type localStorageDataType = {
  countTypeCd: string;
  statusCd: string;
  taskId: string;
};
