/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Actionable,
  View,
  Text,
  DatePicker,
  SearchBar,
  Button,
  SingleValue,
  Select,
} from '@az/starc-ui';

import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { Table } from '@shared/components/Table/Table';
import { TableStylingVariants } from '@shared/components/Table/tableConstants';
import { DEFAULT_PAGE, PAGE_SIZE } from '@shared/constants/constants';

import styles from './InventoryHistory.module.scss';
import { generateDateString } from '@ofm/utils/utils';
import {
  INVENTORY_HISTORY_EVENT_TABLE_COLUMNS,
  INVENTORY_HISTORY_TABLE_COLUMNS,
} from '@inventory/constants/tableConstants';
import { useGetEventLogs } from '@shared/services/hooks/useGetEventLogs';
import {
  mapInventoryEventLogTableRows,
  mapInventoryHistory,
  mapInventoryHistoryTableRows,
} from '@inventory/utils/table/tableUtils';
import { EventLogListType, EventLogType } from '@shared/types/service.type';
import { EmptyState } from '@shared/components/EmptyState/EmptyState';
import { Box } from '@az/starc-ui-icons';
import { EVENT_TYPE_CDS } from '@inventory/constants/constants';
import { padWithZeros } from '@shared/utils/commonUtils';

export const InventoryHistory = () => {
  /* Constants */
  const dateFormat = 'MM/DD/YYYY';
  const dateFormatWithTime = 'MM/DD/YYYY HH:mm';
  const defaultStartDate = generateDateString(
    new Date(new Date().setHours(new Date().getHours() - 24)),
    dateFormat
  );
  const defaultEndDate = generateDateString(new Date(), dateFormat);

  /* Constants */
  const { t } = useTranslation();
  const dateBefore25Year = generateDateString(
    new Date(new Date().setFullYear(new Date().getFullYear() - 25)),
    'MM/DD/YYYY 00:00'
  );

  /* State variables */
  const [inventoryData, setInventoryData] = useState<EventLogListType>([]);
  const [, setSortBy] = useState<string>('');
  const [, setDirection] = useState<string>('ASC');
  const [searchTrigger, setSearchTrigger] = useState(true);
  const [productSearch, setProductSearch] = useState('');
  const [productSearchValue, setProductSearchValue] = useState('');
  const [layoutNameSearch, setLayoutNameSearch] = useState('');
  const [layoutNameSearchValue, setLayoutNameSearchValue] = useState('');
  const [eventTypeCd, setEventTypeCd] = useState('');
  const [eventTypeCdValue, setEventTypeCdValue] = useState<SingleValue>({
    value: EVENT_TYPE_CDS[0].value,
    label: t(EVENT_TYPE_CDS[0].label),
  });
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [startDateError, setStartDateError] = useState<string>('');
  const [endDateError, setEndDateError] = useState<string>('');
  const [startDateFormatted, setStartDateFormatted] = useState('');
  const [endDateFormatted, setEndDateFormatted] = useState('');

  /* Queries */
  const { eventLogsData, isLoading: isFetchingLogsData } = useGetEventLogs(
    {
      layoutNames: layoutNameSearch ? [layoutNameSearch] : [],
      productIds: productSearch ? [padWithZeros(productSearch, 16)] : [],
      eventTypeCds: eventTypeCd ? [eventTypeCd] : [],
      startTime: startDateFormatted,
      endTime: endDateFormatted,
    },
    searchTrigger
  );

  /* Functions */
  const onStartDateChange = useCallback(
    (value: string) => {
      const previusDate = new Date(dateBefore25Year);
      const startDateValue = new Date(value + ' 00:00');
      const currentDate = new Date();
      if (startDateValue >= currentDate) {
        setStartDateError(t('PODashboard.POHistory.ErrorMessage.StartDateError'));
        return;
      } else if (startDateValue < previusDate) {
        setStartDateError(t('PODashboard.POHistory.ErrorMessage.InvalidStartDate'));
        return;
      } else {
        setStartDateError('');

        if (new Date(endDate) < startDateValue) {
          setEndDate(value);
        }

        setStartDate(generateDateString(startDateValue, dateFormatWithTime));
      }

      setStartDate(value);
    },
    [dateBefore25Year, endDate, t]
  );

  const compareAndSetEndDate = useCallback((currentDate: Date, endDateValue: Date) => {
    if (
      generateDateString(currentDate, 'MM/DD/YYYY 00:00') ===
      generateDateString(endDateValue, 'MM/DD/YYYY 00:00')
    ) {
      setEndDate(generateDateString(new Date(), dateFormatWithTime));
    } else {
      setEndDate(generateDateString(endDateValue, dateFormatWithTime));
    }
  }, []);

  const onEndDateChange = useCallback(
    (value: string) => {
      const currentDate = new Date();
      currentDate.setHours(23, 59, 59, 999);
      const endDateValue = new Date(value);
      const startDateValue = new Date(startDate);

      if (endDateValue >= currentDate) {
        setEndDateError(t('PODashboard.POHistory.ErrorMessage.EndDateError'));
        return;
      } else if (endDateValue < startDateValue) {
        setEndDateError(t('PODashboard.POHistory.ErrorMessage.EndDateErrorMsg'));
        return;
      } else {
        setEndDateError('');

        compareAndSetEndDate(currentDate, endDateValue);
      }

      setEndDate(value);
    },
    [compareAndSetEndDate, startDate, t]
  );

  const onEventTypeChange = useCallback((value: SingleValue) => {
    setEventTypeCdValue(value);
  }, []);

  const onSearchTriggger = useCallback(() => {
    const endDateValue = new Date(endDate);
    endDateValue.setHours(23, 59, 59, 999);
    setProductSearch(productSearchValue);
    setLayoutNameSearch(layoutNameSearchValue);
    setEventTypeCd(eventTypeCdValue?.value || '');
    setStartDateFormatted(new Date(startDate).toISOString());
    setEndDateFormatted(endDateValue.toISOString());
    setSearchTrigger(true);
  }, [endDate, eventTypeCdValue?.value, layoutNameSearchValue, productSearchValue, startDate]);

  const onClearAll = useCallback(() => {
    setProductSearch('');
    setProductSearchValue('');
    setLayoutNameSearch('');
    setLayoutNameSearchValue('');
    setEventTypeCd('');
    setEventTypeCdValue({
      value: EVENT_TYPE_CDS[0].value,
      label: t(EVENT_TYPE_CDS[0].label),
    });

    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    setStartDateFormatted(new Date(defaultStartDate).toISOString());
    setEndDateFormatted(new Date(defaultEndDate).toISOString());
    setStartDateError('');
    setEndDateError('');

    setSearchTrigger(true);
  }, [defaultEndDate, defaultStartDate, t]);

  /*  Hooks */
  useEffect(() => {
    if (eventLogsData) {
      setInventoryData(mapInventoryHistory(eventLogsData));
    } else {
      setInventoryData([]);
    }

    setSearchTrigger(false);
  }, [eventLogsData]);

  useEffect(() => {
    onSearchTriggger();
  }, []); // Please don't resolve dependancy, it's used to trigger the search

  return (
    <View direction="column" height="100%" className={styles['inventory-history']}>
      <MasterTitle title={t('InventoryHistoryDashboard.InventoryHistory')} />

      <View
        direction="row"
        padding={[4, 6]}
        backgroundColor="secondary"
        className={styles['inventory-history__filters-section']}
      >
        <View.Item>
          <View width="305px" className={styles['inventory-history__filters-section__searchbar']}>
            <SearchBar
              value={productSearchValue}
              onValueChange={setProductSearchValue}
              suggestions={[]}
              className={styles['search-bar']}
              label={t('InventoryHistoryDashboard.Search.ProductId')}
              onValueClear={() => setProductSearchValue('')}
              maxMenuHeight={300}
            />
          </View>
        </View.Item>
        <View.Item>
          <View width="250px" className={styles['inventory-history__filters-section__searchbar']}>
            <SearchBar
              value={layoutNameSearchValue}
              onValueChange={setLayoutNameSearchValue}
              suggestions={[]}
              className={styles['search-bar']}
              label={t('InventoryHistoryDashboard.Search.LayoutName')}
              onValueClear={() => setLayoutNameSearchValue('')}
              maxMenuHeight={300}
            />
          </View>
        </View.Item>
        <View.Item>
          <View width="240px">
            <Select
              label=""
              value={eventTypeCdValue}
              onValueChange={(eventType) => onEventTypeChange(eventType)}
              options={EVENT_TYPE_CDS.map((eventType) => ({
                label: t(eventType.label),
                value: eventType.value,
              }))}
            />
          </View>
        </View.Item>
        <View.Item>
          <View className={styles['inventory-history__filters-section__date-picker']}>
            <DatePicker
              id="startDate"
              value={startDate}
              label={t('PODashboard.POHistory.StartDate')}
              dateFormat="MM/DD/YYYY"
              dateRange={[dateBefore25Year, generateDateString(new Date(), dateFormatWithTime)]}
              yearRange={50}
              size="small"
              required
              onValueChange={(value) => {
                onStartDateChange(value);
              }}
            />
            {startDateError && (
              <View direction="row" justify="space-between">
                <Text size="087" color="error">
                  {startDateError}
                </Text>
              </View>
            )}
          </View>
        </View.Item>
        <View.Item>
          <View className={styles['inventory-history__filters-section__date-picker']}>
            <DatePicker
              id="endDate"
              value={endDate}
              label={t('PODashboard.POHistory.EndDate')}
              dateRange={[startDate, generateDateString(new Date(), dateFormatWithTime)]}
              yearRange={50}
              size="small"
              dateFormat="MM/DD/YYYY"
              required
              onValueChange={(value) => {
                onEndDateChange(value);
              }}
            />
            {endDateError && (
              <View direction="row" justify="space-between">
                <Text size="087" color="error">
                  {endDateError}
                </Text>
              </View>
            )}
          </View>
        </View.Item>
        <View.Item>
          <Button size="large" onClick={onSearchTriggger} loading={isFetchingLogsData}>
            {t('InventoryHistoryDashboard.Search.ButtonText')}
          </Button>
        </View.Item>
        <View.Item
          attributes={{
            style: { height: '100%' },
          }}
        >
          <View height="100%" justify="center">
            <Actionable onClick={onClearAll}>
              <Text
                className={styles['inventory-history__actionable-text']}
                size="087"
                weight="medium"
                variant="text-link"
              >
                {t('PODashboard.ClearAll')}
              </Text>
            </Actionable>
          </View>
        </View.Item>
      </View>

      <View padding={[6, 6]}>
        {!isFetchingLogsData && inventoryData && inventoryData.length === 0 && (
          <View
            justify="center"
            align="center"
            className={styles['po-discrepancy__empty']}
            height="60vh"
          >
            <View width="360px">
              <EmptyState
                svg={Box}
                subtitle={t('Empty.Search.Subtitle')}
                text={t('Empty.Search.Text', {
                  value: 'Event log',
                })}
              />
            </View>
          </View>
        )}

        {!isFetchingLogsData &&
          inventoryData.map((item: EventLogType, index: number) => (
            <View
              key={`${index}_${item.productId}_${item.layoutName}_${item.eventTypeCd}`}
              borderRadius="small"
              className={styles['inventory-history__content-item']}
            >
              <View padding={[2, 2]}>
                <Table
                  columns={INVENTORY_HISTORY_TABLE_COLUMNS}
                  rows={mapInventoryHistoryTableRows([item])}
                  isPaginated={false}
                  isCheckboxDisabled={false}
                  isApiLoadedData={true}
                  pageSize={PAGE_SIZE}
                  defaultPage={DEFAULT_PAGE}
                  isCreditItem={false}
                  isCheckboxTable={false}
                  isLoading={isFetchingLogsData}
                  styleVariant={TableStylingVariants.DETAILS}
                  totalPages={0}
                  onSort={(_sorting, _columnId) => {
                    _sorting.forEach((sorting) => {
                      if (sorting.id === _columnId) {
                        setSortBy(_columnId);
                        setDirection(sorting.direction);
                      }
                    });
                  }}
                />
              </View>
              {item.eventLogPits && item.eventLogPits.length > 0 && (
                <View padding={[2, 6, 2, 30]}>
                  <Table
                    columns={INVENTORY_HISTORY_EVENT_TABLE_COLUMNS}
                    rows={mapInventoryEventLogTableRows(
                      item.eventLogPits,
                      `${item.productId}-${item.layoutName}-${item.eventTypeCd}`
                    )}
                    isPaginated={false}
                    isCheckboxDisabled={false}
                    isApiLoadedData={true}
                    pageSize={PAGE_SIZE}
                    defaultPage={DEFAULT_PAGE}
                    isCreditItem={false}
                    isCheckboxTable={false}
                    isLoading={isFetchingLogsData}
                    styleVariant={TableStylingVariants.DETAILS}
                    totalPages={0}
                    onSort={(_sorting, _columnId) => {
                      _sorting.forEach((sorting) => {
                        if (sorting.id === _columnId) {
                          setSortBy(_columnId);
                          setDirection(sorting.direction);
                        }
                      });
                    }}
                  />
                </View>
              )}
            </View>
          ))}
      </View>
    </View>
  );
};
