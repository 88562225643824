/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState } from 'react';

import { Text, View, Checkbox, Icon, Actionable, Loader, Button } from '@az/starc-ui';

import { Accordion } from '@shared/components/Accordion/Accordion';

import { Props } from '@outbound/components/ReleaseGroupAccordion/ReleaseGroupAccordion.types.tsx';
import Styles from '@outbound/components/ReleaseGroupAccordion/ReleaseGroupAccordion.module.scss';
import { ChevronDown, ChevronUp } from '@az/starc-ui-icons';
import {
  RELEASE_GROUPS_GROUP_TYPE,
  RELEASE_GROUPS_ORDER_TYPE,
  RELEASE_ORDER_FIELD,
  RELEASE_ORDER_STATUS,
} from '@outbound/constants/constants.ts';
import { ActionMenu } from '@mdm/components/ActionMenu/ActionMenu.tsx';
import { useGetReleaseGroups } from '@outbound/services/hooks/useGetReleaseGroups.tsx';
import { DEFAULT_PAGE, MAX_PAGE_SIZE } from '@shared/constants/constants.ts';
import { getAttributeValue } from '@shared/utils/commonUtils.ts';
import { useTranslation } from 'react-i18next';
import { OrderReviewByDCAccordion } from '@outbound/components/OrderReview/OrderReviewByDCAccordion.tsx';
import { OrderReviewByAreaList } from '@outbound/components/OrderReview/OrderReviewByAreaList.tsx';
import { Download } from '@shared/assets/icons';
import { StatusBadge } from '@shared/components/StatusBadge/StatusBadge.tsx';
import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types.ts';

export const OrderReviewRootAccordion = ({
  releaseGroup,
  orderType = RELEASE_GROUPS_ORDER_TYPE.DIF_WORK_ORDER,
}: Props) => {
  /* State variables */
  const [selectedGroupType, setSelectedGroupType] = useState<string>('');

  /* variables */
  const { attributes, groupId, groupName } = releaseGroup;

  /* Constants */
  const { t } = useTranslation();
  const isTransferOrderTab = orderType === RELEASE_GROUPS_ORDER_TYPE.TRANSFER;
  const { releaseGroupsData, isFetching: isReleaseGroupLoading } = useGetReleaseGroups(
    {
      currentPage: DEFAULT_PAGE - 1,
      pageSize: MAX_PAGE_SIZE,
      orderType,
      parentReleaseGroupId: groupId,
      groupTypes: [selectedGroupType],
      statuses: [RELEASE_ORDER_STATUS.RELEASED],
    },
    !!selectedGroupType
  );

  /* State variables */
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const handleAccordionClick = () => {
    setIsAccordionOpen((isOpen) => !isOpen);
    setSelectedGroupType(
      isTransferOrderTab ? RELEASE_GROUPS_GROUP_TYPE.BY_ORDER : RELEASE_GROUPS_GROUP_TYPE.BY_DC
    );
  };

  return (
    <>
      <View
        className={[Styles['order-release-accordion'], Styles['order-release-accordion__root']]}
      >
        <Accordion
          open={isAccordionOpen}
          header={{
            label: (
              <View direction="row" align="center">
                <View
                  direction="row"
                  align="center"
                  className={Styles['order-release-accordion__left']}
                >
                  <View
                    direction="row"
                    align="center"
                    padding={[4, 22, 4, 0]}
                    className={Styles['order-release-accordion__left--label']}
                  >
                    <Text size="100" weight="bold">
                      {groupName}
                    </Text>
                  </View>
                  <View padding={[4, 10, 4, 2]}>
                    <Text size="100" weight="regular">
                      {isTransferOrderTab
                        ? t('OutboundMatrix.OrderRelease.OrderCount', {
                            count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.ORDER_COUNT),
                          })
                        : t('OutboundMatrix.OrderRelease.DCs', {
                            count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.DC_COUNT),
                          })}
                    </Text>
                  </View>
                  <View
                    padding={[4, 10, 4, 2]}
                    className={Styles['order-release-accordion__left--label__pieces']}
                  >
                    <Text size="100" weight="regular">
                      {t('OutboundMatrix.OrderRelease.Pallet', {
                        count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.PALLETS),
                      })}
                    </Text>
                  </View>
                  <View padding={[4, 10, 4, 2]}>
                    <Text size="100" weight="regular">
                      {t('OutboundMatrix.OrderRelease.Pieces', {
                        count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.PIECES),
                      })}
                    </Text>
                  </View>
                </View>
              </View>
            ),
            auxiliaryLabel: (
              <View
                direction="row"
                align="center"
                className={Styles['order-release-accordion__right']}
              >
                <View gap={2}>
                  <View direction="row" gap={2}>
                    <Checkbox
                      label={t('ViewByDC')}
                      name={RELEASE_GROUPS_GROUP_TYPE.BY_DC}
                      value={RELEASE_GROUPS_GROUP_TYPE.BY_DC}
                      checked={selectedGroupType === RELEASE_GROUPS_GROUP_TYPE.BY_DC}
                      onChange={(event) => {
                        setSelectedGroupType(
                          event.target.checked ? RELEASE_GROUPS_GROUP_TYPE.BY_DC : ''
                        );
                        setIsAccordionOpen(event.target.checked);
                      }}
                    />

                    <Checkbox
                      label={t('ViewByArea')}
                      name={RELEASE_GROUPS_GROUP_TYPE.BY_AREA}
                      value={RELEASE_GROUPS_GROUP_TYPE.BY_AREA}
                      checked={selectedGroupType === RELEASE_GROUPS_GROUP_TYPE.BY_AREA}
                      onChange={(event) => {
                        setSelectedGroupType(
                          event.target.checked ? RELEASE_GROUPS_GROUP_TYPE.BY_AREA : ''
                        );
                        setIsAccordionOpen(event.target.checked);
                      }}
                    />

                    <Button variant="secondary">
                      <View direction="row" align="center" justify="center" gap={2}>
                        <Text>{t('Download')}</Text>
                        <Icon svg={Download} />
                      </View>
                    </Button>
                  </View>
                </View>
                <View height="100%">
                  <StatusBadge
                    variant={StatusVariants.READY_FOR_ACTION}
                    text={RELEASE_ORDER_STATUS.RELEASED.replace(/_/g, ' ')}
                  />
                </View>
                <View height="100%">
                  <ActionMenu
                    menus={[
                      {
                        label: t('OutboundMatrix.MenuOptions.ReleaseToPaper'),
                        clickHandler: () => ({}),
                      },
                      {
                        label: t('OutboundMatrix.MenuOptions.CloseToPaper'),
                        clickHandler: () => ({}),
                      },
                    ]}
                  />
                </View>
                <Actionable onClick={handleAccordionClick}>
                  <Icon size={6} svg={isAccordionOpen ? ChevronUp : ChevronDown} />
                </Actionable>
              </View>
            ),
          }}
        >
          <>
            {isReleaseGroupLoading && <Loader color="primary" />}
            {selectedGroupType === RELEASE_GROUPS_GROUP_TYPE.BY_AREA && (
              <OrderReviewByAreaList
                releaseGroup={releaseGroup}
                orderType={orderType}
                rootGroupId={groupId}
                groupTypes={[selectedGroupType]}
              />
            )}
            {[RELEASE_GROUPS_GROUP_TYPE.BY_ORDER, RELEASE_GROUPS_GROUP_TYPE.BY_DC].includes(
              selectedGroupType
            ) &&
              (releaseGroupsData?.content || []).map((releaseGroup) => (
                <OrderReviewByDCAccordion
                  releaseGroup={releaseGroup}
                  rootGroupId={groupId}
                  orderType={orderType}
                />
              ))}
          </>
        </Accordion>
      </View>
    </>
  );
};
