/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Tabs, View } from '@az/starc-ui';

import { CycleCountTabsData } from './CycleCountTabs.constants';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './CycleCountTabs.module.scss';
import { PAGE_URLS } from '@shared/constants/routes';
import { useLocation, useNavigate } from 'react-router-dom';

export const CycleCountTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const rootPath = PAGE_URLS.CYCLE_COUNTS;
  const hasTabAfterRoot = new RegExp(`${rootPath}/.+`).test(location.pathname);

  const fetchDefaultTab = useCallback((): string => {
    if (!hasTabAfterRoot) {
      return CycleCountTabsData[1].label;
    }

    const currentTab = location.pathname.split('/').at(-1);
    return CycleCountTabsData.find((data) => data.value === currentTab)?.label ?? '';
  }, [hasTabAfterRoot, location.pathname]);

  const onTabsChange = useCallback(
    (value: string) => {
      navigate(`${rootPath}/${value}`);
    },
    [navigate, rootPath]
  );

  const DefaultTab = () => {
    return (
      <View backgroundColor="secondary">
        <Tabs
          zeroBorder
          onTabChange={onTabsChange}
          defaultTab={t(fetchDefaultTab())}
          className={styles['cycle-counts-tabs']}
          weight="bold"
        >
          {CycleCountTabsData.map((tab) => {
            return (
              <Tabs.Item value={tab.value} key={tab.value} label={t(tab.label)}>
                <></>
              </Tabs.Item>
            );
          })}
        </Tabs>
      </View>
    );
  };

  useEffect(() => {
    if (!hasTabAfterRoot) {
      navigate(`${location.pathname}/${CycleCountTabsData[1].value}`, { replace: true });
    }
  }, [hasTabAfterRoot, location, navigate]);

  return <DefaultTab />;
};
