/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Modal, View, Text, Icon, Button, Close, Actionable, Notification } from '@az/starc-ui';
import { Warning } from '@az/starc-ui-icons';
import { useTranslation } from 'react-i18next';
import { LocationSchema, UserType } from '@inventory/types/types';
import { CYCLE_COUNT_STATUS_CD } from '@inventory/constants/constants';
import styles from '../../CycleCountsModal.module.scss';

type RemoveUserConfirmationModalProps = {
  isOpen: boolean;
  onModalClose: () => void;
  removedUser: UserType | null;
  subZoneLocations?: LocationSchema[];
  onConfirm?: () => void;
};

export const RemoveUserConfirmationModal = ({
  isOpen,
  onModalClose,
  removedUser,
  subZoneLocations = [],
  onConfirm,
}: RemoveUserConfirmationModalProps) => {
  const { t } = useTranslation();
  const inProgressTaskCount =
    subZoneLocations?.filter(
      (location) =>
        removedUser?.id === location.assignedUserId &&
        location.countStatusCd === CYCLE_COUNT_STATUS_CD.INPROGRESS
    )?.length || 0;

  const onRemove = () => {
    onConfirm?.();
    onModalClose?.();
  };

  return (
    <Modal
      open={isOpen}
      closeByClickAway={false}
      onClose={onModalClose}
      size="small"
      attributes={{ style: { width: 'calc((var(--st-unit-28) * 6))' } }}
    >
      <View direction="column" gap={6} padding={[4, 4]}>
        <View
          direction="row"
          align="center"
          gap={0}
          height={{
            s: 'var(--st-unit-12)',
            m: 'calc(var(--st-unit-13) + 2px)',
          }}
        >
          <View.Item grow>
            <View justify="center">
              <Text variant="display-5" as="h2" size="175" weight="bold" color="primary">
                {t('CycleCount.ChooseAssigneesModal.RemoveConfirmationModal.Title', {
                  userName: removedUser?.name,
                })}
              </Text>
            </View>
          </View.Item>
          <Actionable onClick={onModalClose}>
            <View direction="row" justify="center" align="center" gap={2} padding={[0, 4]}>
              <Icon svg={<Close role="img" />} size={5} />
            </View>
          </Actionable>
        </View>
        <Notification
          className={styles['cycle-counts-modal__notification_warning']}
          type="custom"
          customNotification={{
            svg: Warning,
            barColor: 'warning',
            iconColor: 'warning',
          }}
          text={t('CycleCount.ChooseAssigneesModal.RemoveConfirmationModal.Description', {
            userName: removedUser?.name,
            taskCount: inProgressTaskCount,
          })}
        />
      </View>
      <View padding={[4, 0, 4, 4]} className={styles['cycle-counts-modal__footer']}>
        <View
          width="100%"
          direction="row"
          justify="end"
          gap={3}
          className={styles['cycle-counts-modal__footer__actions']}
        >
          <Button
            size="large"
            variant="secondary"
            attributes={{ style: { width: 'fit-content' } }}
            onClick={onModalClose}
          >
            <Text>{t('Cancel')}</Text>
          </Button>
          <Button
            size="large"
            variant="primary"
            attributes={{ style: { width: 'fit-content' } }}
            onClick={onRemove}
          >
            <Text> {t('CycleCount.ChooseAssigneesModal.RemoveConfirmationModal.Confirm')}</Text>
          </Button>
        </View>
      </View>
    </Modal>
  );
};
