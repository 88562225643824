/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button, Pagination, Store, View, Text } from '@az/starc-ui';
import * as T from './ItemLayout.types';
import { Footer } from '@shared/components/Footer/Footer';
import { CheckBoxCard } from '@shared/components/CheckBoxCard/CheckBoxCard';
import s from './ItemLayout.module.scss';
import { ProgressBar } from '@ofm/components/ProgressBar/ProgressBar';
import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types';
import { CardGridSkeleton } from '@shared/components/Skeletons/CardGridSkeleton';
import { EmptyState } from '../EmptyState/EmptyState';
import { useTranslation } from 'react-i18next';
import { addPadding } from '@ofm/utils/utils';
import { ID_PADDINGS } from '@shared/constants/constants';
import { useAtom } from 'jotai';
import { warehouseAtom } from '@ofm/atoms/warehouse/warehouseAtom';
export const ItemLayout = ({
  items,
  selectedItems,
  onItemSelect,
  buttons,
  emptySubtitle,
  emptyText,
  paginationProps,
  itemsInProgress,
  isLoading,
  showProgressSkeleton,
  progress,
  setItemId,
  setShowOrderDetailsDrawer,
  headerTitle,
  backButtonOnClick,
}: T.Props) => {
  /* Constants */
  const { t } = useTranslation();
  const hasItems = !!(items && items.length > 0);
  const hasProgress = !!(progress && progress.length > 0);
  const [warehouse] = useAtom(warehouseAtom);

  if (isLoading) {
    return (
      <View padding={6} justify="start" height="100%">
        <CardGridSkeleton hasProgress={!!progress || showProgressSkeleton} items={8} />
      </View>
    );
  } else {
    const received = items?.filter((item) => item.status === 'RECEIVED') || [];
    const requested = items?.filter((item) => item.status === 'REQUESTED') || [];
    return (
      <>
        <View padding={6} height="100%" className="scrollable-section">
          {headerTitle && <Text variant="display-3">{headerTitle}</Text>}
          {hasProgress &&
            progress.map((progressProps) => (
              <View key={progressProps.title} padding={[0, 0, 8, 0]}>
                <ProgressBar {...progressProps} />
              </View>
            ))}
          {hasItems ? (
            <View height="inherit" justify="space-between">
              {warehouse.type == 'DIF' ? (
                <View padding={[5, 0, 0, 0]}>
                  <View padding={[5, 0, 5, 0]}>
                    <Text variant="display-4">Received</Text>
                  </View>
                  <View gap={4} className={s['list']} direction="row">
                    {received.map((item) => {
                      const isItemInProgress = itemsInProgress?.includes(item.id);
                      const isItemSelected = selectedItems?.includes(item.id);

                      return (
                        <View.Item key={item.id} className={s['list__item']}>
                          <CheckBoxCard
                            title={addPadding(item.storeNumber || '', ID_PADDINGS.STORE)}
                            isChecked={isItemSelected || isItemInProgress}
                            isCheckboxDisabled={isItemInProgress}
                            isCardDisabled={isItemInProgress || item.disabled}
                            onChangeHandler={() => onItemSelect && onItemSelect(item.id)}
                            shouldHideCheckbox={!onItemSelect}
                            statusBadge={
                              isItemInProgress
                                ? {
                                    variant: StatusVariants.IN_PROGRESS,
                                    text: t('WaveStatus.InProgress'),
                                  }
                                : undefined
                            }
                            tag={
                              item.secondaryStatus
                                ? {
                                    text: item.secondaryStatus,
                                    variant: 'order',
                                  }
                                : undefined
                            }
                            onClick={() => {
                              if (setItemId && setShowOrderDetailsDrawer) {
                                setItemId(item.id);
                                setShowOrderDetailsDrawer(true);
                              }
                            }}
                          />
                        </View.Item>
                      );
                    })}
                  </View>

                  <View padding={[5, 0, 5, 0]}>
                    <Text variant="display-4">Requested</Text>
                  </View>
                  <View gap={4} className={s['list']} direction="row">
                    {requested.map((item) => {
                      const isItemInProgress = itemsInProgress?.includes(item.id);
                      const isItemSelected = selectedItems?.includes(item.id);

                      return (
                        <View.Item key={item.id} className={s['list__item']}>
                          <CheckBoxCard
                            title={addPadding(item.storeNumber || '', ID_PADDINGS.STORE)}
                            isChecked={isItemSelected}
                            isCheckboxDisabled={isItemInProgress}
                            onChangeHandler={() => onItemSelect && onItemSelect(item.id)}
                            shouldHideCheckbox={!onItemSelect}
                            statusBadge={
                              isItemInProgress
                                ? {
                                    variant: StatusVariants.IN_PROGRESS,
                                    text: t('WaveStatus.InProgress'),
                                  }
                                : undefined
                            }
                            tag={
                              item.secondaryStatus
                                ? {
                                    text: item.secondaryStatus,
                                    variant: 'order',
                                  }
                                : undefined
                            }
                            onClick={() => {
                              if (setItemId && setShowOrderDetailsDrawer) {
                                setItemId(item.id);
                                setShowOrderDetailsDrawer(true);
                              }
                            }}
                          />
                        </View.Item>
                      );
                    })}
                  </View>
                </View>
              ) : (
                <View as="ul" gap={4} className={s['list']}>
                  {items.map((item) => {
                    const isItemInProgress = itemsInProgress?.includes(item.id);
                    const isItemSelected = selectedItems?.includes(item.id);

                    return (
                      <View.Item as="li" key={item.id} className={s['list__item']}>
                        <CheckBoxCard
                          title={addPadding(item.storeNumber || '', ID_PADDINGS.STORE)}
                          isChecked={isItemSelected}
                          isCheckboxDisabled={isItemInProgress}
                          onChangeHandler={() => onItemSelect && onItemSelect(item.id)}
                          shouldHideCheckbox={!onItemSelect}
                          statusBadge={
                            isItemInProgress
                              ? {
                                  variant: StatusVariants.IN_PROGRESS,
                                  text: t('WaveStatus.InProgress'),
                                }
                              : undefined
                          }
                          tag={
                            item.secondaryStatus
                              ? {
                                  text: item.secondaryStatus,
                                  variant: 'order',
                                }
                              : undefined
                          }
                          onClick={() => {
                            if (setItemId && setShowOrderDetailsDrawer) {
                              setItemId(item.id);
                              setShowOrderDetailsDrawer(true);
                            }
                          }}
                        />
                      </View.Item>
                    );
                  })}
                </View>
              )}
              {paginationProps && (
                <View justify="center" align="center" padding={[6, 0]}>
                  <Pagination {...paginationProps} />
                </View>
              )}
            </View>
          ) : (
            <>
              {!hasProgress && emptySubtitle && emptyText && (
                <EmptyState subtitle={emptySubtitle} text={emptyText} svg={Store} />
              )}
            </>
          )}
        </View>
        {progress && (
          <Footer hasBackButton={true} backButtonOnClick={backButtonOnClick}>
            {buttons &&
              buttons.map((button) => (
                <Button
                  key={button.title}
                  attributes={{ style: { width: 'fit-content' } }}
                  title={button.title}
                  variant={button.variant}
                  disabled={button.isDisabled}
                  aria-disabled={button.isDisabled}
                  onClick={button.clickHandler}
                  loading={button.loading}
                >
                  {button.title}
                </Button>
              ))}
          </Footer>
        )}
      </>
    );
  }
};
