/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  Button,
  View,
  Text,
  FormControl,
  Select,
  DatePicker,
  Link,
  TextField,
  Divider,
} from '@az/starc-ui';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { ActionMenu } from '@mdm/components/ActionMenu/ActionMenu.tsx';
import styles from './UserDetail.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import {
  ENTITY_ASSOCIATIONS,
  LANGUAGE_OPTIONS,
  MAX_USER_VISITING_END_DATE_YEAR,
  STATUS,
  USER_DEPARTMENTS,
  USER_SHIFT_ID,
  USER_STATUS_OPTIONS,
  USER_WAGE_CODE_OPTIONS,
} from '@mdm/constants/constants.ts';
import { asyncUserAtom } from '@shared/atoms/user/userAtom.ts';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { UserViewSchema } from '@shared/schemas/userSchema.ts';
import { Controller, useForm } from 'react-hook-form';
import { getFormDefaults } from '@mdm/utils/form/formUtils.tsx';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetUserByUserId } from '@mdm/services/hooks/useGetUserByUserId.tsx';
import { getFormInputError } from '@ofm/utils/utils.ts';
import { DetailsSectionSkeleton } from '@shared/components/Skeletons/DetailsSectionSkeleton.tsx';
import { useMutateUser } from '@shared/services/hooks/useMutateUser.tsx';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import dayjs from 'dayjs';
import { getSelectedDC } from '@mdm/utils/utils.ts';
import { UserAssignedRole } from '@mdm/pages/RDM/User/Detail/UserAssignedRole.tsx';
import { UserShiftList } from '@mdm/pages/RDM/User/Detail/UserShiftList.tsx';
import { UserShiftRowDataType } from '@mdm/pages/RDM/User/Detail/UserShift.types.ts';
import { useGetShifts } from '@mdm/services/hooks/useGetShifts.tsx';

export const UserDetail = () => {
  /* Atoms */
  const [user] = useAtom(asyncUserAtom);

  /* State variables */
  const { userId } = useParams();
  const [isDataLoaded, setIsDataLoaded] = useState(!userId);
  const [visitingStartDateOpen, setVisitingStartDateOpen] = useState(false);
  const [visitingEndDateOpen, setVisitingEndDateOpen] = useState(false);

  /* Constants */
  const { t } = useTranslation();
  const dateRange: [string, string] = [
    dayjs().format(t('DateFormat.Long')),
    dayjs().add(MAX_USER_VISITING_END_DATE_YEAR, 'year').format(t('DateFormat.Long')),
  ];
  type FormData = z.infer<typeof UserViewSchema>;
  const { userId: id } = useParams();
  const breadcrumbs = useBreadcrumb(id ? PAGE_URLS.USER_CREATE : '');
  const navigate = useNavigate();
  const { userData, isLoading: isUserLoading } = useGetUserByUserId(
    {
      userId: userId || '',
      entityAssociations: `${ENTITY_ASSOCIATIONS.ROLES},${ENTITY_ASSOCIATIONS.SHIFTS},${ENTITY_ASSOCIATIONS.DEPTS}`,
    },
    !!userId
  );
  const { shiftsData } = useGetShifts({});
  const { isLoading: isUserUpdating, mutateUser } = useMutateUser();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();

  const {
    control,
    handleSubmit,
    reset: userReset,
    getValues,
    formState: { errors },
    setValue,
    register,
    watch,
  } = useForm<FormData>({
    defaultValues: getFormDefaults(UserViewSchema),
    resolver: zodResolver(UserViewSchema),
  });

  const firstName = watch('firstName');
  const lastName = watch('lastName');

  /* Function */
  const onShiftClick = (shift: UserShiftRowDataType, dayOfWeek: string, isChecked: boolean) => {
    const primaryFacility = userData?.facilities.find((facility) => facility.primaryFl);
    if (primaryFacility && !primaryFacility.shifts) {
      primaryFacility.shifts = [];
    }
    const shifts = primaryFacility && primaryFacility.shifts ? primaryFacility.shifts : [];
    const foundShiftIndex = shifts.findIndex(
      (shiftValue) => shiftValue.shiftId === shift.shiftId && shiftValue.dayOfWeek === dayOfWeek
    );
    let existingShiftData = (shiftsData || []).find(
      (data) => data.shiftId === shift.shiftId && data.dayOfWeek === dayOfWeek
    );

    if ([USER_SHIFT_ID.THIRD, USER_SHIFT_ID.WEEKEND_THIRD].includes(shift.shiftId)) {
      existingShiftData = (shiftsData || []).find(
        (data) =>
          data.shiftId === shift.shiftId && data.dayOfWeek === dayOfWeek && data.toTime === '23:59'
      );
    }

    if (isChecked) {
      if (foundShiftIndex === -1 && existingShiftData) {
        shifts.push({
          dayOfWeek,
          fromTime: existingShiftData.fromTime || '',
          toTime: existingShiftData.toTime || '',
        });
      }
    } else {
      if (foundShiftIndex !== -1) {
        shifts.splice(foundShiftIndex, 1);
      }
    }
  };

  const onSubmit = () => {
    const payload = getValues();
    const facilities = [];
    const primaryFacility = userData?.facilities.find((facility) => facility.primaryFl);
    const shifts = primaryFacility && primaryFacility.shifts ? primaryFacility.shifts : [];
    let primaryFacilityDepartments = primaryFacility?.departments || [];
    const existingPrimaryFacilityDepartment = primaryFacilityDepartments.find(
      (dept) => dept.deptId === payload.deptId
    );

    primaryFacilityDepartments = primaryFacilityDepartments.map((department) => {
      return {
        ...department,
        primaryFl: department.deptId === payload.deptId,
        statusCd: department.deptId === payload.deptId ? STATUS.ACTIVE : STATUS.INACTIVE,
      };
    });

    if (!existingPrimaryFacilityDepartment && payload.deptId) {
      primaryFacilityDepartments.push({
        deptId: payload.deptId,
        statusCd: STATUS.ACTIVE,
        primaryFl: true,
      });
    }

    const primaryFacilityId = primaryFacility?.facilityId || getSelectedDC();
    // Primary facility
    facilities.push({
      facilityId: primaryFacilityId || '',
      primaryFl: true,
      roles: payload.roleId
        ? [
            {
              roleId: payload.roleId,
            },
          ]
        : null,
      wageCd: payload.wageCd,
      departments: primaryFacilityDepartments.length ? primaryFacilityDepartments : null,
      shifts: shifts.map((shift) => {
        return {
          dayOfWeek: shift.dayOfWeek,
          fromTime: shift.fromTime,
          toTime: shift.toTime,
        };
      }),
      activeFromDate: primaryFacility?.activeFromDate
        ? dayjs(primaryFacility?.activeFromDate).format(t('DateFormat.CreateOrder'))
        : '',
      activeToDate: primaryFacility?.activeToDate
        ? dayjs(primaryFacility?.activeToDate).format(t('DateFormat.CreateOrder'))
        : '',
      statusCd: STATUS.ACTIVE,
    });

    // additional facility
    let additionalFacilities =
      userData?.facilities.filter(
        (facility) => !facility.primaryFl && facility.facilityId !== primaryFacilityId
      ) || [];

    // check if additional facility already exists
    const existingAdditionalFacility = additionalFacilities.find(
      (facility) => facility.facilityId === payload.additionalFacilityId
    );

    // if additional facility does not exist, add it
    if (!existingAdditionalFacility && payload.additionalFacilityId) {
      additionalFacilities.push({
        facilityId: payload.additionalFacilityId,
        primaryFl: false,
        statusCd: STATUS.ACTIVE,
        departments: null,
        activeFromDate: payload.visitingStartDate
          ? dayjs(payload.visitingStartDate).format(t('DateFormat.CreateOrder'))
          : '',
        activeToDate: payload.visitingEndDate
          ? dayjs(payload.visitingEndDate).format(t('DateFormat.CreateOrder'))
          : '',
        wageCd: payload.wageCd,
      });
    }

    // update the status of additional facilities
    additionalFacilities = additionalFacilities.map((facility) => {
      return {
        ...facility,
        statusCd:
          facility.facilityId === payload.additionalFacilityId ? STATUS.ACTIVE : STATUS.INACTIVE,
      };
    });

    // find current selected additional facility
    const additionalFacility = additionalFacilities.find(
      (facility) => facility.facilityId === payload.additionalFacilityId
    );

    // update the status of additional facility departments
    let additionalFacilityDepartments = additionalFacility?.departments || [];
    const existingAdditionalFacilityDepartment = additionalFacilityDepartments.find(
      (dept) => dept.deptId === payload.reportingToDeptId
    );

    additionalFacilityDepartments = additionalFacilityDepartments.map((department) => {
      return {
        ...department,
        primaryFl: department.deptId === payload.reportingToDeptId,
        statusCd: department.deptId === payload.reportingToDeptId ? STATUS.ACTIVE : STATUS.INACTIVE,
      };
    });

    // if additional facility department does not exist, add it
    if (!existingAdditionalFacilityDepartment && payload.reportingToDeptId) {
      additionalFacilityDepartments.push({
        deptId: payload.reportingToDeptId,
        statusCd: STATUS.ACTIVE,
        primaryFl: true,
      });
    }

    // update the additional facility
    if (additionalFacility) {
      additionalFacility.departments = additionalFacilityDepartments.length
        ? additionalFacilityDepartments
        : null;
      additionalFacility.activeFromDate = payload.visitingStartDate
        ? dayjs(payload.visitingStartDate).format(t('DateFormat.CreateOrder'))
        : '';
      additionalFacility.activeToDate = payload.visitingEndDate
        ? dayjs(payload.visitingEndDate).format(t('DateFormat.CreateOrder'))
        : '';
      additionalFacility.wageCd = payload.wageCd;
      additionalFacility.statusCd = STATUS.ACTIVE;
    }

    // add additional facilities in payload
    for (let i = 0; i < additionalFacilities.length; i++) {
      facilities.push(additionalFacilities[i]);
    }

    mutateUser(
      {
        userId: userId || '',
        userRequestType: {
          userKey: payload.userKey,
          userId: userId || '',
          employeeId: payload.employeeId,
          firstName: payload.firstName,
          middleName: payload.middleName,
          secondLastName: payload.secondLastName,
          lastName: payload.lastName,
          statusCd: payload.statusCd,
          countryCd: payload.countryCd,
          stateProvinceCd: payload.stateProvinceCd,
          city: payload.city,
          addressLine1: payload.addressLine1,
          addressLine2: payload.addressLine2,
          postalCd: payload.postalCd,
          hireDate: payload.hireDate,
          jobCd: payload.jobCd,
          jobTitle: payload.jobTitle,
          preferedLanguage: payload.preferedLanguage,
          multilingualFl: payload.multilingualFl,
          temporaryFl: payload.temporaryFl,
          clockedInFl: payload.clockedInFl,
          userPinCd: payload.userPinCd || '',
          facilities,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['users']);
          queryClient.invalidateQueries(['usersSearch']);
          const successMessage = t('Success.Action.User.Updated', {
            userId: payload.userId,
          });
          handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
        },
      }
    );
  };

  const handleResetAdditionalFacilityField = () => {
    setValue('visitingStartDate', '');
    setValue('visitingEndDate', '');
    setValue('additionalFacilityId', '');
    setValue('reportingToDeptId', '');
  };

  /* Hooks */
  useEffect(() => {
    // set the form data coming from user view api
    if (userData && userData.userId) {
      const primaryFacility = userData?.facilities.find((facility) => facility.primaryFl);
      const primaryFacilityId = primaryFacility?.facilityId || getSelectedDC();
      const additionalFacility = userData?.facilities.find(
        (facility) =>
          !facility.primaryFl &&
          facility.statusCd === STATUS.ACTIVE &&
          facility.facilityId !== primaryFacilityId
      );

      const activeAdditionalFacilityDepartment = (additionalFacility?.departments || []).find(
        (dept) => dept.statusCd === STATUS.ACTIVE
      );

      const activePrimaryFacilityDepartment = (primaryFacility?.departments || []).find(
        (dept) => dept.primaryFl
      );

      userReset({
        ...userData,
        preferedLanguage: userData?.preferedLanguage ? userData?.preferedLanguage : '',
        deptId: activePrimaryFacilityDepartment ? activePrimaryFacilityDepartment.deptId : '',
        roleId: primaryFacility?.roles?.[0]?.roleId || '',
        wageCd: primaryFacility?.wageCd || '',
        additionalFacilityId: additionalFacility?.facilityId || '',
        reportingToDeptId: activeAdditionalFacilityDepartment
          ? activeAdditionalFacilityDepartment.deptId
          : '',
        visitingStartDate: additionalFacility?.activeFromDate
          ? dayjs(additionalFacility.activeFromDate).format(t('DateFormat.Long'))
          : '',
        visitingEndDate: additionalFacility?.activeToDate
          ? dayjs(additionalFacility.activeToDate).format(t('DateFormat.Long'))
          : '',
      });

      setIsDataLoaded(true);
    } else if (userId && !userData && !isUserLoading) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('UserNotFound'));
    }
  }, [handleNotification, isUserLoading, t, userData, userId, userReset]);

  if (!isDataLoaded) {
    return <DetailsSectionSkeleton items={10} />;
  } else {
    return (
      <>
        <View
          className={styles['user-detail']}
          backgroundColor="secondary"
          direction="column"
          height="100%"
        >
          <View.Item
            attributes={{
              'data-testid': 'user-details-header',
            }}
          >
            <MasterTitle
              title={`${t('User')}: ${
                firstName || lastName ? firstName + ' ' + lastName : t('Untitled')
              }`}
              breadcrumbProps={breadcrumbs}
            >
              <View
                attributes={{
                  'data-testid': 'user-details-action',
                }}
                direction="row"
                justify="end"
                align="center"
                gap={4}
              >
                <View.Item>
                  <View direction="row" gap={4}>
                    <View.Item>
                      <Button variant="secondary" size="large" onClick={() => navigate(-1)}>
                        <View direction="row" align="center" justify="center" gap={2}>
                          <Text>{t('Cancel')}</Text>
                        </View>
                      </Button>
                    </View.Item>
                  </View>
                </View.Item>
                <View.Item>
                  <View direction="row" gap={4}>
                    <View.Item>
                      <Button
                        size="large"
                        loading={isUserUpdating}
                        onClick={() => handleSubmit(onSubmit)()}
                      >
                        <View direction="row" align="center" justify="center" gap={2}>
                          <Text>{t('SaveEdits')}</Text>
                        </View>
                      </Button>
                    </View.Item>
                  </View>
                </View.Item>
                {id ? (
                  <ActionMenu
                    menus={[
                      {
                        label: t('DeactivateUser'),
                        clickHandler: () => ({}),
                        labelColor: 'error',
                      },
                    ]}
                  />
                ) : null}
              </View>
            </MasterTitle>
          </View.Item>
          <View padding={6} backgroundColor="secondary">
            <View gap={3}>
              <View.Item>
                <Text size="125" weight="bold">
                  {t('UserDetails.BasicInformation.Title')}
                </Text>
              </View.Item>
              <View.Item>
                <Text weight="regular" color="600" size="087">
                  {t('UserDetails.BasicInformation.SubTitle')}
                </Text>
              </View.Item>
            </View>
            <View
              backgroundColor="secondary"
              height="100%"
              className={styles['user-detail__form-field-section']}
            >
              <View>
                <form>
                  <View direction="row" gap={4}>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <FormLabel text={t('Warehouse')} isRequired={true} />
                        <FormControl>
                          <Select
                            attributes={{
                              'data-testid': 'warehose-id',
                            }}
                            label={t('Warehouse')}
                            name="warehouse"
                            variant="no-label"
                            multiSelect={false}
                            defaultValue={{
                              label:
                                'DC ' +
                                userData?.facilities.find((facility) => facility.primaryFl)
                                  ?.facilityId,
                              value:
                                'DC ' +
                                userData?.facilities.find((facility) => facility.primaryFl)
                                  ?.facilityId,
                            }}
                            options={[]}
                            disabled
                          />
                        </FormControl>
                        <Text weight="regular" color="600" size="087">
                          {t('UserDetails.WarehouseInstruction')}
                        </Text>
                      </View>
                    </View.Item>
                  </View>
                  <View
                    className={styles['user-detail__form-field-section']}
                    direction="row"
                    gap={4}
                    align="center"
                  >
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <FormLabel
                          text={`${t('UserDetails.AdditionalWarehouse')} (${t('Optional')})`}
                        />
                        <FormControl hasError={!!errors.additionalFacilityId}>
                          <Controller
                            control={control}
                            name="additionalFacilityId"
                            render={({ field: { onChange, onBlur, value } }) => (
                              <>
                                <Select
                                  label={t('UserDetails.AdditionalWarehouse')}
                                  placeholder={t('UserDetails.AdditionalWarehouse')}
                                  name="additionalFacilityId"
                                  variant="no-label"
                                  multiSelect={false}
                                  options={
                                    user?.warehouses.map((warehouse) => ({
                                      value: warehouse.id,
                                      label: warehouse.name,
                                    })) || []
                                  }
                                  value={{
                                    label:
                                      (
                                        user?.warehouses.map((warehouse) => ({
                                          value: warehouse.id,
                                          label: warehouse.name,
                                        })) || []
                                      ).find((option) => option.value === value)?.label || '',
                                    value: value || '',
                                  }}
                                  inputAttributes={{ onBlur }}
                                  onValueChange={(additionalFacilityId) =>
                                    onChange(additionalFacilityId?.value)
                                  }
                                />
                              </>
                            )}
                          />
                          {errors.additionalFacilityId && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {getFormInputError(errors.additionalFacilityId.type)}
                              </FormControl.Error>
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <FormLabel
                          text={`${t('UserDetails.ReportingTo')} (${t('UserDetails.Department')})`}
                        />
                        <FormControl hasError={!!errors.reportingToDeptId}>
                          <Controller
                            control={control}
                            name="reportingToDeptId"
                            render={({ field: { onChange, onBlur, value } }) => (
                              <>
                                <Select
                                  label={t('UserDetails.ReportingTo')}
                                  placeholder={t('UserDetails.ReportingTo')}
                                  name="reportingToDeptId"
                                  variant="no-label"
                                  multiSelect={false}
                                  options={USER_DEPARTMENTS}
                                  value={{
                                    label:
                                      USER_DEPARTMENTS.find((option) => option.value === value)
                                        ?.label || '',
                                    value: value || '',
                                  }}
                                  inputAttributes={{ onBlur }}
                                  onValueChange={(reportingToDeptId) =>
                                    onChange(reportingToDeptId?.value)
                                  }
                                />
                              </>
                            )}
                          />
                          {errors.reportingToDeptId && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {getFormInputError(errors.reportingToDeptId.type)}
                              </FormControl.Error>
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <FormLabel text={`${t('UserDetails.VisitTimePeriod')}`} />
                        <FormControl hasError={!!errors.visitingStartDate}>
                          <Controller
                            control={control}
                            name="visitingStartDate"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <DatePicker
                                  attributes={{
                                    'data-testid': 'visting-start-date',
                                  }}
                                  className={styles['user-detail__date-picker']}
                                  dateFormat={t('DateFormat.Long')}
                                  label={t('UserPage.StartDate')}
                                  value={value}
                                  open={visitingStartDateOpen}
                                  automaticUpdate={false}
                                  dateRange={dateRange}
                                  onOpen={() => setVisitingStartDateOpen(true)}
                                  onClose={() => setVisitingStartDateOpen(false)}
                                  onValueChange={(value) => {
                                    onChange(value);
                                    setVisitingStartDateOpen(false);
                                  }}
                                />
                              </>
                            )}
                          />
                          {errors.visitingStartDate && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {getFormInputError(errors.visitingStartDate.type)}
                              </FormControl.Error>
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 2 }}>
                      <View gap={2}>
                        <View attributes={{ style: { visibility: 'hidden' } }}>
                          <FormLabel text={`${t('UserDetails.VisitTimePeriod')}`} />
                        </View>
                        <View direction="row">
                          <FormControl hasError={!!errors.visitingEndDate}>
                            <Controller
                              control={control}
                              name="visitingEndDate"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <DatePicker
                                    attributes={{
                                      'data-testid': 'visting-end-date',
                                    }}
                                    className={styles['user-detail__date-picker']}
                                    dateFormat={t('DateFormat.Long')}
                                    label={t('UserPage.EndDate')}
                                    value={value}
                                    open={visitingEndDateOpen}
                                    automaticUpdate={false}
                                    dateRange={dateRange}
                                    onOpen={() => setVisitingEndDateOpen(true)}
                                    onClose={() => setVisitingEndDateOpen(false)}
                                    onValueChange={(value) => {
                                      onChange(value);
                                      setVisitingEndDateOpen(false);
                                    }}
                                  />
                                </>
                              )}
                            />
                            {errors.visitingEndDate && (
                              <View direction="row" justify="space-between">
                                <FormControl.Error>
                                  {getFormInputError(errors.visitingEndDate.type)}
                                </FormControl.Error>
                              </View>
                            )}
                          </FormControl>
                        </View>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 1 }}>
                      <View attributes={{ style: { visibility: 'hidden' } }}>
                        <FormLabel text={`${t('UserDetails.VisitTimePeriod')}`} />
                      </View>
                      <Link onClick={() => handleResetAdditionalFacilityField()}>
                        {' '}
                        {t('ResetAll')}
                      </Link>
                    </View.Item>
                  </View>
                  <View direction="row" gap={4}>
                    <View.Item>
                      <Text weight="regular" color="600" size="087">
                        {t('UserDetails.PermissionInstruction')}{' '}
                        <Link onClick={() => ({})}>{t('LearnMore')}</Link>
                      </Text>
                    </View.Item>
                  </View>
                  <View
                    className={styles['user-detail__form-field-section']}
                    direction="row"
                    gap={4}
                  >
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <FormLabel text={t('UserDetails.FirstName')} isRequired={true} />
                      <FormControl hasError={!!errors.firstName}>
                        <TextField
                          attributes={{
                            'data-testid': 'firstname',
                          }}
                          inputAttributes={{
                            ...register('firstName'),
                          }}
                          defaultValue=""
                        />
                        {errors.firstName && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error>
                              {getFormInputError(errors.firstName.type)}
                            </FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <FormLabel text={t('UserDetails.LastName')} isRequired={true} />
                      <FormControl hasError={!!errors.lastName}>
                        <TextField
                          attributes={{
                            'data-testid': 'lastname',
                          }}
                          inputAttributes={{
                            ...register('lastName'),
                          }}
                          defaultValue=""
                        />
                        {errors.lastName && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error>
                              {getFormInputError(errors.lastName.type)}
                            </FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View.Item>
                  </View>
                  <View
                    className={styles['user-detail__form-field-section']}
                    direction="row"
                    gap={4}
                  >
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <FormLabel text={t('UserDetails.HiredOn')} />
                      <FormControl>
                        <TextField
                          attributes={{
                            'data-testid': 'hiredOn',
                          }}
                          inputAttributes={{
                            ...register('hireDate'),
                          }}
                          defaultValue=""
                          disabled
                        />
                      </FormControl>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <FormLabel text={t('UserDetails.EmployeeID')} isRequired={true} />
                      <FormControl>
                        <TextField
                          attributes={{
                            'data-testid': 'employee-id',
                          }}
                          inputAttributes={{
                            ...register('employeeId'),
                          }}
                          defaultValue=""
                          disabled
                        />
                      </FormControl>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <FormLabel text={t('UserDetails.RimsID')} />
                      <FormControl>
                        <TextField
                          attributes={{
                            'data-testid': 'rims-id',
                          }}
                          inputAttributes={{
                            ...register('userIdGenerated'),
                          }}
                          defaultValue=""
                          disabled
                        />
                      </FormControl>
                      <Text
                        attributes={{ style: { marginTop: 'var(--st-unit-1)' } }}
                        weight="regular"
                        color="600"
                        size="087"
                      >
                        {t('UserDetails.RIMSIDInstruction')}
                      </Text>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <FormLabel text={t('UserDetails.Language')} isRequired={true} />
                      <FormControl hasError={!!errors.preferedLanguage}>
                        <Controller
                          control={control}
                          name="preferedLanguage"
                          render={({ field: { onChange, onBlur, value } }) => (
                            <>
                              <Select
                                label={t('UserDetails.Status')}
                                placeholder={t('UserDetails.Status')}
                                name="preferedLanguage"
                                variant="no-label"
                                multiSelect={false}
                                options={LANGUAGE_OPTIONS}
                                attributes={{
                                  'data-testid': 'language',
                                }}
                                value={{
                                  label:
                                    LANGUAGE_OPTIONS.find((option) => option.value === value)
                                      ?.label || '',
                                  value: value || '',
                                }}
                                inputAttributes={{ onBlur }}
                                onValueChange={(preferedLanguage) =>
                                  onChange(preferedLanguage?.value)
                                }
                              />
                            </>
                          )}
                        />
                        {errors.preferedLanguage && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error>
                              {getFormInputError(errors.preferedLanguage.type)}
                            </FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View.Item>
                  </View>

                  <View padding={[4, 0]}>
                    <Divider />
                  </View>

                  <View gap={3}>
                    <View.Item>
                      <Text size="125" weight="bold">
                        {t('UserDetails.RolesAndPermissions.Title')}
                      </Text>
                    </View.Item>
                    <View.Item>
                      <Text weight="regular" color="600" size="087">
                        {t('UserDetails.RolesAndPermissions.SubTitle')}{' '}
                        {`DC ${
                          userData?.facilities.find((facility) => facility.primaryFl)?.facilityId
                        }`}
                      </Text>
                    </View.Item>
                  </View>

                  <View
                    className={styles['user-detail__form-field-section']}
                    direction="row"
                    gap={4}
                  >
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <FormLabel text={t('UserDetails.Status')} isRequired={true} />
                      <FormControl hasError={!!errors.statusCd}>
                        <Controller
                          control={control}
                          name="statusCd"
                          render={({ field: { onChange, onBlur, value } }) => (
                            <>
                              <Select
                                attributes={{
                                  'data-testid': 'status',
                                }}
                                label={t('UserDetails.Status')}
                                placeholder={t('UserDetails.Status')}
                                name="statusCd"
                                variant="no-label"
                                multiSelect={false}
                                options={USER_STATUS_OPTIONS}
                                value={{
                                  label:
                                    USER_STATUS_OPTIONS.find((option) => option.value === value)
                                      ?.label || '',
                                  value: value || '',
                                }}
                                inputAttributes={{ onBlur }}
                                onValueChange={(statusCd) => onChange(statusCd?.value)}
                              />
                            </>
                          )}
                        />
                        {errors.statusCd && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error>
                              {getFormInputError(errors.statusCd.type)}
                            </FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <FormLabel text={t('UserDetails.WageCode')} isRequired={true} />
                      <FormControl hasError={!!errors.wageCd}>
                        <Controller
                          control={control}
                          name="wageCd"
                          render={({ field: { onChange, onBlur, value } }) => (
                            <>
                              <Select
                                label={t('UserDetails.WageCode')}
                                placeholder={t('UserDetails.WageCode')}
                                name="wageCd"
                                variant="no-label"
                                multiSelect={false}
                                attributes={{
                                  'data-testid': 'wage-code',
                                }}
                                options={USER_WAGE_CODE_OPTIONS}
                                value={{
                                  label:
                                    USER_WAGE_CODE_OPTIONS.find((option) => option.value === value)
                                      ?.label || '',
                                  value: value || '',
                                }}
                                inputAttributes={{ onBlur }}
                                onValueChange={(wageCd) => onChange(wageCd?.value)}
                              />
                            </>
                          )}
                        />
                        {errors.wageCd && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error>
                              {getFormInputError(errors.wageCd.type)}
                            </FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <FormLabel text={t('UserDetails.Department')} isRequired={true} />
                      <FormControl hasError={!!errors.deptId}>
                        <Controller
                          control={control}
                          name="deptId"
                          render={({ field: { onChange, onBlur, value } }) => (
                            <>
                              <Select
                                label={t('UserDetails.Department')}
                                placeholder={t('UserDetails.Department')}
                                name="deptId"
                                variant="no-label"
                                multiSelect={false}
                                options={USER_DEPARTMENTS}
                                attributes={{
                                  'data-testid': 'department',
                                }}
                                value={{
                                  label:
                                    USER_DEPARTMENTS.find((option) => option.value === value)
                                      ?.label || '',
                                  value: value || '',
                                }}
                                inputAttributes={{ onBlur }}
                                onValueChange={(deptId) => onChange(deptId?.value)}
                              />
                            </>
                          )}
                        />
                        {errors.deptId && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error>
                              {getFormInputError(errors.deptId.type)}
                            </FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View.Item>
                  </View>

                  <View padding={[4, 0, 0, 0]}>
                    <Divider />
                  </View>

                  <View className={styles['user-detail__roles-section']} direction="row" gap={4}>
                    <UserAssignedRole userData={userData} />
                  </View>

                  <View padding={[4, 0, 0, 0]}>
                    <Divider />
                  </View>

                  <View className={styles['user-detail__shift-section']} direction="row" gap={4}>
                    <UserShiftList userData={userData} onShiftClick={onShiftClick} />
                  </View>
                </form>
              </View>
            </View>
          </View>
        </View>
      </>
    );
  }
};
