/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { USER_ENDPOINTS } from '@shared/constants/routes';
import { apiService } from './apiService';
import { CURRENT_MOCK_USER } from '@ofm/constants/dataConstants';

export const userService = {
  retrieveUserInfo: async (token?: string) => {
    await apiService.post(USER_ENDPOINTS.RETRIEVE_USER, undefined, {
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    });
    //return parseAndLog(UserSchema, response.data);
    return CURRENT_MOCK_USER;
  },
};
