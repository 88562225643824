/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { memo } from 'react';
import { View, Text } from '@az/starc-ui';
import { t } from 'i18next';

import { EMPTY_VALUE } from '@shared/constants/constants';

import { ListSkeleton } from './ListSkeleton';
import * as T from './TopVariancesSection.types';
import styles from './TopVariancesSection.module.scss';

export const List = memo(({ data, isDollarVariance, isLoading, isError }: T.ListProps) => {
  if (isLoading) {
    return <ListSkeleton />;
  }

  if (!data?.length || isError) {
    return (
      <View
        attributes={{ style: { minHeight: 315 } }}
        className={styles['list-component-item']}
        justify="center"
        align="center"
        padding={2}
      >
        <Text>{isError ? t('TopVariances.ItemsFetchError') : t('TopVariances.NoItemsFound')}</Text>
      </View>
    );
  }

  return (
    <View
      direction="column"
      align="center"
      gap={2}
      width="100%"
      attributes={{ style: { minHeight: 315 } }}
    >
      {data.map((item, index) => (
        <View
          key={index}
          className={styles['list-component-item']}
          direction="row"
          align="center"
          justify="space-between"
          width="100%"
          height="54px"
          padding={4}
        >
          <View width="10%">
            <Text>{index + 1}</Text>
          </View>
          <View width="90%" direction="row" justify="space-between">
            <View>
              <Text color="gray-700" weight="medium" size="087">
                {item.name ?? EMPTY_VALUE}
              </Text>
            </View>
            <View>
              <Text color="gray-700" weight="regular" size="087">
                {item.value !== undefined && item.value !== null
                  ? `${isDollarVariance ? '$' : ''}${item.value.toFixed(2)}`
                  : ''}
              </Text>
            </View>
          </View>
        </View>
      ))}
    </View>
  );
});
