/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  Checkbox,
  classNames,
  FormControl,
  Icon,
  Loader,
  Radio,
  Select,
  Text,
  TextField,
  View,
} from '@az/starc-ui';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import { PAGE_URLS } from '@shared/constants/routes.ts';

import { useSessionStorage } from '@shared/hooks/useStorage';
import { SESSION_DC_ID_KEY } from '@shared/constants/storageConstants';
import { z } from 'zod';
import {
  defaultAttributeValues,
  defaultLayoutAssetValues,
  SubzoneSchema,
} from '@mdm/schemas/subzoneSchema.ts';

import styles from './SubzoneDetail.module.scss';
import { Info } from '@az/starc-ui-icons';
import { Tab } from '@mdm/components/Tabs/Tabs.types.ts';
import { Tabs } from '@mdm/components/Tabs/Tabs.tsx';
import { SubzoneVehicleList } from '@mdm/pages/RDM/LocationManager/Subzone/Detail/SubzoneVehicleList.tsx';
import { SubzoneLocationList } from '@mdm/pages/RDM/LocationManager/Subzone/Detail/SubzoneLocationList.tsx';
import { getFormInputError } from '@ofm/utils/utils.ts';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import {
  displayAttributeFieldValidationMessage,
  displayLayoutAssetFieldValidationMessage,
} from '@mdm/utils/form/validationUtils.tsx';
import {
  getFormDefaults,
  getFormFieldIndexByKey,
  getLayoutAssetFormFieldIndexByAssetTypeCd,
} from '@mdm/utils/form/formUtils.tsx';
import {
  EMPTY_RECORD_COUNT,
  MAX_LENGTH_FIELD,
  SUBZONE_FIELD,
  ASSET_DEFAULT_SORT_FIELD,
  ASSET_TYPE_CODE,
  LAYOUT_ENTITY_TYPE_CODE,
  DEFAULT_DIMENSION_UNIT,
  LAYOUT_ENTITY_CODE,
  DEFAULT_WEIGHT_UNIT,
} from '@mdm/constants/constants.ts';
import { useGetZones } from '@mdm/services/hooks/useGetZones.tsx';
import {
  DEFAULT_PAGE,
  LAYOUT,
  MAX_PAGE_SIZE,
  NOTIFICATION_TYPES,
  RDM,
} from '@shared/constants/constants.ts';
import { useMutateSubzone } from '@mdm/services/hooks/useMutateSubzone.ts';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useGetSubzoneById } from '@mdm/services/hooks/useGetSubzoneById.tsx';
import { DetailsSectionSkeleton } from '@shared/components/Skeletons/DetailsSectionSkeleton.tsx';
import { useGetLocations } from '@mdm/services/hooks/useGetLocations.tsx';
import { useDeleteSubzone } from '@mdm/services/hooks/useDeleteSubzone.ts';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig.ts';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';
import { ActionMenu } from '@mdm/components/ActionMenu/ActionMenu.tsx';
import { useGetContainers } from '@mdm/services/hooks/useGetContainers.tsx';
import { LayoutViewType } from '@mdm/types/schema.type.ts';

export const SubzoneDetail = () => {
  /* State */
  const [locationCount, setLocationCount] = useState(EMPTY_RECORD_COUNT);
  const [subzoneViewData, setSubzoneViewData] = useState<LayoutViewType>({
    active: false,
    entityCd: '',
    entityTypeCd: '',
    layoutDistinctName: '',
    layoutKey: '',
    layoutName: '',
    layoutNameDesc: '',
    attributes: [],
    assets: [],
    products: [],
  });

  const [containerTypeOptions, setContainerTypeOptions] = useState<SelectOption[]>([]);
  const [subzoneTypeOptions, setSubzoneTypeOptions] = useState<SelectOption[]>([]);
  const [pickMethodOptions, setPickMethodOptions] = useState<SelectOption[]>([]);
  const [labelSortOptions, setLabelSortOptions] = useState<SelectOption[]>([]);
  const [skuPickClassOptions, setSkuPickClassOptions] = useState<SelectOption[]>([]);

  /* Constants */
  type FormData = z.infer<typeof SubzoneSchema>;
  const [selectedDC] = useSessionStorage<string>(SESSION_DC_ID_KEY);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { subzoneId, selectedZoneId } = useParams();
  const [isDataLoaded, setIsDataLoaded] = useState(!subzoneId);
  const { isLoading: isSubzoneUpdating, mutateSubzone } = useMutateSubzone();
  const { mutateDeleteSubzone, isLoading: isDeleting } = useDeleteSubzone();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();

  const {
    control,
    handleSubmit,
    register,
    reset: subzoneReset,
    formState: { errors },
    setValue,
    getValues,
    watch,
    trigger,
  } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: getFormDefaults(SubzoneSchema),
    resolver: zodResolver(SubzoneSchema),
  });

  const subzoneName = watch('layoutName');

  const breadcrumbs = useBreadcrumb(subzoneId ? PAGE_URLS.SUB_ZONE_CREATE : '');

  const { locationsData } = useGetLocations(
    {
      currentPage: DEFAULT_PAGE - 1,
      parentLayoutDistinctName: subzoneId || '',
      entityAssociations: [],
      sortBy: 'layoutName',
      direction: 'ASC',
    },
    !!subzoneId
  );

  const { subzoneData, isFetching: isSubzoneLoading } = useGetSubzoneById(
    {
      layoutId: subzoneId || '',
    },
    !!subzoneId
  );

  const { containersData, isFetching: isContainerLoading } = useGetContainers({
    currentPage: DEFAULT_PAGE - 1,
    sortBy: ASSET_DEFAULT_SORT_FIELD,
    pageSize: MAX_PAGE_SIZE,
  });

  const { configsData, isLoading: isConfigDataLoading } = useGetFacilityConfig({
    domainCd: RDM,
    subDomainCd: LAYOUT,
  });

  const { fields: attributeFields } = useFieldArray({
    control,
    name: 'attributes',
  });

  /* Functions */
  const revalidateAttributeField = (attributeName: string) => {
    const attributeIndex = attributeFields.findIndex((field) => field.name === attributeName);
    if (attributeIndex !== -1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      trigger(`attributes[attribute-${attributeIndex}]`);
    }
  };

  const onLayoutMappingAttributeChange = (
    assetCd: string,
    attributeName: string,
    value: string
  ) => {
    const layoutAssetMapping = getValues().assets;
    const foundLayoutIndex = layoutAssetMapping?.findIndex((asset) => asset.assetCd === assetCd);

    if (layoutAssetMapping && foundLayoutIndex !== undefined && foundLayoutIndex !== -1) {
      const layout = layoutAssetMapping[foundLayoutIndex];
      const foundAttributeIndex = layout.assetLayoutMappingAttrib?.findIndex((attribute) => {
        return attribute.name === attributeName;
      });

      if (
        foundAttributeIndex !== undefined &&
        foundAttributeIndex !== -1 &&
        layout.assetLayoutMappingAttrib
      ) {
        layout.assetLayoutMappingAttrib[foundAttributeIndex].value = value;
      }
    }

    const subzoneViewData = subzoneData?.content[0];

    if (subzoneViewData) {
      const subzoneViewDataAssetIndex = (subzoneViewData.assets || [])?.findIndex(
        (asset) => asset.assetCd === assetCd
      );

      if (subzoneViewDataAssetIndex !== -1) {
        const foundAttributeIndex = (
          (subzoneViewData.assets || [])[subzoneViewDataAssetIndex].assetLayoutMappingAttrib || []
        ).findIndex((attribute) => {
          return attribute.name === attributeName;
        });

        if (
          foundAttributeIndex !== -1 &&
          (subzoneViewData.assets || [])[subzoneViewDataAssetIndex].assetLayoutMappingAttrib
        ) {
          ((subzoneViewData.assets || [])[subzoneViewDataAssetIndex].assetLayoutMappingAttrib ||
            [])[foundAttributeIndex].value = value;
        }
      }
    }
  };

  const tabs: Array<Tab> = [
    {
      name: t('SubzoneDetails.Tabs.Location.Total'),
      value: 'locations',
      numberOfItems: subzoneId ? locationCount : EMPTY_RECORD_COUNT,
      content: (
        <SubzoneLocationList subzoneId={subzoneId} onCountChangeHandler={setLocationCount} />
      ),
    },
    {
      name: t('SubzoneDetails.Tabs.Vehicle.Total'),
      value: 'vehicles',
      numberOfItems: subzoneId
        ? (subzoneViewData.assets || []).filter(
            (asset) => asset.assetTypeCd === ASSET_TYPE_CODE.VEHICLE
          ).length
        : EMPTY_RECORD_COUNT,
      content: (
        <SubzoneVehicleList
          subzoneId={subzoneId}
          subzoneData={subzoneViewData}
          parentLayoutDistinctName={getValues()['parentLayoutDistinctName']}
          isSubzoneLoading={isSubzoneLoading}
          isSubzoneUpdating={isSubzoneUpdating}
          onLayoutMappingAttributeChange={onLayoutMappingAttributeChange}
        />
      ),
    },
  ];

  const onUpdateSubzone = (context = 'subzone') => {
    const payload = getValues();
    if (payload.attributes) {
      payload.attributes = payload.attributes
        .filter((attribute) => attribute.value !== '')
        .map((attribute) => {
          return {
            ...attribute,
            value: attribute.value.toString(),
          };
        });
    }

    const oldAttributes = subzoneData?.content[0].layout.attributes || [];
    const updatedAttributes = payload.attributes || [];

    if ((subzoneData?.content[0].xrefs || []).length) {
      const oldSubzoneType = oldAttributes.find(
        (attribute) => attribute.name === SUBZONE_FIELD.SUBZONE_TYPE
      );
      const updatedSubzoneType = updatedAttributes.find(
        (attribute) => attribute.name === SUBZONE_FIELD.SUBZONE_TYPE
      );
      if (
        oldSubzoneType &&
        updatedSubzoneType &&
        oldSubzoneType.value !== updatedSubzoneType.value
      ) {
        handleNotification(
          NOTIFICATION_TYPES.ERROR,
          t('Errors.Subzones.ExistingSubzoneReservePrioritySubzoneTypeChange')
        );
        return false;
      }
    }

    mutateSubzone(
      {
        layoutKey: payload.layoutKey || '',
        layoutRequest: {
          ...payload,
          parentEntityTypeCd: LAYOUT_ENTITY_TYPE_CODE.DEFAULT,
          assets: (payload.assets || [])
            .filter((asset) => !!asset.assetCd)
            .map((asset) => {
              return {
                assetCd: asset.assetCd,
                assetDesc: asset.assetDesc,
                assetTypeCd: asset.assetTypeCd,
                ...(asset.assetTypeCd === ASSET_TYPE_CODE.VEHICLE && {
                  assetLayoutMappingAttrib: asset.assetLayoutMappingAttrib,
                }),
              };
            }),
        },
      },
      {
        onSuccess: (data) => {
          if (data) {
            queryClient.invalidateQueries(['subzones']);
            queryClient.invalidateQueries(['subzone']);
            let successMessage = t('Success.Action.Subzone.Created');
            if (payload.layoutKey) {
              successMessage = t('Success.Action.Subzone.Updated', {
                subzoneName: payload.layoutName,
              });
            }

            if (context === 'vehicle') {
              successMessage = t('Success.Action.Vehicle.Updated', {
                vehicleName: '',
              });
            }
            handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
            if (!subzoneId && data.layoutDistinctName) {
              navigate(PAGE_URLS.SUB_ZONE_DETAILS(data.layoutDistinctName));
            } else {
              const layoutDistinctName = `${LAYOUT_ENTITY_CODE.SUBZONE}=${payload.layoutName},${payload.parentLayoutDistinctName}`;
              navigate(PAGE_URLS.SUB_ZONE_DETAILS(layoutDistinctName));
            }
          }
        },
      }
    );
  };

  const onSubmit = () => {
    onUpdateSubzone();
  };

  const onDeleteSubzone = () => {
    if (subzoneId && !isDeleting) {
      const payload = getValues();
      mutateDeleteSubzone(
        {
          layoutKey: payload.layoutKey || '',
        },
        {
          onSuccess: async () => {
            navigate(PAGE_URLS.SUB_ZONE_LIST);
          },
        }
      );
    }
  };

  /* Hooks */
  const populateDropdownOptions = useCallback(() => {
    const pickMethods = (configsData || [])
      .filter((config) => config.configCd === SUBZONE_FIELD.PICK_METHOD)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));
    setPickMethodOptions(pickMethods);

    const subzoneTypes = (configsData || [])
      .filter((config) => config.configCd === SUBZONE_FIELD.SUBZONE_TYPE)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));

    setSubzoneTypeOptions(subzoneTypes);

    const labelSorts = (configsData || [])
      .filter((config) => config.configCd === SUBZONE_FIELD.LABEL_SORT)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));

    setLabelSortOptions(labelSorts);

    const skuPickClasses = (configsData || [])
      .filter((config) => config.configCd === SUBZONE_FIELD.SKU_PICK_CLASS)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));

    setSkuPickClassOptions(skuPickClasses);

    const containerTypes = (containersData?.content || []).map((config) => ({
      label: config.assetDesc,
      value: config.assetCd,
    }));

    setContainerTypeOptions(containerTypes);
  }, [configsData, containersData]);

  const { zonesData, isFetching: isZonesLoading } = useGetZones({
    currentPage: DEFAULT_PAGE - 1,
    entityAssociations: [],
    sortBy: 'layoutName',
    direction: 'ASC',
    pageSize: MAX_PAGE_SIZE,
  });

  /* Hooks */
  useEffect(() => {
    // preselect the zone name dropdown if query params has selectedZoneId value set
    if (selectedZoneId) {
      setValue('parentLayoutDistinctName', selectedZoneId);
    }
  }, [setValue, selectedZoneId]);

  useEffect(() => {
    if (subzoneData && subzoneData.content[0]) {
      const products = subzoneData.content[0].products || [];
      let attributes = subzoneData.content[0].layout.attributes || [];
      for (const defaultAttributeValue of defaultAttributeValues) {
        if (!attributes.find((a) => a.name === defaultAttributeValue.name)) {
          attributes.push(defaultAttributeValue);
        }
      }

      attributes = attributes.filter((attribute) =>
        defaultAttributeValues.find(
          (defaultAttributeValue) => attribute.name === defaultAttributeValue.name
        )
      );

      const assets = subzoneData.content[0].assets || [];
      for (const defaultLayoutAssetValue of defaultLayoutAssetValues) {
        if (!assets.find((a) => a.assetTypeCd === defaultLayoutAssetValue.assetTypeCd)) {
          assets.push(defaultLayoutAssetValue);
        }
      }

      subzoneReset({
        ...subzoneData.content[0].layout,
        parentLayoutDistinctName: subzoneData.content[0].parent?.layoutDistinctName || '',
        attributes,
        assets,
        products,
      });

      setSubzoneViewData({
        ...subzoneData.content[0].layout,
        attributes,
        assets,
        products,
      });

      setIsDataLoaded(true);
    } else if (subzoneId && subzoneData && subzoneData.content.length === 0) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('SubzoneNotFound'));
    }
  }, [handleNotification, subzoneData, subzoneId, subzoneReset, t]);

  useEffect(() => {
    populateDropdownOptions();
  }, [configsData, populateDropdownOptions]);

  useEffect(() => {
    setLocationCount(locationsData?.totalElements || EMPTY_RECORD_COUNT);
  }, [locationsData]);

  if (!isDataLoaded) {
    return <DetailsSectionSkeleton items={10} />;
  } else {
    return (
      <>
        <View className={styles['subzone-detail']} backgroundColor="secondary" direction="column">
          <View
            attributes={{
              'data-testid': 'subzone-details-header',
            }}
          >
            <MasterTitle
              title={`${t('Subzone')}: ${subzoneName ? subzoneName : t('Untitled')}`}
              breadcrumbProps={breadcrumbs}
            >
              <View
                attributes={{
                  'data-testid': 'subzone-details-action',
                }}
                direction="row"
                justify="end"
                align="center"
                gap={4}
              >
                <View.Item>
                  <View direction="row" gap={4}>
                    <View.Item>
                      <Button
                        variant="secondary"
                        size="large"
                        onClick={() => navigate(PAGE_URLS.SUB_ZONE_LIST)}
                      >
                        <View direction="row" align="center" justify="center" gap={2}>
                          <Text>{t('Cancel')}</Text>
                        </View>
                      </Button>
                    </View.Item>
                  </View>
                </View.Item>
                <View.Item>
                  <View direction="row" gap={4}>
                    <View.Item>
                      <Button
                        size="large"
                        loading={isSubzoneUpdating}
                        onClick={() => handleSubmit(onSubmit)()}
                      >
                        <View direction="row" align="center" justify="center" gap={2}>
                          <Text>{t('SaveEdits')}</Text>
                        </View>
                      </Button>
                    </View.Item>
                  </View>
                </View.Item>
                {subzoneId ? (
                  <ActionMenu
                    menus={[
                      {
                        label: isDeleting ? <Loader /> : t('RemoveSubzone'),
                        clickHandler: onDeleteSubzone,
                        labelColor: 'error',
                      },
                    ]}
                  />
                ) : null}
              </View>
            </MasterTitle>
          </View>
          <View padding={6}>
            <View>
              <View.Item>
                <Text size="125" weight="bold">
                  {t('SubzoneDetails.Title')}
                </Text>
              </View.Item>
            </View>

            <View
              className={classNames(
                styles['subzone-detail__form-field-section'],
                styles['subzone-detail__banner-section']
              )}
              justify="center"
            >
              <View direction="row" gap={2} align="center">
                <Icon svg={Info} />
                <Text>{t('SubzoneDetails.Banner.Title')}</Text>
              </View>
            </View>

            <View
              backgroundColor="secondary"
              className={styles['subzone-detail__form-field-section']}
            >
              <View>
                <form key={btoa(JSON.stringify(subzoneViewData))}>
                  <View direction="row" gap={4}>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <Text
                          className={styles['subzone-detail__form-field-label--required']}
                          weight="medium"
                          size="087"
                        >
                          {t('Warehouse')}
                        </Text>
                        <FormControl>
                          <Select
                            attributes={{
                              'data-testid': 'warehouse-id',
                            }}
                            label={t('Warehouse')}
                            name="warehouse"
                            variant="no-label"
                            multiSelect={false}
                            defaultValue={{
                              label: 'DC ' + selectedDC?.toString(),
                              value: 'DC ' + selectedDC?.toString(),
                            }}
                            options={[]}
                            disabled
                          />
                        </FormControl>
                        <Text weight="regular" color="600" size="087">
                          {t('SubzoneDetails.WarehouseInstruction')}
                        </Text>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View
                        attributes={{
                          'data-testid': 'zone-name',
                        }}
                        gap={2}
                      >
                        <Text
                          className={styles['subzone-detail__form-field-label--required']}
                          weight="medium"
                          size="087"
                        >
                          {t('SubzoneDetails.ZoneName.Label')}
                        </Text>
                        <FormControl hasError={!!errors.parentLayoutDistinctName}>
                          <Controller
                            control={control}
                            name="parentLayoutDistinctName"
                            render={({ field: { onChange, onBlur, value } }) => (
                              <>
                                {isZonesLoading ? (
                                  <Loader variant="spinner" color="primary" size="small" />
                                ) : (
                                  <Select
                                    label={t('SubzoneDetails.ZoneName.Placeholder')}
                                    placeholder={t('SubzoneDetails.ZoneName.Placeholder')}
                                    name="parentLayoutDistinctName"
                                    variant="no-label"
                                    disabled={!!selectedZoneId}
                                    options={(zonesData?.content || []).map((row) => ({
                                      label: row.layout.layoutName,
                                      value: row.layout.layoutDistinctName.toString(),
                                    }))}
                                    value={{
                                      label:
                                        (zonesData?.content || []).find(
                                          (option) => option.layout.layoutDistinctName === value
                                        )?.layout.layoutName || '',
                                      value: value || '',
                                    }}
                                    inputAttributes={{ onBlur }}
                                    onValueChange={(layoutName) => onChange(layoutName?.value)}
                                  />
                                )}
                              </>
                            )}
                          />
                          {errors.parentLayoutDistinctName && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {getFormInputError(errors.parentLayoutDistinctName.type)}
                              </FormControl.Error>
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                  </View>

                  <View
                    direction="row"
                    gap={4}
                    className={styles['subzone-detail__form-field-section']}
                  >
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <Text
                          className={styles['subzone-detail__form-field-label--required']}
                          weight="medium"
                          size="087"
                        >
                          {t('SubzoneDetails.Name.Label')}
                        </Text>
                        <FormControl hasError={!!errors.layoutName}>
                          <TextField
                            attributes={{
                              'data-testid': 'name',
                            }}
                            inputAttributes={{
                              ...register('layoutName'),
                              placeholder: t('SubzoneDetails.Name.Placeholder'),
                              maxLength: MAX_LENGTH_FIELD.SUBZONE_NAME,
                            }}
                            defaultValue=""
                            disabled={!!subzoneId}
                          />
                          {errors?.layoutName && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>{errors?.layoutName.message}</FormControl.Error>
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View
                        attributes={{
                          'data-testid': 'subzone-type',
                        }}
                        gap={2}
                      >
                        <Text
                          className={styles['subzone-detail__form-field-label--required']}
                          weight="medium"
                          size="087"
                        >
                          {t('SubzoneDetails.Type.Label')}
                        </Text>

                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.SUBZONE_TYPE
                                )
                              )
                            )
                          }
                        >
                          <Controller
                            control={control}
                            name={`attributes.${getFormFieldIndexByKey(
                              getValues()['attributes'],
                              SUBZONE_FIELD.SUBZONE_TYPE
                            )}.value`}
                            render={({ field: { onChange, onBlur, value } }) => (
                              <>
                                {isConfigDataLoading ? (
                                  <Loader variant="spinner" color="primary" size="small" />
                                ) : (
                                  <Select
                                    label={t('SubzoneDetails.Type.Placeholder')}
                                    variant="no-label"
                                    options={subzoneTypeOptions}
                                    value={{
                                      label:
                                        subzoneTypeOptions.find((option) => option.value === value)
                                          ?.label || '',
                                      value: value || '',
                                    }}
                                    inputAttributes={{ onBlur }}
                                    onValueChange={(SUBZONE_TYPE) => {
                                      onChange(SUBZONE_TYPE?.value);
                                      revalidateAttributeField(SUBZONE_FIELD.SUBZONE_TYPE);
                                    }}
                                  />
                                )}
                              </>
                            )}
                          />

                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.SUBZONE_TYPE
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View
                        attributes={{
                          'data-testid': 'container-type',
                        }}
                        gap={2}
                      >
                        <Text weight="medium" size="087">
                          {t('SubzoneDetails.ContainerType.Label')}
                        </Text>
                        <FormControl
                          hasError={
                            !!(
                              errors.assets &&
                              displayLayoutAssetFieldValidationMessage(
                                errors,
                                getLayoutAssetFormFieldIndexByAssetTypeCd(
                                  getValues()['assets'],
                                  ASSET_TYPE_CODE.CONTAINER
                                )
                              )
                            )
                          }
                        >
                          <Controller
                            control={control}
                            name={`assets.${getLayoutAssetFormFieldIndexByAssetTypeCd(
                              getValues()['assets'],
                              ASSET_TYPE_CODE.CONTAINER
                            )}.assetCd`}
                            render={({ field: { onChange, onBlur, value } }) => (
                              <>
                                {isContainerLoading ? (
                                  <Loader variant="spinner" color="primary" size="small" />
                                ) : (
                                  <Select
                                    label={t('SubzoneDetails.ContainerType.Placeholder')}
                                    variant="no-label"
                                    options={containerTypeOptions}
                                    value={{
                                      label:
                                        containerTypeOptions.find(
                                          (option) => option.value === value
                                        )?.label || '',
                                      value: value || '',
                                    }}
                                    inputAttributes={{ onBlur }}
                                    onValueChange={(CONTAINER_TYPE) =>
                                      onChange(CONTAINER_TYPE?.value)
                                    }
                                  />
                                )}
                              </>
                            )}
                          />

                          <FormControl.Error>
                            {errors.assets &&
                              displayLayoutAssetFieldValidationMessage(
                                errors,
                                getLayoutAssetFormFieldIndexByAssetTypeCd(
                                  getValues()['assets'],
                                  ASSET_TYPE_CODE.CONTAINER
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>

                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <FormLabel
                          text={t('SubzoneDetails.CategoryLabel.Label')}
                          isRequired={true}
                        />
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.CATEGORY_LABEL
                                )
                              )
                            )
                          }
                        >
                          <TextField
                            attributes={{
                              'data-testid': 'category-label',
                            }}
                            defaultValue=""
                            inputAttributes={{
                              placeholder: t('SubzoneDetails.CategoryLabel.Placeholder'),
                              ...register(
                                `attributes.${getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.CATEGORY_LABEL
                                )}.value`
                              ),
                              onChange: (e) => {
                                setValue(
                                  `attributes.${getFormFieldIndexByKey(
                                    getValues()['attributes'],
                                    SUBZONE_FIELD.CATEGORY_LABEL
                                  )}.value`,
                                  e.target.value
                                );
                                revalidateAttributeField(SUBZONE_FIELD.CATEGORY_LABEL);
                              },
                              maxLength: MAX_LENGTH_FIELD.SUB_ZONE_CATEGORY_LABEL,
                            }}
                          />
                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.CATEGORY_LABEL
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                  </View>

                  <View
                    direction="row"
                    gap={4}
                    className={styles['subzone-detail__form-field-section']}
                  >
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <Text weight="medium" size="087">
                          {`${t('SubzoneDetails.Description.Label')} (${t('Optional')})`}
                        </Text>
                        <FormControl hasError={!!errors?.layoutNameDesc}>
                          <TextField
                            attributes={{
                              'data-testid': 'description',
                            }}
                            inputAttributes={{
                              ...register('layoutNameDesc'),
                              placeholder: t('SubzoneDetails.Description.Placeholder'),
                              maxLength: MAX_LENGTH_FIELD.SUBZONE_DESCRIPTION,
                            }}
                            defaultValue=""
                          />
                          {errors?.layoutNameDesc && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {errors?.layoutNameDesc.message}
                              </FormControl.Error>
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <Text
                          className={styles['subzone-detail__form-field-label--required']}
                          weight="medium"
                          size="087"
                        >
                          {t('SubzoneDetails.PickMethod.Label')}
                        </Text>
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.PICK_METHOD
                                )
                              )
                            )
                          }
                        >
                          <Controller
                            control={control}
                            name={`attributes.${getFormFieldIndexByKey(
                              getValues()['attributes'],
                              SUBZONE_FIELD.PICK_METHOD
                            )}.value`}
                            render={({ field: { onChange, onBlur, value } }) => (
                              <>
                                {isConfigDataLoading ? (
                                  <Loader variant="spinner" color="primary" size="small" />
                                ) : (
                                  <Select
                                    attributes={{
                                      'data-testid': 'pick-method',
                                    }}
                                    label={t('SubzoneDetails.PickMethod.Placeholder')}
                                    variant="no-label"
                                    options={pickMethodOptions}
                                    value={{
                                      label:
                                        pickMethodOptions.find((option) => option.value === value)
                                          ?.label || '',
                                      value: value || '',
                                    }}
                                    inputAttributes={{ onBlur }}
                                    onValueChange={(PICK_METHOD) => {
                                      onChange(PICK_METHOD?.value);
                                      revalidateAttributeField(SUBZONE_FIELD.PICK_METHOD);
                                    }}
                                  />
                                )}
                              </>
                            )}
                          />

                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.PICK_METHOD
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <Text
                          className={styles['subzone-detail__form-field-label--required']}
                          weight="medium"
                          size="087"
                        >
                          {t('SubzoneDetails.LabelSort.Label')}
                        </Text>
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.LABEL_SORT
                                )
                              )
                            )
                          }
                        >
                          <Controller
                            control={control}
                            name={`attributes.${getFormFieldIndexByKey(
                              getValues()['attributes'],
                              SUBZONE_FIELD.LABEL_SORT
                            )}.value`}
                            render={({ field: { onChange, onBlur, value } }) => (
                              <>
                                {isConfigDataLoading ? (
                                  <Loader variant="spinner" color="primary" size="small" />
                                ) : (
                                  <Select
                                    attributes={{
                                      'data-testid': 'label-sort',
                                    }}
                                    label={t('SubzoneDetails.LabelSort.Placeholder')}
                                    variant="no-label"
                                    options={labelSortOptions}
                                    value={{
                                      label:
                                        labelSortOptions.find((option) => option.value === value)
                                          ?.label || '',
                                      value: value || '',
                                    }}
                                    inputAttributes={{ onBlur }}
                                    onValueChange={(LABEL_SORT) => {
                                      onChange(LABEL_SORT?.value);
                                      revalidateAttributeField(SUBZONE_FIELD.LABEL_SORT);
                                    }}
                                  />
                                )}
                              </>
                            )}
                          />

                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.LABEL_SORT
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <Text
                          className={styles['subzone-detail__form-field-label--required']}
                          weight="medium"
                          size="087"
                        >
                          {t('SubzoneDetails.SkuPickClass.Label')}
                        </Text>
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.SKU_PICK_CLASS
                                )
                              )
                            )
                          }
                        >
                          <Controller
                            control={control}
                            name={`attributes.${getFormFieldIndexByKey(
                              getValues()['attributes'],
                              SUBZONE_FIELD.SKU_PICK_CLASS
                            )}.value`}
                            render={({ field: { onChange, onBlur, value } }) => (
                              <>
                                {isConfigDataLoading ? (
                                  <Loader variant="spinner" color="primary" size="small" />
                                ) : (
                                  <Select
                                    attributes={{
                                      'data-testid': 'pick-class',
                                    }}
                                    label={t('SubzoneDetails.SkuPickClass.Placeholder')}
                                    variant="no-label"
                                    options={skuPickClassOptions}
                                    value={{
                                      label:
                                        skuPickClassOptions.find((option) => option.value === value)
                                          ?.label || '',
                                      value: value || '',
                                    }}
                                    inputAttributes={{ onBlur }}
                                    onValueChange={(SKU_PICK_CLASS) => {
                                      onChange(SKU_PICK_CLASS?.value);
                                      revalidateAttributeField(SUBZONE_FIELD.SKU_PICK_CLASS);
                                    }}
                                  />
                                )}
                              </>
                            )}
                          />

                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.SKU_PICK_CLASS
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                  </View>

                  <View
                    direction="row"
                    gap={4}
                    className={styles['subzone-detail__form-field-section']}
                  >
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <Text
                          className={styles['subzone-detail__form-field-label--required']}
                          weight="medium"
                          size="087"
                        >
                          {t('SubzoneDetails.PickDropLocation.Label')}
                        </Text>
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.PICK_DROP_LOCATION
                                )
                              )
                            )
                          }
                        >
                          <TextField
                            attributes={{
                              'data-testid': 'pick-drop-location',
                            }}
                            inputAttributes={{
                              placeholder: t('SubzoneDetails.PickDropLocation.Placeholder'),
                              ...register(
                                `attributes.${getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.PICK_DROP_LOCATION
                                )}.value`
                              ),
                              maxLength: MAX_LENGTH_FIELD.SUBZONE_PICK_DROP_LOCATION,
                              onChange: (e) => {
                                setValue(
                                  `attributes.${getFormFieldIndexByKey(
                                    getValues()['attributes'],
                                    SUBZONE_FIELD.PICK_DROP_LOCATION
                                  )}.value`,
                                  e.target.value
                                );
                                revalidateAttributeField(SUBZONE_FIELD.PICK_DROP_LOCATION);
                              },
                            }}
                            defaultValue=""
                          />
                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.PICK_DROP_LOCATION
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <Text
                          className={styles['subzone-detail__form-field-label--required']}
                          weight="medium"
                          size="087"
                        >
                          {t('SubzoneDetails.PutDropLocation.Label')}
                        </Text>
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.PUT_DROP_LOCATION
                                )
                              )
                            )
                          }
                        >
                          <TextField
                            attributes={{
                              'data-testid': 'put-drop-location',
                            }}
                            inputAttributes={{
                              placeholder: t('SubzoneDetails.PutDropLocation.Placeholder'),
                              ...register(
                                `attributes.${getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.PUT_DROP_LOCATION
                                )}.value`
                              ),
                              onChange: (e) => {
                                setValue(
                                  `attributes.${getFormFieldIndexByKey(
                                    getValues()['attributes'],
                                    SUBZONE_FIELD.PUT_DROP_LOCATION
                                  )}.value`,
                                  e.target.value
                                );
                                revalidateAttributeField(SUBZONE_FIELD.PUT_DROP_LOCATION);
                              },
                              maxLength: MAX_LENGTH_FIELD.SUBZONE_PUT_DROP_LOCATION,
                            }}
                            defaultValue=""
                          />
                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.PUT_DROP_LOCATION
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View
                        attributes={{
                          'data-testid': 'multiple-parts-allowed-within-location',
                        }}
                        gap={2}
                      >
                        <Text
                          className={styles['subzone-detail__form-field-label--required']}
                          weight="medium"
                          size="087"
                        >
                          {t('SubzoneDetails.MultiplePartsAllowedWithinLocation.Label')}
                        </Text>
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.MULT_PART_ALLOW_LOC
                                )
                              )
                            )
                          }
                        >
                          <Controller
                            control={control}
                            name={`attributes.${getFormFieldIndexByKey(
                              getValues()['attributes'],
                              SUBZONE_FIELD.MULT_PART_ALLOW_LOC
                            )}.value`}
                            render={({ field: { onChange, value } }) => (
                              <View direction="row" gap={4}>
                                <Radio
                                  value="true"
                                  label={t('Yes')}
                                  checked={value === 'true'}
                                  onChange={(isMultiplePartsAllowedWithinLocation) =>
                                    onChange(isMultiplePartsAllowedWithinLocation)
                                  }
                                />
                                <Radio
                                  value="false"
                                  label={t('No')}
                                  checked={value === 'false'}
                                  onChange={(isMultiplePartsAllowedWithinLocation) =>
                                    onChange(isMultiplePartsAllowedWithinLocation)
                                  }
                                />
                              </View>
                            )}
                          />

                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.MULT_PART_ALLOW_LOC
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View
                        attributes={{
                          'data-testid': 'mezzanine',
                        }}
                        gap={2}
                      >
                        <Text
                          className={styles['subzone-detail__form-field-label--required']}
                          weight="medium"
                          size="087"
                        >
                          {t('SubzoneDetails.Mezzanine.Label')}
                        </Text>
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.MEZZANINE
                                )
                              )
                            )
                          }
                        >
                          <Controller
                            control={control}
                            name={`attributes.${getFormFieldIndexByKey(
                              getValues()['attributes'],
                              SUBZONE_FIELD.MEZZANINE
                            )}.value`}
                            render={({ field: { onChange, value } }) => (
                              <View direction="row" gap={4}>
                                <Radio
                                  value="true"
                                  label={t('Yes')}
                                  checked={value === 'true'}
                                  onChange={(MEZZANINE) => onChange(MEZZANINE)}
                                />
                                <Radio
                                  value="false"
                                  label={t('No')}
                                  checked={value === 'false'}
                                  onChange={(MEZZANINE) => onChange(MEZZANINE)}
                                />
                              </View>
                            )}
                          />

                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.MEZZANINE
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                  </View>

                  <View
                    className={styles['subzone-detail__form-field-section']}
                    direction="row"
                    gap={4}
                  >
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <FormLabel text={t('SubzoneDetails.Length.Label')} />
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.LENGTH
                                )
                              )
                            )
                          }
                        >
                          <TextField
                            attributes={{
                              'data-testid': 'length',
                            }}
                            defaultValue=""
                            inputAttributes={{
                              placeholder: t('SubzoneDetails.Length.Placeholder'),
                              ...register(
                                `attributes.${getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.LENGTH
                                )}.value`
                              ),
                              maxLength: MAX_LENGTH_FIELD.SUBZONE_LENGTH,
                            }}
                            endElement={<Text color="400">{DEFAULT_DIMENSION_UNIT}</Text>}
                          />
                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.LENGTH
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <FormLabel text={t('SubzoneDetails.Width.Label')} />
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.WIDTH
                                )
                              )
                            )
                          }
                        >
                          <TextField
                            attributes={{
                              'data-testid': 'width',
                            }}
                            defaultValue=""
                            inputAttributes={{
                              placeholder: t('SubzoneDetails.Width.Placeholder'),
                              ...register(
                                `attributes.${getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.WIDTH
                                )}.value`
                              ),
                              maxLength: MAX_LENGTH_FIELD.SUBZONE_WIDTH,
                            }}
                            endElement={<Text color="400">{DEFAULT_DIMENSION_UNIT}</Text>}
                          />
                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.WIDTH
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <FormLabel text={t('SubzoneDetails.Height.Label')} />
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.HEIGHT
                                )
                              )
                            )
                          }
                        >
                          <TextField
                            attributes={{
                              'data-testid': 'height',
                            }}
                            defaultValue=""
                            inputAttributes={{
                              placeholder: t('SubzoneDetails.Height.Placeholder'),
                              ...register(
                                `attributes.${getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.HEIGHT
                                )}.value`
                              ),
                              maxLength: MAX_LENGTH_FIELD.SUBZONE_HEIGHT,
                            }}
                            endElement={<Text color="400">{DEFAULT_DIMENSION_UNIT}</Text>}
                          />
                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.HEIGHT
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <FormLabel text={t('SubzoneDetails.Weight.Label')} />
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.WEIGHT
                                )
                              )
                            )
                          }
                        >
                          <TextField
                            attributes={{
                              'data-testid': 'weight',
                            }}
                            defaultValue=""
                            inputAttributes={{
                              placeholder: t('SubzoneDetails.Weight.Placeholder'),
                              ...register(
                                `attributes.${getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.WEIGHT
                                )}.value`
                              ),
                              maxLength: MAX_LENGTH_FIELD.SUBZONE_WEIGHT,
                            }}
                            endElement={<Text color="400">{DEFAULT_WEIGHT_UNIT}</Text>}
                          />
                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.WEIGHT
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                  </View>

                  <View
                    direction="row"
                    gap={4}
                    className={styles['subzone-detail__form-field-section']}
                  >
                    <View.Item
                      attributes={{
                        'data-testid': 'conveyor-flag',
                      }}
                      columns={{ s: 12, l: 3 }}
                    >
                      <FormControl
                        hasError={
                          !!(
                            errors.attributes &&
                            errors.attributes[
                              getFormFieldIndexByKey(
                                getValues()['attributes'],
                                SUBZONE_FIELD.CONVEYOR_FLAG
                              )
                            ]
                          )
                        }
                      >
                        <Controller
                          control={control}
                          name={`attributes.${getFormFieldIndexByKey(
                            getValues()['attributes'],
                            SUBZONE_FIELD.CONVEYOR_FLAG
                          )}.value`}
                          render={({ field: { onChange, value, ref } }) => (
                            <Checkbox
                              name={`attributes.${getFormFieldIndexByKey(
                                getValues()['attributes'],
                                SUBZONE_FIELD.CONVEYOR_FLAG
                              )}.value`}
                              value={value}
                              checked={value && value.toString() === 'true'}
                              inputAttributes={{ ref }}
                              label={t('SubzoneDetails.ConveyorFlag.Label')}
                              onChange={(event) => onChange(event)}
                            />
                          )}
                        />
                        {errors.attributes &&
                          errors.attributes[
                            getFormFieldIndexByKey(
                              getValues()['attributes'],
                              SUBZONE_FIELD.CONVEYOR_FLAG
                            )
                          ] && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {
                                  errors.attributes[
                                    getFormFieldIndexByKey(
                                      getValues()['attributes'],
                                      SUBZONE_FIELD.CONVEYOR_FLAG
                                    )
                                  ]?.message
                                }
                              </FormControl.Error>
                            </View>
                          )}
                      </FormControl>
                    </View.Item>
                    <View.Item
                      attributes={{
                        'data-testid': 'item-verify',
                      }}
                      columns={{ s: 12, l: 3 }}
                    >
                      <FormControl
                        hasError={
                          !!(
                            errors.attributes &&
                            errors.attributes[
                              getFormFieldIndexByKey(
                                getValues()['attributes'],
                                SUBZONE_FIELD.ITEM_VERIFY
                              )
                            ]
                          )
                        }
                      >
                        <Controller
                          control={control}
                          name={`attributes.${getFormFieldIndexByKey(
                            getValues()['attributes'],
                            SUBZONE_FIELD.ITEM_VERIFY
                          )}.value`}
                          render={({ field: { onChange, value, ref } }) => (
                            <Checkbox
                              name={`attributes.${getFormFieldIndexByKey(
                                getValues()['attributes'],
                                SUBZONE_FIELD.ITEM_VERIFY
                              )}.value`}
                              value={value}
                              checked={value && value.toString() === 'true'}
                              inputAttributes={{ ref }}
                              label={t('SubzoneDetails.ItemVerify.Label')}
                              onChange={(event) => onChange(event)}
                            />
                          )}
                        />
                        {errors.attributes &&
                          errors.attributes[
                            getFormFieldIndexByKey(
                              getValues()['attributes'],
                              SUBZONE_FIELD.ITEM_VERIFY
                            )
                          ] && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {
                                  errors.attributes[
                                    getFormFieldIndexByKey(
                                      getValues()['attributes'],
                                      SUBZONE_FIELD.ITEM_VERIFY
                                    )
                                  ]?.message
                                }
                              </FormControl.Error>
                            </View>
                          )}
                      </FormControl>
                    </View.Item>
                    <View.Item
                      attributes={{
                        'data-testid': 'bidirectional-pick',
                      }}
                      columns={{ s: 12, l: 3 }}
                    >
                      <FormControl
                        hasError={
                          !!(
                            errors.attributes &&
                            errors.attributes[
                              getFormFieldIndexByKey(
                                getValues()['attributes'],
                                SUBZONE_FIELD.BIDIRECTIONAL_PICK
                              )
                            ]
                          )
                        }
                      >
                        <Controller
                          control={control}
                          name={`attributes.${getFormFieldIndexByKey(
                            getValues()['attributes'],
                            SUBZONE_FIELD.BIDIRECTIONAL_PICK
                          )}.value`}
                          render={({ field: { onChange, value, ref } }) => (
                            <Checkbox
                              name={`attributes.${getFormFieldIndexByKey(
                                getValues()['attributes'],
                                SUBZONE_FIELD.BIDIRECTIONAL_PICK
                              )}.value`}
                              value={value}
                              checked={value && value.toString() === 'true'}
                              inputAttributes={{ ref }}
                              label={t('SubzoneDetails.BidirectionalPick.Label')}
                              onChange={(event) => onChange(event)}
                            />
                          )}
                        />
                        {errors.attributes &&
                          errors.attributes[
                            getFormFieldIndexByKey(
                              getValues()['attributes'],
                              SUBZONE_FIELD.BIDIRECTIONAL_PICK
                            )
                          ] && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {
                                  errors.attributes[
                                    getFormFieldIndexByKey(
                                      getValues()['attributes'],
                                      SUBZONE_FIELD.BIDIRECTIONAL_PICK
                                    )
                                  ]?.message
                                }
                              </FormControl.Error>
                            </View>
                          )}
                      </FormControl>
                    </View.Item>
                    <View.Item
                      attributes={{
                        'data-testid': 'item-desc-prompt',
                      }}
                      columns={{ s: 12, l: 3 }}
                    >
                      <FormControl
                        hasError={
                          !!(
                            errors.attributes &&
                            errors.attributes[
                              getFormFieldIndexByKey(
                                getValues()['attributes'],
                                SUBZONE_FIELD.ITEM_DESC_PROMPT
                              )
                            ]
                          )
                        }
                      >
                        <Controller
                          control={control}
                          name={`attributes.${getFormFieldIndexByKey(
                            getValues()['attributes'],
                            SUBZONE_FIELD.ITEM_DESC_PROMPT
                          )}.value`}
                          render={({ field: { onChange, value, ref } }) => (
                            <Checkbox
                              name={`attributes.${getFormFieldIndexByKey(
                                getValues()['attributes'],
                                SUBZONE_FIELD.ITEM_DESC_PROMPT
                              )}.value`}
                              value={value}
                              checked={value && value.toString() === 'true'}
                              inputAttributes={{ ref }}
                              label={t('SubzoneDetails.ItemDescPrompt.Label')}
                              onChange={(event) => onChange(event)}
                            />
                          )}
                        />
                        {errors.attributes &&
                          errors.attributes[
                            getFormFieldIndexByKey(
                              getValues()['attributes'],
                              SUBZONE_FIELD.ITEM_DESC_PROMPT
                            )
                          ] && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {
                                  errors.attributes[
                                    getFormFieldIndexByKey(
                                      getValues()['attributes'],
                                      SUBZONE_FIELD.ITEM_DESC_PROMPT
                                    )
                                  ]?.message
                                }
                              </FormControl.Error>
                            </View>
                          )}
                      </FormControl>
                    </View.Item>
                  </View>

                  <View
                    direction="row"
                    gap={4}
                    className={styles['subzone-detail__form-field-section']}
                  >
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <Text
                          className={styles['subzone-detail__form-field-label--required']}
                          weight="medium"
                          size="087"
                        >
                          {t('SubzoneDetails.PickPriority.Label')}
                        </Text>
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.PICK_PRIORITY
                                )
                              )
                            )
                          }
                        >
                          <TextField
                            attributes={{
                              'data-testid': 'pick-priority',
                            }}
                            inputAttributes={{
                              placeholder: t('SubzoneDetails.PickPriority.Placeholder'),
                              maxLength: MAX_LENGTH_FIELD.SUBZONE_PICK_PRIORITY,
                              ...register(
                                `attributes.${getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.PICK_PRIORITY
                                )}.value`
                              ),
                              onChange: (e) => {
                                setValue(
                                  `attributes.${getFormFieldIndexByKey(
                                    getValues()['attributes'],
                                    SUBZONE_FIELD.PICK_PRIORITY
                                  )}.value`,
                                  e.target.value
                                );
                                revalidateAttributeField(SUBZONE_FIELD.PICK_PRIORITY);
                              },
                            }}
                            defaultValue=""
                          />
                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.PICK_PRIORITY
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>

                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <Text
                          className={styles['subzone-detail__form-field-label--required']}
                          weight="medium"
                          size="087"
                        >
                          {t('SubzoneDetails.PutawayPriority.Label')}
                        </Text>
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.PUT_PRIORITY
                                )
                              )
                            )
                          }
                        >
                          <TextField
                            attributes={{
                              'data-testid': 'putaway-priority',
                            }}
                            inputAttributes={{
                              placeholder: t('SubzoneDetails.PutawayPriority.Placeholder'),
                              maxLength: MAX_LENGTH_FIELD.SUBZONE_PUT_PRIORITY,
                              ...register(
                                `attributes.${getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.PUT_PRIORITY
                                )}.value`
                              ),
                              onChange: (e) => {
                                setValue(
                                  `attributes.${getFormFieldIndexByKey(
                                    getValues()['attributes'],
                                    SUBZONE_FIELD.PUT_PRIORITY
                                  )}.value`,
                                  e.target.value
                                );
                                revalidateAttributeField(SUBZONE_FIELD.PUT_PRIORITY);
                              },
                            }}
                            defaultValue=""
                          />
                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  SUBZONE_FIELD.PUT_PRIORITY
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                  </View>
                </form>
              </View>
            </View>
          </View>

          <Tabs tabs={tabs} />
        </View>
      </>
    );
  }
};
