/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  CYCLE_COUNT_TABS,
  CYCLE_COUNT_TYPE_CD,
  HEADER_COMPONENT,
  USER_STATUS_CD,
  CYCLE_COUNT_STATUS_CD,
  REPORT_TYPE,
} from '@inventory/constants/constants';
import { ROUTES } from '@shared/constants/routes';
import { InboundOrderDataType, SubzoneType, LocationSchema } from '@inventory/types/types';
import { assigneeListType } from '@inventory/pages/CycleCounts/components/Subzones/Subzones.types';

export const getSubzone = (input: string) => {
  const match = input.match(/SZ=([^,]*)/);
  const szValue = match ? match[1] : null;
  return szValue;
};

export const getInboundOrderDataBySubzone = (
  inboundOrderData: InboundOrderDataType[],
  subzone: string
) =>
  inboundOrderData?.filter((item) =>
    item.inboundOrder.inboundOrderLines.some(
      (line) =>
        line.inboundOrderLineLocations &&
        line.inboundOrderLineLocations.some((location) => {
          if (getSubzone(location.layoutDistinctName) === subzone) {
            return true;
          } else {
            return false;
          }
        })
    )
  );

export const checkCountTypeCd = (CountTypeCdValue: string) => {
  const countTypeMap: { [key: string]: string } = {
    [CYCLE_COUNT_TABS.WALL_TO_WALL]: CYCLE_COUNT_TYPE_CD.WALL_TO_WALL,
    [CYCLE_COUNT_TABS.RISK_BASED]: CYCLE_COUNT_TYPE_CD.RBCC,
    [CYCLE_COUNT_TYPE_CD.WALL_TO_WALL]: HEADER_COMPONENT.WALL_TO_WALL,
    [CYCLE_COUNT_TYPE_CD.RBCC]: HEADER_COMPONENT.RISK_BASED,
  };

  return countTypeMap[CountTypeCdValue] || '';
};

export function getDistinctAssignedUsers(subzones: SubzoneType[]): {
  subzoneAssignedUsers: assigneeListType[];
  subZoneLocations: LocationSchema[];
} {
  const userMap = new Map();
  const combinedLocations = new Set<LocationSchema>();

  subzones.forEach((subzone) => {
    // Add all locations to the combinedLocations set
    (subzone.locations || []).forEach((location) => {
      combinedLocations.add(location);
    });

    (subzone?.assignedUsers || []).forEach(({ userStatusCd, userId, firstName, lastName }) => {
      if (userStatusCd !== USER_STATUS_CD.UNASSIGNED && !userMap.has(userId)) {
        userMap.set(userId, {
          userId: userId,
          firstName: firstName,
          lastName: lastName,
          statusCd: userStatusCd,
          layoutLocationCount: 0,
          assignee: firstName ? `${firstName} ${lastName ?? ''}` : '',
          isNewUser: false,
        });
      }
    });
  });

  return {
    subzoneAssignedUsers: Array.from(userMap.values()),
    subZoneLocations: Array.from(combinedLocations),
  };
}

export function getInProgressLocationCountsByUser(subZoneLocations: LocationSchema[]) {
  const userInProgressMap = new Map();

  subZoneLocations?.forEach((location: LocationSchema) => {
    if (location.assignedUserId && location.countStatusCd === CYCLE_COUNT_STATUS_CD.INPROGRESS) {
      if (userInProgressMap.has(location.assignedUserId)) {
        const currentCount = userInProgressMap.get(location.assignedUserId) || 0;
        userInProgressMap.set(location.assignedUserId, currentCount + 1);
      } else {
        userInProgressMap.set(location.assignedUserId, 1);
      }
    }
  });

  return userInProgressMap;
}

export const getReportType = (selectedTab: string | undefined) => {
  switch (selectedTab) {
    case ROUTES.OVERVIEW:
      return REPORT_TYPE.OVERVIEW;
    case ROUTES.SKUS_WITH_VARIANCES:
      return REPORT_TYPE.SKU_WITH_VARIANCE;
    case ROUTES.SUBZONES:
      return REPORT_TYPE.SUBZONE_PROGRESS;
    default:
      return '';
  }
};
