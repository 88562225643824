/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types.ts';

export enum OrderReleaseStatus {
  RELEASED = 'RELEASED',
  UNRELEASED = 'UNRELEASED',
  PARTIALLY_RELEASED = 'PARTIALLY_RELEASED',
  READY_FOR_RELEASE = 'READY_FOR_RELEASE',
  RPLENISHMENT_NOT_RUN = 'RPLENISHMENT_NOT_RUN',
}

export const OrderReleaseTabTitles = {
  OUTBOUND_MATRIX: 'OutboundMatrix.OrderTabs.OutboundMatrix',
  ORDER_RELEASE: 'OutboundMatrix.OrderTabs.OrderRelease',
  ORDER_REVIEW: 'OutboundMatrix.OrderTabs.OrderReview',
  HISTORICAL_ORDER: 'OutboundMatrix.OrderTabs.HistoricalOrders',
  REPLENISHMENT: 'OutboundMatrix.OrderTabs.Replenishment',
  WILL_CALL: 'OutboundMatrix.OrderTabs.WillCallOrders',
  NEW_STORE: 'OutboundMatrix.OrderTabs.NewStoreOrders',
  LTD: 'OutboundMatrix.OrderTabs.LongTailDistribution',
  TRANSFER: 'OutboundMatrix.OrderTabs.TransferOrders',
  CROSS_DOCK: 'OutboundMatrix.OrderTabs.CrossDock',
  DIF: 'OutboundMatrix.OrderTabs.Dif',
};

export const TrailerStatus = {
  NOT_STARTED: 'OutBoundShipments.NotStarted',
  IN_PROGRESS: 'OutBoundShipments.InProgress',
  CT_DOCK_LANE_CLOSED: 'OutBoundShipments.CtDockLaneClosed',
  SHIPPED: 'OutBoundShipments.Shipped',
};

export const ShipmentTimeTypes = {
  DSD: 'dsd',
  PO: 'po',
  LTL: 'ltl',
  LTD: 'ltd',
};

export const MatrixSortOptions = {
  ROUTE: 'ROUTE',
  WAVE: 'WAVE',
  RELESASE: 'RELESASE',
};

export const MatrixGroupBy = {
  WAVE: 'OutboundMatrix.OrderRelease.Wave',
  ROUTE: 'OutboundMatrix.OrderRelease.Route',
  NEW: 'OutboundMatrix.OrderRelease.New',
  BACKUP: 'OutboundMatrix.OrderRelease.Backup',
  DC: 'OutboundMatrix.OrderRelease.DC',
};

export const MatrixHeaderConst = {
  MinWrapDetailsCount: 3,
  ShowTooltipCount: 9,
};

export enum MatrixCellStatus {
  CLOSE = 'close',
  EMPTY = 'empty',
  PAPER = 'paper',
}

export const RELEASE_GROUPS_GROUP_TYPE = {
  BY_AREA: 'BY_AREA',
  BY_DC: 'BY_DC',
  BY_ORDER: 'BY_ORDER',
  ROOT: 'ROOT',
  LEAF: 'LEAF',
};

export const RELEASE_GROUPS_ORDER_TYPE = {
  DIF_WORK_ORDER: 'DIF_WORK_ORDER',
  TRANSFER: 'TRANSFER',
};

export const RELEASE_ORDER_STATUS = {
  CREATED: 'RELEASE_GROUP_CREATED',
  RELEASED: 'RELEASED',
  READY_FOR_RELEASE: 'READY_FOR_RELEASE',
  PARTIALLY_RELEASED: 'PARTIALLY_RELEASED',
  INPROGRESS: 'INPROGRESS',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const RELEASE_ORDER_STATUS_COLOR_CODE = {
  RELEASED: StatusVariants.COMPLETED,
  READY_FOR_RELEASE: StatusVariants.READY_FOR_ACTION,
  PARTIALLY_RELEASED: StatusVariants.IN_PROGRESS,
  INPROGRESS: StatusVariants.IN_PROGRESS,
  IN_PROGRESS: StatusVariants.IN_PROGRESS,
};

export const RELEASE_ORDER_FIELD = {
  AREA_COUNT: 'countOfAreas',
  DC_COUNT: 'countOfShipToFacilityId',
  ORDER_COUNT: 'countOfOrderIds',
  PALLETS: 'totalPallets',
  PIECES: 'totalPieces',
  SKU_COUNT: 'countOfSkus',
};

export const LAYOUT_TYPE_CD = {
  DOCK: 'DOCK',
};

export const LAYOUT_STATUS_CD = {
  OPEN: 'OPEN',
};
