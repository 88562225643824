/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const LIST = 'list';
export const KANBAN = 'kanban';
export const INYARD = 'inYard';
export const DCLOCATION = 'dcLocation';
export const GATE = 'Gate';
export const YARD = 'Yard';
export const DOOR = 'Door';
export const LANE = 'Lane';
export const AISLE = 'Aisle';
export const NOT_STARTED = 'NOT_STARTED';
export const DEFAULT_PAGE = 0;
export const PAGE_SIZE = 20;
export const DEFAULT_SORT_BY = 'arrivalTime';
export const SORT_BY_PRIORITY = 'priority';
export const ASC = 'ASC';
export const DESC = 'DESC';
export const SOURCE_ORDER_NUMBER = 'sourceOrderNbr';
export const TRAILER_ORDERS = 'TRAILER_ORDERS';
export const ORDERS_LINES = 'ORDER_LINES';
export const RECEIVED_LINES = 'RECEIVED_LINES';
export const READY_FOR_SIGNATURE = 'READY_FOR_SIGNATURE';
export const READY_FOR_FINALIZATION = 'READY_FOR_FINALIZATION';

export const DOMAIN_TYPE_CD = 'PO';
export const ORDER_LOCATION_TYPE_CD = 'DOOR';
export const COUNT_BY_PO_STATUS = 'countByPOStatus';
export const QUANTITY_MATCHED = 'quantity-matched';
export const QUANTITY_UNMATCHED = 'quantity-unmatched';
export const QUANTITY_FINALIZED = 'quantity-finalized';

export const ALPHA_NUMERIC_STRICT_REGEX = /^[a-zA-Z0-9 ]+$/;
export const ALPHA_NUMERIC_STRICT_WITH_SPACE_REGEX = /^[a-zA-Z0-9\s]+$/;
export const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9 !@#$%^&*()_+{}[\]:;<>,.?~\\/-]+$/;
export const ALPHA_NUMERIC_WITH_SPACE_REGEX = /^[a-zA-Z\d\s]+$/i;
export const NUMERIC_REGEX = /^[0-9]+$/;

export const MAX_VISIBLE_AVATARS_PO_DASHBOARD = 2;
export const MAX_VISIBLE_AVATARS_REPLENISHMENT_DASHBOARD = 1;

export const DEBOUNCE_TIME = 600;

export enum POActions {
  VIEW_DETAILS = 'VIEW_DETAILS',
  EDIT_DETAILS = 'EDIT_DETAILS',
  PUT_ON_HOLD = 'PUT_ON_HOLD',
  REMOVE = 'REMOVE',
  REMOVE_HOLD = 'REMOVE_HOLD',
  FINALIZE = 'FINALIZE',
}

export enum Lengths {
  SOURCE_ORDER_NUMBER = 8,
  RECEIVING_DOOR = 5,
  MAX_CARRIER_NAME = 30,
  MAX_TRAILER_NUMBER = 20,
  MAX_LOCATION = 10,
}

export const TRAILER_ORDER_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  RECEIVING_IN_PROGRESS: 'RECEIVING_IN_PROGRESS',
  READY_FOR_FINALIZATION: 'READY_FOR_FINALIZATION',
  IN_IC_REVIEW: 'IN_IC_REVIEW',
  READY_FOR_SIGNATURE: 'READY_FOR_SIGNATURE',
  CLOSED: 'CLOSED',
  REMOVED: 'REMOVED',
  CANCELED: 'CANCELED',
  HOLD: 'HOLD',
  FINALIZED: 'FINALIZED',
};

export const ORDER_TYPES = {
  DSD: 'DSD',
  PO: 'PO',
  LTL: 'LTL',
  LTD: 'LTD',
  REGULAR: 'REGULAR',
};

export const TRAILER_ORDER_STATUS_CDS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};

export const INBOUND_ORDER_STATUS = {
  ACCEPTED: 'ACCEPTED',
  NOT_ACCEPTED: 'NOT_ACCEPTED',
  IC_REVIEW_IN_PROGRESS: 'IC_REVIEW_IN_PROGRESS',
  IC_REVIEW_COMPLETED: 'IC_REVIEW_COMPLETED',
  READY_FOR_FINALIZATION: 'READY_FOR_FINALIZATION',
  RECEIVING_IN_PROGRESS: 'RECEIVING_IN_PROGRESS',
};

export const INBOUND_ORDER_LINE_STATUS = {
  ACCEPTED: 'ACCEPTED',
  CANCELLED: 'CANCELLED',
  IC_REVIEW_IN_PROGRESS: 'IC_REVIEW_IN_PROGRESS',
  IC_REVIEW_COMPLETED: 'IC_REVIEW_COMPLETED',
  NOT_ACCEPTED: 'NOT_ACCEPTED',
};

export const REPLENISHMENT_STATUS = {
  UNASSIGNED: 'UNASSIGNED',
  ASSIGNED: 'ASSIGNED',
  IN_PROGRESS: 'INPROGRESS',
  NEW: 'NEW',
  ALL: 'ALL',
  SKIPPED: 'SKIPPED',
};

export const REPLENISHMENT_STATUS_CDS = {
  ASSIGNED: 'ASSIGNED',
  IN_PROGRESS: 'INPROGRESS',
  NEW: 'NEW',
  SKIPPED: 'SKIPPED',
  UNASSIGNED: 'UNASSIGNED',
  CANCELED: 'CANCELED',
  COMPLETED: 'COMPLETED',
};

export const REPLENISHMENT_METRICS = {
  COUNT_BY_PRIORITY: 'COUNT_BY_PRIORITY',
  METRIC_BY_EFFECTIVE_TS: 'METRIC_BY_EFFECTIVE_TS',
};

export const REPLEN_ID = 'replnId';

export const REPLENISHMENT_ENTITY_ASSOCIATIONS = {
  REPLN_REQUEST: 'REPLN_REQUEST',
  REPLN_ASSIGNED_USERS: 'REPLN_ASSIGNED_USERS',
  REPLN_ASSETS: 'REPLN_ASSETS',
};

export const REPLENISHMENT_TIME = {
  ALL: 'ALL',
  OVER24HRS: 'OVER24HRS',
  ICREVIEW_IN_PROGRESS: 'IC_REVIEW_IN_PROGRESS',
  ICREVIEW_COMPLETED: 'IC_REVIEW_COMPLETED',
};

export const DUMMY_SOURCE_ORDER_NUMBER = '12345678';
export const DUMMY_TRAILER_IN_ID = 'c69a2a6bc85745168e213a91067f2141';

export enum FILE_TYPES {
  CSV = 'CSV',
  PDF = 'PDF',
}

export const REPLENISHMENT_FILTER_VALUE = {
  GROUP_BY_SUBZONE: 'groupBySubzone',
  GRID_VIEW: 'gridView',
  COUNT_By_SUBZONE: 'countBySubzone',
  COUNT_By_PRIORITY: 'countByPriority',
  SHOW_6000_REPLENISHMENTS: 'show6000Replenishments',
  ZONERS_IN_REPLENISHMENTS: 'zonersInReplenishments',
};

export const PO_DASHBOARD_SEARCH_BY = {
  NON_FINALIZED_PO_NUMBER: 'Non Finalized PO Number',
  SKU: 'SKU',
  PART_NUMBER: 'Part Number',
  VENDOR_NAME: 'Vendor Name',
};

export const PO_HISTORY_SEARCH_BY = {
  FINALIZED_PO_NUMBER: 'Finalized PO Number',
  SKU: 'SKU',
  PART_NUMBER: 'Part Number',
  VENDOR_NAME: 'Vendor Name',
};

export enum REPLENISHMENT_SORT_BY {
  DATE = 'srcRequestedTs',
  OUTS = 'outsAmount',
  SLOT_QTY = 'currentSlotQty',
  PIECES = 'totalReplnQty',
  SLOT = 'toLayoutName',
  SOURCE_LOCATION = 'fromLayoutName',
}

export enum ReplenishmentPriority {
  SevenThousand = '7000',
  SevenThousandPlus = '7000-plus',
  SixThousand = '6000',
}

export const TOTE_COMPARTMENT_SIZE_OPTIONS = [
  {
    label: 'Full Tote',
    value: 'FULL_TOTE',
  },
  {
    label: '1/2 Tote',
    value: '1/2_TOTE',
  },
  {
    label: '1/4 Tote',
    value: '1/4_TOTE',
  },
  {
    label: '1/8 Tote',
    value: '1/8_TOTE',
  },
];
