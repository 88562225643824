/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Divider, Icon, Modal, SearchBar, Text, View, classNames } from '@az/starc-ui';
import { Close } from '@az/starc-ui-icons';

import { Avatar } from '@shared/components/Avatar/Avatar';
import { EmptySuggestions } from '@shared/components/EmptySuggestions/EmptySuggestions';
import {
  EMPTY_VALUE,
  MAX_USER_SEARCH_FIELD_LENGTH,
  USER_STATUS_CD,
} from '@shared/constants/constants';

import { UserSearchSchemaType } from '@shared/types/schema.type';

import { RecommendedAssignees } from '@inbound/components/RecommendedAssignees/RecommendedAssignees';
import * as T from './UnreplenishedAddAssignessModal.types';
import s from './UnreplenishedAddAssignessModal.module.scss';
import { ReplenishmentUpdateByCriteria, ReplenishmentUsersType } from '@inbound/types/types';
import { AssigneesSuggestion } from '../ReplenishmentAddAssigneesModal/AssigneesSuggestion/AssigneesSuggestion';

export const UnreplenishedAddAssignessModal = ({
  priorityNumber,
  subzoneName,
  isOpen,
  keyName,
  assigneesData,
  onClose,
  onSubmit,
}: T.Props) => {
  /* State variables */
  const [searchValue, setSearchValue] = useState<string>('');

  const [matchingUsers, setMatchingUsers] = useState<UserSearchSchemaType[] | null>([]);
  const [hasNoResults, setHasNoResults] = useState<boolean>(false);
  const [hideSuggestions, setHideSuggestions] = useState(false);

  const [assignedUsers, setAssignedUsers] = useState<ReplenishmentUsersType | null>();

  const searchBar = useRef(null);

  /* Constants */
  const { t } = useTranslation();
  const maxRecommendedAssignee = 5;
  const recommendedAssignees = assigneesData.slice(0, maxRecommendedAssignee);

  /* Functions */

  const handleItemSelect = (user: UserSearchSchemaType) => {
    if (user) {
      setAssignedUsers({
        userId: user.userId,
        userStatusCd: USER_STATUS_CD.ASSIGNED,
        firstName: `${user?.firstName}`,
        lastName: `${user?.lastName}`,
      });
    }
  };

  const handleSearchSuggestions = () => {
    if (hasNoResults) {
      return <EmptySuggestions />;
    } else if (matchingUsers && matchingUsers.length > 0) {
      return (
        <AssigneesSuggestion
          data={matchingUsers}
          selectedUser={assignedUsers}
          recommendedAssignees={recommendedAssignees}
          onItemClick={handleItemSelect}
        />
      );
    } else {
      return <EmptySuggestions />;
    }
  };

  const onAssigneeSelect = (user: UserSearchSchemaType) => {
    handleItemSelect(user);
  };

  useEffect(() => {
    if (isOpen) {
      onAssigneeRemove();
    }
  }, [isOpen]);

  const onAssigneeRemove = () => {
    // alert("called");
    setAssignedUsers(null);
  };

  const handleOnClose = () => {
    setSearchValue('');
    onClose();
  };

  const handleOnSubmit = () => {
    if (assignedUsers && priorityNumber && subzoneName) {
      const payload: ReplenishmentUpdateByCriteria = {
        searchCriteria: {
          statusCds: ['NEW'],
          ...(keyName === 'vehicle'
            ? {
                replnAsset: {
                  assetCd: priorityNumber,
                },
              }
            : {
                priority: [priorityNumber],
              }),
          toParentLayoutName: subzoneName,
        },
        update: {
          statusCd: 'ASSIGNED',
          replnAssignedUsers: [
            {
              userId: assignedUsers.userId,
              firstName: assignedUsers.firstName ?? '',
              lastName: assignedUsers.lastName ?? '',
              userStatusCd: USER_STATUS_CD.ASSIGNED,
            },
          ],
        },
      };

      setSearchValue('');
      onSubmit(payload);
    }
    handleOnClose();
  };

  /* Hooks */

  useEffect(() => {
    // Filter assignees data according to search value in UI level
    if (assigneesData) {
      const assignees = assigneesData;

      if (searchValue) {
        const usersToShow = assignees.filter(
          (userItem: UserSearchSchemaType) =>
            userItem.userId === searchValue ||
            userItem.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
            userItem.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
            userItem.firstName
              .concat(' ', userItem.lastName.toLowerCase())
              .toLowerCase()
              .includes(searchValue.toLowerCase())
        );

        if (usersToShow.length) {
          setMatchingUsers(usersToShow);
          setHasNoResults(false);
        } else {
          setMatchingUsers([]);
          setHasNoResults(true);
        }
      } else {
        setMatchingUsers(assignees);
        setHasNoResults(false);
      }
    }
  }, [assigneesData, searchValue]);

  // useEffect added to close suggestion after click outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (searchBar.current && !(searchBar.current as HTMLElement).contains(event.target as Node)) {
        setHideSuggestions(true);
      } else {
        setHideSuggestions(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchBar]);

  return (
    <Modal
      open={isOpen}
      onClose={() => handleOnClose()}
      className={s['add-assignee-modal']}
      closeByClickAway={false}
    >
      <View className={s['add-assignee-modal__header']}>
        <View direction="column">
          <Text as="h2" size="175" weight="bold" color="primary">
            {t('PODashboard.Assignees.AddAssignee')}
          </Text>
        </View>
        <Button
          variant="ghost"
          onClick={handleOnClose}
          className={classNames(s['close-icon'], s['add-assignee-modal__header__close-button'])}
        >
          <Icon svg={Close} />
        </Button>
      </View>

      <View direction="column" className={s['add-assignee-modal__body']}>
        <View.Item attributes={{ ref: searchBar }}>
          <SearchBar
            value={searchValue}
            onValueChange={setSearchValue}
            suggestions={handleSearchSuggestions()}
            className={s['add-assignee-modal__body__search-bar']}
            label={t('PODashboard.Assignees.SearchPlaceholder')}
            maxMenuHeight={300}
            hideSuggestions={hideSuggestions}
            inputAttributes={{ maxLength: MAX_USER_SEARCH_FIELD_LENGTH }}
          />
        </View.Item>

        <View.Item>
          <View padding={[6, 0]}>
            <Divider color="300" />
          </View>
        </View.Item>

        <View.Item>
          {!assignedUsers && (
            <RecommendedAssignees
              assignees={recommendedAssignees}
              className={s['add-edit-assignee-recommended-assignee']}
              onButtonClick={(user) => onAssigneeSelect(user)}
            />
          )}

          {assignedUsers && (
            <View
              direction="column"
              className={s['add-assignee-modal__body__assignees_items_wrapper']}
            >
              <>
                {assignedUsers && (
                  <View.Item key={t('PODashboard.ItemKey', { item: 'assignees' })}>
                    <View direction="row" align="center">
                      <View.Item grow>
                        <Avatar
                          name={
                            assignedUsers?.firstName && assignedUsers?.lastName
                              ? `${assignedUsers.firstName} ${assignedUsers.lastName}`
                              : EMPTY_VALUE
                          }
                          size="large"
                          variant="assignee"
                          showText={true}
                        />
                      </View.Item>

                      <View.Item>
                        <Button variant="pill" onClick={() => onAssigneeRemove()}>
                          {t('Remove')}
                        </Button>
                      </View.Item>
                    </View>
                  </View.Item>
                )}
              </>
            </View>
          )}
        </View.Item>
      </View>

      <View className={s['add-assignee-modal__footer']}>
        <View
          width="100%"
          direction="row"
          justify="end"
          className={s['add-assignee-modal__footer__actions']}
        >
          <Button
            variant="secondary"
            attributes={{ style: { width: 'fit-content' } }}
            onClick={handleOnClose}
          >
            <Text>{t('Cancel')}</Text>
          </Button>

          <Button
            variant="primary"
            attributes={{ style: { width: 'fit-content' } }}
            onClick={handleOnSubmit}
          >
            <Text>{t('Update')}</Text>
          </Button>
        </View>
      </View>
    </Modal>
  );
};
