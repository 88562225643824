/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { FooterProps } from './Footer.types';
import s from './Footer.module.scss';
import { Button, View, classNames } from '@az/starc-ui';
import { ArrowLeft } from '@az/starc-ui-icons';

export const Footer = ({ children, className, hasBackButton, backButtonOnClick }: FooterProps) => {
  if (hasBackButton) {
    return (
      <View
        direction="row"
        justify="space-between"
        className={classNames(s['footer'], className)}
        align="center"
        padding={[4, 6, 4, 4]}
      >
        <View>
          <Button startIcon={ArrowLeft} variant="ghost" onClick={backButtonOnClick}>
            Back
          </Button>
        </View>
        <View direction="row" align="center" justify="end" gap={2}>
          {children}
        </View>
      </View>
    );
  } else {
    return (
      <View
        direction="row"
        align="center"
        justify="end"
        className={classNames(s['footer'], className)}
      >
        {children}
      </View>
    );
  }
};
