/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import { View, Text, Icon, Link } from '@az/starc-ui';
import { ArrowRight } from '@az/starc-ui-icons';

import { WidgetWithChildren } from '@taskManagement/components/WidgetWithChildren/WidgetWithChildren';

import { Stat } from '@shared/components/Stat/Stat';
import { CombinedPillTabs } from '@shared/components/CombinedPillTabs/CombinedPillTabs';

import { AccuracyMeasurementModal } from '@inventory/components/CycleCountsModal/AccuracyMeasurementModal/AccuracyMeasurementModal';
import {
  CycleCountFilter,
  FilterOption,
} from '@inventory/pages/CycleCounts/components/CycleCountFilter';
import { usePreparedFilterOptions } from '@inventory/hooks/usePreparedFilterOptions';
import { useGetPhaseProgress } from '@inventory/services/hooks/useCycleCounts';
import {
  DOLLAR_VARIANCE_THRESHOLD,
  METRICS,
  QTY_VARIANCE_THRESHOLD,
} from '@inventory/constants/constants';
import { formattedDate } from '@inventory/utils/topVarianceUtils';
import { calculateAccuracyMeasurements } from '@inventory/utils/accuracyMeasurementUtil';
import { checkCountTypeCd } from '@inventory/utils/utils';

import { AccuracyMeasurementsCardSkeleton } from './AccuracyMeasurementsCardSkeleton';
import * as T from '../../CycleCountDetailsOverview.types';
import styles from '../../CycleCountDetailsOverview.module.scss';

export const AccuracyMeasurementCard = ({ isRBCCFlow = false }: T.Props) => {
  const { countTypeCd } = useParams();
  const countTypeCdvalue = checkCountTypeCd(countTypeCd || '');

  const { defaultFilter, defaultOptions } = usePreparedFilterOptions({
    countTypeCd: countTypeCdvalue,
  });

  const [selectedFilter, setSelectedFilter] = useState<FilterOption>(defaultFilter);
  const [openAccuracyAdjustmentModal, setAccuracyAdjustmentModal] = useState(false);

  const [accuracyMeasurementData, setAccuracyMeasurementData] = useState({
    netDollarAccuracy: '0',
    absDollarAccuracy: '0',
    totalDollarCounted: 0,
    netDollarVariance: 0,
    absDollarVariance: 0,
    netPiecesAccuracy: '0',
    absPiecesAccuracy: '0',
    totalPiecesCounted: 0,
    netPiecesVariance: 0,
    absPiecesVariance: 0,
  });

  // Query
  const { data, isLoading } = useGetPhaseProgress({
    countTypeCd: countTypeCdvalue,
    fromTs: selectedFilter.fromTs || '',
    toTs: selectedFilter.toTs || '',
    dollarVarianceThershold: DOLLAR_VARIANCE_THRESHOLD,
    qtyVarianceThershold: QTY_VARIANCE_THRESHOLD,
    metrics: METRICS,
  });

  const handleFilterChange = (newFilter: FilterOption) => {
    const filterDates = {
      ...newFilter,
      fromTs: newFilter.fromTs || new Date().toISOString(),
      toTs: newFilter.toTs || new Date().toISOString(),
    };

    setSelectedFilter(filterDates);
  };

  useEffect(() => {
    if (data) {
      setAccuracyMeasurementData(calculateAccuracyMeasurements(data));
    }
  }, [data]);

  return (
    <View direction="row">
      <View width="100%">
        <WidgetWithChildren className={styles['cycle-count-card']}>
          <View direction="row" align="center" justify="space-between" padding={2}>
            <Text weight="bold" size="125">
              {t(`CycleCount.AccuracyMeasurementsCard.Title`)}
            </Text>
            <Link onClick={() => setAccuracyAdjustmentModal(true)}>
              <View direction="row" align="center" justify="space-between">
                <Text weight="bold" size="100">
                  {t(`CycleCount.AccuracyMeasurementsCard.ViewCustomDateRange`)}
                </Text>
                <Icon svg={ArrowRight} />
              </View>
            </Link>
          </View>
          <WidgetWithChildren.Body className={styles['shift-progress-body']}>
            <View direction="column">
              <View>
                {isRBCCFlow ? (
                  <CombinedPillTabs<FilterOption>
                    TabsFilterData={defaultOptions}
                    onTabSelect={setSelectedFilter}
                    selectedValue={selectedFilter}
                  />
                ) : (
                  <CycleCountFilter
                    countTypeCd={countTypeCdvalue}
                    selectedFilter={selectedFilter}
                    setSelectedFilter={handleFilterChange}
                  />
                )}
              </View>
              {isLoading ? (
                <AccuracyMeasurementsCardSkeleton />
              ) : (
                <View direction="column" padding={[6, 0]}>
                  <View direction="column">
                    <View>
                      <Text weight="bold" color="500">
                        {t(`CycleCount.AccuracyMeasurementsCard.DOLLARS`)}
                      </Text>
                    </View>
                  </View>
                  <View direction="row" padding={[4, 0]} gap={2} width="100%" align="center">
                    <View.Item grow>
                      <Stat
                        title={t(`CycleCount.AccuracyMeasurementsCard.NetDollarAccuracy`)}
                        primaryText={t(`CycleCount.AccuracyMeasurementModal.PercentValue`, {
                          value: accuracyMeasurementData.netDollarAccuracy,
                        })}
                        width="100%"
                      />
                    </View.Item>
                    <View.Item grow>
                      <Stat
                        title={t(`CycleCount.AccuracyMeasurementsCard.AbsoluteDollarAccuracy`)}
                        primaryText={t(`CycleCount.AccuracyMeasurementModal.PercentValue`, {
                          value: accuracyMeasurementData.absDollarAccuracy,
                        })}
                        width="100%"
                      />
                    </View.Item>
                  </View>
                  <View direction="row" gap={2} width="100%" align="center">
                    <View.Item grow className={styles['gray-card']}>
                      <Stat
                        title={t(`CycleCount.AccuracyMeasurementsCard.TotalDollarsCounted`)}
                        primaryText={t(`CycleCount.AccuracyMeasurementModal.DollarValue`, {
                          amount: accuracyMeasurementData.totalDollarCounted.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          ),
                        })}
                        width="100%"
                      />
                    </View.Item>
                    <View.Item grow className={styles['gray-card']}>
                      <Stat
                        title={t(`CycleCount.AccuracyMeasurementsCard.NetDollarVariance`)}
                        primaryText={t(`CycleCount.AccuracyMeasurementModal.DollarValue`, {
                          amount: accuracyMeasurementData.netDollarVariance.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          ),
                        })}
                        width="100%"
                      />
                    </View.Item>
                    <View.Item grow className={styles['gray-card']}>
                      <Stat
                        title={t(`CycleCount.AccuracyMeasurementsCard.AbsoluteDollarVariance`)}
                        primaryText={t(`CycleCount.AccuracyMeasurementModal.DollarValue`, {
                          amount: accuracyMeasurementData.absDollarVariance.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          ),
                        })}
                        width="100%"
                      />
                    </View.Item>
                  </View>
                </View>
              )}
              {isLoading ? (
                <AccuracyMeasurementsCardSkeleton />
              ) : (
                <View direction="column">
                  <View direction="column">
                    <View>
                      <Text weight="bold" color="500">
                        {t(`CycleCount.AccuracyMeasurementsCard.PIECES`)}
                      </Text>
                    </View>
                  </View>
                  <View direction="row" padding={[4, 0]} gap={2} width="100%" align="center">
                    <View.Item grow>
                      <Stat
                        title={t(`CycleCount.AccuracyMeasurementsCard.NetPieceAccuracy`)}
                        primaryText={t(`CycleCount.AccuracyMeasurementModal.PercentValue`, {
                          value: accuracyMeasurementData.netPiecesAccuracy,
                        })}
                        width="100%"
                      />
                    </View.Item>
                    <View.Item grow>
                      <Stat
                        title={t(`CycleCount.AccuracyMeasurementsCard.AbsolutePieceAccuracy`)}
                        primaryText={t(`CycleCount.AccuracyMeasurementModal.PercentValue`, {
                          value: accuracyMeasurementData.absPiecesAccuracy,
                        })}
                        width="100%"
                      />
                    </View.Item>
                  </View>
                  <View direction="row" gap={2} width="100%" align="center">
                    <View.Item grow className={styles['gray-card']}>
                      <Stat
                        title={t(`CycleCount.AccuracyMeasurementsCard.TotalPiecesCounted`)}
                        primaryText={accuracyMeasurementData.totalPiecesCounted.toLocaleString()}
                        width="100%"
                      />
                    </View.Item>
                    <View.Item grow className={styles['gray-card']}>
                      <Stat
                        title={t(`CycleCount.AccuracyMeasurementsCard.NetPieceVariance`)}
                        primaryText={accuracyMeasurementData.netPiecesVariance.toLocaleString()}
                        width="100%"
                      />
                    </View.Item>
                    <View.Item grow className={styles['gray-card']}>
                      <Stat
                        title={t(`CycleCount.AccuracyMeasurementsCard.AbsolutePieceVariance`)}
                        primaryText={accuracyMeasurementData.absPiecesVariance.toLocaleString()}
                        width="100%"
                      />
                    </View.Item>
                  </View>
                </View>
              )}
            </View>
          </WidgetWithChildren.Body>
        </WidgetWithChildren>
        {openAccuracyAdjustmentModal && (
          <AccuracyMeasurementModal
            isOpen={openAccuracyAdjustmentModal}
            startDate={formattedDate(selectedFilter.fromTs, 'MM/DD/YYYY')}
            endDate={formattedDate(selectedFilter.toTs, 'MM/DD/YYYY')}
            accuracyMeasurementData={accuracyMeasurementData}
            onClose={() => setAccuracyAdjustmentModal(false)}
            countTypeCd={data.countTypeCd}
          />
        )}
      </View>
    </View>
  );
};
