/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Text, View, Icon, Button, Actionable } from '@az/starc-ui';

import { Accordion } from '@shared/components/Accordion/Accordion';

import { Props } from '@outbound/components/ReleaseGroupAccordion/ReleaseGroupAccordion.types.tsx';
import Styles from '@outbound/components/ReleaseGroupAccordion/ReleaseGroupAccordion.module.scss';
import { ChevronDown, ChevronUp } from '@az/starc-ui-icons';
import {
  RELEASE_GROUPS_GROUP_TYPE,
  RELEASE_GROUPS_ORDER_TYPE,
  RELEASE_ORDER_FIELD,
  RELEASE_ORDER_STATUS,
} from '@outbound/constants/constants.ts';
import { ActionMenu } from '@mdm/components/ActionMenu/ActionMenu.tsx';
import { StatusBadge } from '@shared/components/StatusBadge/StatusBadge.tsx';
import { getAttributeValue } from '@shared/utils/commonUtils.ts';
import { useTranslation } from 'react-i18next';
import { OrderReviewByAreaList } from '@outbound/components/OrderReview/OrderReviewByAreaList.tsx';
import { Download } from '@shared/assets/icons';
import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types.ts';

export const OrderReviewByDCAccordion = ({
  releaseGroup,
  rootGroupId = '',
  orderType = RELEASE_GROUPS_ORDER_TYPE.DIF_WORK_ORDER,
}: Props) => {
  /* Constants */
  const { t } = useTranslation();
  const { attributes, groupName } = releaseGroup;
  const isTransferOrderTab = orderType === RELEASE_GROUPS_ORDER_TYPE.TRANSFER;

  /* State variables */
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [selectedGroupType, setSelectedGroupType] = useState<string>('');

  /* Functions */
  const handleAccordionClick = () => {
    setIsAccordionOpen((isOpen) => !isOpen);
  };

  useEffect(() => {
    if (isAccordionOpen && !selectedGroupType) {
      setSelectedGroupType(RELEASE_GROUPS_GROUP_TYPE.BY_DC);
    }
  }, [isAccordionOpen, selectedGroupType]);

  return (
    <>
      <View className={classNames(Styles['order-release-accordion'])}>
        <Accordion
          open={isAccordionOpen}
          header={{
            label: (
              <View direction="row" align="center">
                <View
                  direction="row"
                  align="center"
                  className={Styles['order-release-accordion__left']}
                >
                  <View
                    direction="row"
                    align="center"
                    padding={[4, 22, 4, 0]}
                    className={Styles['order-release-accordion__left--label']}
                  >
                    <Text size="100" weight="bold">
                      {groupName}
                    </Text>
                  </View>
                  <View padding={[4, 10, 4, 2]}>
                    <Text size="100" weight="regular">
                      {isTransferOrderTab
                        ? t('OutboundMatrix.OrderRelease.Skus', {
                            count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.SKU_COUNT),
                          })
                        : t('OutboundMatrix.OrderRelease.Area', {
                            count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.AREA_COUNT),
                          })}
                    </Text>
                  </View>
                  <View
                    padding={[4, 10, 4, 2]}
                    className={Styles['order-release-accordion__left--label__pallets']}
                  >
                    <Text size="100" weight="regular">
                      {t('OutboundMatrix.OrderRelease.Pallet', {
                        count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.PALLETS),
                      })}
                    </Text>
                  </View>
                  <View padding={[4, 10, 4, 2]}>
                    <Text size="100" weight="regular">
                      {t('OutboundMatrix.OrderRelease.Pieces', {
                        count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.PIECES),
                      })}
                    </Text>
                  </View>
                </View>
              </View>
            ),
            auxiliaryLabel: (
              <View
                direction="row"
                align="center"
                className={Styles['order-release-accordion__right']}
              >
                <View height="100%">
                  <Button variant="secondary">
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Text>{t('Download')}</Text>
                      <Icon svg={Download} />
                    </View>
                  </Button>
                </View>
                <View height="100%">
                  <StatusBadge
                    variant={StatusVariants.READY_FOR_ACTION}
                    text={RELEASE_ORDER_STATUS.RELEASED.replace(/_/g, ' ')}
                  />
                </View>
                <View height="100%">
                  <ActionMenu
                    menus={[
                      {
                        label: t('OutboundMatrix.MenuOptions.ReleaseToPaper'),
                        clickHandler: () => ({}),
                      },
                      {
                        label: t('OutboundMatrix.MenuOptions.CloseToPaper'),
                        clickHandler: () => ({}),
                      },
                    ]}
                  />
                </View>
                <Actionable onClick={handleAccordionClick}>
                  <Icon size={6} svg={isAccordionOpen ? ChevronUp : ChevronDown} />
                </Actionable>
              </View>
            ),
          }}
        >
          {isAccordionOpen && (
            <OrderReviewByAreaList
              releaseGroup={releaseGroup}
              orderType={orderType}
              rootGroupId={rootGroupId}
            />
          )}
        </Accordion>
      </View>
    </>
  );
};
