/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text, TextProps } from '@az/starc-ui';
import styles from './DifOrders.module.scss';
import dayjs from 'dayjs';
import { DIF_DATA } from '@ofm/constants/dataConstants';
import { useTranslation } from 'react-i18next';
import { ExtendedCard } from '@shared/components/ExtendedCard/ExtendedCard';
import { PAGE_URLS } from '@shared/constants/routes';
import { generateDateString, statusToBadgeVariant } from '@ofm/utils/utils';

export const DifOrders = () => {
  /* Constants */
  const { t } = useTranslation();

  const textProps: Pick<TextProps, 'color' | 'size' | 'weight'> = {
    color: 'primary',
    size: '125',
    weight: 'bold',
  };

  const startOfToday = dayjs().startOf('day');
  const startOfTomorrow = startOfToday.add(1, 'day');

  const todayGroup = DIF_DATA.filter((wave) => {
    const currentDate = dayjs(wave.dueTimestamp);
    return currentDate.isBefore(startOfTomorrow) && currentDate.isAfter(startOfToday);
  });

  const upcomingGroup = DIF_DATA.filter((wave) => {
    const currentDate = dayjs(wave.dueTimestamp);
    return currentDate.isAfter(startOfTomorrow.subtract(1, 'second'));
  });

  const pastDueGroup = DIF_DATA.filter((wave) => {
    const currentDate = dayjs(wave.dueTimestamp);
    return currentDate.isBefore(startOfToday.add(1, 'second'));
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const generateContent = (waveData: any, type: 'Today' | 'Past' | 'Upcoming') => {
    if (type != 'Upcoming') {
      return (
        <View direction="row" gap={6}>
          <Text>Ready to Bill: {waveData.readyToBill}</Text>
          <Text>Sent to Outbound: {waveData.SentToOutbound}</Text>
        </View>
      );
    } else {
      return (
        <View direction="row" gap={6}>
          <Text>Requested: {waveData.requested}</Text>
        </View>
      );
    }
  };

  const generateWave = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    waveData: any,
    isErrorState = false,
    type: 'Today' | 'Past' | 'Upcoming'
  ) => {
    return (
      <ExtendedCard
        className={styles['wave-container__card']}
        key={waveData.id}
        url={PAGE_URLS.WAVE_DETAILS(waveData.id, type)}
        title={generateDateString(new Date(waveData.dueTimestamp), t('DateFormat.WaveTitle'))}
        //subtext={`${waveData.storeCount}/${waveData.storeMax}`}
        statusBadge={{
          variant: statusToBadgeVariant(waveData.status),
          text: waveData.status,
        }}
        isErrorState={isErrorState}
        content={generateContent(waveData, type)}
      />
    );
  };

  return (
    <View height="100%" width="100%" className={styles['diforders']} padding={4}>
      {pastDueGroup.length > 0 && (
        <View>
          <Text className={styles['wave-container__group-heading']} as="h2" {...textProps}>
            {t('WaveContainer.PastDue')}
          </Text>
          <View gap={4}>
            {pastDueGroup.map((wave) => (
              <View>{generateWave(wave, true, 'Past')}</View>
            ))}
          </View>
        </View>
      )}
      {todayGroup.length > 0 && (
        <View>
          <Text className={styles['wave-container__group-heading']} {...textProps} as="h2">
            {t('WaveContainer.Today')}
          </Text>
          <View>{todayGroup.map((wave) => generateWave(wave, false, 'Today'))}</View>
        </View>
      )}
      {upcomingGroup.length > 0 && (
        <View>
          <Text className={styles['wave-container__group-heading']} {...textProps} as="h2">
            {t('WaveContainer.Upcoming')}
          </Text>
          <View>
            {upcomingGroup.map((wave) => (
              <View>{generateWave(wave, false, 'Upcoming')}</View>
            ))}
          </View>
        </View>
      )}
    </View>
  );
};
