/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { useAtom } from 'jotai';

import { Text, View, Checkbox, Icon, Button, Actionable } from '@az/starc-ui';

import { Accordion } from '@shared/components/Accordion/Accordion';
import {
  selectedOrdersAtom,
  laneConfirmationItemAtom,
  ltdGroupedOrdersAtom,
  selectedRootReleaseOrdersAtom,
} from '@outbound/atoms/releaseOrder/releaseOrderAtom';

import { Props } from './ReleaseGroupAccordion.types.tsx';
import Styles from './ReleaseGroupAccordion.module.scss';
import { ChevronDown, ChevronUp } from '@az/starc-ui-icons';
import {
  RELEASE_GROUPS_GROUP_TYPE,
  RELEASE_GROUPS_ORDER_TYPE,
  RELEASE_ORDER_FIELD,
  RELEASE_ORDER_STATUS,
  RELEASE_ORDER_STATUS_COLOR_CODE,
} from '@outbound/constants/constants.ts';
import { ReleaseGroupList } from '@outbound/components/ReleaseGroupAccordion/ReleaseGroupList.tsx';
import { ActionMenu } from '@mdm/components/ActionMenu/ActionMenu.tsx';
import { StatusBadge } from '@shared/components/StatusBadge/StatusBadge.tsx';
import { handleReleaseOrderCheck } from '@outbound/utils/util.ts';
import { getAttributeValue } from '@shared/utils/commonUtils.ts';
import { useMutateOrderReplenishment } from '@outbound/services/hooks/useRunReplenishment.tsx';
import { useQueryClient } from '@tanstack/react-query';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useTranslation } from 'react-i18next';

export const IntermediateReleaseGroupAccordion = ({
  releaseGroup,
  rootGroupId = '',
  orderType = RELEASE_GROUPS_ORDER_TYPE.DIF_WORK_ORDER,
}: Props) => {
  /*Atoms*/
  const [selectedOrders, setSelectedOrders] = useAtom(selectedOrdersAtom);
  const [selectedRootReleaseOrders, setSelectedRootReleaseOrders] = useAtom(
    selectedRootReleaseOrdersAtom
  );
  const [selectedItem] = useAtom(laneConfirmationItemAtom);
  const [groupedOrders] = useAtom(ltdGroupedOrdersAtom);

  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const { attributes, groupId, groupName, status } = releaseGroup;
  const isTransferOrderTab = orderType === RELEASE_GROUPS_ORDER_TYPE.TRANSFER;

  /* State variables */
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [selectedGroupType, setSelectedGroupType] = useState<string>('');
  const queryClient = useQueryClient();

  /* Functions */
  const handleCheckAll = (state: boolean) => {
    setSelectAllChecked(state);
    if (state) {
      setSelectedRootReleaseOrders((prev) => [...new Set([...prev, rootGroupId])]);
      setSelectedOrders((prev) => [...new Set([...prev, releaseGroup.groupId, rootGroupId])]);
    } else {
      setSelectedOrders((prev) => prev.filter((item) => item !== releaseGroup.groupId));
    }
  };

  const handleAccordionClick = () => {
    setIsAccordionOpen((isOpen) => !isOpen);
  };

  /* Replenishment Hook */
  const { mutateReleaseGroups, isLoading: isRunReplishmentUpdating } =
    useMutateOrderReplenishment();

  /* Function to handle replenishment */
  const handleRunReplenishment = () => {
    mutateReleaseGroups(
      {
        releaseOrderReplenish: { groupIds: [releaseGroup.groupId] },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['releaseGroups']);
          handleNotification(
            NOTIFICATION_TYPES.SUCCESS,
            t('Success.Action.RunReplenishment.Updated')
          );
          setSelectedOrders([]);
          setSelectedRootReleaseOrders([]);
        },
      }
    );
  };

  useEffect(() => {
    if (isAccordionOpen && !selectedGroupType) {
      setSelectedGroupType(RELEASE_GROUPS_GROUP_TYPE.BY_DC);
    }
  }, [isAccordionOpen, selectedGroupType]);

  return (
    <>
      <View className={classNames(Styles['order-release-accordion'])}>
        <Accordion
          open={isAccordionOpen}
          header={{
            label: (
              <View direction="row" align="center">
                <Checkbox
                  name="select-all"
                  value={releaseGroup.groupId}
                  onChange={(event) => handleCheckAll(event.target.checked)}
                  checked={selectAllChecked}
                  disabled={releaseGroup.status === RELEASE_ORDER_STATUS.RELEASED}
                  className={Styles['order-release-accordion__checkbox']}
                />
                <View
                  direction="row"
                  align="center"
                  className={Styles['order-release-accordion__left']}
                >
                  <View
                    direction="row"
                    align="center"
                    padding={[4, 22, 4, 0]}
                    className={Styles['order-release-accordion__left--label']}
                  >
                    <Text size="100" weight="bold">
                      {groupName}
                    </Text>
                  </View>
                  <View padding={[4, 10, 4, 2]}>
                    <Text size="100" weight="regular">
                      {isTransferOrderTab
                        ? t('OutboundMatrix.OrderRelease.Skus', {
                            count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.SKU_COUNT),
                          })
                        : t('OutboundMatrix.OrderRelease.Area', {
                            count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.AREA_COUNT),
                          })}
                    </Text>
                  </View>
                  <View
                    padding={[4, 10, 4, 2]}
                    className={Styles['order-release-accordion__left--label__pallets']}
                  >
                    <Text size="100" weight="regular">
                      {t('OutboundMatrix.OrderRelease.Pallet', {
                        count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.PALLETS),
                      })}
                    </Text>
                  </View>
                  <View padding={[4, 10, 4, 2]}>
                    <Text size="100" weight="regular">
                      {t('OutboundMatrix.OrderRelease.Pieces', {
                        count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.PIECES),
                      })}
                    </Text>
                  </View>
                </View>
              </View>
            ),
            auxiliaryLabel: (
              <View
                direction="row"
                align="center"
                className={Styles['order-release-accordion__right']}
              >
                {status === RELEASE_ORDER_STATUS.CREATED && (
                  <View height="100%">
                    <Button
                      variant="secondary"
                      loading={isRunReplishmentUpdating}
                      onClick={handleRunReplenishment}
                    >
                      <Text size="100">{t('OutboundMatrix.OrderRelease.RunReplenishment')}</Text>
                    </Button>
                  </View>
                )}
                {status !== RELEASE_ORDER_STATUS.CREATED && (
                  <View height="100%">
                    <StatusBadge
                      variant={
                        RELEASE_ORDER_STATUS_COLOR_CODE[
                          status as keyof typeof RELEASE_ORDER_STATUS_COLOR_CODE
                        ]
                      }
                      text={status.replace(/_/g, ' ')}
                    />
                  </View>
                )}
                <View height="100%">
                  <ActionMenu
                    menus={[
                      {
                        label: t('OutboundMatrix.MenuOptions.ReleaseToPaper'),
                        clickHandler: () => ({}),
                      },
                      {
                        label: t('OutboundMatrix.MenuOptions.CloseToPaper'),
                        clickHandler: () => ({}),
                      },
                    ]}
                  />
                </View>
                <Actionable onClick={handleAccordionClick}>
                  <Icon size={6} svg={isAccordionOpen ? ChevronUp : ChevronDown} />
                </Actionable>
              </View>
            ),
          }}
        >
          <Checkbox.Group
            name="storeOrders"
            value={selectedOrders}
            onChange={(e) =>
              handleReleaseOrderCheck(
                e,
                selectedOrders,
                setSelectedOrders,
                groupedOrders,
                selectedItem,
                groupId,
                selectedRootReleaseOrders,
                setSelectedRootReleaseOrders,
                rootGroupId || ''
              )
            }
          >
            {isAccordionOpen && (
              <ReleaseGroupList
                releaseGroup={releaseGroup}
                orderType={orderType}
                rootGroupId={rootGroupId}
              />
            )}
          </Checkbox.Group>
        </Accordion>
      </View>
    </>
  );
};
