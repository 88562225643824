/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { AttributeSchema } from '@mdm/schemas/attributeSchema.ts';

// eslint-disable-next-line import/no-cycle
import {
  addCustomAlphaNumericAttributeFieldValidation,
  addCustomMaxLengthAttributeFieldValidation,
  addCustomNumericAttributeFieldValidation,
  addCustomRequiredAttributeFieldValidation,
  isNumeric,
} from '@mdm/utils/form/validationUtils.tsx';
import {
  LAYOUT_ENTITY_CODE,
  LAYOUT_ENTITY_TYPE_CODE,
  LOCATION_FIELD,
  LOCATION_FLAG_DEDICATED,
  LOCATION_FLAG_DYNAMIC,
  LOCATION_FLAG_RANDOM,
  LOCATION_NAME_REGEX,
  LOCATION_TYPE,
  MAX_LENGTH_FIELD,
} from '@mdm/constants/constants.ts';
import { t } from 'i18next';
import { isAlphaNumeric } from '@shared/utils/validationUtils.ts';
import { LayoutAssetSchema, LayoutProductSchema } from '@mdm/schemas/layoutSchema.ts';

export const defaultAttributeValues = [
  {
    name: LOCATION_FIELD.LOCATION_TYPE,
    value: '',
  },
  {
    name: LOCATION_FIELD.CHECK_DIGIT,
    value: '0',
  },
  {
    name: LOCATION_FIELD.LOT,
    value: '',
  },
  {
    name: LOCATION_FIELD.QUANTITY_UNITS,
    value: '0',
  },
  {
    name: LOCATION_FIELD.QUANTITY_RESERVED,
    value: '0',
  },
  {
    name: LOCATION_FIELD.PACK_SIZE,
    value: '0',
  },
  {
    name: LOCATION_FIELD.LOCATION_FLAG,
    value: '',
  },
  {
    name: LOCATION_FIELD.LOCATION_LOCK,
    value: 'false',
  },
  {
    name: LOCATION_FIELD.SLOT_FRONTS,
    value: '0',
  },
  {
    name: LOCATION_FIELD.SLOT_LAYERS,
    value: '0',
  },
  {
    name: LOCATION_FIELD.SLOT_DEEPS,
    value: '0',
  },
  {
    name: LOCATION_FIELD.SLOT_WIDTH,
    value: '0',
  },
  {
    name: LOCATION_FIELD.FULL_PALLET,
    value: 'false',
  },
  {
    name: LOCATION_FIELD.LENGTH,
    value: '0',
  },
  {
    name: LOCATION_FIELD.WIDTH,
    value: '0',
  },
  {
    name: LOCATION_FIELD.HEIGHT,
    value: '0',
  },
  {
    name: LOCATION_FIELD.WEIGHT,
    value: '0',
  },
];

export const defaultProductValue = [
  {
    productId: '',
    min: '0',
    max: '0',
    isActive: true,
  },
];

export const LocationSchema = z.object({
  layoutKey: z.string().optional(),
  layoutName: z
    .string()
    .max(MAX_LENGTH_FIELD.LOCATION_NAME)
    .superRefine((data, ctx) => {
      if (data === '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('Form.RequiredField'),
        });
      }

      if (data.length > MAX_LENGTH_FIELD.LOCATION_NAME) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.LOCATION_NAME }),
        });
      }

      if (!isAlphaNumeric(data)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('Form.AlphaNumericError'),
        });
      }

      if (data && !LOCATION_NAME_REGEX.test(data)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('ValidationError.Location.Name'),
        });
      }
    }),
  layoutNameDesc: z.string().nullable().default(''),
  layoutDistinctName: z.string().optional(),
  parentLayoutDistinctName: z.string().min(1),
  entityCd: z.string().default(LAYOUT_ENTITY_CODE.LOCATION),
  entityTypeCd: z.string().default(LAYOUT_ENTITY_TYPE_CODE.DEFAULT),
  active: z.boolean().default(true),
  mapSequence: z.number().nullable().default(null).optional(),
  attributes: z
    .array(AttributeSchema)
    .superRefine((data, ctx) => {
      const requiredFields = [
        LOCATION_FIELD.CHECK_DIGIT,
        LOCATION_FIELD.LOCATION_TYPE,
        LOCATION_FIELD.LOCATION_FLAG,
      ];

      addCustomRequiredAttributeFieldValidation(data, requiredFields, ctx);

      const numericFields = [
        {
          key: LOCATION_FIELD.CHECK_DIGIT,
        },
        {
          key: LOCATION_FIELD.SLOT_FRONTS,
        },
        {
          key: LOCATION_FIELD.SLOT_DEEPS,
        },
        {
          key: LOCATION_FIELD.SLOT_LAYERS,
        },
        {
          key: LOCATION_FIELD.QUANTITY_RESERVED,
        },
        {
          key: LOCATION_FIELD.SLOT_WIDTH,
        },
        {
          key: LOCATION_FIELD.PACK_SIZE,
        },
        {
          key: LOCATION_FIELD.LENGTH,
        },
        {
          key: LOCATION_FIELD.WIDTH,
        },
        {
          key: LOCATION_FIELD.HEIGHT,
        },
        {
          key: LOCATION_FIELD.WEIGHT,
        },
      ];

      addCustomNumericAttributeFieldValidation(data, numericFields, ctx);

      const maxLengthFields = [
        {
          name: LOCATION_FIELD.CHECK_DIGIT,
          maxLength: MAX_LENGTH_FIELD.CHECK_DIGIT,
        },
        {
          name: LOCATION_FIELD.LOT,
          maxLength: MAX_LENGTH_FIELD.LOCATION_LOT,
        },
        {
          name: LOCATION_FIELD.SLOT_FRONTS,
          maxLength: MAX_LENGTH_FIELD.LOCATION_SLOT_FRONT,
        },
        {
          name: LOCATION_FIELD.SLOT_DEEPS,
          maxLength: MAX_LENGTH_FIELD.LOCATION_SLOT_DEEP,
        },
        {
          name: LOCATION_FIELD.SLOT_LAYERS,
          maxLength: MAX_LENGTH_FIELD.LOCATION_SLOT_LAYER,
        },
        {
          name: LOCATION_FIELD.SLOT_WIDTH,
          maxLength: MAX_LENGTH_FIELD.LOCATION_CURRENT_WIDTH,
        },
        {
          name: LOCATION_FIELD.LENGTH,
          maxLength: MAX_LENGTH_FIELD.LOCATION_LENGTH,
        },
        {
          name: LOCATION_FIELD.WIDTH,
          maxLength: MAX_LENGTH_FIELD.LOCATION_WIDTH,
        },
        {
          name: LOCATION_FIELD.HEIGHT,
          maxLength: MAX_LENGTH_FIELD.LOCATION_HEIGHT,
        },
        {
          name: LOCATION_FIELD.WEIGHT,
          maxLength: MAX_LENGTH_FIELD.LOCATION_WEIGHT,
        },
      ];
      addCustomMaxLengthAttributeFieldValidation(data, maxLengthFields, ctx);

      const alphaNumericFields = [
        {
          key: LOCATION_FIELD.LOT,
        },
      ];
      addCustomAlphaNumericAttributeFieldValidation(data, alphaNumericFields, ctx);

      const locationFlagIndex = data.findIndex(
        (attribute) => attribute.name === LOCATION_FIELD.LOCATION_FLAG
      );

      const locationTypeIndex = data.findIndex(
        (attribute) => attribute.name === LOCATION_FIELD.LOCATION_TYPE
      );

      if (locationFlagIndex !== -1 && locationTypeIndex !== -1) {
        if (
          data[locationTypeIndex].value === LOCATION_TYPE.SLOT &&
          ![LOCATION_FLAG_DEDICATED, LOCATION_FLAG_DYNAMIC].includes(data[locationFlagIndex].value)
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [`attribute-${locationFlagIndex}`],
            message: t('ValidationError.Location.LocationTypeAndLocationFlagCompatibility.Slot'),
          });
        }

        if (
          data[locationTypeIndex].value === LOCATION_TYPE.RESERVE &&
          ![LOCATION_FLAG_RANDOM].includes(data[locationFlagIndex].value)
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [`attribute-${locationFlagIndex}`],
            message: t('ValidationError.Location.LocationTypeAndLocationFlagCompatibility.Reserve'),
          });
        }
      }
    })
    .nullable()
    .default(defaultAttributeValues),
  assets: z.array(LayoutAssetSchema).nullable().default([]),
  products: z
    .array(LayoutProductSchema)
    .nullable()
    .superRefine((data, ctx) => {
      for (let i = 0; i < (data || []).length; i++) {
        if (data && data[i].productId === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [`${i}.productId`],
            message: t('Form.RequiredField'),
          });
        }

        if (data && data[i].min === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [`${i}.min`],
            message: t('Form.RequiredField'),
          });
        }

        if (data && data[i].max === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [`${i}.max`],
            message: t('Form.RequiredField'),
          });
        }

        if (data && data[i].min) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (!isNumeric(data[i].min.toString())) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [`${i}.min`],
              message: t('Form.NumericError'),
            });
          }
        }

        if (data && data[i].max) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (!isNumeric(data[i].max.toString())) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [`${i}.max`],
              message: t('Form.NumericError'),
            });
          }
        }

        if (data && data[i].productId && data[i].productId.length > MAX_LENGTH_FIELD.LOCATION_SKU) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [`${i}.productId`],
            message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.LOCATION_SKU }),
          });
        }

        if (data && data[i].productId && data[i].productId.length > MAX_LENGTH_FIELD.LOCATION_SKU) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [`${i}.productId`],
            message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.LOCATION_SKU }),
          });
        }

        if (
          data &&
          data[i] &&
          data[i].min &&
          data[i].min !== null &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data[i].min.toString().length > MAX_LENGTH_FIELD.LOCATION_MINIMUM
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [`${i}.min`],
            message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.LOCATION_MINIMUM }),
          });
        }

        if (
          data &&
          data[i] &&
          data[i].max !== null &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data[i].max.toString().length > MAX_LENGTH_FIELD.LOCATION_MAXIMUM
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [`${i}.max`],
            message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.LOCATION_MAXIMUM }),
          });
        }

        if (data && data[i] && Number(data[i].min) > Number(data[i].max)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [`${i}.min`],
            message: t('ValidationError.Location.MINIMUM'),
          });
        }
      }
    })
    .default(defaultProductValue),
});

export const LocationAssociatedSkuListSchema = z.object({
  sku: z.string(),
  description: z.string(),
  quantity: z.number(),
  partNumber: z.string(),
});
