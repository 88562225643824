/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { View, Divider, ProgressBar, Text, Icon, Link } from '@az/starc-ui';
import { ArrowRight } from '@az/starc-ui-icons';
import { t } from 'i18next';
import { WidgetWithChildren } from '@taskManagement/components/WidgetWithChildren/WidgetWithChildren';
import styles from '../../CycleCountDetailsOverview.module.scss';
import * as T from '../../CycleCountDetailsOverview.types';

import { Stat } from '@shared/components/Stat/Stat';
import { CombinedPillTabs } from '@shared/components/CombinedPillTabs/CombinedPillTabs';
import { calculatePercentageWithSign, calculatePercentage } from '@shared/utils/commonUtils';

import { useGetPhaseProgress } from '@inventory/services/hooks/useCycleCounts';
import { mapDateFilterToRange } from '@inventory/utils/topVarianceUtils';
import { checkCountTypeCd } from '@inventory/utils/utils';

import {
  DateFilters,
  DOLLAR_VARIANCE_THRESHOLD,
  METRICS,
  QTY_VARIANCE_THRESHOLD,
} from '@inventory/constants/constants';
import { ProgressCardSkeleton } from './ProgressCardSkeleton';
import { PAGE_URLS, ROUTES } from '@shared/constants/routes';
import { FilterOption } from '@inventory/pages/CycleCounts/components/CycleCountFilter';

export const ProgressCard = ({ showFilter = false, isRBCCFlow = false }: T.Props) => {
  const { id, countTypeCd } = useParams();
  const navigate = useNavigate();
  const rootPath = PAGE_URLS.CYCLE_COUNTS_DETAILS(id ?? '', countTypeCd ?? '');
  const [slotsCountedPercentage, setSlotsCounted] = useState('0');
  const [reservedSlotsCountedPercentage, setReservedSlotsCounted] = useState('0');
  const [locationCountedPercentage, setLocationCountedPercentage] = useState('0');
  const [locationCountedProgress, setLocationCountedProgress] = useState(0);
  const [totalLocationCountExpected, setTotalLocationCountExpected] = useState(0);
  const [totalLocationCountCompleted, setTotalLocationCountCompleted] = useState(0);

  const [totalFpsLayoutCountExpected, setTotalFpsLayoutCountExpected] = useState(0);
  const [totalFpsLayoutCountCompleted, setTotalFpsLayoutCountCompleted] = useState(0);
  const [totalRsrvLayoutCountCompleted, setTotalRsrvLayoutCountCompleted] = useState(0);
  const [totalRsrvLayoutCountExpected, setTotalRsrvLayoutCountExpected] = useState(0);

  const [varianceCounted, setVarianceCounted] = useState(0);
  const [dollarVarianceOverThreshold, setDollarVarianceOverThreshold] = useState(0);
  const [pieceAdjustmentsOverThreshold, setPieceAdjustmentsOverThreshold] = useState(0);

  const [selectedFilter, setSelectedFilter] = useState({
    value: DateFilters.THIS_WEEK,
    label: DateFilters.THIS_WEEK,
    ...mapDateFilterToRange(DateFilters.THIS_WEEK),
  });

  const [, setProgressValue, { startTs, endTs, phase }] =
    useOutletContext<[unknown, React.Dispatch<React.SetStateAction<number>>, T.PhaseData]>();

  const handleFilterChange = (newFilter: FilterOption) => {
    const { fromTs, toTs } =
      newFilter.value === DateFilters.THIS_WEEK
        ? mapDateFilterToRange(DateFilters.THIS_WEEK)
        : { fromTs: startTs || new Date().toISOString(), toTs: endTs || new Date().toISOString() };

    const filterDates = {
      ...newFilter,
      fromTs,
      toTs,
    };

    setSelectedFilter(filterDates);
  };

  const filters: FilterOption[] = [
    {
      value: DateFilters.THIS_WEEK,
      label: t('CycleCount.CombinedPillTabs.ThisWeek'),
      ...mapDateFilterToRange(DateFilters.THIS_WEEK),
    },
    {
      value: DateFilters.PHASE,
      label: t('CycleCount.CombinedPillTabs.Phase', { phase: phase }),
      fromTs: startTs || new Date().toISOString(),
      toTs: endTs || new Date().toISOString(),
    },
  ];

  const countTypeCdValue = checkCountTypeCd(countTypeCd || '');

  // Query
  const { data, isLoading } = useGetPhaseProgress({
    countTypeCd: countTypeCdValue,
    ...(isRBCCFlow
      ? { taskId: id }
      : {
          fromTs: selectedFilter.fromTs || '',
          toTs: selectedFilter.toTs || '',
        }),
    dollarVarianceThershold: DOLLAR_VARIANCE_THRESHOLD,
    qtyVarianceThershold: QTY_VARIANCE_THRESHOLD,
    metrics: METRICS,
  });

  useEffect(() => {
    const taskData = data;

    if (taskData) {
      const {
        locationCounts,
        varianceCounted,
        dollarVarianceOverThreshold,
        pieceAdjustmentsOverThreshold,
      } = taskData;

      const {
        totalFpsLayoutCountExpected = 0,
        totalFpsLayoutCountCompleted = 0,
        totalRsrvLayoutCountExpected = 0,
        totalRsrvLayoutCountCompleted = 0,
      } = locationCounts || {};
      // Set total completed and expected counts
      const totalCompleted = totalFpsLayoutCountCompleted + totalRsrvLayoutCountCompleted;
      const totalExpected = totalFpsLayoutCountExpected + totalRsrvLayoutCountExpected;

      setTotalLocationCountCompleted(totalCompleted);
      setTotalLocationCountExpected(totalExpected);

      setTotalFpsLayoutCountExpected(totalFpsLayoutCountExpected);
      setTotalFpsLayoutCountCompleted(totalFpsLayoutCountCompleted);

      setTotalRsrvLayoutCountExpected(totalRsrvLayoutCountExpected);
      setTotalRsrvLayoutCountCompleted(totalRsrvLayoutCountCompleted);

      // Calculate percentages
      setSlotsCounted(
        calculatePercentageWithSign(
          totalFpsLayoutCountCompleted,
          totalFpsLayoutCountExpected,
          false
        )
      );

      setReservedSlotsCounted(
        calculatePercentageWithSign(
          totalRsrvLayoutCountCompleted,
          totalRsrvLayoutCountExpected,
          false
        )
      );

      const locationCountedPercentage = Number(calculatePercentage(totalCompleted, totalExpected));
      setLocationCountedPercentage(
        calculatePercentageWithSign(totalCompleted, totalExpected, false)
      );
      setLocationCountedProgress(locationCountedPercentage);
      setProgressValue(locationCountedPercentage);

      //set variances
      setVarianceCounted(varianceCounted?.varianceCount ?? 0);
      setDollarVarianceOverThreshold(dollarVarianceOverThreshold?.varianceCount ?? 0);
      setPieceAdjustmentsOverThreshold(pieceAdjustmentsOverThreshold?.varianceCount ?? 0);
    }
  }, [id, data, setProgressValue, startTs, endTs]);

  return (
    <View direction="row">
      <View width="100%">
        <WidgetWithChildren className={styles['cycle-count-card']}>
          <View direction="row" align="center" justify="space-between" padding={2}>
            <Text weight="bold" size="125">
              {t(`CycleCount.ProgressCard.Title`)}
            </Text>
            <Link onClick={() => navigate(`${rootPath}/${ROUTES.SUBZONES}`)}>
              <View direction="row" align="center" justify="space-between">
                <Text weight="bold" size="100">
                  {t(`CycleCount.ProgressCard.ViewSubzoneProgress`)}
                </Text>
                <Icon svg={ArrowRight} />
              </View>
            </Link>
          </View>
          <WidgetWithChildren.Body className={styles['shift-progress-body']}>
            <View direction="column">
              {showFilter && (
                <View>
                  <CombinedPillTabs
                    TabsFilterData={filters}
                    onTabSelect={(value) => handleFilterChange(value)}
                    selectedValue={selectedFilter}
                  />
                </View>
              )}
              {isLoading ? (
                <ProgressCardSkeleton />
              ) : (
                <>
                  <View direction="column">
                    <View padding={isRBCCFlow ? [0, 0, 6, 0] : [6, 0]}>
                      <Text weight="bold" color="500" size="075">
                        {t(`CycleCount.ProgressCard.LocationCounted`)}
                      </Text>
                    </View>
                    <View>
                      <View direction="row" justify="space-between" align="center">
                        <View>
                          <Text weight="bold" size="175">
                            {locationCountedPercentage}
                          </Text>
                        </View>
                        <View>
                          <Text variant="small-body" color="500">
                            {totalLocationCountCompleted}/{totalLocationCountExpected}
                          </Text>
                        </View>
                      </View>
                      <View>
                        <ProgressBar
                          value={locationCountedProgress}
                          max={100}
                          label=""
                          attributes={{ style: { width: '100%' } }}
                        />
                      </View>
                    </View>
                  </View>
                  <View direction="row" padding={[4, 0]} gap={2} width="100%" align="center">
                    {isRBCCFlow && (
                      <View.Item grow>
                        <Stat
                          title={t(`CycleCount.WallToWallPhases.Subzone.Stats.LocationsCounted`)}
                          primaryText={locationCountedPercentage}
                          secondaryTextProps={{
                            secondaryText: `${totalLocationCountCompleted}/${totalLocationCountExpected}`,
                          }}
                          width="100%"
                        />
                      </View.Item>
                    )}
                    <View.Item grow>
                      <Stat
                        title={t(`CycleCount.ProgressCard.SlotsCounted`)}
                        primaryText={slotsCountedPercentage}
                        secondaryTextProps={{
                          secondaryText: `${totalFpsLayoutCountCompleted}/${totalFpsLayoutCountExpected}`,
                        }}
                        width="100%"
                      />
                    </View.Item>
                    <View.Item grow>
                      <Stat
                        title={t(`CycleCount.ProgressCard.RSRVsCounted`)}
                        primaryText={reservedSlotsCountedPercentage}
                        secondaryTextProps={{
                          secondaryText: `${totalRsrvLayoutCountCompleted}/${totalRsrvLayoutCountExpected}`,
                        }}
                        width="100%"
                      />
                    </View.Item>
                  </View>
                  <View>
                    <Divider color="300" className={styles['shift-widget__divider']} />
                  </View>
                  <View padding={[4, 0]}>
                    <View direction="row" align="center" justify="space-between">
                      <Text weight="bold" size="125">
                        {t(`CycleCount.ProgressCard.Variances`)}
                      </Text>
                      <Link onClick={() => navigate(`${rootPath}/${ROUTES.SKUS_WITH_VARIANCES}`)}>
                        <View direction="row" align="center" justify="space-between">
                          <Text weight="bold" size="100">
                            {t(`CycleCount.ProgressCard.ViewSKUswithVariances`)}
                          </Text>
                          <Icon svg={ArrowRight} />
                        </View>
                      </Link>
                    </View>
                    <View direction="row" padding={[4, 0]} gap={2} width="100%" align="center">
                      <View.Item grow>
                        <Stat
                          title={t(`CycleCount.ProgressCard.VariancesCounted`)}
                          primaryText={varianceCounted.toString()}
                          width="100%"
                        />
                      </View.Item>
                      <View.Item grow>
                        <Stat
                          title={t(`CycleCount.ProgressCard.DollarVariancesOver$250`)}
                          primaryText={dollarVarianceOverThreshold.toString()}
                          width="100%"
                        />
                      </View.Item>
                      <View.Item grow>
                        <Stat
                          title={t(`CycleCount.ProgressCard.PieceAdjustmentsOver250`)}
                          primaryText={pieceAdjustmentsOverThreshold.toString()}
                          width="100%"
                        />
                      </View.Item>
                    </View>
                  </View>
                </>
              )}
            </View>
          </WidgetWithChildren.Body>
        </WidgetWithChildren>
      </View>
    </View>
  );
};
