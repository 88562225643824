/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View } from '@az/starc-ui';
import { useAtom } from 'jotai';

import { Props, ReleaseGroup } from './ReleaseGroupAccordion.types.tsx';
import {
  RELEASE_GROUPS_GROUP_TYPE,
  RELEASE_GROUPS_ORDER_TYPE,
} from '@outbound/constants/constants.ts';
import { useGetReleaseGroups } from '@outbound/services/hooks/useGetReleaseGroups.tsx';
import {
  DEFAULT_PAGE,
  MAX_PAGE_SIZE,
  NOTIFICATION_TYPES,
  PAGE_SIZE,
} from '@shared/constants/constants.ts';
import {
  selectedOrdersAtom,
  laneConfirmationItemAtom,
  ltdGroupedOrdersAtom,
  selectedRootReleaseOrdersAtom,
} from '@outbound/atoms/releaseOrder/releaseOrderAtom';
import { TableStylingVariants } from '@shared/components/Table/tableConstants.ts';
import { Table } from '@shared/components/Table/Table.tsx';
import { mapDifOrderReleaseByAreaTableRows } from '@outbound/utils/table/releaseOrderTableUtils.tsx';
import { DIF_RELEASE_ORDER_BY_AREA_COLUMNS } from '@shared/components/Table/tableColumnData.ts';
import { handleReleaseOrderCheck } from '@outbound/utils/util.ts';
import { useMutateOrderReplenishment } from '@outbound/services/hooks/useRunReplenishment.tsx';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';

import style from './ReleaseGroupList.module.scss';

export const ReleaseGroupList = ({
  releaseGroup,
  orderType = RELEASE_GROUPS_ORDER_TYPE.DIF_WORK_ORDER,
  groupTypes = [RELEASE_GROUPS_GROUP_TYPE.LEAF],
  rootGroupId,
}: Props) => {
  /*Atoms*/
  const [selectedOrders, setSelectedOrders] = useAtom(selectedOrdersAtom);
  const [selectedRootReleaseOrders, setSelectedRootReleaseOrders] = useAtom(
    selectedRootReleaseOrdersAtom
  );
  const [selectedItem] = useAtom(laneConfirmationItemAtom);
  const [groupedOrders] = useAtom(ltdGroupedOrdersAtom);

  /* variables */
  const { groupId } = releaseGroup;

  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const queryClient = useQueryClient();
  const { releaseGroupsData, isFetching: isReleaseGroupLoading } = useGetReleaseGroups({
    currentPage: DEFAULT_PAGE - 1,
    pageSize: MAX_PAGE_SIZE,
    orderType: orderType,
    parentReleaseGroupId: groupId,
    groupTypes,
  });

  /* Replenishment Hook */
  const { mutateReleaseGroups, isLoading: isReplenshimentRunning } = useMutateOrderReplenishment();

  /* Function */
  const onRunReplenishmentClick = (_releaseGroup: ReleaseGroup) => {
    mutateReleaseGroups(
      {
        releaseOrderReplenish: { groupIds: [_releaseGroup.groupId] },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['releaseGroups']);
          handleNotification(
            NOTIFICATION_TYPES.SUCCESS,
            t('Success.Action.RunReplenishment.Updated')
          );
          setSelectedOrders([]);
          setSelectedRootReleaseOrders([]);
        },
      }
    );
  };

  return (
    <>
      <View className={style['order-release-list']}>
        <Table
          columns={DIF_RELEASE_ORDER_BY_AREA_COLUMNS}
          rows={mapDifOrderReleaseByAreaTableRows(
            releaseGroupsData?.content || [],
            (e) =>
              handleReleaseOrderCheck(
                e,
                selectedOrders,
                setSelectedOrders,
                groupedOrders,
                selectedItem,
                groupId,
                selectedRootReleaseOrders,
                setSelectedRootReleaseOrders,
                rootGroupId || ''
              ),
            onRunReplenishmentClick,
            isReplenshimentRunning
          )}
          isPaginated={true}
          isCheckboxDisabled={true}
          pageSize={PAGE_SIZE}
          defaultPage={DEFAULT_PAGE}
          isCreditItem={false}
          isCheckboxTable={false}
          showDeselectCheckbox={false}
          isApiLoadedData={true}
          isLoading={isReleaseGroupLoading}
          styleVariant={TableStylingVariants.DETAILS}
          totalPages={releaseGroupsData?.totalPages || 0}
          onSort={() => ({})}
        />
      </View>
    </>
  );
};
