/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { View, Text, Actionable, Icon, Close, Select, Checkbox } from '@az/starc-ui';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { TableStylingVariants } from '@shared/components/Table/tableConstants';

import { POSearch } from '@inbound/components/POSearch/POSearch';
import { DropdownOption } from '@inbound/components/DropdownSelectMenu/DropdownSelectMenu.types';
import { useGetTrailerOrders } from '@inbound/services/hooks/useGetTrailerOrders';
import {
  ASC,
  DEFAULT_PAGE,
  DEFAULT_SORT_BY,
  DOMAIN_TYPE_CD,
  TRAILER_ORDER_STATUS,
  TRAILER_ORDER_STATUS_CDS,
  ORDERS_LINES,
  INBOUND_ORDER_STATUS,
} from '@inbound/constants/constants';
import { mapUItoDBField, toUTCString } from '@inbound/utils/utils';
import { ARRIVAL_TIMES } from '@inbound/constants/dataConstants';

import { DropdownSelectMenu } from '@inbound/components/DropdownSelectMenu/DropdownSelectMenu';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig';
import { COMMODITY_TYPE, COMMON, INBOUND, PAGE_SIZE, PO_TYPE } from '@shared/constants/constants';

import { Table } from '@shared/components/Table/Table';

import { PAGE_URLS } from '@shared/constants/routes';

import { GroupByOptions, URLQuery } from '@inventory/constants/constants';

import {
  FinalizationDashboardDataRowType,
  FinalizationDashboardDividerRowType,
  FinalizationDashboardLocationRowType,
} from '@inventory/types/types';

import styles from './FnalizationDashboard.module.scss';
import {
  FINALIZATIONDASHBOARD_DIVIDER_LABELS,
  LOCATION_TYPES,
} from '@inventory/constants/dataConstants';
import {
  FINALIZE_DASHBOARD_LOCATIONS_TABLE_COLUMNS,
  FINALIZE_DASHBOARD_TABLE_COLUMNS,
} from '@inventory/constants/tableConstants';

import {
  mapFinalizationDashboardLocationsRows,
  mapFinalizationDashboardRows,
  mapTrailerOrders,
} from '@inventory/utils/table/tableUtils';
import { SingleValue } from '@az/starc-ui/dist/components/select/Select.types';
import { useGetInboundOrder } from '@inbound/services/hooks/useGetInboundOrder';
import { InboundOrderICReviewType } from '@inbound/types/types';
import { TableSorting } from '@shared/components/Table/Table.types';
import { generateSubzoneNameFromDistinctName } from '@shared/utils/commonUtils';

export const FinalizationDashboard = () => {
  /* State Variables */
  const [arrivalTimeFilters, setArrivalTimeFilters] = useState<DropdownOption[]>([]);
  const [poTypes, setPOTypes] = useState<DropdownOption[]>([]);
  const [commodityTypes, setCommodityTypes] = useState<DropdownOption[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState<string>('');
  const [direction, setDirection] = useState<string>('ASC');
  const [poData, setPOData] = useState<FinalizationDashboardDividerRowType[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');

  const [, setLocationRowData] = useState<FinalizationDashboardLocationRowType[]>([]);
  const [filteredLocationRowData, setFilteredLocationRowData] = useState<
    FinalizationDashboardLocationRowType[]
  >([]);
  const [isCheckboxSelected, setIsCheckboxSelected] = useState<boolean>(false);
  const [originalLocationRowData, setOriginalLocationRowData] = useState<
    FinalizationDashboardLocationRowType[]
  >([]);

  const [locationTypeSelected, setLocationTypeSelected] = useState<SingleValue | null>(
    LOCATION_TYPES[0]
  );
  const [searchParams, setSearchParams] = useSearchParams();

  /* Constants */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedGroupBy = searchParams.get(URLQuery.GROUP_BY);
  const GroupByOptionValue = selectedGroupBy
    ? GroupByOptions.find((option) => option.value === selectedGroupBy)
    : GroupByOptions[0];

  /* Queries */
  const { configsData: PO_TYPES } = useGetFacilityConfig({
    domainCd: INBOUND,
    subDomainCd: COMMON,
    configCd: PO_TYPE,
  });
  const { configsData: COMMODITY_TYPES } = useGetFacilityConfig({
    domainCd: INBOUND,
    subDomainCd: COMMON,
    configCd: COMMODITY_TYPE,
  });
  /**
   * searchPage: Current Page, Page Size, SortBy, Direction (ASC, DESC)
   * searchCriteria:
   *   userAssignState: 'BOTH', 'ASSIGNED', 'UNASSIGNED'
   *   arrivalTimestamps: [{ startTime, endTime }]
   *   orderTypes: LTD, PO, ...
   *   commodityTypes: AO, ...
   *   orderStatus: 'NOT_STARTED', 'READY_FOR_SIGNATURE', ...
   * hooks last 2 parameters isEnabled & isDelay (for debounce), so it need to be passed here
   */
  const {
    trailerOrdersData,
    isLoading,
    isFetching: isFetchingTableData,
  } = useGetTrailerOrders(
    {
      searchPage: {
        currentPage: currentPage - 1,
        pageSize: PAGE_SIZE,
        sortBy: mapUItoDBField(sortBy ? sortBy : DEFAULT_SORT_BY),
        direction: direction.toUpperCase(),
      },
      searchCriteria: {
        inboundOrder: {
          domainTypeCd: DOMAIN_TYPE_CD,
          orderTypeCds: poTypes.map((item) => item.value.toUpperCase()),
          statusCds: [TRAILER_ORDER_STATUS.IN_IC_REVIEW],
        },
        trailerOrder: {
          arrivalTimeStamps: arrivalTimeFilters.map(({ value }) => {
            const index = ARRIVAL_TIMES.findIndex((item) => item.value === value);
            const datetime = new Date().setSeconds(0) - index * 86400000;
            return {
              startTime:
                index < ARRIVAL_TIMES.length - 1
                  ? toUTCString(new Date(datetime - 86400000))
                  : toUTCString(new Date(2020, 1, 1)),
              endTime: toUTCString(new Date(datetime)),
            };
          }),
          commodityTypeCds: commodityTypes.map((item) => item.value),
          statusCds: [TRAILER_ORDER_STATUS_CDS.OPEN],
        },
        entityAssociations: [ORDERS_LINES],
      },
    },
    true,
    true,
    true
  );

  const {
    trailerOrdersData: trailerOrdersSearchData,
    isLoading: isSearchLoading,
    refetch: refetchSearchData,
  } = useGetTrailerOrders(
    {
      searchPage: {
        currentPage: DEFAULT_PAGE,
        pageSize: PAGE_SIZE,
        sortBy: mapUItoDBField(DEFAULT_SORT_BY),
        direction: ASC,
      },
      searchCriteria: {
        inboundOrder: {
          domainTypeCd: DOMAIN_TYPE_CD,
          sourceOrderNbr: searchValue,
        },
        trailerOrder: {
          statusCds: [TRAILER_ORDER_STATUS_CDS.OPEN],
        },
      },
    },
    false
  );

  const { inboundOrderData } = useGetInboundOrder({
    searchCriteria: {
      inboundOrder: {
        domainTypeCd: DOMAIN_TYPE_CD,
        statusCds: [TRAILER_ORDER_STATUS.IN_IC_REVIEW],
      },
      entityAssociations: [ORDERS_LINES],
    },
  });

  /* Functions */
  const onBeginChecking = (poNumber: string) => {
    navigate(PAGE_URLS.PO_DESCRIPANCY(poNumber));
  };

  const onSubzoneBeginChecking = (subzone: string) => {
    navigate(PAGE_URLS.SUBZONE_DESCRIPANCY(subzone));
  };

  const handleArrivalFilterChange = (values: DropdownOption[]) => {
    setCurrentPage(1);
    setArrivalTimeFilters(values);
  };

  const handlePOTypeFilterChange = (values: DropdownOption[]) => {
    setCurrentPage(1);
    setPOTypes(values);
  };

  const handleCommodityTypeFilterChange = (values: DropdownOption[]) => {
    setCurrentPage(1);
    setCommodityTypes(values);
  };

  const onClearAll = () => {
    handleArrivalFilterChange([]);
    handlePOTypeFilterChange([]);
    handleCommodityTypeFilterChange([]);
  };

  const onRemoveFilter = (
    filters: DropdownOption[],
    handleFilterChange: (values: DropdownOption[]) => void,
    index: number
  ) => {
    const result = [...filters];
    result.splice(index, 1);
    handleFilterChange(result);
  };
  const handleItemSearch = (value: string) => {
    setSearchValue(value);

    if (value?.length === 8) {
      setTimeout(() => {
        refetchSearchData(); // fake the delay to set state
      }, 1);
    }
  };

  const addArrivalTimeDividers = useCallback(
    (trailerOrders: FinalizationDashboardDataRowType[]) => {
      const data = [...trailerOrders];
      const currentTime = new Date();
      const grouped: Record<string, Array<FinalizationDashboardDataRowType>> = {};
      data.forEach((object) => {
        const timeDifference = currentTime.getTime() - object.arrivalTime.getTime();
        const hoursDifference = timeDifference / (1000 * 60 * 60);
        let group;
        if (object.priority > 0) {
          group = 'top';
        } else if (hoursDifference <= 24) {
          group = '24 Hours';
        } else if (hoursDifference <= 48) {
          group = '48 Hours';
        } else if (hoursDifference <= 72) {
          group = '72 Hours';
        } else {
          group = '72+ Hours';
        }
        if (!grouped[group]) {
          grouped[group] = [];
        }
        grouped[group].push(object);
      });
      let dataWithDivider: FinalizationDashboardDividerRowType[] = Object.keys(grouped)
        .sort((a, b) => -a.localeCompare(b))
        .map((key) => {
          let items: FinalizationDashboardDividerRowType[] = grouped[key].map((item) => ({
            dividerLabel: undefined,
            ...item,
            priority: item.priority ? item.priority > 0 : undefined,
          }));
          items =
            sortBy || key === 'top'
              ? [...items]
              : [{ ...FINALIZATIONDASHBOARD_DIVIDER_LABELS[key] }, ...items];
          return items;
        })
        .flat();
      if (sortBy !== '' && sortBy !== 'arrivalTime') {
        dataWithDivider = trailerOrders.map((item) => ({
          dividerLabel: undefined,
          ...item,
          priority: item.priority ? item.priority > 0 : undefined,
        }));
      }
      setPOData(dataWithDivider);
    },
    [sortBy]
  );

  const processInboundOrderLocationData = (inboundOrderData: InboundOrderICReviewType[]) => {
    const obj: Record<string, [number, string[], string | undefined]> = {};
    inboundOrderData.forEach((order) => {
      order.inboundOrder.inboundOrderLines?.forEach((line) => {
        if (
          line.statusCd == INBOUND_ORDER_STATUS.IC_REVIEW_IN_PROGRESS ||
          line.statusCd == INBOUND_ORDER_STATUS.IC_REVIEW_COMPLETED
        ) {
          line?.inboundOrderLineLocations?.forEach((location) => {
            const subzoneName = generateSubzoneNameFromDistinctName(location?.layoutDistinctName);
            if (subzoneName) {
              if (obj[subzoneName]) {
                obj[subzoneName] = [
                  obj[subzoneName][0] + 1,
                  [...new Set(...obj[subzoneName][1], order.inboundOrder.sourceOrderNbr)],
                  location?.layoutAttribNameType || undefined,
                ];
              } else {
                obj[subzoneName] = [
                  1,
                  [order.inboundOrder.sourceOrderNbr],
                  location?.layoutAttribNameType || undefined,
                ];
              }
            }
          });
        }
      });
    });

    const FinalizationDashboardLocationData: FinalizationDashboardLocationRowType[] = [];
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const element = obj[key];
        FinalizationDashboardLocationData.push({
          subzoneName: key,
          poWithError: element[1].length,
          location: element[2],
          linesWithError: element[0],
        });
      }
    }
    setOriginalLocationRowData(FinalizationDashboardLocationData);
    setLocationRowData(FinalizationDashboardLocationData);
    setFilteredLocationRowData(FinalizationDashboardLocationData);
  };

  const onSort = (mappedSorting: TableSorting[], columnID: string) => {
    const foundColumn = mappedSorting.find((column) => column.id === columnID);
    const sortDirection = foundColumn?.direction === 'asc' ? 1 : -1;
    const sortedData = [...filteredLocationRowData].sort((a, b) => {
      if (columnID === 'TotalPOswithError') {
        return (a.poWithError - b.poWithError) * sortDirection;
      } else if (columnID === 'TotalLineswithError') {
        return (a.linesWithError - b.linesWithError) * sortDirection;
      } else if (columnID === 'subzone') {
        return a.subzoneName.localeCompare(b.subzoneName) * sortDirection;
      }
      return -1;
    });

    setFilteredLocationRowData(sortedData);
  };

  const applyFilters = useCallback(() => {
    let filteredData = originalLocationRowData;

    if (isCheckboxSelected) {
      filteredData = filteredData.filter((item) => item.linesWithError >= 10);
    }

    if (locationTypeSelected?.value !== LOCATION_TYPES[0].value) {
      filteredData = filteredData.filter((item) => item.location === locationTypeSelected?.label);
    }

    setFilteredLocationRowData(filteredData);
  }, [isCheckboxSelected, locationTypeSelected, originalLocationRowData]);

  const onSelectCheckbox = () => {
    setIsCheckboxSelected(!isCheckboxSelected);
  };

  const handleLocationTypeFilterChange = (values: SingleValue) => {
    setLocationTypeSelected(values);
  };
  const handleGroupByChange = (values: SingleValue) => {
    setFilteredLocationRowData(originalLocationRowData);
    setIsCheckboxSelected(false);
    setSearchParams({ groupBy: values?.value ?? '' });
  };

  /*  Hooks */

  useEffect(() => {
    if (trailerOrdersData) {
      addArrivalTimeDividers(
        mapTrailerOrders(trailerOrdersData.content) as FinalizationDashboardDataRowType[]
      );
      setTotalPage(Math.ceil(trailerOrdersData.totalElements / PAGE_SIZE));
    }
  }, [trailerOrdersData, isLoading, addArrivalTimeDividers]);

  useEffect(() => {
    if (trailerOrdersSearchData) {
      if (searchValue && searchValue?.length === 8) {
        if (!trailerOrdersSearchData.empty) {
          const trailerOrderData = trailerOrdersSearchData.content[0].trailerOrder;

          navigate(
            PAGE_URLS.PO_DETAILS(trailerOrderData.sourceOrderNbr, trailerOrderData.receiptId)
          );
        }
      }
    }
  }, [trailerOrdersSearchData, isSearchLoading, searchValue, t, navigate]);

  useEffect(() => {
    if (inboundOrderData) {
      processInboundOrderLocationData(inboundOrderData.content);
    }
  }, [inboundOrderData]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters, isCheckboxSelected, locationTypeSelected]);

  return (
    <View direction="column" height="100%" className={styles['finalization-dashboard']}>
      <MasterTitle
        title={t('FinalizationDashboard.Title')}
        // TODO: Add functionality to save page to favorites column
        titleActionProps={{
          label: 'Favorite',
          handleClick: () => {
            return;
          },
        }}
      >
        <View direction="row" justify="end" align="center" gap={4}>
          <View.Item columns={5}>
            <POSearch
              isSearchLoading={true}
              label={t('FinalizationDashboard.Search')}
              onItemSearch={handleItemSearch}
            />
          </View.Item>
          <View.Item columns={4}>
            <Select
              label=""
              variant="no-label"
              // placeholder={t('FinalizationDashboard.GroupByPurchaseOrder')}
              value={{
                label: t(GroupByOptionValue?.label ?? ''),
                value: GroupByOptionValue?.value ?? '',
              }}
              options={GroupByOptions.map((option) => ({
                label: t(option.label),
                value: option.value,
              }))}
              onValueChange={handleGroupByChange}
            />
          </View.Item>
        </View>
      </MasterTitle>

      {selectedGroupBy == URLQuery.LOCATIONS ? (
        <>
          <View direction="row" padding={[4, 6]} gap={4}>
            <View.Item columns={2}>
              <Select
                label=""
                name="locationType"
                variant="no-label"
                placeholder="All Location Types"
                options={LOCATION_TYPES.map((locationOption) => ({
                  label: locationOption.label,
                  value: locationOption.value,
                }))}
                onValueChange={(value) => handleLocationTypeFilterChange(value)}
              />
            </View.Item>
            <View attributes={{ style: { marginTop: '20px' } }}>
              <Checkbox
                value="ford"
                name="make"
                label={t('FinalizationDashboard.CheckboxLabel')}
                checked={isCheckboxSelected}
                onChange={() => onSelectCheckbox()}
              />
            </View>
          </View>
          <View padding={[4, 6]}>
            <Table
              columns={FINALIZE_DASHBOARD_LOCATIONS_TABLE_COLUMNS}
              rows={mapFinalizationDashboardLocationsRows(
                filteredLocationRowData,
                onSubzoneBeginChecking
              )}
              pageSize={PAGE_SIZE}
              isPaginated={false}
              defaultPage={0}
              isCheckboxTable={false}
              isCreditItem={false}
              styleVariant={TableStylingVariants.DETAILS}
              totalPages={0}
              onSort={onSort}
            />
          </View>
        </>
      ) : (
        <>
          <View
            direction="row"
            padding={[4, 6]}
            className={styles['finalization-dashboard__filters-section']}
          >
            <View.Item>
              <DropdownSelectMenu
                width={200}
                name="arrivalTimeFilter"
                label="All Arrival Time"
                value={arrivalTimeFilters}
                options={ARRIVAL_TIMES}
                onChange={handleArrivalFilterChange}
                onReset={() => handleArrivalFilterChange([])}
              />
            </View.Item>
            <View.Item>
              <DropdownSelectMenu
                width={320}
                name="poTypes"
                label="All PO Types"
                value={poTypes}
                options={
                  PO_TYPES
                    ? PO_TYPES.map((type) => ({
                        label: `${type.configValue} (${type.description})`,
                        value: type.configValue,
                      }))
                    : []
                }
                onChange={handlePOTypeFilterChange}
                onReset={() => handlePOTypeFilterChange([])}
              />
            </View.Item>
            <View.Item>
              <DropdownSelectMenu
                width={320}
                name="commodityTypes"
                label="All Commodity Types"
                searchable={true}
                value={commodityTypes}
                options={
                  COMMODITY_TYPES
                    ? COMMODITY_TYPES.map((item) => ({
                        label: `${item.configValue} (${item.description})`,
                        value: item.configValue,
                      }))
                    : []
                }
                onChange={handleCommodityTypeFilterChange}
                onReset={() => handleCommodityTypeFilterChange([])}
              />
            </View.Item>

            <View.Item
              attributes={{
                style: { height: '100%' },
              }}
            >
              <View height="100%" justify="center">
                <Actionable onClick={onClearAll}>
                  <Text
                    className={styles['finalization-dashboard__actionable-text']}
                    size="087"
                    weight="medium"
                    variant="text-link"
                  >
                    {t('PODashboard.ClearAll')}
                  </Text>
                </Actionable>
              </View>
            </View.Item>
          </View>
          <View direction="row" padding={[2, 6]}>
            {arrivalTimeFilters &&
              arrivalTimeFilters.map((filter, index) => (
                <View className={styles['finalization-dashboard__filter-item']}>
                  <Text size="087">{filter.label}</Text>
                  <Actionable
                    onClick={() => {
                      onRemoveFilter(arrivalTimeFilters, handleArrivalFilterChange, index);
                    }}
                  >
                    <Icon svg={Close} size={4} />
                  </Actionable>
                </View>
              ))}
            {poTypes.length > 0 &&
              poTypes.map((filter, index) => (
                <View className={styles['finalization-dashboard__filter-item']}>
                  <Text size="087">{filter.label}</Text>
                  <Actionable
                    onClick={() => {
                      onRemoveFilter(poTypes, handlePOTypeFilterChange, index);
                    }}
                  >
                    <Icon svg={Close} size={4} />
                  </Actionable>
                </View>
              ))}
            {commodityTypes.length > 0 &&
              commodityTypes.map((filter, index) => (
                <View className={styles['finalization-dashboard__filter-item']}>
                  <Text size="087">{filter.label}</Text>
                  <Actionable
                    onClick={() => {
                      onRemoveFilter(commodityTypes, handleCommodityTypeFilterChange, index);
                    }}
                  >
                    <Icon svg={Close} size={4} />
                  </Actionable>
                </View>
              ))}
          </View>
          <View padding={[4, 6]}>
            <Table
              columns={FINALIZE_DASHBOARD_TABLE_COLUMNS}
              rows={mapFinalizationDashboardRows(poData, onBeginChecking)}
              pageSize={PAGE_SIZE}
              defaultPage={DEFAULT_PAGE}
              isCreditItem={false}
              isCheckboxTable={false}
              isCheckboxDisabled={false}
              isApiLoadedData={true}
              isLoading={isFetchingTableData}
              styleVariant={TableStylingVariants.DETAILS}
              totalPages={totalPage}
              onPageChange={(_pageNumber) => {
                setCurrentPage(_pageNumber);
              }}
              onSort={(_sorting, _columnId) => {
                _sorting.forEach((sorting) => {
                  if (sorting.id === _columnId) {
                    setSortBy(_columnId);
                    setDirection(sorting.direction);
                  }
                });
              }}
              isPaginated={false}
            />
          </View>
        </>
      )}
    </View>
  );
};
