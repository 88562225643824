/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { AttributeSchema } from '@mdm/schemas/attributeSchema.ts';

import {
  addCustomAlphaNumericAttributeFieldValidation,
  addCustomMaxLengthAttributeFieldValidation,
  addCustomNumericAttributeFieldValidation,
  addCustomRequiredAttributeFieldValidation,
} from '@mdm/utils/form/validationUtils.tsx';
import {
  ASSET_TYPE_CODE,
  LAYOUT_ENTITY_CODE,
  LAYOUT_ENTITY_TYPE_CODE,
  MAX_LENGTH_FIELD,
  SUBZONE_FIELD,
} from '@mdm/constants/constants.ts';
import { LayoutAssetSchema, LayoutProductSchema } from '@mdm/schemas/layoutSchema.ts';
import { t } from 'i18next';
import { isAlphaNumeric } from '@shared/utils/validationUtils.ts';

export const defaultLayoutAssetValues = [
  {
    assetTypeCd: ASSET_TYPE_CODE.CONTAINER,
    assetCd: '',
    assetDesc: '',
    assetAttributes: null,
    assetLayoutMappingAttrib: null,
  },
];

export const defaultAttributeValues = [
  {
    name: SUBZONE_FIELD.LABEL_SORT,
    value: '',
  },
  {
    name: SUBZONE_FIELD.PICK_PRIORITY,
    value: '0',
  },
  {
    name: SUBZONE_FIELD.SUBZONE_TYPE,
    value: '',
  },
  {
    name: SUBZONE_FIELD.MEZZANINE,
    value: 'false',
  },
  {
    name: SUBZONE_FIELD.CONVEYOR_FLAG,
    value: 'false',
  },
  {
    name: SUBZONE_FIELD.MULT_PART_ALLOW_LOC,
    value: 'false',
  },
  {
    name: SUBZONE_FIELD.PICK_DROP_LOCATION,
    value: '',
  },
  {
    name: SUBZONE_FIELD.PUT_DROP_LOCATION,
    value: '',
  },
  {
    name: SUBZONE_FIELD.SKU_PICK_CLASS,
    value: '',
  },
  {
    name: SUBZONE_FIELD.ITEM_DESC_PROMPT,
    value: 'false',
  },
  {
    name: SUBZONE_FIELD.PUT_PRIORITY,
    value: '0',
  },
  {
    name: SUBZONE_FIELD.CATEGORY_LABEL,
    value: '',
  },
  {
    name: SUBZONE_FIELD.BIDIRECTIONAL_PICK,
    value: 'false',
  },
  {
    name: SUBZONE_FIELD.PICK_METHOD,
    value: '',
  },
  {
    name: SUBZONE_FIELD.ITEM_VERIFY,
    value: 'false',
  },
  {
    name: SUBZONE_FIELD.PACK_METHOD,
    value: '',
  },
  {
    name: SUBZONE_FIELD.LENGTH,
    value: '0',
  },
  {
    name: SUBZONE_FIELD.WIDTH,
    value: '0',
  },
  {
    name: SUBZONE_FIELD.HEIGHT,
    value: '0',
  },
  {
    name: SUBZONE_FIELD.WEIGHT,
    value: '0',
  },
  {
    name: SUBZONE_FIELD.RESERVE_COUNT,
    value: '0',
  },
  {
    name: SUBZONE_FIELD.SLOT_COUNT,
    value: '0',
  },
];

export const SubzoneSchema = z.object({
  layoutKey: z.string().optional(),
  layoutName: z.string().superRefine((val, ctx) => {
    if (val == '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.RequiredField'),
      });
    }

    if (val.length > MAX_LENGTH_FIELD.SUBZONE_NAME) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        maximum: MAX_LENGTH_FIELD.SUBZONE_NAME,
        type: 'string',
        inclusive: true,
        message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.SUBZONE_NAME }),
      });
    }

    if (!isAlphaNumeric(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.AlphaNumericError'),
      });
    }
  }),
  layoutNameDesc: z
    .string()
    .nullable()
    .default('')
    .optional()
    .superRefine((val, ctx) => {
      if (val && val.length > MAX_LENGTH_FIELD.SUBZONE_DESCRIPTION) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          maximum: MAX_LENGTH_FIELD.SUBZONE_DESCRIPTION,
          type: 'string',
          inclusive: true,
          message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.SUBZONE_DESCRIPTION }),
        });
      }

      if (val && !isAlphaNumeric(val, true)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('Form.AlphaNumericError'),
        });
      }
    }),
  layoutDistinctName: z.string().optional(),
  parentLayoutDistinctName: z.string().min(1),
  active: z.boolean().default(true),
  entityCd: z.string().default(LAYOUT_ENTITY_CODE.SUBZONE),
  entityTypeCd: z.string().default(LAYOUT_ENTITY_TYPE_CODE.DEFAULT),
  mapSequence: z.number().nullable().default(null).optional(),
  attributes: z
    .array(AttributeSchema)
    .superRefine((data, ctx) => {
      const requiredFields = [
        SUBZONE_FIELD.CATEGORY_LABEL,
        SUBZONE_FIELD.LABEL_SORT,
        SUBZONE_FIELD.MEZZANINE,
        SUBZONE_FIELD.MULT_PART_ALLOW_LOC,
        SUBZONE_FIELD.PICK_METHOD,
        SUBZONE_FIELD.PICK_PRIORITY,
        SUBZONE_FIELD.PICK_DROP_LOCATION,
        SUBZONE_FIELD.PUT_DROP_LOCATION,
        SUBZONE_FIELD.PUT_PRIORITY,
        SUBZONE_FIELD.SUBZONE_TYPE,
        SUBZONE_FIELD.SKU_PICK_CLASS,
        SUBZONE_FIELD.LENGTH,
        SUBZONE_FIELD.WIDTH,
        SUBZONE_FIELD.HEIGHT,
        SUBZONE_FIELD.WEIGHT,
      ];
      addCustomRequiredAttributeFieldValidation(data, requiredFields, ctx);

      const alphaNumericFields = [
        {
          key: SUBZONE_FIELD.CATEGORY_LABEL,
          allowSpace: true,
          allowUnderscore: true,
        },
        {
          key: SUBZONE_FIELD.PICK_DROP_LOCATION,
        },
        {
          key: SUBZONE_FIELD.PUT_DROP_LOCATION,
        },
      ];
      addCustomAlphaNumericAttributeFieldValidation(data, alphaNumericFields, ctx);

      const maxLengthFields = [
        {
          name: SUBZONE_FIELD.CATEGORY_LABEL,
          maxLength: MAX_LENGTH_FIELD.SUB_ZONE_CATEGORY_LABEL,
        },
        {
          name: SUBZONE_FIELD.PICK_DROP_LOCATION,
          maxLength: MAX_LENGTH_FIELD.SUBZONE_PICK_DROP_LOCATION,
        },
        {
          name: SUBZONE_FIELD.PUT_DROP_LOCATION,
          maxLength: MAX_LENGTH_FIELD.SUBZONE_PUT_DROP_LOCATION,
        },
        {
          name: SUBZONE_FIELD.LENGTH,
          maxLength: MAX_LENGTH_FIELD.SUBZONE_LENGTH,
        },
        {
          name: SUBZONE_FIELD.WIDTH,
          maxLength: MAX_LENGTH_FIELD.SUBZONE_WIDTH,
        },
        {
          name: SUBZONE_FIELD.HEIGHT,
          maxLength: MAX_LENGTH_FIELD.SUBZONE_HEIGHT,
        },
        {
          name: SUBZONE_FIELD.WEIGHT,
          maxLength: MAX_LENGTH_FIELD.SUBZONE_WEIGHT,
        },
        {
          name: SUBZONE_FIELD.PICK_PRIORITY,
          maxLength: MAX_LENGTH_FIELD.SUBZONE_PICK_PRIORITY,
        },
        {
          name: SUBZONE_FIELD.PUT_PRIORITY,
          maxLength: MAX_LENGTH_FIELD.SUBZONE_PUT_PRIORITY,
        },
      ];
      addCustomMaxLengthAttributeFieldValidation(data, maxLengthFields, ctx);

      const numericFields = [
        {
          key: SUBZONE_FIELD.LENGTH,
        },
        {
          key: SUBZONE_FIELD.WIDTH,
        },
        {
          key: SUBZONE_FIELD.HEIGHT,
        },
        {
          key: SUBZONE_FIELD.WEIGHT,
        },
        {
          key: SUBZONE_FIELD.PICK_PRIORITY,
        },
        {
          key: SUBZONE_FIELD.PUT_PRIORITY,
        },
      ];

      addCustomNumericAttributeFieldValidation(data, numericFields, ctx);
    })
    .nullable()
    .default(defaultAttributeValues),
  assets: z
    .array(LayoutAssetSchema)
    .nullable()
    .default([
      {
        assetTypeCd: ASSET_TYPE_CODE.CONTAINER,
        assetCd: '',
        assetDesc: '',
        assetAttributes: null,
        assetLayoutMappingAttrib: null,
      },
    ]),
  products: z.array(LayoutProductSchema).nullable().default([]),
});
