/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View } from '@az/starc-ui';

import { Props } from '@outbound/components/ReleaseGroupAccordion/ReleaseGroupAccordion.types.tsx';
import {
  RELEASE_GROUPS_GROUP_TYPE,
  RELEASE_GROUPS_ORDER_TYPE,
} from '@outbound/constants/constants.ts';
import { useGetReleaseGroups } from '@outbound/services/hooks/useGetReleaseGroups.tsx';
import { DEFAULT_PAGE, PAGE_SIZE } from '@shared/constants/constants.ts';
import { TableStylingVariants } from '@shared/components/Table/tableConstants.ts';
import { ORDER_REVIEW_ORDER_DETAIL_COLUMNS } from '@shared/components/Table/tableColumnData.ts';

import style from './OrderReviewByAreaList.module.scss';
import { mapOrderReviewOrderDetailTableRows } from '@outbound/utils/table/orderReviewByAreaTableUtils.tsx';
import { Table } from '@shared/components/Table/Table.tsx';
import { useState } from 'react';

export const OrderReviewOrderDetailList = ({
  releaseGroup,
  orderType = RELEASE_GROUPS_ORDER_TYPE.DIF_WORK_ORDER,
  groupTypes = [RELEASE_GROUPS_GROUP_TYPE.LEAF],
}: Props) => {
  /* State */
  const [currentPage, setCurrentPage] = useState<number>(1);

  /* Variables */
  const { groupId } = releaseGroup;

  /* Constants */
  const { releaseGroupsData, isFetching: isReleaseGroupLoading } = useGetReleaseGroups({
    currentPage: currentPage - 1,
    pageSize: PAGE_SIZE,
    orderType: orderType,
    parentReleaseGroupId: groupId,
    groupTypes,
  });

  return (
    <>
      <View
        className={style['order-release-list']}
        attributes={{
          style: {
            maxWidth: '100%',
            overflowX: 'auto',
          },
        }}
      >
        <Table
          columns={ORDER_REVIEW_ORDER_DETAIL_COLUMNS}
          rows={mapOrderReviewOrderDetailTableRows(releaseGroupsData?.content || [])}
          isPaginated={true}
          isCheckboxDisabled={false}
          pageSize={PAGE_SIZE}
          defaultPage={DEFAULT_PAGE}
          isCreditItem={false}
          isCheckboxTable={false}
          isLoading={isReleaseGroupLoading}
          isApiLoadedData={true}
          styleVariant={TableStylingVariants.DETAILS}
          totalPages={releaseGroupsData?.totalPages || 0}
          onPageChange={(newPage) => {
            setCurrentPage(newPage);
          }}
          onSort={() => ({})}
        />
      </View>
    </>
  );
};
