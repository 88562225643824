/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState, useMemo, useEffect, useRef } from 'react';
import {
  View,
  Text,
  Actionable,
  Icon,
  ActionDelete,
  Add,
  Link,
  Modal,
  Close,
  Button,
  Skeleton,
} from '@az/starc-ui';
import { t } from 'i18next';
import { ASC, DEFAULT_PAGE, PAGE_SIZE } from '@inbound/constants/constants';
import { USER_ID, USER_ROLES, NOTIFICATION_TYPES } from '@shared/constants/constants';
import { UserSearchSchemaType } from '@shared/types/schema.type';
import { useUsersSearch } from '@shared/services/hooks/useUsersSearch';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { AssigneeSelect } from '@inventory/components/AssigneeSelect/AssigneeSelect';
import { useMutateSubZoneAssignees } from '@inventory/services/hooks/useMutateSubZoneAssignees';
import { SubzoneType } from '@inventory/types/types';
import {
  getDistinctAssignedUsers,
  getInProgressLocationCountsByUser,
} from '@inventory/utils/utils';
import { SubZoneAssigneesLocCountSyncParams } from '@inventory/types/service.types';
import { USER_STATUS_CD } from '@inventory/constants/constants';
import { assigneeListType } from '@inventory/pages/CycleCounts/components/Subzones/Subzones.types';
import { RemoveUserConfirmationModal } from './RemoveUserConfirmationModal';
import styles from '../../CycleCountsModal.module.scss';

type MultipleRBCCAssigneeProps = {
  showAssigneeModal: boolean;
  closeModal: () => void;
  countTypeCd: string;
  statusCd?: string;
  taskId?: string;
  subzones?: SubzoneType[];
};

type UpdateMessageRefType = {
  addedUsers: assigneeListType[];
  removedUsers: assigneeListType[];
};

export const MultipleRBCCAssignee = ({
  showAssigneeModal,
  closeModal,
  countTypeCd,
  statusCd,
  taskId,
  subzones = [],
}: MultipleRBCCAssigneeProps) => {
  const { subzoneAssignedUsers: existingUsers, subZoneLocations } = useMemo(
    () => getDistinctAssignedUsers(subzones),
    [subzones]
  );

  const usersWithInProgressLocations = useMemo(
    () => getInProgressLocationCountsByUser(subZoneLocations),
    [subZoneLocations]
  );

  const existingUserIds = useMemo(() => existingUsers.map((user) => user.userId), [existingUsers]);

  const [assignedUsers, setAssignedUsers] = useState(new Map());
  const [unAssignedUsers, setUnAssignedUsers] = useState(new Map());
  const [showAssigneeSelection, setShowAssigneeSelection] = useState(true);
  const [isRemoveConfirmationModalOpen, setRemoveConfirmationModalOpen] = useState<boolean>(false);
  const [userToRemove, setUserToRemove] = useState<assigneeListType | null>(null);
  const [removedUserIndex, setRemovedUserIndex] = useState<number | null>(null);

  const updateMessageRef = useRef<UpdateMessageRefType>({
    addedUsers: [],
    removedUsers: [],
  });

  const { usersSearchData: assigneesData, isLoading: isUserLoading } = useUsersSearch({
    searchPage: {
      page: DEFAULT_PAGE,
      size: PAGE_SIZE,
      sortBy: USER_ID,
      direction: ASC,
    },
    searchCriteria: {
      deptId: USER_ROLES.INVENTORY_CONTROL,
    },
  });

  const { isLoading: isMutateSubzoneAssignee, mutateSubZoneAssignees } =
    useMutateSubZoneAssignees();

  const { handleNotification } = useNotificationHandler();

  const handleAddAssignee = () => {
    setShowAssigneeSelection(false);
    setAssignedUsers((prevAssignedUsers) => {
      const newAssignedUsers = new Map(prevAssignedUsers);
      newAssignedUsers.set(newAssignedUsers.size, {
        userId: '',
        firstName: '',
        lastName: '',
        layoutLocationCount: 0,
        assignee: '',
        isNewUser: true,
      });
      return newAssignedUsers;
    });
  };

  const addUser = (index: number, user: assigneeListType) => {
    setAssignedUsers((prev) => {
      const updated = new Map(prev);
      updated.set(index, user);
      const sortedEntries = Array.from(updated.entries()).sort(([a], [b]) => a - b);
      return new Map(sortedEntries);
    });

    setUnAssignedUsers((prev) => {
      const updated = new Map(prev);
      updated.delete(user.userId);
      return updated;
    });
  };

  const removeUser = (index: number, user: assigneeListType) => {
    setAssignedUsers((prev) => {
      const updated = new Map(prev);
      updated.delete(index);
      const sortedEntries = Array.from(updated.entries()).sort(([a], [b]) => a - b);
      return new Map(sortedEntries);
    });

    if (user.userId) {
      setUnAssignedUsers((prev) => {
        const updated = new Map(prev);
        updated.set(user.userId, user);
        return updated;
      });
    }
  };

  const generateSubZoneAssignmentPayload = () => {
    const newOrUpdatedUsers = new Set<assigneeListType>();
    const addedUsers: assigneeListType[] = [];
    const removedUsers: assigneeListType[] = [];

    [...assignedUsers.values()].forEach((user) => {
      if (!existingUserIds.includes(user.userId)) {
        user.statusCd = USER_STATUS_CD.ASSIGNED;
        newOrUpdatedUsers.add(user);
        addedUsers.push(user.assignee);
      }
    });

    existingUserIds.forEach((userId) => {
      if (unAssignedUsers.has(userId)) {
        const user = unAssignedUsers.get(userId);
        if (user) {
          user.statusCd = USER_STATUS_CD.UNASSIGNED;
          newOrUpdatedUsers.add(user);
          removedUsers.push(user.assignee);
        }
      }
    });

    updateMessageRef.current = { addedUsers, removedUsers };

    return Array.from(newOrUpdatedUsers);
  };

  const constructUpdateMessage = (
    addedUsers: assigneeListType[],
    removedUsers: assigneeListType[]
  ) => {
    if (addedUsers.length === 1 && removedUsers.length === 0) {
      return t('CycleCount.ChooseAssigneesModal.RBCCAssigneesAssigned', {
        userName: addedUsers[0],
      });
    }

    if (addedUsers.length === 0 && removedUsers.length === 1) {
      return t('CycleCount.ChooseAssigneesModal.RBCCAssigneesRemoved', {
        userName: removedUsers[0],
      });
    }

    if (addedUsers.length > 0 && removedUsers.length > 0) {
      return t('CycleCount.ChooseAssigneesModal.RBCCAssigneesUpdated', {
        action: 'assigned and removed',
        direction: 'from',
      });
    }

    if (addedUsers.length > 0) {
      return t('CycleCount.ChooseAssigneesModal.RBCCAssigneesUpdated', {
        action: 'assigned',
        direction: 'to',
      });
    }

    if (removedUsers.length > 0) {
      return t('CycleCount.ChooseAssigneesModal.RBCCAssigneesUpdated', {
        action: 'removed',
        direction: 'from',
      });
    }

    return '';
  };

  const submitAssignedUsers = () => {
    const allUsers = generateSubZoneAssignmentPayload();
    try {
      const subZoneAssigneesData = allUsers.map(
        (user) =>
          user.userId && {
            countRequest: {
              countTypeCd: countTypeCd,
              statusCd: statusCd,
              taskId: taskId,
            },
            user: {
              userId: user.userId ?? '',
              firstName: user.firstName ?? '',
              lastName: user.lastName ?? '',
              statusCd: user.statusCd,
              layoutLocationCount: user.layoutLocationCount,
            },
          }
      );

      const payload: { AssignSubZoneLocCountRequest: SubZoneAssigneesLocCountSyncParams } = {
        AssignSubZoneLocCountRequest: subZoneAssigneesData as SubZoneAssigneesLocCountSyncParams,
      };

      mutateSubZoneAssignees(payload, {
        onSuccess: () => {
          const { addedUsers, removedUsers } = updateMessageRef.current;
          const successMessage = constructUpdateMessage(addedUsers, removedUsers);

          handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
          closeModal();
        },
      });
    } catch (error) {
      const errorMessage = t('Errors.Action.UpdateSubZoneAssignee');
      handleNotification(NOTIFICATION_TYPES.ERROR, errorMessage);
    }
  };

  const onClear = (index: number, user: assigneeListType) => {
    setAssignedUsers((prev) => {
      const updated = new Map(prev);
      const isExistingUser = existingUserIds.includes(user.userId);
      updated.set(index, {
        userId: '',
        firstName: '',
        lastName: '',
        layoutLocationCount: 0,
        assignee: '',
        isNewUser: isExistingUser ? false : user.isNewUser,
      });
      return updated;
    });

    if (user.userId) {
      setUnAssignedUsers((prev) => {
        const updated = new Map(prev);
        updated.set(user.userId, user);
        return updated;
      });
    }
  };

  const onRemoveUser = (index: number, user: assigneeListType) => {
    // Count tasks in progress for this user
    if (usersWithInProgressLocations.has(user.userId)) {
      setUserToRemove(user);
      // need this state to pass index to "removeUser" function
      setRemovedUserIndex(index);
      setRemoveConfirmationModalOpen(true);
    } else {
      if (user.isNewUser) {
        setShowAssigneeSelection(true);
      }
      removeUser(index, user);
    }
  };

  const onAddAssignee = (
    index: number,
    prevUser: assigneeListType,
    newUser: UserSearchSchemaType
  ) => {
    const isNewUserExistingUser = existingUserIds.includes(newUser.userId)
      ? false
      : prevUser.isNewUser;
    if (prevUser.userId) {
      removeUser(index, prevUser);
    }
    addUser(index, mapUserToAssignee(newUser, USER_STATUS_CD.ASSIGNED, isNewUserExistingUser));
  };

  const mapUserToAssignee = (
    user: UserSearchSchemaType,
    status: string,
    isExistingUser = false
  ) => ({
    userId: user.userId,
    firstName: user.firstName,
    lastName: user.lastName,
    statusCd: status,
    layoutLocationCount: 0,
    assignee: user.firstName ? `${user.firstName} ${user.lastName ?? ''}` : '',
    facilities: user.facilities,
    isNewUser: isExistingUser,
  });

  const shouldEnableAddAssignee = useMemo(() => {
    const hasNewUsers = [...assignedUsers.values()].some(
      (user) => !existingUserIds.includes(user.userId) && user.isNewUser
    );

    const allUsersAreExisting = !hasNewUsers;
    const noUsersAssigned = assignedUsers.size === 0;

    return allUsersAreExisting || showAssigneeSelection || noUsersAssigned;
  }, [assignedUsers, existingUserIds, showAssigneeSelection]);

  const shouldEnableSave = useMemo(() => {
    // Check if there are any users without a userId
    const hasUsersWithoutUserId = [...assignedUsers.values()].some((user) => !user.userId);

    // If any user doesn't have a userId, the save button should be disabled
    if (hasUsersWithoutUserId) {
      return false;
    }

    if (assignedUsers.size === 0) {
      return false; // If no assigned users, save is not enabled
    }

    const hasAnyExistingUserRemoved = existingUserIds.some((key) => unAssignedUsers.has(key));
    const hasNewAssignedUser = [...assignedUsers.values()].some((user) => {
      return user.userId && !existingUserIds.includes(user.userId);
    });

    return hasNewAssignedUser || hasAnyExistingUserRemoved;
  }, [assignedUsers, existingUserIds, unAssignedUsers]);

  useEffect(() => {
    if (assigneesData?.content) {
      const newAssignedUsers = new Map();
      const newUnAssignedUsers = new Map();
      let assignedUsersIndex = 0;

      assigneesData.content.forEach((user) => {
        if (existingUserIds.includes(user.userId)) {
          newAssignedUsers.set(
            assignedUsersIndex++,
            mapUserToAssignee(user, USER_STATUS_CD.ASSIGNED)
          );
        } else {
          newUnAssignedUsers.set(user.userId, mapUserToAssignee(user, USER_STATUS_CD.UNASSIGNED));
        }
      });

      setAssignedUsers(newAssignedUsers);
      setUnAssignedUsers(newUnAssignedUsers);
    }
  }, [assigneesData?.content, existingUserIds]);

  return (
    <>
      <Modal
        open={showAssigneeModal}
        onClose={closeModal}
        closeByClickAway={false}
        attributes={{ style: { width: '552px', padding: 4 } }}
      >
        <View gap={2}>
          <View direction="column" gap={2}>
            <View
              direction="row"
              justify="space-between"
              align="center"
              className={styles['cycle-counts-modal__header']}
            >
              <Text
                as="h2"
                size="175"
                weight="bold"
                color="primary"
                attributes={{ style: { margin: 0 } }}
              >
                {t('CycleCount.ChooseAssigneesModal.Title')}
              </Text>

              <Button variant="ghost" onClick={closeModal}>
                <Icon svg={Close} />
              </Button>
            </View>
            <View.Item columns={12}>
              <View>
                <Text>{t('CycleCount.ChooseAssigneesModal.MultipleAssigneesDescription')}</Text>
              </View>
            </View.Item>
          </View>
          <View gap={2}>
            <View gap={2}>
              <View.Item>
                <View direction="row" justify="start" gap={3}>
                  <View.Item>
                    <Text variant="display-5">
                      {t('CycleCount.ChooseAssigneesModal.Assignees')}
                    </Text>
                  </View.Item>
                </View>
              </View.Item>
              {isUserLoading ? (
                <View>
                  <Skeleton borderRadius="small" height="var(--st-unit-13)" />
                </View>
              ) : (
                Array.from(assignedUsers.keys()).map((key, index) => {
                  const userListItem = assignedUsers.get(key);
                  return (
                    <View key={index} direction="row" gap={2}>
                      <View.Item columns={11}>
                        <AssigneeSelect
                          data={Array.from(unAssignedUsers.values()) || []}
                          name={`Assignee-${index}`}
                          label={t('CycleCount.ChooseAssigneesModal.Assignees')}
                          selectedAssignee={userListItem}
                          setSelectedAssignee={() => {
                            onClear(key, userListItem);
                          }}
                          onSelect={(selectedAssignee) => {
                            onAddAssignee(
                              key,
                              userListItem,
                              selectedAssignee as UserSearchSchemaType
                            );
                          }}
                        />
                      </View.Item>
                      <View.Item columns={1}>
                        <View align="center" justify="center" padding={[2, 0, 0, 0]}>
                          <Actionable onClick={() => onRemoveUser(key, userListItem)}>
                            <View
                              key={index}
                              height="30px"
                              width="30px"
                              backgroundColor="secondary"
                              align="center"
                              justify="center"
                              attributes={{
                                style: {
                                  borderRadius: '50%',
                                },
                              }}
                            >
                              <Icon svg={ActionDelete} color="primary" size={7} />
                            </View>
                          </Actionable>
                        </View>
                      </View.Item>
                    </View>
                  );
                })
              )}
            </View>

            {shouldEnableAddAssignee && !isUserLoading && (
              <View.Item columns={4}>
                <Link onClick={handleAddAssignee} startIcon={Add}>
                  {t('CycleCount.ChooseAssigneesModal.AddAssignee')}
                </Link>
              </View.Item>
            )}
          </View>

          <View direction="row" justify="end">
            <View.Item>
              <Button variant="secondary" size="large" onClick={closeModal}>
                {t(`CycleCount.ChooseAssigneesModal.Cancel`)}
              </Button>
            </View.Item>
            <View.Item gapBefore={6}>
              <Button
                size="large"
                onClick={submitAssignedUsers}
                disabled={!shouldEnableSave}
                loading={isMutateSubzoneAssignee}
              >
                {t(`CycleCount.ChooseAssigneesModal.Save`)}
              </Button>
            </View.Item>
          </View>
        </View>
      </Modal>

      <RemoveUserConfirmationModal
        isOpen={isRemoveConfirmationModalOpen}
        onModalClose={() => {
          setRemoveConfirmationModalOpen(false);
          setRemovedUserIndex(null);
          setUserToRemove(null);
        }}
        subZoneLocations={subZoneLocations}
        removedUser={{
          name: userToRemove?.assignee ?? '',
          id: userToRemove?.userId ?? '',
        }}
        onConfirm={() => removeUser(removedUserIndex as number, userToRemove as assigneeListType)}
      />
    </>
  );
};
