/**
 * Copyright 2025 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React from 'react';
import { Table } from '@shared/components/Table/Table';
import { PAGE_SIZE } from '@shared/constants/constants';
import { DEFAULT_PAGE } from '@inbound/constants/constants';
import { TableStylingVariants } from '@shared/components/Table/tableConstants';

type UnreplenishedDataType = {
  priority: number | null;
  userId: string | null;
  vehicle: string | null;
  subzone: string | null;
  replenCount: number;
};

export const ReplenishmentDashboardGridView: React.FC<{
  data?: UnreplenishedDataType[];
  title: string;
  setShowAssigneesModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDataForModal: React.Dispatch<React.SetStateAction<[string, string, string | number] | null>>;
}> = ({ data = [], title, setShowAssigneesModal, setDataForModal }) => {
  const prepareHeaders = (data: UnreplenishedDataType[], title: string) => {
    if (!Array.isArray(data) || data.length === 0) {
      return [];
    }

    // Ensure labels are non-null strings
    return [title, ...Array.from(new Set(data.map((item) => item.subzone || 'Unknown')))].map(
      (header) => ({
        id: `header_${header}`,
        label: header,
      })
    );
  };

  const prepareRows = (data: UnreplenishedDataType[], title: string) => {
    if (!Array.isArray(data) || data.length === 0) {
      return [];
    }

    const keyName: keyof UnreplenishedDataType = title === 'Priority' ? 'priority' : 'vehicle';
    const headers = prepareHeaders(data, title);

    const groupedData = new Map<string | number, UnreplenishedDataType[]>();

    data.forEach((item) => {
      const key = item[keyName];
      if (key !== 0 && key !== undefined && key !== null && key !== '') {
        if (!groupedData.has(key)) {
          groupedData.set(key, []);
        }
        (groupedData.get(key) ?? []).push(item);
      }
    });

    return Array.from(groupedData.entries()).map(([key, group]) => {
      const cells = headers.map(({ label }) => {
        if (label === 'Priority' || label === 'Vehicle') {
          return { value: key };
        }

        const entry = group.find((d) => d.subzone === label);
        return {
          value:
            entry && entry.replenCount > 0 ? (
              <span
                onClick={() => onCellClick(keyName, label as string, key)}
                style={{ cursor: 'pointer', color: 'black' }}
              >
                {entry.replenCount}
              </span>
            ) : entry ? (
              entry.replenCount
            ) : (
              0
            ),
        };
      });

      const rowId = `row_${key}_${cells
        .map((cell) => (cell.value != null ? cell.value : ''))
        .join('_')}`;

      return {
        id: rowId,
        cells,
      };
    });
  };

  const onCellClick = (keyName: string, subzone: string, priorityNumber: number | string) => {
    setShowAssigneesModal(true);
    const tempData: [string, string, number | string] = [keyName, subzone, priorityNumber];
    setDataForModal(tempData);
  };

  const columns = prepareHeaders(data, title);
  const rows = prepareRows(data, title);

  return (
    <Table
      columns={columns}
      rows={rows}
      totalPages={0}
      isPaginated={false}
      isCheckboxDisabled={false}
      isApiLoadedData={true}
      pageSize={PAGE_SIZE}
      defaultPage={DEFAULT_PAGE}
      isCreditItem={false}
      isCheckboxTable={false}
      styleVariant={TableStylingVariants.DETAILS}
      onSort={(): void => {
        throw new Error('Function not implemented.');
      }}
    />
  );
};
