/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { useEffect } from 'react';
import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useTranslation } from 'react-i18next';
import { replenishmentService } from '@inbound/services/replenishmentService';

export const useGetReplenishmentMovesGroups = (param: string, isEnabled = true) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const {
    data: replenishmentCountData,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['replenishmentCount'],
    queryFn: () => replenishmentService.getReplenishmentMovesGroups(param),
    enabled: isEnabled,
  });

  useEffect(() => {
    if (isError) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Errors.ReplenishmentMovesgroup.Description'));
    }
  }, [isError, handleNotification, t]);

  return { replenishmentCountData, isLoading, isError, refetch };
};
