/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGE_URLS, ROUTES } from '@shared/constants/routes.ts';
import { KEY, KEY_CODE } from '@shared/constants/keyConstants.ts';
import { Button, SearchBar, Text, View } from '@az/starc-ui';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import styles from '@outbound/pages/ControlDesk/ControlDesk.module.scss';
import { OrderReleaseTabTitles, RELEASE_GROUPS_GROUP_TYPE } from '@outbound/constants/constants.ts';
import { CombinedTabs } from '@shared/components/CombinedTabs/CombinedTabs.tsx';
import { useGetReleaseGroups } from '@outbound/services/hooks/useGetReleaseGroups.tsx';
import { DEFAULT_PAGE, MAX_PAGE_SIZE, NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { useAtom } from 'jotai/index';
import {
  selectedOrdersAtom,
  selectedReleaseOrderLaneAtom,
  selectedReleaseOrderTypeAtom,
  selectedRootReleaseOrdersAtom,
} from '@outbound/atoms/releaseOrder/releaseOrderAtom.ts';
import { LaneConfirmationAccordion } from '@outbound/pages/ControlDesk/ReleaseOrders/LaneConfirmation/LaneConfirmationAccordion/LaneConfirmationAccordion.tsx';
import { useMutateReleaseOrder } from '@outbound/services/hooks/useMutateReleaseOrder.tsx';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useTranslation } from 'react-i18next';

export const LaneConfirmation = () => {
  /* State variables */
  const [selectedOrders] = useAtom(selectedOrdersAtom);
  const [selectedRootReleaseOrders] = useAtom(selectedRootReleaseOrdersAtom);
  const [selectedReleaseOrderType] = useAtom(selectedReleaseOrderTypeAtom);
  const [selectedReleaseOrderLane, setSelectedReleaseOrderLane] = useAtom(
    selectedReleaseOrderLaneAtom
  );

  const [storeOrderSearch, setStoreOrderSearch] = useState('');
  const { mutateReleaseOrder, isLoading } = useMutateReleaseOrder();

  /* Constants */
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { handleNotification } = useNotificationHandler();
  const tabs = [
    {
      name: t(OrderReleaseTabTitles.OUTBOUND_MATRIX),
      value: ROUTES.REPLENISHMENT,
    },
    {
      name: t(OrderReleaseTabTitles.ORDER_RELEASE),
      value: ROUTES.LANE_CONFIRMATION,
    },
    {
      name: t(OrderReleaseTabTitles.ORDER_REVIEW),
      value: ROUTES.New_Store,
    },
    {
      name: t(OrderReleaseTabTitles.HISTORICAL_ORDER),
      value: ROUTES.Long_Tail_Distribution,
    },
  ];

  const pageTitle = 'OutboundMatrix.OrderRelease.LaneConfirmation';

  /* Queries */
  const { releaseGroupsData } = useGetReleaseGroups(
    {
      currentPage: DEFAULT_PAGE - 1,
      pageSize: MAX_PAGE_SIZE,
      orderType: selectedReleaseOrderType,
      groupTypes: [RELEASE_GROUPS_GROUP_TYPE.ROOT],
    },
    selectedOrders.length > 0 || selectedRootReleaseOrders.length > 0
  );

  /* Functions */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const setSearchParam = (_param: string) => {};

  const onKeyDown = (e: {
    key: string;
    keyCode: number;
    preventDefault: () => void;
    currentTarget: { value: string };
  }) => {
    if (e.key === KEY.ENTER || e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
      const inputValue = (e.currentTarget as HTMLInputElement).value;
      if (inputValue) {
        setSearchParam(inputValue);
      }
    }
  };
  const onSelectionChange = (value: string) => {
    if (value !== undefined) {
      setSearchParam(value);
    }
  };

  const handleReleaseOrder = () => {
    const releaseGroups = selectedReleaseOrderLane.map((releaseOrderLane) => {
      return {
        id: releaseOrderLane.groupId,
        laneCode: releaseOrderLane.laneId,
      };
    });

    mutateReleaseOrder(
      {
        releaseGroups,
      },
      {
        onSuccess: () => {
          handleNotification(NOTIFICATION_TYPES.SUCCESS, t('Success.Action.ReleaseOrder.Released'));
          setSelectedReleaseOrderLane([]);
          queryClient.invalidateQueries(['releaseGroups']);
          queryClient.invalidateQueries(['releaseGroupLanes']);
          onNavigateToControlDesk();
        },
      }
    );
  };

  const onNavigateToControlDesk = () => {
    navigate(PAGE_URLS.OUTBOUND_CONTROL_DESK);
  };

  return (
    <View direction="column" height="100%">
      <MasterTitle
        title={t(pageTitle)}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        titleActionProps={{ label: 'Favorite', handleClick: () => {} }}
        breadcrumbProps={{
          data: [
            {
              label: t('Sidenav.OutboundControlDesk'),
              onClick: onNavigateToControlDesk,
            },
            { label: t('OutboundMatrix.OrderRelease.Title'), onClick: () => void 0 },
          ],
        }}
        subtitle={
          <View direction="row" gap={4}>
            <Text>
              {t('OutboundMatrix.OrderRelease.ShiftGoalOrdersReleased', { count: 15, max: 80 })}
            </Text>
          </View>
        }
      >
        <View direction="row" justify="end" align="center" gap={4}>
          <>
            <View.Item columns={4}>
              <SearchBar
                value={storeOrderSearch}
                className={styles['search-bar']}
                onValueChange={setStoreOrderSearch}
                suggestions={[]}
                label={t('Search.DCNumber')}
                onSelectionChange={onSelectionChange}
                inputAttributes={{ onKeyDown: onKeyDown }}
                maxMenuHeight={300}
              />
            </View.Item>
          </>
          <View.Item>
            <Button
              size="large"
              variant="primary"
              loading={isLoading}
              disabled={
                !selectedReleaseOrderLane.filter((releaseOrderLane) => releaseOrderLane.laneId)
                  .length
              }
              onClick={() => handleReleaseOrder()}
            >
              <View direction="row" align="center" justify="center" gap={2}>
                <Text>{t('OutboundMatrix.OrderRelease.Release')}</Text>
              </View>
            </Button>
          </View.Item>
        </View>
      </MasterTitle>
      <View className={styles['outbound-matrix-tabs-wrapper']}>
        <View direction="column" height="100%" width="100%">
          <CombinedTabs tabs={tabs} rootPath={PAGE_URLS.OUTBOUND_CONTROL_DESK} />
        </View>
      </View>

      <View padding={4}>
        {(releaseGroupsData?.content || [])
          .filter((releaseGroup) => selectedRootReleaseOrders.includes(releaseGroup.groupId))
          .map((releaseGroup) => (
            <LaneConfirmationAccordion
              releaseGroup={releaseGroup}
              orderType={selectedReleaseOrderType}
            />
          ))}
      </View>
    </View>
  );
};
