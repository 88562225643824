/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text } from '@az/starc-ui';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { PAGE_URLS } from '@shared/constants/routes';
import { CycleCountTable } from '../CycleCountTable/CycleCount.table';
import { useCycleCount } from '@inventory/services/hooks/useCycleCounts';
import { CycleCountRowType } from '@inventory/types/types';
import { useEffect, useMemo, useState } from 'react';
import { SORT_BY, SORT_ORDER } from '@shared/constants/constants';
import { mapCycleCountAPIData } from '@inventory/utils/table/cycleCountTableUtils';
import {
  CYCLE_COUNT_ENTITY_ASSOCIATION,
  CYCLE_COUNT_STATUS_CD,
  CYCLE_COUNT_TYPE_CD,
  PAGE,
  SIZE_FOUR,
} from '@inventory/constants/constants';
import { cycleCountCreatePhaseAtom } from '@inventory/atoms/cycleCountCreatePhase';
import { SortRowsParam } from '@shared/components/Table/Table.types';
import { checkCountTypeCd } from '@inventory/utils/utils';

type ActiveCountTableProps = {
  countTypeCd: string;
};

export const ActiveCountTable: React.FC<ActiveCountTableProps> = ({ countTypeCd }) => {
  /* Atoms */
  const [, setCreateBtnEnableValue] = useAtom(cycleCountCreatePhaseAtom); // set the All CycleCount CreatePhase state for enable/disable Create Btn
  const navigate = useNavigate();
  const [activeData, setActiveData] = useState<CycleCountRowType[]>();
  const countTypeCdValue = checkCountTypeCd(countTypeCd);
  const isRBCCFlow = countTypeCdValue == CYCLE_COUNT_TYPE_CD.RBCC;

  /* Query for All Cycle Count Active phase data */
  const { wallToWallData: allPhaseActiveCycleCountData, isLoading } = useCycleCount({
    searchPage: {
      page: PAGE,
      size: SIZE_FOUR,
      sort: [
        {
          sortBy: SORT_BY.END_TS,
          direction: SORT_ORDER.DESC,
        },
      ],
    },
    searchCriteria: {
      countRequest: {
        countTypeCds: [CYCLE_COUNT_TYPE_CD.WALL_TO_WALL, CYCLE_COUNT_TYPE_CD.RBCC], // To Do: other INVENOTY and CUSTOM types should be added in future
        statusCd: [CYCLE_COUNT_STATUS_CD.NEW, CYCLE_COUNT_STATUS_CD.INPROGRESS],
      },
      entitiyAssociations: [
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_SUBZONE,
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_ASSIGNED_USER,
      ],
    },
  });

  // Mapped currentActiveData using : Location count search API response
  const currentActiveData: CycleCountRowType[] = useMemo(() => {
    if (
      allPhaseActiveCycleCountData?.content &&
      allPhaseActiveCycleCountData.content.some((item) => item.countTypeCd === countTypeCdValue)
    ) {
      return mapCycleCountAPIData(
        allPhaseActiveCycleCountData.content.filter(
          (item) => item.countTypeCd === countTypeCdValue
        ),
        [CYCLE_COUNT_STATUS_CD.NEW, CYCLE_COUNT_STATUS_CD.INPROGRESS],
        countTypeCdValue
      );
    }
    return [];
  }, [allPhaseActiveCycleCountData?.content, countTypeCdValue]);

  const handleTableRowClick = (clickedRow: SortRowsParam) => {
    const { id } = clickedRow;
    navigate(PAGE_URLS.CYCLE_COUNTS_DETAILS(id, countTypeCd));
  };

  const isActiveItem = activeData?.some((item) => item.status === CYCLE_COUNT_STATUS_CD.NEW);

  useEffect(() => {
    if (currentActiveData) {
      setActiveData(currentActiveData);
    }

    let wallToWallExists = false;
    let rbccExists = false;
    let inventoryExists = false;
    let customExists = false;

    if (allPhaseActiveCycleCountData && allPhaseActiveCycleCountData?.content.length > 0) {
      const statusSet = new Set([CYCLE_COUNT_STATUS_CD.NEW, CYCLE_COUNT_STATUS_CD.INPROGRESS]);

      allPhaseActiveCycleCountData.content.forEach((record) => {
        if (!statusSet.has(record.statusCd)) return; // Skip records with unmatched status

        switch (record.countTypeCd) {
          case CYCLE_COUNT_TYPE_CD.WALL_TO_WALL:
            wallToWallExists = true;
            break;
          case CYCLE_COUNT_TYPE_CD.RBCC:
            rbccExists = true;
            break;
          case CYCLE_COUNT_TYPE_CD.INVENTORY:
            inventoryExists = true;
            break;
          case CYCLE_COUNT_TYPE_CD.CUSTOM:
            customExists = true;
            break;
          default:
            break; // No action for other types
        }
      });
    }
    setCreateBtnEnableValue({
      WALL_TO_WALL: wallToWallExists,
      RISK_BASED: rbccExists,
      INVENTORY: inventoryExists,
      CUSTOM: customExists,
    });
  }, [setCreateBtnEnableValue, currentActiveData, allPhaseActiveCycleCountData]);

  return (
    <>
      <View.Item>
        <CycleCountTable
          data={activeData}
          isLoading={isLoading}
          noRecordsLabel={
            isRBCCFlow
              ? 'CycleCount.ActiveTable.NoRecords.RiskBased'
              : 'CycleCount.ActiveTable.NoRecords.WallToWall'
          }
          label="CycleCount.ActiveTable.Title"
          isPaginated={false}
          handleTableRowClick={(clickedRow) => handleTableRowClick(clickedRow)}
        />
        {isActiveItem && <Text color="error">{t('CycleCount.ActiveTable.IncompleTePhase')}</Text>}
      </View.Item>
    </>
  );
};
