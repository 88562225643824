/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useLocation, useNavigate } from 'react-router-dom';

import { t } from 'i18next';

import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { PAGE_URLS } from '@shared/constants/routes';

import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';

import { Modal } from '@outbound/components/Modal/Modal';
import { releaseOrders } from '../OrderTabs/data';
import { SubzoneAccordion } from './SubzoneAccordion';
import { closePaperOrders, closePaperSubzoneData } from './data';
import { closingPaperAtom } from '@outbound/atoms/releaseToPaper/releaseToPaperAtom';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { ClosingPaperRowTypes } from '@shared/components/Table/Table.types';

export const ClosingPaperModal = () => {
  /* Atoms */
  const [closingPaper, setClosingPaper] = useAtom(closingPaperAtom);

  /* Constants */
  const { handleNotification } = useNotificationHandler();
  const navigate = useNavigate();
  const location = useLocation();

  /* State variables */
  const [, setUpdatedPaperData] = useState<ClosingPaperRowTypes[]>(closePaperSubzoneData);

  /* Functions */
  const onClosePaperModal = () => {
    setClosingPaper(false);
  };

  const onNavigateToControlDesk = () => {
    const selectedTabRoute = location.pathname.split('/').at(-1);
    navigate(`${PAGE_URLS.OUTBOUND_CONTROL_DESK}/${selectedTabRoute}`);
  };

  const onConfirmAndClose = () => {
    // remove after api integration
    onClosePaperModal();
    onNavigateToControlDesk();
    setClosingPaper(false);
    handleNotification(
      NOTIFICATION_TYPES.SUCCESS,
      t('OutboundMatrix.ReleaseToPaper.ClosingPaperText', {
        subzone: 'HBP1',
      })
    );
  };

  return (
    <Modal
      title={t('OutboundMatrix.ReleaseToPaper.ClosePaperTitle')}
      subTitle={t('OutboundMatrix.ReleaseToPaper.ClosePaperSubtitle')}
      open={closingPaper}
      isCancelBtn={false}
      primaryBtnText={t('OutboundMatrix.ReleaseToPaper.ConfirmAndClose')}
      onClose={onClosePaperModal}
      onSuccess={onConfirmAndClose}
      size="auto"
    >
      {closePaperOrders.map((store) => (
        <SubzoneAccordion
          storeNumber={store.id}
          item={store}
          order={releaseOrders.releaseToPaper}
          subzone={store.subzones}
          setUpdatedPaperData={setUpdatedPaperData}
        />
      ))}
    </Modal>
  );
};
