/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Chat, Text, Tooltip, View } from '@az/starc-ui';

import {
  MAX_VISIBLE_AVATARS_CYCLE_COUNT,
  CYCLE_COUNT_TYPE_CD,
} from '@inventory/constants/constants';
import {
  PAST_COUNT_MAP_COLUMN,
  PAST_COUNT_SORT_COLUMN,
} from '@inventory/pages/CycleCounts/components/PastCountTable/PastCountTable.constants';
import { CycleCountRowType, locationCountSearchType, SubzoneType } from '@inventory/types/types';

import { AvatarGroup } from '@shared/components/AvatarGroup/AvatarGroup';
import { EMPTY_VALUE, USER_STATUS_CD } from '@shared/constants/constants';
import {
  getValidPercentage,
  formatNumberWithCurrency,
  generateDateString,
} from '@shared/utils/commonUtils';
import { t } from 'i18next';

const generateCycleCountsTableCells = (record: CycleCountRowType) => {
  const {
    countName,
    startedDate,
    closedDate,
    users,
    netAccuracy,
    totalCountRevenue,
    netAdjustment,
    absPcAdjustment,
    totalPcCount,
    absPcAccuracy,
    progress,
    endCountReason,
  } = record;
  return [
    {
      value: (
        <Text attributes={{ 'data-testvalue': countName }} weight="heavy">
          {countName}
        </Text>
      ),
      sortValue: countName,
    },
    {
      value: startedDate && generateDateString(new Date(startedDate), t('DateFormat.Long')),
      sortValue: startedDate && generateDateString(new Date(startedDate), t('DateFormat.Long')),
    },
    {
      value: closedDate && generateDateString(new Date(closedDate), t('DateFormat.Long')),
      sortValue: closedDate && generateDateString(new Date(closedDate), t('DateFormat.Long')),
    },
    {
      value:
        (users && (
          <View align="center">
            <AvatarGroup
              users={users.filter(
                (user, index, self) =>
                  index === self.findIndex((userIndex) => userIndex.id === user.id)
              )}
              size="small"
              variant="assignee"
              maxDisplay={MAX_VISIBLE_AVATARS_CYCLE_COUNT}
            />
          </View>
        )) ||
        EMPTY_VALUE,
    },
    {
      value: getValidPercentage(netAccuracy),
      sortValue: netAccuracy,
    },
    {
      value: formatNumberWithCurrency(totalCountRevenue),
      sortValue: totalCountRevenue,
    },
    {
      value: formatNumberWithCurrency(netAdjustment),
      sortValue: netAdjustment,
    },
    {
      value: getValidPercentage(absPcAccuracy),
      sortValue: absPcAccuracy,
    },
    {
      value: totalPcCount,
      sortValue: totalPcCount,
    },
    {
      value: absPcAdjustment,
      sortValue: absPcAdjustment,
    },
    {
      value: getValidPercentage(progress),
      sortValue: progress,
    },
    {
      value: endCountReason ? (
        <Tooltip triggerType="hover" svg={Chat} svgSize={6} bodyText={endCountReason} />
      ) : (
        EMPTY_VALUE
      ),
      sortValue: endCountReason,
    },
  ];
};

export const mapCycleCountRows = (records: CycleCountRowType[]) => {
  return records?.map((record: CycleCountRowType) => ({
    id: record.cycleCountId.toString(),
    cells: generateCycleCountsTableCells(record),
  }));
};

function getCountName(rec: locationCountSearchType, isRBCCFlow: boolean) {
  // If rec.year or rec.phase is missing, return EMPTY_VALUE
  if (!rec.year || !rec.phase) {
    return EMPTY_VALUE;
  }

  // If RBCC flow is active, return combined phase with week if rec.week is available
  if (isRBCCFlow) {
    return t('CycleCount.RBCCCountName', {
      year: rec.year,
      period: rec.phase,
      week: rec.week ?? '',
    });
  }

  // Otherwise, return year and phase
  return t('CycleCount.WallToWallCountName', {
    year: rec.year,
    phase: rec.phase,
  });
}

function getUniqueAssignedUsers(subzones: SubzoneType[] | undefined, isRBCCFlow: boolean) {
  if (!subzones || !Array.isArray(subzones)) {
    return [];
  }

  if (isRBCCFlow) {
    // Filter unique users when isRBCCFlow is true
    const userMap = new Map();

    subzones.forEach((subzone) => {
      (subzone.assignedUsers || []).forEach((user) => {
        const userId = Number(user.userId);
        if (user.userStatusCd !== USER_STATUS_CD.UNASSIGNED && !userMap.has(userId)) {
          userMap.set(userId, {
            id: userId,
            firstName: user.firstName || EMPTY_VALUE,
            lastName: user.lastName || EMPTY_VALUE,
          });
        }
      });
    });

    return Array.from(userMap.values());
  } else {
    // Return all users without deduplication
    return subzones.flatMap((subzone) =>
      (subzone.assignedUsers || [])
        ?.filter((item) => item.userStatusCd !== USER_STATUS_CD.UNASSIGNED)
        .map((user) => ({
          id: Number(user.userId),
          firstName: user.firstName || EMPTY_VALUE,
          lastName: user.lastName || EMPTY_VALUE,
        }))
    );
  }
}

// Helper function to map Active and Past Data using location count search API
export const mapCycleCountAPIData = (
  data: locationCountSearchType[] | undefined,
  statusFilter: string[],
  countTypeCd: string
): CycleCountRowType[] => {
  const isRBCCFlow = countTypeCd === CYCLE_COUNT_TYPE_CD.RBCC;
  return (
    data
      ?.filter((rec: locationCountSearchType) => statusFilter.includes(rec.statusCd))
      .map((rec: locationCountSearchType) => ({
        cycleCountId: rec.taskId ? rec.taskId : EMPTY_VALUE,
        countName: getCountName(rec, isRBCCFlow),
        phase: rec.phase ? rec.phase : 0,
        startedDate: rec.startTs ? rec.startTs : '',
        closedDate: rec.endTs ? rec.endTs : '',
        status: rec.statusCd,
        users: getUniqueAssignedUsers(rec.subzones, isRBCCFlow),
        netAccuracy:
          rec.totalInvAmtCounted && rec.totalInvAmtExpected
            ? (rec.totalInvAmtCounted / rec.totalInvAmtExpected) * 100
            : 0,
        totalCountRevenue: rec.totalInvAmtCounted ? rec.totalInvAmtCounted : 0,
        netAdjustment: rec.totalInvAmtAdjCalc ? rec.totalInvAmtAdjCalc : 0,
        absPcAccuracy:
          rec.totalInvQtyCounted && rec.totalInvQtyExpected
            ? (rec.totalInvQtyCounted / rec.totalInvQtyExpected) * 100
            : 0,
        totalPcCount: rec.totalInvQtyCounted ? rec.totalInvQtyCounted : 0,
        absPcAdjustment: rec.totalInvQtyAdjustedCalc ? rec.totalInvQtyAdjustedCalc : 0,
        progress:
          rec.totalInvAmtCounted && rec.totalInvAmtExpected
            ? (rec.totalInvAmtCounted / rec.totalInvAmtExpected) * 100
            : 0,
        endCountReason: rec.endReason || '',
      })) || []
  );
};

export const checkSortingColumn = (columnId: string) => {
  const columnMap: { [columnId: string]: string } = {
    [PAST_COUNT_MAP_COLUMN.COUNT_NAME]: PAST_COUNT_SORT_COLUMN.END_TS,
    [PAST_COUNT_MAP_COLUMN.STARTED_DATE]: PAST_COUNT_SORT_COLUMN.START_TS,
    [PAST_COUNT_MAP_COLUMN.CLOSED_DATE]: PAST_COUNT_SORT_COLUMN.END_TS,
    [PAST_COUNT_MAP_COLUMN.NET_ACCURACY]: PAST_COUNT_SORT_COLUMN.TOTAL_INV_AMT_ACCURACY,
    [PAST_COUNT_MAP_COLUMN.TOTAL_COUNT]: PAST_COUNT_SORT_COLUMN.TOTAL_INV_AMT_COUNTED,
    [PAST_COUNT_MAP_COLUMN.NET_ADJUSTMENT]: PAST_COUNT_SORT_COLUMN.TOTAL_INV_AMT_ADJ_CALC,
    [PAST_COUNT_MAP_COLUMN.ABS_PC_ADJUSTMENT]: PAST_COUNT_SORT_COLUMN.TOTAL_INV_QTY_ADJUSTED_CALC,
    [PAST_COUNT_MAP_COLUMN.TOTAL_PC_COUNT]: PAST_COUNT_SORT_COLUMN.TOTAL_INV_QTY_COUNTED,
    [PAST_COUNT_MAP_COLUMN.ABS_PC_ACCURACY]: PAST_COUNT_SORT_COLUMN.TOTAL_INV_QTY_ACCURACY,
    [PAST_COUNT_MAP_COLUMN.PROGRESS]: PAST_COUNT_SORT_COLUMN.TOTAL_INV_AMT_COUNTED,
    [PAST_COUNT_MAP_COLUMN.END_COUNT_REASON]: PAST_COUNT_SORT_COLUMN.END_REASON,
  };
  return columnMap[columnId] || '';
};
