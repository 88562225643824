/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { t } from 'i18next';

import {
  OrderReviewByAreaType,
  ReleaseGroup,
} from '@outbound/components/ReleaseGroupAccordion/ReleaseGroupAccordion.types.tsx';
import { RELEASE_ORDER_FIELD } from '@outbound/constants/constants.ts';
import { getAttributeValue } from '@shared/utils/commonUtils.ts';
import { Actionable, Button, Icon, Text, View } from '@az/starc-ui';
import { Download } from '@shared/assets/icons';
import { ActionMenu } from '@mdm/components/ActionMenu/ActionMenu.tsx';
import { ChevronDown, ChevronUp } from '@az/starc-ui-icons';

export const mapOrderReviewByAreaTableRows = (
  rows: OrderReviewByAreaType[],
  onAccordionClick: (row: OrderReviewByAreaType) => void
) => {
  return rows.map((releaseGroup) => {
    const countOfSkus = getAttributeValue(releaseGroup.attributes, RELEASE_ORDER_FIELD.SKU_COUNT);

    const totalPallets = getAttributeValue(releaseGroup.attributes, RELEASE_ORDER_FIELD.PALLETS);

    const totalPieces = getAttributeValue(releaseGroup.attributes, RELEASE_ORDER_FIELD.PIECES);

    return {
      id: releaseGroup.groupId.toString(),
      cells: [
        { value: releaseGroup.groupName, sortValue: releaseGroup.groupName },
        { value: countOfSkus, sortValue: countOfSkus },
        { value: totalPallets, sortValue: totalPallets },
        { value: totalPieces, sortValue: totalPieces },
        {
          value: (
            <Button variant="secondary" size="small">
              <View direction="row" align="center" justify="center" gap={2}>
                <Text>{t('Download')}</Text>
                <Icon svg={Download} />
              </View>
            </Button>
          ),
          sortValue: releaseGroup.groupId,
        },
        {
          value: (
            <View direction="row" align="center" justify="end" gap={4} padding={[0, 2, 0, 0]}>
              <View height="100%">
                <ActionMenu
                  menus={[
                    {
                      label: t('OutboundMatrix.MenuOptions.ReleaseToPaper'),
                      clickHandler: () => ({}),
                    },
                    {
                      label: t('OutboundMatrix.MenuOptions.CloseToPaper'),
                      clickHandler: () => ({}),
                    },
                  ]}
                />
              </View>
              <Actionable onClick={() => onAccordionClick(releaseGroup)}>
                <Icon size={6} svg={releaseGroup.isRowExpandable ? ChevronUp : ChevronDown} />
              </Actionable>
            </View>
          ),
          sortValue: releaseGroup.groupId,
        },
      ],
      isRowExpandable: releaseGroup.isRowExpandable,
    };
  });
};

export const mapOrderReviewOrderDetailTableRows = (rows: ReleaseGroup[]) => {
  return rows.map((releaseGroup) => {
    const countOfSkus = getAttributeValue(releaseGroup.attributes, RELEASE_ORDER_FIELD.SKU_COUNT);

    const totalPallets = getAttributeValue(releaseGroup.attributes, RELEASE_ORDER_FIELD.PALLETS);

    const totalPieces = getAttributeValue(releaseGroup.attributes, RELEASE_ORDER_FIELD.PIECES);

    return {
      id: releaseGroup.groupId.toString(),
      cells: [
        { value: releaseGroup.groupName, sortValue: releaseGroup.groupName },
        { value: countOfSkus, sortValue: countOfSkus },
        { value: totalPallets, sortValue: totalPallets },
        { value: totalPieces, sortValue: totalPieces },
        { value: totalPieces, sortValue: totalPieces },
        { value: totalPieces, sortValue: totalPieces },
        { value: totalPieces, sortValue: totalPieces },
        { value: totalPieces, sortValue: totalPieces },
        { value: totalPieces, sortValue: totalPieces },
        { value: totalPieces, sortValue: totalPieces },
        { value: totalPieces, sortValue: totalPieces },
        { value: totalPieces, sortValue: totalPieces },
        { value: totalPieces, sortValue: totalPieces },
        { value: totalPieces, sortValue: totalPieces },
      ],
    };
  });
};
