/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal, Text, View, classNames, DatePicker, Divider } from '@az/starc-ui';
import dayjs from 'dayjs';
import { Close } from '@az/starc-ui-icons';

import { Stat } from '@shared/components/Stat/Stat';
import { ACTIONS, NOTIFICATION_TYPES } from '@shared/constants/constants';
import { debounce } from '@shared/utils/commonUtils';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';

import { endDateRegex, startDateRegex, dateRegex } from '@inbound/utils/regex';
import { calculateAccuracyMeasurements } from '@inventory/utils/accuracyMeasurementUtil';
import { getValidDate } from '@inventory/utils/topVarianceUtils';
import { useGetPhaseProgress } from '@inventory/services/hooks/useCycleCounts';
import {
  DOLLAR_VARIANCE_THRESHOLD,
  METRICS,
  QTY_VARIANCE_THRESHOLD,
} from '@inventory/constants/constants';

import * as T from './AccuracyMeasurementModal.types';
import s from './AccuracyMeasurementModal.module.scss';

export const AccuracyMeasurementModal = ({
  isOpen,
  accuracyMeasurementData: accuracyData,
  onClose,
  countTypeCd,
  startDate = '',
  endDate = '',
}: T.Props) => {
  const [accuracyMeasurementData, setAccuracyData] = useState(accuracyData);
  const [startTs, setStartTs] = useState(startDate); //MM/DD/YYYY
  const [endTs, setEndTs] = useState(getValidDate(endDate));
  const [startDateError, setStartDateError] = useState<string>('');
  const [endDateError, setEndDateError] = useState<string>('');
  const [isEndDateDisabled, setIsEndDateDisabled] = useState<boolean>(false);
  const [startDateIsOpen, setStartDateOpen] = useState(false);
  const [endDateIsOpen, setEndDateOpen] = useState(false);

  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const dateFormat = t('DateFormat.Long');
  const currentDate = dayjs().endOf('day').toDate();
  const twoYearsAgo = dayjs(currentDate).subtract(2, 'year').startOf('day').toDate();

  const formattedDate = (date: string, isEndOfDay = false) => {
    if (!date || !dayjs(date).isValid()) {
      return '';
    }
    return isEndOfDay
      ? dayjs(date).endOf('day').toISOString()
      : dayjs(date).startOf('day').toISOString();
  };

  const { refetch } = useGetPhaseProgress(
    {
      countTypeCd: countTypeCd,
      fromTs: formattedDate(startTs),
      toTs: formattedDate(endTs, true),
      dollarVarianceThershold: DOLLAR_VARIANCE_THRESHOLD,
      qtyVarianceThershold: QTY_VARIANCE_THRESHOLD,
      metrics: METRICS,
    },
    false
  );

  const getDashboardMatrixAPI = useMemo(() => {
    return debounce(() => {
      refetch()
        .then(({ data }) => {
          const calculatedData = calculateAccuracyMeasurements(data);
          setAccuracyData(calculatedData);
          return;
        })
        .catch(() => {
          handleNotification(NOTIFICATION_TYPES.ERROR, t('Errors.Inventory.Description'));
        });
    });
  }, [refetch, setAccuracyData, handleNotification, t]);

  const validateDates = useCallback((startDate: string, endDate: string): boolean => {
    const validStartRange = dayjs(twoYearsAgo); // Start from 2 years ago
    const validEndRange = dayjs(currentDate); // End at today
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    if (!start.isValid() || !end.isValid()) {
      return false;
    }

    if (start.isBefore(validStartRange) || start.isAfter(validEndRange)) {
      return false;
    }

    if (end.isBefore(start) || end.isAfter(validEndRange)) {
      return false;
    }

    return true;
  }, []);

  // Check if the date format is valid (MM/DD/YYYY)
  const isValidDate = (date: string) => {
    return dateRegex.test(date);
  };

  useEffect(() => {
    if (!isValidDate(startTs)) {
      setStartDateError(t('PODashboard.POHistory.ErrorMessage.InvalidStartDate'));
      return;
    }
    if (!isValidDate(endTs)) {
      setEndDateError(t('PODashboard.POHistory.ErrorMessage.InvalidEndDate'));
      return;
    }
    if (validateDates(startTs, endTs)) {
      getDashboardMatrixAPI();
    }
  }, [endTs, startTs, t, getDashboardMatrixAPI, validateDates]);

  const onModalClose = () => {
    onClose(ACTIONS.CLOSE);
  };

  const onStartDateChange = (value: string) => {
    setIsEndDateDisabled(true);

    if (value.length >= 10 && startDateRegex.test(value)) {
      const selectedStartDate = dayjs(value);
      const validStartRange = dayjs(twoYearsAgo); // Start from 2 years ago
      const validEndRange = dayjs(currentDate); // End at today

      if (!selectedStartDate.isValid()) {
        // Invalid date format
        setStartDateError(t('PODashboard.POHistory.ErrorMessage.InvalidStartDate'));
        return;
      }

      if (selectedStartDate.isAfter(validEndRange)) {
        setStartDateError(t('PODashboard.POHistory.ErrorMessage.StartDateError'));
        return;
      } else if (selectedStartDate.isBefore(validStartRange)) {
        setStartDateError(t('PODashboard.POHistory.ErrorMessage.InvalidStartDate'));
        return;
      } else {
        setIsEndDateDisabled(false);
        setStartDateError('');

        if (endTs && dayjs(endTs).isBefore(selectedStartDate)) {
          setEndTs(value);
        }

        setStartTs(selectedStartDate.format(dateFormat));
      }
    } else if (value.length >= 10 && !startDateRegex.test(value)) {
      setStartDateError(t('PODashboard.POHistory.ErrorMessage.InvalidStartDate'));
      return;
    }
    setStartDateOpen(false);
    setStartTs(value);
  };

  const compareAndSetEndDate = useCallback(
    (currentDate: dayjs.Dayjs, endDateValue: dayjs.Dayjs) => {
      const formattedCurrentDate = currentDate.format(`${dateFormat} 00:00`);
      const formattedEndDate = endDateValue.format(`${dateFormat} 00:00`);

      if (formattedCurrentDate === formattedEndDate) {
        setEndTs(dayjs().format(dateFormat));
      } else {
        setEndTs(endDateValue.format(dateFormat));
      }
    },
    [dateFormat]
  );

  const onEndDateChange = (value: string) => {
    if (value.length >= 10 && endDateRegex.test(value)) {
      const selectedEndDate = dayjs(value);
      const validEndRange = dayjs(currentDate); // Until today
      const validStartRange = dayjs(startTs); // From start date

      if (!selectedEndDate.isValid()) {
        // Invalid date format
        setEndDateError(t('PODashboard.POHistory.ErrorMessage.InvalidEndDate'));
        return;
      }

      if (selectedEndDate.isAfter(validEndRange)) {
        setEndDateError(t('PODashboard.POHistory.ErrorMessage.EndDateError'));
        return;
      } else if (selectedEndDate.isBefore(validStartRange)) {
        setEndDateError(t('PODashboard.POHistory.ErrorMessage.EndDateErrorMsg'));
        return;
      } else {
        setEndDateError('');
        compareAndSetEndDate(validEndRange, selectedEndDate);
      }
    } else if (value.length >= 10 && !endDateRegex.test(value)) {
      setEndDateError(t('PODashboard.POHistory.ErrorMessage.InvalidStartDate'));
      return;
    }
    setEndDateOpen(false);
    setEndTs(value);
  };

  return (
    <Modal
      open={isOpen}
      closeByClickAway={false}
      onClose={onModalClose}
      className={s['inventory-count-modal']}
    >
      <form>
        <View className={s['inventory-count-modal__header']}>
          <View>
            <Text as="h2" size="200" weight="bold" color="primary">
              {t('CycleCount.AccuracyMeasurementModal.Title')}
            </Text>
          </View>
          <Button
            variant="ghost"
            onClick={onModalClose}
            className={classNames(
              s['close-icon'],
              s['inventory-count-modal__header__close-button']
            )}
          >
            <Icon svg={Close} />
          </Button>
        </View>

        <View className={s['inventory-count-modal__body']}>
          <View direction="column" className={s['inventory-count-modal__body__section']}>
            <View.Item className={s['inventory-count-modal__body__section_item']}>
              <View direction="row" gap={3}>
                <View.Item columns={4}>
                  <DatePicker
                    className={s['inventory-count-modal__date-picker']}
                    id="startDate"
                    label={t(`CycleCount.AccuracyMeasurementModal.StartDate`)}
                    dateFormat={dateFormat}
                    value={startTs}
                    dateRange={[
                      dayjs(twoYearsAgo).format(dateFormat),
                      dayjs(currentDate).format(dateFormat),
                    ]}
                    size="small"
                    onValueChange={onStartDateChange}
                    open={startDateIsOpen}
                    onOpen={() => setStartDateOpen(true)}
                    onClose={() => setStartDateOpen(false)}
                    automaticUpdate={false}
                  />
                  {startDateError && (
                    <View direction="row" justify="space-between">
                      <Text size="087" color="error">
                        {startDateError}
                      </Text>
                    </View>
                  )}
                </View.Item>
                <View.Item columns={4}>
                  <DatePicker
                    className={s['inventory-count-modal__date-picker']}
                    id="endDate"
                    label={t(`CycleCount.AccuracyMeasurementModal.EndDate`)}
                    dateFormat={dateFormat}
                    value={endTs}
                    dateRange={[
                      dayjs(startTs).startOf('day').format(dateFormat),
                      dayjs(currentDate).format(dateFormat),
                    ]}
                    disabled={isEndDateDisabled}
                    size="small"
                    onValueChange={onEndDateChange}
                    open={endDateIsOpen}
                    onOpen={() => setEndDateOpen(true)}
                    onClose={() => setEndDateOpen(false)}
                    automaticUpdate={false}
                  />
                  {endDateError && (
                    <View direction="row" justify="space-between">
                      <Text size="087" color="error">
                        {endDateError}
                      </Text>
                    </View>
                  )}
                </View.Item>
              </View>
            </View.Item>
          </View>

          <View.Item>
            <View padding={[6, 0]}>
              <Divider color="300" />
            </View>
          </View.Item>

          <View direction="column" gap={1} className={s['inventory-count-modal__body__section']}>
            <View.Item>
              <Text
                weight="bold"
                size="075"
                className={s['inventory-count-modal__body__section__title-uppercase-gray']}
              >
                {t(`CycleCount.AccuracyMeasurementModal.Dollars`)}
              </Text>
            </View.Item>

            <View.Item className={s['inventory-count-modal__body__section__item']}>
              <View direction="row" gap={2}>
                <View.Item
                  columns={6}
                  className={s['inventory-count-modal__body__section__item__border-color']}
                >
                  <Stat
                    title={t(`CycleCount.AccuracyMeasurementModal.NetDollarsAccuracy`)}
                    primaryText={t(`CycleCount.AccuracyMeasurementModal.PercentValue`, {
                      value: accuracyMeasurementData.netDollarAccuracy,
                    })}
                    width="100%"
                  />
                </View.Item>

                <View.Item columns={6}>
                  <Stat
                    title={t(`CycleCount.AccuracyMeasurementModal.AbsAccuracy`)}
                    primaryText={t(`CycleCount.AccuracyMeasurementModal.PercentValue`, {
                      value: accuracyMeasurementData.absDollarAccuracy,
                    })}
                    width="100%"
                  />
                </View.Item>
              </View>
            </View.Item>
            <View.Item className={s['inventory-count-modal__body__section__item']}>
              <View direction="row" gap={2}>
                <View.Item columns={4} backgroundColor="disabled" grow>
                  <Stat
                    size="medium"
                    title={t(`CycleCount.AccuracyMeasurementModal.TotalDollarsCounted`)}
                    primaryText={t(`CycleCount.AccuracyMeasurementModal.DollarValue`, {
                      amount: accuracyMeasurementData.totalDollarCounted.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }),
                    })}
                    width="100%"
                  />
                </View.Item>

                <View.Item columns={4} backgroundColor="disabled" grow>
                  <Stat
                    size="medium"
                    title={t(`CycleCount.AccuracyMeasurementModal.NetVariance`)}
                    primaryText={t(`CycleCount.AccuracyMeasurementModal.DollarValue`, {
                      amount: accuracyMeasurementData.netDollarVariance.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }),
                    })}
                    width="100%"
                  />
                </View.Item>

                <View.Item columns={4} backgroundColor="disabled" grow>
                  <Stat
                    size="medium"
                    title={t(`CycleCount.AccuracyMeasurementModal.AbsVariance`)}
                    primaryText={t(`CycleCount.AccuracyMeasurementModal.DollarValue`, {
                      amount: accuracyMeasurementData.absDollarVariance.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }),
                    })}
                    width="100%"
                  />
                </View.Item>
              </View>
            </View.Item>
          </View>

          <View.Item>
            <View padding={[3, 0]} />
          </View.Item>

          <View direction="column" gap={1} className={s['inventory-count-modal__body__section']}>
            <View.Item>
              <Text
                weight="bold"
                size="075"
                className={s['inventory-count-modal__body__section__title-uppercase-gray']}
              >
                {t(`CycleCount.AccuracyMeasurementModal.Pieces`)}
              </Text>
            </View.Item>

            <View.Item className={s['inventory-count-modal__body__section__item']}>
              <View direction="row" gap={2}>
                <View.Item columns={6}>
                  <Stat
                    title={t(`CycleCount.AccuracyMeasurementModal.NetPiecesAccuracy`)}
                    primaryText={t(`CycleCount.AccuracyMeasurementModal.PercentValue`, {
                      value: accuracyMeasurementData.netPiecesAccuracy,
                    })}
                    width="100%"
                  />
                </View.Item>

                <View.Item columns={6}>
                  <Stat
                    title={t(`CycleCount.AccuracyMeasurementModal.AbsAccuracy`)}
                    primaryText={t(`CycleCount.AccuracyMeasurementModal.PercentValue`, {
                      value: accuracyMeasurementData.absPiecesAccuracy,
                    })}
                    width="100%"
                  />
                </View.Item>
              </View>
            </View.Item>
            <View.Item className={s['inventory-count-modal__body__section__item']}>
              <View direction="row" gap={2}>
                <View.Item columns={4} backgroundColor="disabled" grow>
                  <Stat
                    size="medium"
                    title={t(`CycleCount.AccuracyMeasurementModal.TotalPiecesCounted`)}
                    primaryText={accuracyMeasurementData.totalPiecesCounted.toLocaleString()}
                    width="100%"
                  />
                </View.Item>

                <View.Item columns={4} backgroundColor="disabled" grow>
                  <Stat
                    size="medium"
                    title={t(`CycleCount.AccuracyMeasurementModal.NetVariance`)}
                    primaryText={accuracyMeasurementData.netPiecesVariance.toLocaleString()}
                    width="100%"
                  />
                </View.Item>

                <View.Item columns={4} backgroundColor="disabled" grow>
                  <Stat
                    size="medium"
                    title={t(`CycleCount.AccuracyMeasurementModal.AbsVariance`)}
                    primaryText={accuracyMeasurementData.absPiecesVariance.toLocaleString()}
                    width="100%"
                  />
                </View.Item>
              </View>
            </View.Item>
          </View>
        </View>
      </form>
    </Modal>
  );
};
