/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

const DIF_RELEASE_ORDER_BY_AREA_COLUMNS = [
  {
    label: '',
    id: 'area-select',
    textAlign: 'start' as const,
    width: 'var(--st-unit-1)',
    isCheckbox: true,
  },
  {
    label: 'Table.OrderRelease.Area',
    id: 'area',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.OrderRelease.SKU',
    id: 'sku',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.OrderRelease.Pallets',
    id: 'pallet',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.OrderRelease.Pieces',
    id: 'pieces',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'calc(var(--st-unit-28) * 2)' },
];

const LANE_CONFIRMATION_COLUMNS = [
  {
    label: 'Table.OrderRelease.DC',
    id: 'area',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.OrderRelease.Area',
    id: 'sku',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.OrderRelease.Pallets',
    id: 'pallet',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.OrderRelease.Pieces',
    id: 'pieces',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'calc(var(--st-unit-22) * 2)' },
];

const ORDER_REVIEW_BY_AREA_COLUMNS = [
  {
    label: 'Table.OrderRelease.Area',
    id: 'area',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.OrderRelease.SKU',
    id: 'sku',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.OrderRelease.Pallets',
    id: 'pallet',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.OrderRelease.Pieces',
    id: 'pieces',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  { label: '', id: 'download', textAlign: 'center' as const, width: 'calc(var(--st-unit-23) * 2)' },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'calc(var(--st-unit-20) * 2)' },
];

const ORDER_REVIEW_ORDER_DETAIL_COLUMNS = [
  {
    label: 'Table.OrderReviewOrderDetailList.CustomerNumber',
    id: 'customerNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.OrderReviewOrderDetailList.OrderNumber',
    id: 'orderNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.OrderReviewOrderDetailList.Sku',
    id: 'sku',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.OrderReviewOrderDetailList.RFTransactionNumber',
    id: 'rfTransactionNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30))',
    isSorted: false,
  },
  {
    label: 'Table.OrderReviewOrderDetailList.PT',
    id: 'pt',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.OrderReviewOrderDetailList.Subzone',
    id: 'subzone',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.OrderReviewOrderDetailList.Lane',
    id: 'lane',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.OrderReviewOrderDetailList.Status',
    id: 'status',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.OrderReviewOrderDetailList.QtyOrdered',
    id: 'qtyOrdered',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30))',
    isSorted: false,
  },
  {
    label: 'Table.OrderReviewOrderDetailList.QtyAllocated',
    id: 'qtyAllocated',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30))',
    isSorted: false,
  },
  {
    label: 'Table.OrderReviewOrderDetailList.QtyAssigned',
    id: 'qtyAssigned',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30))',
    isSorted: false,
  },
  {
    label: 'Table.OrderReviewOrderDetailList.QtyPicked',
    id: 'qtyPicked',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30))',
    isSorted: false,
  },
  {
    label: 'Table.OrderReviewOrderDetailList.QtyDelivered',
    id: 'qtyDelivered',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30))',
    isSorted: false,
  },
  {
    label: 'Table.OrderReviewOrderDetailList.UserId',
    id: 'userId',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
];

export {
  DIF_RELEASE_ORDER_BY_AREA_COLUMNS,
  LANE_CONFIRMATION_COLUMNS,
  ORDER_REVIEW_BY_AREA_COLUMNS,
  ORDER_REVIEW_ORDER_DETAIL_COLUMNS,
};
