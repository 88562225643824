/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { AttributeSchema } from '@mdm/schemas/attributeSchema.ts';
import {
  addCustomAlphaNumericAttributeFieldValidation,
  addCustomMaxLengthAttributeFieldValidation,
  addCustomNumericAttributeFieldValidation,
  addCustomRequiredAttributeFieldValidation,
} from '@mdm/utils/form/validationUtils.tsx';
import {
  CONSOLIDATION_LOCATION_FIELD,
  DEFAULT_CONSOLIDATION_STATUS,
  LAYOUT_ENTITY_CODE,
  LAYOUT_ENTITY_TYPE_CODE,
  MAX_LENGTH_FIELD,
} from '@mdm/constants/constants.ts';
import { t } from 'i18next';
import { isAlphaNumeric } from '@shared/utils/validationUtils.ts';
import { LayoutAssetSchema, LayoutProductSchema } from '@mdm/schemas/layoutSchema.ts';

export const defaultAttributeValues = [
  {
    name: CONSOLIDATION_LOCATION_FIELD.LOCATION_TYPE,
    value: '',
  },
  {
    name: CONSOLIDATION_LOCATION_FIELD.CONS_LOCATION_STATUS,
    value: DEFAULT_CONSOLIDATION_STATUS,
  },
  {
    name: CONSOLIDATION_LOCATION_FIELD.HEIGHT,
    value: '1',
  },
  {
    name: CONSOLIDATION_LOCATION_FIELD.WIDTH,
    value: '1',
  },
  {
    name: CONSOLIDATION_LOCATION_FIELD.DEPTH,
    value: '1',
  },
  {
    name: CONSOLIDATION_LOCATION_FIELD.ORDER,
    value: '',
  },
];

export const ConsolidationLocationSchema = z.object({
  zoneName: z.string().min(1),
  layoutKey: z.string().optional(),
  layoutName: z.string().superRefine((data, ctx) => {
    if (data === '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.RequiredField'),
      });
    }

    if (data.length > MAX_LENGTH_FIELD.CONSOLIDATION_LOCATION_NAME) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.MaxCharacterAmount', {
          count: MAX_LENGTH_FIELD.CONSOLIDATION_LOCATION_NAME,
        }),
      });
    }

    if (!isAlphaNumeric(data)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.AlphaNumericError'),
      });
    }
  }),
  layoutNameDesc: z.string().nullable().default(''),
  layoutDistinctName: z.string().optional(),
  parentLayoutDistinctName: z.string().min(1),
  entityCd: z.string().default(LAYOUT_ENTITY_CODE.LOCATION),
  entityTypeCd: z.string().default(LAYOUT_ENTITY_TYPE_CODE.CONSOLIDATED),
  active: z.boolean().default(true),
  mapSequence: z.number().nullable().default(null).optional(),
  attributes: z
    .array(AttributeSchema)
    .superRefine((data, ctx) => {
      const requiredFields = [
        CONSOLIDATION_LOCATION_FIELD.LOCATION_TYPE,
        CONSOLIDATION_LOCATION_FIELD.CONS_LOCATION_STATUS,
        CONSOLIDATION_LOCATION_FIELD.HEIGHT,
        CONSOLIDATION_LOCATION_FIELD.WIDTH,
        CONSOLIDATION_LOCATION_FIELD.DEPTH,
      ];
      addCustomRequiredAttributeFieldValidation(data, requiredFields, ctx);

      const numericFields = [
        {
          key: CONSOLIDATION_LOCATION_FIELD.HEIGHT,
          allowOnlyPositiveNumber: true,
        },
        {
          key: CONSOLIDATION_LOCATION_FIELD.WIDTH,
          allowOnlyPositiveNumber: true,
        },
        {
          key: CONSOLIDATION_LOCATION_FIELD.DEPTH,
          allowOnlyPositiveNumber: true,
        },
      ];

      addCustomNumericAttributeFieldValidation(data, numericFields, ctx);

      const maxLengthFields = [
        {
          name: CONSOLIDATION_LOCATION_FIELD.WIDTH,
          maxLength: MAX_LENGTH_FIELD.CONSOLIDATION_LOCATION_WIDTH,
        },
        {
          name: CONSOLIDATION_LOCATION_FIELD.HEIGHT,
          maxLength: MAX_LENGTH_FIELD.CONSOLIDATION_LOCATION_HEIGHT,
        },
        {
          name: CONSOLIDATION_LOCATION_FIELD.DEPTH,
          maxLength: MAX_LENGTH_FIELD.CONSOLIDATION_LOCATION_DEPTH,
        },
        {
          name: CONSOLIDATION_LOCATION_FIELD.ORDER,
          maxLength: MAX_LENGTH_FIELD.CONSOLIDATION_LOCATION_ORDER,
        },
      ];

      addCustomMaxLengthAttributeFieldValidation(data, maxLengthFields, ctx);

      const alphaNumericFields = [
        {
          key: CONSOLIDATION_LOCATION_FIELD.ORDER,
        },
      ];
      addCustomAlphaNumericAttributeFieldValidation(data, alphaNumericFields, ctx);
    })
    .nullable()
    .default(defaultAttributeValues),
  assets: z.array(LayoutAssetSchema).nullable().default([]),
  products: z.array(LayoutProductSchema).nullable().default([]),
});
