/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { DeepRequired, FieldErrorsImpl, GlobalError } from 'react-hook-form';
import { z } from 'zod';
import { t } from 'i18next';
// eslint-disable-next-line import/no-cycle
import { AttributeType } from '@mdm/types/schema.type.ts';
import { isAlphaNumeric } from '@shared/utils/validationUtils.ts';
import { ALLOW_ONLY_POSITIVENUMBER } from '@mdm/constants/constants.ts';

/**
 * Check if the value is numeric
 * @param value
 */
export const isNumeric = (value: string) => {
  const regEx = /^[0-9]*$/;
  return regEx.test(value);
};

/**
 * Check if the value is positive numeric
 * @param value
 */
export const isPositiveNumeric = (value: string) => {
  const regEx = /^[0-9]*[1-9]+$|^[1-9]+[0-9]*$/;
  return regEx.test(value);
};

/**
 * Display attribute field validation message
 * @param errors
 * @param fieldKey
 */
export const displayAttributeFieldValidationMessage = (
  errors: Partial<FieldErrorsImpl<DeepRequired<FormData>>> & {
    root?: Record<string, GlobalError> & GlobalError;
  },
  fieldKey: number
) => {
  // Disabled eslint rule for the below line as we are using the dynamic key to access the object
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return errors?.attributes[`attribute-${fieldKey}`]?.message || '';
};

/**
 * Display layout asset field validation message
 * @param errors
 * @param fieldKey
 */
export const displayLayoutAssetFieldValidationMessage = (
  errors: Partial<FieldErrorsImpl<DeepRequired<FormData>>> & {
    root?: Record<string, GlobalError> & GlobalError;
  },
  fieldKey: number
) => {
  // Disabled eslint rule for the below line as we are using the dynamic key to access the object
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return errors?.assets[`asset-${fieldKey}`]?.message || '';
};

/**
 * Add custom required field validation
 * @param data
 * @param fields
 * @param ctx
 * @param pathPrefix
 */
export const addCustomRequiredAttributeFieldValidation = (
  data: AttributeType[],
  fields: string[],
  ctx: z.RefinementCtx,
  pathPrefix = ''
) => {
  for (const key of fields) {
    const foundFieldIndex = data.findIndex((attribute) => attribute.name === key);
    if (foundFieldIndex !== -1 && data[foundFieldIndex].value === '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: pathPrefix
          ? [`${pathPrefix}.attribute-${foundFieldIndex}`]
          : [`attribute-${foundFieldIndex}`],
        message: t('Form.RequiredField'),
      });
    }
  }
};

/**
 * Add custom numeric field validation
 * @param data
 * @param fields
 * @param ctx
 */
export const addCustomNumericAttributeFieldValidation = (
  data: AttributeType[],
  fields: { key: string; allowOnlyPositiveNumber?: boolean }[],
  ctx: z.RefinementCtx
) => {
  for (const field of fields) {
    const foundFieldIndex = data.findIndex((attribute) => attribute.name === field.key);
    if (foundFieldIndex !== -1) {
      let isValidValue = false;
      let message = t('Form.NumericError');
      if (field[ALLOW_ONLY_POSITIVENUMBER]) {
        isValidValue = isPositiveNumeric(data[foundFieldIndex].value);
        message = t('Form.PositiveNumericError');
      } else {
        isValidValue = isNumeric(data[foundFieldIndex].value);
      }

      if (!isValidValue) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [`attribute-${foundFieldIndex}`],
          message: message,
        });
      }
    }
  }
};

/**
 * Add custom alphanumeric field validation
 * @param data
 * @param fields
 * @param ctx
 * @param pathPrefix
 */
export const addCustomAlphaNumericAttributeFieldValidation = (
  data: AttributeType[],
  fields: { key: string; allowSpace?: boolean; allowUnderscore?: boolean }[],
  ctx: z.RefinementCtx,
  pathPrefix = ''
) => {
  for (const field of fields) {
    const foundFieldIndex = data.findIndex((attribute) => attribute.name === field.key);
    if (
      foundFieldIndex !== -1 &&
      data[foundFieldIndex].value &&
      !isAlphaNumeric(data[foundFieldIndex].value, field.allowSpace, field.allowUnderscore)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: pathPrefix
          ? [`${pathPrefix}.attribute-${foundFieldIndex}`]
          : [`attribute-${foundFieldIndex}`],
        message: t('Form.AlphaNumericError'),
      });
    }
  }
};

/**
 * Add custom max length field validation
 * @param data
 * @param fields
 * @param ctx
 * @param pathPrefix
 */
export const addCustomMaxLengthAttributeFieldValidation = (
  data: AttributeType[],
  fields: { name: string; maxLength: number }[],
  ctx: z.RefinementCtx,
  pathPrefix = ''
) => {
  for (const field of fields) {
    const foundFieldIndex = data.findIndex((attribute) => attribute.name === field.name);

    if (foundFieldIndex !== -1) {
      if (data[foundFieldIndex].value && data[foundFieldIndex].value.length > field.maxLength) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: pathPrefix
            ? [`${pathPrefix}.attribute-${foundFieldIndex}`]
            : [`attribute-${foundFieldIndex}`],
          message: t('Form.MaxCharacterAmount', { count: field.maxLength }),
        });
      }
    }
  }
};
