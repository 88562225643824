/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Dropdown, View, Text, SearchBar, Actionable } from '@az/starc-ui';

import { KEY } from '@shared/constants/keyConstants';
import { Avatar } from '@shared/components/Avatar/Avatar';
import { UserSearchSchemaType } from '@shared/types/schema.type';

import * as T from './AssigneeSelect.types';
import styles from './AssigneeSelect.module.scss';
import { USER_ROLES } from '@shared/constants/constants';

export const AssigneeSelect = ({
  label,
  data,
  selectedAssignee,
  setSelectedAssignee,
  onSelect,
}: T.Props) => {
  /* State variables */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const [value, setValue] = useState<string>(
    selectedAssignee ? `${selectedAssignee.firstName} ${selectedAssignee.lastName}` : ''
  );
  const [assigneesData, setAssigneesData] = useState<UserSearchSchemaType[]>();

  /* Constants */
  const { t } = useTranslation();

  /* Functions */
  const toggleDropdown = (
    event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const onAssigneeSelect = (assignee: UserSearchSchemaType | null) => {
    setIsOpen(false);
    onSelect(assignee);
    setValue(
      assignee?.firstName && assignee.lastName ? `${assignee?.firstName} ${assignee?.lastName}` : ''
    );
  };

  const onValueChange = (value: string) => {
    setValue(value);
    setSearchValue(value);
    setIsOpen(true);
  };

  useEffect(() => {
    if (selectedAssignee) {
      if (selectedAssignee.userId == '') {
        setValue('');
      } else {
        setValue(`${selectedAssignee.firstName} ${selectedAssignee.lastName}`);
      }
    }
  }, [selectedAssignee]);
  /* Hooks */
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      const target: Node = ((event as MouseEvent).target ||
        (event as TouchEvent).targetTouches[0]) as Node;
      if (
        dropdownRef.current &&
        buttonRef.current &&
        !dropdownRef.current.contains(target) &&
        !buttonRef.current.contains(target) &&
        isOpen
      ) {
        setIsOpen(false);
      }
    };

    const handleScroll = (event: Event) => {
      const target: Node = event.target as Node;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    document.addEventListener('scroll', handleScroll, true);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === KEY.ESCAPE && isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const filteredOptions = useMemo(() => {
    if (!assigneesData) {
      return;
    } else {
      return assigneesData
        .filter((assignee) =>
          `${assignee.firstName} ${assignee.lastName}`
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        )
        .sort((firstAssignee, secondAssignee) => {
          // Move users with `isSuggested` to the top
          if (firstAssignee.isSuggested && !secondAssignee.isSuggested) return -1;
          if (!firstAssignee.isSuggested && secondAssignee.isSuggested) return 1;
          return 0;
        });
    }
  }, [assigneesData, searchValue]);

  useEffect(() => {
    if (!data) return;
    const updatedUserList = data.map((user) => {
      const hasDevRole = user.facilities?.some(
        (facility) => facility.roles?.some((role) => role.roleId === USER_ROLES.CYCLE_COUNTER) // Replace 'DEV' with your constant if needed
      );
      return {
        ...user,
        isSuggested: hasDevRole, // Adding check for display suggested tag
      };
    });

    setAssigneesData(updatedUserList);
  }, [data]);

  return (
    <View direction="column" gap={3}>
      <Dropdown open={isOpen} contentGap={0} className={styles['dropdown-select-menu']}>
        <View attributes={{ ref: buttonRef }}>
          <Dropdown.Button
            label={label}
            onClick={toggleDropdown}
            className={styles['dropdown-select-menu__button']}
          >
            <View direction="row" wrap={false} align="center">
              {selectedAssignee && value && (
                <Avatar
                  name={`${selectedAssignee?.firstName} ${selectedAssignee?.lastName}`}
                  size="large"
                />
              )}

              <SearchBar
                className={
                  value
                    ? `${styles['dropdown-select-menu__no-search-bar']}`
                    : styles['dropdown-select-menu__search-bar']
                }
                suggestions={[]}
                label={t('ReplenishmentDashboard.AddReplenishment.Assignee.Placeholder')}
                variant="pro"
                value={value}
                onValueChange={onValueChange}
                onValueClear={() => setSelectedAssignee(null)}
              />
            </View>
          </Dropdown.Button>
        </View>
        <Dropdown.Content className={styles['dropdown-select-menu__dropdown__content']}>
          <View
            attributes={{
              ref: dropdownRef,
            }}
            gap={1}
            padding={[2, 0]}
          >
            <View direction="column" className={styles['dropdown-select-menu__scrollable-section']}>
              {filteredOptions?.length === 0 ? (
                <Text size="087" weight="medium" variant="text-link" align="center">
                  {t('PODashboard.NoMatch')}
                </Text>
              ) : (
                filteredOptions?.map((user) => (
                  <Actionable
                    key={user.userId}
                    className={styles['dropdown-select-menu__scrollable-section__option']}
                    onClick={() => onAssigneeSelect(user)}
                  >
                    <View direction="row" justify="center" align="center" padding={[3, 4]}>
                      <View.Item grow>
                        <Avatar
                          name={`${user.firstName} ${user.lastName}`}
                          size="large"
                          variant="assignee"
                          showText={true}
                        />
                      </View.Item>

                      {user.isSuggested && (
                        <View.Item>
                          <View
                            direction="row"
                            justify="center"
                            align="center"
                            min-height="var(--st-unit-6)"
                            width="fit-content"
                            borderRadius="small"
                            className={
                              styles[`dropdown-select-menu__scrollable-section__option__badge`]
                            }
                          >
                            <Text
                              size="100"
                              weight="bold"
                              className={styles[`assignees-suggestion__item__badge--text`]}
                            >
                              {t('PODashboard.Assignees.Suggested')}
                            </Text>
                          </View>
                        </View.Item>
                      )}
                    </View>
                  </Actionable>
                ))
              )}
            </View>
          </View>
        </Dropdown.Content>
      </Dropdown>
    </View>
  );
};
