/**
 * Copyright 2025 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useAtom } from 'jotai/index';
import { selectedReleaseOrderTypeAtom } from '@outbound/atoms/releaseOrder/releaseOrderAtom.ts';
import { useGetReleaseGroups } from '@outbound/services/hooks/useGetReleaseGroups.tsx';
import { DEFAULT_PAGE, MAX_PAGE_SIZE } from '@shared/constants/constants.ts';
import {
  RELEASE_GROUPS_GROUP_TYPE,
  RELEASE_GROUPS_ORDER_TYPE,
} from '@outbound/constants/constants.ts';
import { useEffect } from 'react';
import { Loader, View } from '@az/starc-ui';
import styles from '@outbound/pages/ControlDesk/ReleaseOrders/OrderTabs/OrderTabs.module.scss';
import { OrderSection } from '@outbound/components/OrderSection/OrderSection.tsx';
import { OrderReviewRootAccordion } from '@outbound/components/OrderReview/OrderReviewRootAccordion.tsx';

export const DifOrderReview = () => {
  /*Atoms*/
  const [, setSelectedReleaseOrderType] = useAtom(selectedReleaseOrderTypeAtom);

  const { releaseGroupsData, isFetching: isReleaseOrderLoading } = useGetReleaseGroups({
    currentPage: DEFAULT_PAGE - 1,
    pageSize: MAX_PAGE_SIZE,
    orderType: RELEASE_GROUPS_ORDER_TYPE.DIF_WORK_ORDER,
    groupTypes: [RELEASE_GROUPS_GROUP_TYPE.ROOT],
  });

  /* Hooks */
  useEffect(() => {
    setSelectedReleaseOrderType(RELEASE_GROUPS_ORDER_TYPE.DIF_WORK_ORDER);
  }, [setSelectedReleaseOrderType]);

  return (
    <View direction="column" padding={6} className={styles['order-release']}>
      <View direction="column" className={styles['order-release__content']}>
        <OrderSection>
          {isReleaseOrderLoading && <Loader color="primary" />}
          {(releaseGroupsData?.content || []).map((releaseGroup) => (
            <OrderReviewRootAccordion releaseGroup={releaseGroup} />
          ))}
        </OrderSection>
      </View>
    </View>
  );
};
