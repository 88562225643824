/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { INBOUND_RECEIVING_ENDPOINTS } from '@shared/constants/routes.ts';
import { apiService } from '@shared/services/apiService.ts';
import { paginatedParseAndLog, parseAndLog } from '@shared/utils/service/serviceUtils';

import {
  ASC,
  DEFAULT_PAGE,
  DOMAIN_TYPE_CD,
  ORDER_TYPES,
  PAGE_SIZE,
  RECEIVED_LINES,
  SOURCE_ORDER_NUMBER,
  TRAILER_ORDER_STATUS,
  TRAILER_ORDERS,
} from '@inbound/constants/constants';
import {
  InboundOrderCuntByTimeSchema,
  InboundOrderListSchema,
} from '@inbound/schemas/inboundOrderSchema';
import { InboundTrailerOrderSchema } from '@inbound/schemas/inboundTrailerOrderSchema';
import { RetriveDashboardsSchema } from '@inbound/schemas/retriveDashboardsSchema';
import { GetInboundOrderParams, GetRetriveDashboardsParams } from '@inbound/types/service.type';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const inboundOrderService = {
  getInboundOrderBySourceOrderNumber: async (sourceOrderNumber: string) => {
    const payload = {
      searchCriteria: {
        inboundOrder: {
          domainTypeCd: DOMAIN_TYPE_CD,
          sourceOrderNbr: sourceOrderNumber,
        },
        entityAssociations: [TRAILER_ORDERS],
      },
      searchPage: {
        page: DEFAULT_PAGE,
        size: PAGE_SIZE,
        sort: [
          {
            sortBy: SOURCE_ORDER_NUMBER,
            direction: ASC,
          },
        ],
      },
    };

    const response = await apiService.post(INBOUND_RECEIVING_ENDPOINTS.GET_INBOUND_ORDERS, payload);

    return paginatedParseAndLog(InboundOrderListSchema, response.data);
  },

  getInboundOrderBySourceOrderNumberAndReceiptId: async (
    sourceOrderNumber: string,
    receiptId: string
  ) => {
    const payload = {
      params: {
        entityAssociations: RECEIVED_LINES,
      },
    };

    const response = await apiService.get(
      INBOUND_RECEIVING_ENDPOINTS.GET_INBOUND_ORDERS_BY_SOURCE_ORDER_AND_RECEIPT(
        sourceOrderNumber,
        receiptId
      ),
      payload
    );

    return parseAndLog(InboundTrailerOrderSchema, response.data);
  },

  getInboundOrder: async (options?: GetInboundOrderParams) => {
    const payload = {
      searchCriteria: {
        ...options?.searchCriteria,
      },
      searchPage: {
        page: DEFAULT_PAGE,
        size: options?.pagesize ? options?.pagesize : PAGE_SIZE,
        sort: [
          {
            sortBy: SOURCE_ORDER_NUMBER,
            direction: ASC,
          },
        ],
      },
    };

    const response = await apiService.post(INBOUND_RECEIVING_ENDPOINTS.GET_INBOUND_ORDERS, payload);

    return paginatedParseAndLog(InboundOrderListSchema, response.data);
  },

  getRetriveDashboards: async (options: GetRetriveDashboardsParams) => {
    const currentTime = dayjs().utc();
    const oneMonthAgo = currentTime.subtract(1, 'month');

    const payload = {
      ...options,
      srcCreateTs: options.srcCreateTs ? options.srcCreateTs : oneMonthAgo.toISOString(),
    };

    const response = await apiService.post(
      INBOUND_RECEIVING_ENDPOINTS.GET_INBOUND_ORDERS_BY_TIME,
      payload
    );

    return parseAndLog(RetriveDashboardsSchema, response.data);
  },

  getInboundOrderCountByTimeRange: async () => {
    const currentTime = dayjs().utc();
    const oneMonthAgo = currentTime.subtract(1, 'month');

    const payload = {
      reportType: 'countByTimeRange',
      srcCreateTs: oneMonthAgo.toISOString(),
      orderTypeCds: [ORDER_TYPES.DSD, ORDER_TYPES.PO, ORDER_TYPES.LTL, ORDER_TYPES.LTD],
      statusCds: [
        TRAILER_ORDER_STATUS.NOT_STARTED,
        TRAILER_ORDER_STATUS.RECEIVING_IN_PROGRESS,
        TRAILER_ORDER_STATUS.READY_FOR_FINALIZATION,
        TRAILER_ORDER_STATUS.IN_IC_REVIEW,
        TRAILER_ORDER_STATUS.READY_FOR_SIGNATURE,
        TRAILER_ORDER_STATUS.HOLD,
      ],
    };

    const response = await apiService.post(
      INBOUND_RECEIVING_ENDPOINTS.GET_INBOUND_ORDERS_BY_TIME,
      payload
    );

    return parseAndLog(InboundOrderCuntByTimeSchema, response.data);
  },
};
