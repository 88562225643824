/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DetailsSectionSkeleton } from '@shared/components/Skeletons/DetailsSectionSkeleton.tsx';
import { Accordion, Button, Divider, Loader, Text, View } from '@az/starc-ui';
import { z } from 'zod';

import styles from '@mdm/pages/RDM/LocationManager/Location/Detail/LocationDetail.module.scss';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import { defaultAttributeValues, LocationSchema } from '@mdm/schemas/locationSchema.ts';
import { getFormDefaults, getFormFieldIndexByKey } from '@mdm/utils/form/formUtils.tsx';
import { useMutateLocation } from '@mdm/services/hooks/useMutateLocation.ts';
import { DEFAULT_PAGE, MAX_PAGE_SIZE, NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { LocationDetailForm } from '@mdm/pages/RDM/LocationManager/Location/Detail/LocationDetailForm.tsx';
import { useGetLocationByLayoutKey } from '@mdm/services/hooks/useGetLocationByLayoutKey.tsx';
import { useDeleteLocation } from '@mdm/services/hooks/useDeleteLocation.ts';
import { ActionMenu } from '@mdm/components/ActionMenu/ActionMenu.tsx';
import { LocationAssociatedSkuList } from '@mdm/pages/RDM/LocationManager/Location/Detail/LocationAssociatedSkuList.tsx';
import {
  LAYOUT_ENTITY_CODE,
  LAYOUT_ENTITY_TYPE_CODE,
  LOCATION_FIELD,
  LOCATION_FLAG_DEDICATED,
  LOCATION_TYPE,
  SUBZONE_FIELD,
  SUBZONE_TYPE,
} from '@mdm/constants/constants.ts';
import { useGetSubzones } from '@mdm/services/hooks/useGetSubzones.ts';
import { subzoneService } from '@mdm/services/subzoneService.ts';

export const LocationDetail = () => {
  /* State */
  const { locationId, selectedSubzoneId } = useParams();
  const [isDataLoaded, setIsDataLoaded] = useState(!locationId);
  const [isSkuValid, setSkuValid] = useState(true);
  const [isSubzoneForwardCompatible, setIsSubzoneForwardCompatible] = useState<boolean>(true);
  const [isSubzoneReserveCompatible, setIsSubzoneReserveCompatible] = useState<boolean>(true);

  /* Constants */
  type FormData = z.infer<typeof LocationSchema>;
  const { t } = useTranslation();

  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumb(locationId ? PAGE_URLS.LOCATION_CREATE : '');
  const { isLoading: isLocationUpdating, mutateLocation } = useMutateLocation();
  const { mutateDeleteLocation, isLoading: isDeleting } = useDeleteLocation();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();

  const { subzonesData, isFetching: isSubzoneListLoading } = useGetSubzones({
    currentPage: DEFAULT_PAGE - 1,
    pageSize: MAX_PAGE_SIZE,
    entityAssociations: [],
  });

  const hookFormMethods = useForm<FormData>({
    mode: 'onBlur',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    defaultValues: getFormDefaults(LocationSchema),
    resolver: zodResolver(LocationSchema),
  });

  const { handleSubmit, reset: locationReset, getValues, watch, setValue } = hookFormMethods;

  const locationName = watch('layoutName');
  const subzoneDistinctName = watch('parentLayoutDistinctName');

  /* Functions */
  const onSubmit = async () => {
    const subzoneData = await subzoneService.getSubzoneById(subzoneDistinctName);
    const subzoneAttributes = subzoneData?.content[0].layout.attributes || [];
    const subzoneType = subzoneAttributes.find(
      (attribute) => attribute.name === SUBZONE_FIELD.SUBZONE_TYPE
    );

    const payload = getValues();
    const attributes = getValues()['attributes'];
    const locationTypeAttributeIndex = getFormFieldIndexByKey(
      attributes,
      LOCATION_FIELD.LOCATION_TYPE
    );

    // If sku is invalid don't submit the payload
    if (!isSkuValid) {
      return false;
    }

    let sku = '';
    if (payload.products && payload.products.length) {
      sku = payload.products[0].productId;
    }

    if (payload.attributes) {
      payload.attributes = payload.attributes
        .filter((attribute) => attribute.value !== '')
        .map((attribute) => {
          return {
            ...attribute,
            value: attribute.name === LOCATION_FIELD.SKU ? sku : attribute.value.toString(),
          };
        });
    }

    if (attributes && attributes[locationTypeAttributeIndex].value === LOCATION_TYPE.RESERVE) {
      payload.products = [];
    } else {
      payload.products = [...(payload.products || [])].filter(
        (product) => product.productId.trim() !== ''
      );
    }

    if (attributes && subzoneType && locationTypeAttributeIndex !== -1) {
      if (
        attributes[locationTypeAttributeIndex].value !== LOCATION_TYPE.SLOT &&
        subzoneType.value === SUBZONE_TYPE.FORWARD_PICK
      ) {
        setIsSubzoneForwardCompatible(false);
        return false;
      } else {
        setIsSubzoneForwardCompatible(true);
      }

      if (
        attributes[locationTypeAttributeIndex].value !== LOCATION_TYPE.RESERVE &&
        subzoneType.value === SUBZONE_TYPE.RESERVE
      ) {
        setIsSubzoneReserveCompatible(false);
        return false;
      } else {
        setIsSubzoneReserveCompatible(true);
      }
    }

    mutateLocation(
      {
        layoutKey: payload.layoutKey || '',
        layoutRequest: {
          ...payload,
          assets: [],
          parentEntityTypeCd: LAYOUT_ENTITY_TYPE_CODE.DEFAULT,
        },
      },
      {
        onSuccess: (data) => {
          if (data) {
            queryClient.invalidateQueries(['locations']);
            let successMessage = t('Success.Action.Location.Created');
            if (payload.layoutKey) {
              successMessage = t('Success.Action.Location.Updated', {
                locationName: payload.layoutName,
              });
            }
            handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
            if (!locationId && data.layoutDistinctName) {
              navigate(PAGE_URLS.LOCATION_DETAILS(data.layoutDistinctName));
            } else {
              const layoutDistinctName = `${LAYOUT_ENTITY_CODE.LOCATION}=${payload.layoutName},${payload.parentLayoutDistinctName}`;
              navigate(PAGE_URLS.LOCATION_DETAILS(layoutDistinctName));
            }
          }
        },
      }
    );
  };

  const onDeleteLocation = () => {
    if (locationId && !isDeleting) {
      const payload = getValues();
      mutateDeleteLocation(
        {
          layoutKey: payload.layoutKey || '',
        },
        {
          onSuccess: async () => {
            navigate(PAGE_URLS.LOCATION_LIST);
          },
        }
      );
    }
  };

  /* Hooks */
  const { locationData } = useGetLocationByLayoutKey(
    {
      layoutKey: locationId || '',
    },
    !!locationId
  );

  useEffect(() => {
    if (locationData && locationData.content[0]) {
      let attributes = locationData.content[0].layout.attributes || [];
      const products = locationData.content[0].products || [];
      for (const defaultAttributeValue of defaultAttributeValues) {
        if (!attributes.find((a) => a.name === defaultAttributeValue.name)) {
          attributes.push(defaultAttributeValue);
        }
      }

      attributes = attributes.filter((attribute) =>
        defaultAttributeValues.find(
          (defaultAttributeValue) => attribute.name === defaultAttributeValue.name
        )
      );

      const locationTypeAttributeIndex = getFormFieldIndexByKey(
        attributes,
        LOCATION_FIELD.LOCATION_TYPE
      );

      const locationFlagAttributeIndex = getFormFieldIndexByKey(
        attributes,
        LOCATION_FIELD.LOCATION_FLAG
      );

      const newLocationTypeValue = attributes[locationTypeAttributeIndex].value;
      const newLocationFlagValue = attributes[locationFlagAttributeIndex].value;

      if (products && products.length === 0) {
        products.push({
          productId:
            newLocationFlagValue === LOCATION_FLAG_DEDICATED &&
            newLocationTypeValue === LOCATION_TYPE.SLOT
              ? ''
              : ' ',
          min: '0',
          max: '0',
          isActive: true,
        });
      }

      locationReset({
        ...locationData.content[0].layout,
        parentLayoutDistinctName: locationData.content[0].parent?.layoutDistinctName || '',
        attributes,
        products,
      });

      setIsDataLoaded(true);
    } else if (locationId && locationData && locationData.content.length === 0) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('LocationNotFound'));
    }
  }, [handleNotification, locationData, locationId, locationReset, t]);

  useEffect(() => {
    if (selectedSubzoneId) {
      setValue('parentLayoutDistinctName', selectedSubzoneId);
    }
  }, [selectedSubzoneId, setValue]);

  if (!isDataLoaded) {
    return <DetailsSectionSkeleton items={10} />;
  } else {
    return (
      <View className={styles['location-detail']} backgroundColor="secondary" direction="column">
        <View
          attributes={{
            'data-testid': 'location-details-header',
          }}
        >
          <MasterTitle
            title={`${t('Location')}: ${locationName ? locationName : t('Untitled')}`}
            breadcrumbProps={breadcrumbs}
          >
            <View
              attributes={{
                'data-testid': 'location-detail-action',
              }}
              direction="row"
              justify="end"
              align="center"
              gap={4}
            >
              <View.Item>
                <View direction="row" gap={4}>
                  <View.Item>
                    <Button
                      variant="secondary"
                      size="large"
                      onClick={() => navigate(PAGE_URLS.LOCATION_LIST)}
                    >
                      <View direction="row" align="center" justify="center" gap={2}>
                        <Text>{t('Cancel')}</Text>
                      </View>
                    </Button>
                  </View.Item>
                </View>
              </View.Item>
              <View.Item>
                <View direction="row" gap={4}>
                  <View.Item>
                    <Button
                      size="large"
                      loading={isLocationUpdating}
                      onClick={() => handleSubmit(onSubmit)()}
                    >
                      <View direction="row" align="center" justify="center" gap={2}>
                        <Text>{t('SaveEdits')}</Text>
                      </View>
                    </Button>
                  </View.Item>
                </View>
              </View.Item>
              {locationId ? (
                <ActionMenu
                  menus={[
                    {
                      label: isDeleting ? <Loader /> : t('RemoveLocation'),
                      clickHandler: onDeleteLocation,
                      labelColor: 'error',
                    },
                  ]}
                />
              ) : null}
            </View>
          </MasterTitle>
        </View>

        <View padding={[4, 6]}>
          <Accordion
            className={styles['location-detail__accordion-wrapper']}
            defaultOpen={true}
            headerOptions={{
              headerElement: (
                <View direction="row">
                  <View.Item grow>
                    <Text color="primary" size="125" weight="bold">
                      {t('LocationDetails.Title')}
                    </Text>
                  </View.Item>
                </View>
              ),
            }}
          >
            <View backgroundColor="secondary" height="100%">
              <View className={styles['location-detail__content-section']}>
                <View>
                  <FormProvider {...hookFormMethods}>
                    <LocationDetailForm
                      locationId={locationId}
                      selectedSubzoneId={selectedSubzoneId}
                      setSkuValid={setSkuValid}
                      subzonesData={subzonesData?.content}
                      isSubzoneListLoading={isSubzoneListLoading}
                      isSubzoneForwardCompatible={isSubzoneForwardCompatible}
                      isSubzoneReserveCompatible={isSubzoneReserveCompatible}
                      setIsSubzoneForwardCompatible={setIsSubzoneForwardCompatible}
                      setIsSubzoneReserveCompatible={setIsSubzoneReserveCompatible}
                    />
                  </FormProvider>
                </View>
              </View>
            </View>
          </Accordion>
        </View>

        <View padding={[4, 6]}>
          <Divider />
        </View>

        <View padding={[0, 6, 4, 6]}>
          <View padding={[6, 0, 4, 0]}>
            <LocationAssociatedSkuList
              locationId={locationId}
              locationData={locationData?.content}
            />
          </View>
        </View>
      </View>
    );
  }
};
