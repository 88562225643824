/**
 * Copyright 2025 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { atom } from 'jotai';

export type createPhaseAtoms =
  | {
      WALL_TO_WALL: boolean;
      RISK_BASED: boolean;
      INVENTORY: boolean;
      CUSTOM: boolean;
    }
  | undefined;

export const cycleCountCreatePhaseAtom = atom<createPhaseAtoms>(undefined);
