/**
 * Copyright 2025 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export enum ProgressVariants {
  LARGE_LABEL = 'large-label',
  SMALL_LABEL = 'small-label',
}
export interface Props {
  max: number;
  value: number;
  label: string;
  variant?: ProgressVariants;
}
