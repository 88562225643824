/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const ReplenRequestAdjSchema = z.object({
  eventCd: z.string(),
  operation: z.string().nullable(),
  adjQty: z.number().nullable(),
  createUserId: z.string().nullable(),
  createTs: z.string().nullable(),
});
export const ReplenAssetSchema = z.object({
  assetCd: z.string(),
  createTs: z.string().nullable(),
  createUserId: z.string().nullable(),
  fromLayoutDistinctName: z.string(),
  lastModifiedTs: z.string().nullable(),
  lastModifiedUserId: z.string().nullable().optional(),
  replnAssetKey: z.string(),
  replnPlanMoveKey: z.string(),
});

export const ReplenPerformMoveSchema = z.object({
  replnPerformMoveKey: z.string().optional(),
  userId: z.string().nullable(),
  startTs: z.string().nullable(),
  endTs: z.string().nullable(),
  fromLayoutDistinctName: z.string().nullable(),
  fromLayoutName: z.string().nullable(),
  fromReceiptLabel: z.string().nullable(),
  gatherQty: z.number().nullable(),
  statusCd: z.string().nullable(),
  toLayoutDistinctName: z.string().nullable(),
  toLayoutName: z.string().nullable(),
  toReceiptLabel: z.string().nullable(),
  deliverQty: z.number().nullable(),
  gatherSequence: z.string().nullable(),
  createUserId: z.string().nullable(),
  createTs: z.string().nullable(),
  lastModifiedUserId: z.string().nullable(),
  lastModifiedTs: z.string().nullable(),
});

export const ReplenAssignedUserSchema = z.object({
  replnAssignedUserKey: z.string().optional(),
  userId: z.string(),
  firstName: z.string().nullable().optional(),
  lastName: z.string().nullable().optional(),
  assignedTs: z.string().nullable().optional(),
  userStatusCd: z.string().nullable(),
  createUserId: z.string().nullable().optional(),
  createTs: z.string().nullable().optional(),
  lastModifiedUserId: z.string().nullable().optional(),
  lastModifiedTs: z.string().nullable().optional(),
  replnPerformMoves: z.array(ReplenPerformMoveSchema).optional().nullable(),
});
export const ReplenPlanMoveSchema = z.object({
  replnPlanMoveKey: z.string().optional().nullable(),
  fromLayoutDistinctName: z.string().nullable(),
  fromLayoutName: z.string().nullable(),
  fromParentLayoutDistinctName: z.string().nullable(),
  fromParentLayoutName: z.string().nullable(),
  fromAnchorReceiptDate: z.string().nullable(),
  fromAnchorExpiryDate: z.string().nullable(),
  fromReceiptLabel: z.string().nullable(),
  toLayoutDistinctName: z.string().nullable(),
  toLayoutName: z.string().nullable(),
  toParentLayoutDistinctName: z.string().nullable(),
  toParentLayoutName: z.string().nullable(),
  totalReplnQty: z.number().optional().nullable(),
  completedReplnQty: z.number().nullable().optional(),
  statusCd: z.string().nullable(),
  nextReplenRequestKey: z.string().optional().nullable(),
  taskId: z.string().nullable(),
  createUserId: z.string().nullable(),
  createTs: z.string().nullable(),
  lastModifiedUserId: z.string().nullable(),
  lastModifiedTs: z.string().nullable(),
  replnAssignedUsers: z.array(ReplenAssignedUserSchema).nullable(),
  replnAssets: z.array(ReplenAssetSchema).nullable(),
});

export const ReplenishmentSchema = z.object({
  replnId: z.string().nullable(),
  srcRequestedTs: z.string().nullable(),
  productId: z.string().nullable(),
  partNbrId: z.string().nullable(),
  productLongDesc: z.string().nullable(),
  currentBaseCostAmt: z.number().nullable(),
  layoutDistinctName: z.string().nullable(),
  layoutName: z.string().nullable(),
  layoutType: z.string().nullable(),
  parentLayoutDistinctName: z.string().nullable(),
  parentLayoutName: z.string().nullable(),
  layoutMax: z.number().nullable(),
  layoutMin: z.number().nullable(),
  layoutThreshold: z.number().nullable(),
  originalReplnQty: z.number().nullable(),
  totalReplnQty: z.number().nullable(),
  completedReplnQty: z.number().nullable(),
  replnTypeCd: z.string().nullable().optional(),
  priority: z.number(),
  statusCd: z.string(),
  createUserId: z.string().nullable(),
  createTs: z.string().nullable(),
  lastModifiedUserId: z.string().nullable(),
  lastModifiedTs: z.string().nullable(),
});

export const ReplenishmentListSchema = z.object({
  replnPlanMoveKey: z.string(),
  fromLayoutDistinctName: z.string(),
  fromLayoutName: z.string().nullable(),
  fromParentLayoutDistinctName: z.string().nullable(),
  fromParentLayoutName: z.string().nullable(),
  fromAnchorReceiptDate: z.string().nullable(),
  fromAnchorExpiryDate: z.string().nullable(),
  toLayoutDistinctName: z.string().nullable(),
  toLayoutName: z.string().nullable(),
  toParentLayoutDistinctName: z.string().nullable(),
  toParentLayoutName: z.string().nullable(),
  totalReplnQty: z.number().nullable(),
  completedReplnQty: z.number().nullable().optional(),
  currentSlotQty: z.number().nullable(),
  statusCd: z.string().nullable(),
  priority: z.number().nullable(),
  taskId: z.string().nullable(),
  createUserId: z.string().nullable(),
  createTs: z.string().nullable(),
  lastModifiedUserId: z.string().nullable(),
  lastModifiedTs: z.string().nullable(),
  outsAmount: z.number().nullable(),
  replnRequest: ReplenishmentSchema,
  replnAssignedUsers: z.array(ReplenAssignedUserSchema).nullable(),
  replnAssets: z.array(ReplenAssetSchema).nullable(),
});

export const ReplenishmentUpdateRequestSchema = z.object({
  replnId: z.string().nullable(),
  taskId: z.string().optional(),
  statusCd: z.string(),
  replnAssignedUsers: z.array(ReplenAssignedUserSchema).optional(),
});

export const ReplenishmentMovesGroupsSchema = z.array(
  z.object({
    userId: z.string().nullable(),
    vehicle: z.string().nullable(),
    subzone: z.string().nullable(),
    priority: z.number(),
    replenCount: z.number(),
  })
);
// Define the schema for an individual record
export const UnreplenishedDataObjectSchema = z.object({
  userId: z.string().nullable(), // userId can be null
  vehicle: z.string().nullable(), // vehicle can be null
  subzone: z.string().nullable(), // subzone can be null
  priority: z.number().nullable(), // priority is a number
  replenCount: z.number(), // replenCount is a number
});

// Export the schemas for use in other files
export const UnreplenishedDataSchema = z.array(UnreplenishedDataObjectSchema);

const ReplnAssignedUserSchema = z.object({
  userId: z.string(), // userId is required and must be a string
  firstName: z.string(), // Default is an empty string if not provided
  lastName: z.string(), // Default is an empty string if not provided
  userStatusCd: z.string(), // Must be a string (e.g., `USER_STATUS_CD.ASSIGNED`)
});

// Define schema for `replnAsset` (optional)
const ReplnAssetSchema = z.object({
  assetCd: z.number(), // `assetCd` must be a number
});

// Define schema for `searchCriteria`
const SearchCriteriaSchema = z.object({
  statusCds: z.array(z.string()), // Array of strings for `statusCds`
  replnAsset: ReplnAssetSchema.optional(), // Optional nested object for `replnAsset`
  priority: z.array(z.number()).optional(), // Optional array of numbers for `priority`
  toParentLayoutName: z.string(), // `toParentLayoutName` must be a string
});

// Define the main payload schema
export const ReplenishmentUpdateByCriteriaSchema = z.object({
  searchCriteria: SearchCriteriaSchema, // Validate `searchCriteria` with dynamic structure
  update: z.object({
    statusCd: z.string(),
    replnAssignedUsers: z.array(ReplnAssignedUserSchema), // Array of assigned users
  }),
});
