/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View } from '@az/starc-ui';

import {
  OrderReviewByAreaType,
  Props,
} from '@outbound/components/ReleaseGroupAccordion/ReleaseGroupAccordion.types.tsx';
import {
  RELEASE_GROUPS_GROUP_TYPE,
  RELEASE_GROUPS_ORDER_TYPE,
  RELEASE_ORDER_STATUS,
} from '@outbound/constants/constants.ts';
import { useGetReleaseGroups } from '@outbound/services/hooks/useGetReleaseGroups.tsx';
import { DEFAULT_PAGE, MAX_PAGE_SIZE, PAGE_SIZE } from '@shared/constants/constants.ts';
import { TableStylingVariants } from '@shared/components/Table/tableConstants.ts';
import { ORDER_REVIEW_BY_AREA_COLUMNS } from '@shared/components/Table/tableColumnData.ts';

import { mapOrderReviewByAreaTableRows } from '@outbound/utils/table/orderReviewByAreaTableUtils.tsx';
import { OrderReviewOrderDetailList } from '@outbound/components/OrderReview/OrderReviewOrderDetailList.tsx';
import { useEffect, useState } from 'react';
import { Table } from '@shared/components/Table/Table.tsx';

export const OrderReviewByAreaList = ({
  releaseGroup,
  orderType = RELEASE_GROUPS_ORDER_TYPE.DIF_WORK_ORDER,
  groupTypes = [RELEASE_GROUPS_GROUP_TYPE.LEAF],
}: Props) => {
  /* State */
  const [orderReviewByAreaTableRows, setOrderReviewByAreaTableRows] = useState<
    OrderReviewByAreaType[]
  >([]);

  /* Variables */
  const { groupId } = releaseGroup;

  /* Constants */
  const { releaseGroupsData } = useGetReleaseGroups({
    currentPage: DEFAULT_PAGE - 1,
    pageSize: MAX_PAGE_SIZE,
    orderType: orderType,
    parentReleaseGroupId: groupId,
    groupTypes,
    statuses: [RELEASE_ORDER_STATUS.RELEASED],
  });

  const onAccordionClick = (releaseGroupData: OrderReviewByAreaType) => {
    const newRows = orderReviewByAreaTableRows.map((row) => {
      if (row.groupId === releaseGroupData.groupId) {
        return {
          ...row,
          isRowExpandable: !row.isRowExpandable,
        };
      }
      return row;
    });
    setOrderReviewByAreaTableRows(newRows);
  };

  /* Hooks */
  useEffect(() => {
    if ((releaseGroupsData?.content || []).length) {
      const data = (releaseGroupsData?.content || []).map((item) => ({
        ...item,
        isRowExpandable: false,
      }));

      setOrderReviewByAreaTableRows([...data]);
    }
  }, [releaseGroupsData?.content]);

  return (
    <>
      <View>
        <Table
          columns={ORDER_REVIEW_BY_AREA_COLUMNS}
          rows={mapOrderReviewByAreaTableRows(orderReviewByAreaTableRows, onAccordionClick)}
          expandableTableView={<OrderReviewOrderDetailList releaseGroup={releaseGroup} />}
          isPaginated={false}
          isCheckboxDisabled={false}
          pageSize={PAGE_SIZE}
          defaultPage={DEFAULT_PAGE}
          isCreditItem={false}
          isCheckboxTable={false}
          isApiLoadedData={true}
          styleVariant={TableStylingVariants.EXPANDABLE}
          totalPages={releaseGroupsData?.totalPages || 0}
          onSort={() => ({})}
        />
      </View>
    </>
  );
};
