/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const SIZE_FOUR = 4;
export enum QCSButtons {
  SHIFT_PROGRESS = 'SHIFT_PROGRESS',
  MY_PROGRESS = 'MY_PROGRESS',
}

export const GroupByOptions = [
  {
    label: 'FinalizationDashboard.GroupBy.PurchaseOrder',
    value: 'purchaseOrder',
  },
  {
    label: 'FinalizationDashboard.GroupBy.Locations',
    value: 'locations',
  },
];

export const PODiscrepancyAdjResons = {
  RECOUNT: { label: 'FinalizationDashboard.AdjReasons.Recount', code: '95000' },
  REBOX: { label: 'FinalizationDashboard.AdjReasons.Rebox', code: '99929' },
  RECEIVE: { label: 'FinalizationDashboard.AdjReasons.Receive', code: '99945' },
};

export const NUMERIC_REGEX = /^(\s*|\d+)$/;

export const DISCREPANCY_ACTION_STATE = {
  SAVE: 'SAVE',
  EDIT: 'EDIT',
};

export const DISCREPANCY_STATUS = {
  UNCHECKED: 'UNCHECKED',
  CHECKED: 'CHECKED',
};

export const MAX_TEXTAREA_LENGTH_NOTES_FOR_INBOUND = 1000;
export const MAX_TEXTAREA_LENGTH_REASON_FOR_REVERT_PO_STATUS = 1000;
export const MAX_PCS_INPUT_LENGTH = 20;

export const headersData = [
  {
    label: 'FinalizationDashboard.Table.ProductName',
    width: '20.6%',
  },
  {
    label: 'FinalizationDashboard.Table.Ordered',
    width: '7.7%',
  },
  {
    label: 'FinalizationDashboard.Table.Shipped',
    width: '11.2%',
  },
  {
    label: 'FinalizationDashboard.Table.Received',
    width: '9.0%',
  },
  {
    label: 'FinalizationDashboard.Table.Actual',
    width: '10.3%',
  },
  {
    label: 'FinalizationDashboard.Table.Difference',
    width: '7.7%',
  },
];

export const URLQuery = {
  GROUP_BY: 'groupBy',
  LOCATIONS: 'locations',
  PO: 'purchaseOrder',
};

export enum CountStatus {
  PAST = 'PAST',
  ACTIVE = 'ACTIVE',
}

export enum CHOOSE_ASSIGNEE_CONTENT_TYPE {
  SINGLE_ASSIGNEE = 'SingleAssignee',
  MULTIPLE_ASSIGNEE = 'MultipleAssignees',
}

export const MAX_VISIBLE_AVATARS_CYCLE_COUNT = 3;
export const SINGLE_PAGE_SIZE = 1;
export const PAGE = 0;
export const taskId = '12341234';
export const year = 2024;
export const parentLayoutDistinctName = 'SZ=FER1, ZN=FM';
export const userId = 'wdallas';
export const layoutDistinctNames = 'SZ=FER1, ZN=FM';
export const productId = 'SKU-123';
export const partNbr = 'SKU-123';

export const HEADER_COMPONENT = {
  PHASE: 'phase',
  RISK_BASED: 'Risk-Based',
  WALL_TO_WALL: 'Wall-to-Wall',
  WEEK: 'Week',
  PERIOD: 'P',
};

export const CYCLE_COUNT_TYPE_CD = {
  WALL_TO_WALL: 'WALL_TO_WALL',
  OPPORTUNITY: 'OPPORTUNITY',
  ADJUSTMENT: 'ADJUSTMENT',
  RISKBASED: 'RISKBASED',
  RECOUNT: 'RECOUNT',
  RBCC: 'RBCC',
  INVENTORY: 'INVENTORY',
  CUSTOM: 'CUSTOM',
};

export const CYCLE_COUNT_TABS = {
  WALL_TO_WALL: 'wall-to-wall',
  RISK_BASED: 'risk-based',
};

export const CYCLE_COUNT_STATUS_CD = {
  NEW: 'NEW',
  INPROGRESS: 'INPROGRESS',
  COMPLETE: 'COMPLETE',
  PARTIAL: 'PARTIAL',
};

export const CYCLE_COUNT_ENTITY_ASSOCIATION = {
  COUNT_LAYOUT_SUBZONE: 'COUNT_LAYOUT_SUBZONE',
  COUNT_ASSIGNED_USER: 'COUNT_ASSIGNED_USER',
  COUNT_LAYOUT_LOCATION: 'COUNT_LAYOUT_LOCATION',
  COUNT_PERFORM_ACTION: 'COUNT_PERFORM_ACTION',
};
/* location count command handler sync API required constants */
export const REASON_CODE = {
  PICK_SHORT: 'PICK_SHORT',
  INBOUND_EXCEPTION: 'INBOUND_EXCEPTION',
  OUTBOUND_EXCEPTION: 'OUTBOUND_EXCEPTION',
  INVENTORY_MOVE: 'INVENTORY_MOVE',
  REPL_MOVE: 'REPL_MOVE',
};

export const INV_ADJUSTMENT_TYPE = {
  COMPLETE: 'COMPLETE',
  PARTIAL: 'PARTIAL',
  SKIP: 'SKIP',
};

export const INV_ADJUSTMENT_STATUS = {
  EXISTING: 'EXISTING',
  NEW: 'NEW',
};

export const METRICS = [
  'VARIANCES_COUNTED',
  'DOLLAR_VARIANCE_OVER_THRESHOLD',
  'PIECE_ADJUSTMENTS_OVER_THRESHOLD',
  'LOCATION_COUNTS',
  'ACCURACY_MEASUREMENTS',
];

export const SUBZONE_METRICS = 'SZ_LOCATION_VARIANCES';
export const COUNT_CALENDAR = 'COUNT_CALENDAR';

export const DOLLAR_VARIANCE_THRESHOLD = 250;
export const QTY_VARIANCE_THRESHOLD = 250;
export const DEFAULT = 'DEFAULT';
export const SUBZONE_ENTITY_CD = 'SZ';

export const DateFilters = {
  THIS_WEEK: 'ThisWeek',
  LAST_4_WEEKS: 'Last4Weeks',
  LAST_WEEK: 'LastWeek',
  CURRENT_PERIOD: 'CurrentPeriod',
  FISCAL_YEAR: 'FiscalYear',
  PHASE: 'Phase',
};

export const TOP_VARIANCE_PILLS = [
  {
    value: DateFilters.THIS_WEEK,
    label: 'TopVariances.ProductCategory.TabPills.ThisWeek',
  },
  {
    value: DateFilters.LAST_4_WEEKS,
    label: 'TopVariances.ProductCategory.TabPills.Last4Weeks',
  },
  {
    value: DateFilters.FISCAL_YEAR,
    label: 'TopVariances.ProductCategory.TabPills.FiscalYear',
  },
];

export const RBCC_FILTERS = [
  {
    value: DateFilters.THIS_WEEK,
    label: 'TopVariances.ProductCategory.TabPills.ThisWeek',
  },
  {
    value: DateFilters.LAST_WEEK,
    label: 'TopVariances.ProductCategory.TabPills.LastWeek',
  },
  {
    value: DateFilters.CURRENT_PERIOD,
    label: 'TopVariances.ProductCategory.TabPills.CurrentPeriod',
  },
  {
    value: DateFilters.FISCAL_YEAR,
    label: 'TopVariances.ProductCategory.TabPills.FiscalYear',
  },
];

export const TOP_VARIANCE_CATEGORY_SECTION = {
  DOLLAR_VARIANCE_BY_SKU: 'DOLLAR_VARIANCE_BY_SKU',
  DOLLAR_VARIANCE_BY_SUBZONE: 'DOLLAR_VARIANCE_BY_SUBZONE',
  DOLLAR_VARIANCE_BY_PRODUCT_CATEGORY: 'DOLLAR_VARIANCE_BY_PRODUCT_CATEGORY',
  DOLLAR_VARIANCE_BY_VENDOR: 'DOLLAR_VARIANCE_BY_VENDOR',
  PIECE_ADJUSTMENTS_BY_SKU: 'PIECE_ADJUSTMENTS_BY_SKU',
  PIECE_ADJUSTMENTS_BY_SUBZONE: 'PIECE_ADJUSTMENTS_BY_SUBZONE',
  PIECE_ADJUSTMENTS_BY_PRODUCT_CATEGORY: 'PIECE_ADJUSTMENTS_BY_PRODUCT_CATEGORY',
  PIECE_ADJUSTMENTS_BY_VENDOR: 'PIECE_ADJUSTMENTS_BY_VENDOR',
};

// Used this enum for update subzone assignee
export enum USER_STATUS_CD {
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
}
export enum SUBZONE_ATTRIBUTE_KEY {
  SLOT_COUNT = 'SLOT_COUNT',
  RSRV_COUNT = 'RSRV_COUNT',
}

export const REPORT_TYPE = Object.freeze({
  OVERVIEW: 'OVERVIEW',
  SKU_WITH_VARIANCE: 'SKU_WITH_VARIANCE',
  SUBZONE_PROGRESS: 'SUBZONE_PROGRESS',
});

export const EVENT_TYPE_CDS = [
  { label: 'InventoryHistoryDashboard.EventTypeCds.SelectEventType', value: '' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.Adjustment', value: 'ADJUSTMENT' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.Allocate', value: 'ALLOCATE' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.AllocatePick', value: 'ALLOCATE_PICK' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.AllocateReplen', value: 'ALLOCATE_REPLEN' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.DeallocateReplen', value: 'DEALLOCATE_REPLEN' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.Damage', value: 'DAMAGE' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.Load', value: 'LOAD' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.MoveFrom', value: 'MOVE_FROM' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.MoveTo', value: 'MOVE_TO' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.Opportunity', value: 'OPPORTUNITY' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.Pick', value: 'PICK' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.PickShort', value: 'PICK_SHORT' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.PutAway', value: 'PUT_AWAY' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.Receive', value: 'RECEIVE' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.ReceiveAway', value: 'RECEIVE_AWAY' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.ReceiveMoveFrom', value: 'RECEIVE_MOVE_FROM' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.ReceiveMoveTo', value: 'RECEIVE_MOVE_TO' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.ReplFrom', value: 'REPL_FROM' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.ReplTo', value: 'REPL_TO' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.Rbcc', value: 'RBCC' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.Ship', value: 'SHIP' },
  { label: 'InventoryHistoryDashboard.EventTypeCds.WallToWall', value: 'WALL_TO_WALL' },
];
