/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { INBOUND_RECEIVING_ENDPOINTS } from '@shared/constants/routes';
import { apiService } from '@shared/services/apiService';
import { paginatedParseAndLog, parseAndLog } from '@shared/utils/service/serviceUtils';

import { ASC, DEFAULT_PAGE, DESC, PAGE_SIZE } from '@inbound/constants/constants';
import {
  ReplenishmentListSchema,
  ReplenishmentMovesGroupsSchema,
} from '@inbound/schemas/replenishmentSchema';
import {
  GetReplenishmentParams,
  DownloadReplenishmentParams,
  ManualReplenishmentRequest,
  GetReplenishmentDashboardMetricsParams,
} from '@inbound/types/service.type';
import { ReplenishmentDashboardMetricsSchema } from '@inbound/schemas/replenishmentDashboardMetricsSchema';

import { ReplenishmentUpdateRequestType } from '@inbound/types/types';

export const replenishmentService = {
  getReplenishment: async (options?: GetReplenishmentParams) => {
    const payload = {
      searchCriteria: {
        ...options?.searchCriteria,
      },
      searchPage: {
        page: options?.searchPage?.page ? options?.searchPage?.page : DEFAULT_PAGE,
        size: options?.searchPage?.size ? options?.searchPage?.size : PAGE_SIZE,
        sort: [
          {
            sortBy: options?.searchPage?.sort[0]?.sortBy ?? 'replnRequest.srcRequestedTs',
            direction: ASC,
          },
        ],
      },
    };

    const response = await apiService.post(INBOUND_RECEIVING_ENDPOINTS.GET_REPLENISHMENTS, payload);

    return paginatedParseAndLog(ReplenishmentListSchema, response.data);
  },

  downloadReplenishment: async (options?: DownloadReplenishmentParams) => {
    const payload = {
      searchCriteria: {
        ...options?.searchCriteria,
      },
      searchPage: {
        page: options?.searchPage?.page || DEFAULT_PAGE,
        size: options?.searchPage?.size || PAGE_SIZE,
        sort: [
          {
            sortBy: 'priority',
            direction: DESC,
          },
        ],
      },
    };

    try {
      const response = await apiService.post(
        INBOUND_RECEIVING_ENDPOINTS.DOWNLOAD_REPLENISHMENTS,
        payload,
        {
          responseType: 'blob',
          headers: {
            Accept: options?.type,
          },
        }
      );

      const fileType = response.headers['content-type'];
      const blob = new Blob([response.data], { type: fileType });

      const contentDisposition = response.headers['content-disposition'];
      let fileName = null;

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch && fileNameMatch.length > 1) {
          fileName = fileNameMatch[1];
        }
      }

      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
      return {
        status: true,
      };
    } catch (error) {
      return {
        status: false,
      };
    }
  },
  createManualReplenishment: async (payload: ManualReplenishmentRequest) => {
    return apiService.post(INBOUND_RECEIVING_ENDPOINTS.CREATE_REPLENISHMENTS, payload);
  },
  updateReplenishments: async (
    replenMoveId: string,
    replenishmentUpdateRequest: ReplenishmentUpdateRequestType
  ) => {
    return apiService.put(
      INBOUND_RECEIVING_ENDPOINTS.UPDATE_REPLENISHMENTS(replenMoveId),
      replenishmentUpdateRequest
    );
  },
  getReplenishmentMovesGroups: async (param: string) => {
    const response = await apiService.get(
      `${INBOUND_RECEIVING_ENDPOINTS.GET_REPLENISHMENTS_MOVES_GROUP}?groupBy=${param}`
    );
    return parseAndLog(ReplenishmentMovesGroupsSchema, response.data);
  },
  getReplenishmentDashboardMetrics: async (payload: GetReplenishmentDashboardMetricsParams) => {
    const response = await apiService.post(
      INBOUND_RECEIVING_ENDPOINTS.REPLENISHMENT_DASHBOARD_METRICS,
      payload
    );
    return parseAndLog(ReplenishmentDashboardMetricsSchema, response.data);
  },
};
