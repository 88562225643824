/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Actionable, Text, View } from '@az/starc-ui';

import { Avatar } from '@shared/components/Avatar/Avatar';
import { UserSearchSchemaType } from '@shared/types/schema.type';

import * as T from './AssigneesSuggestion.types';
import styles from './AssigneesSuggestion.module.scss';
import { EMPTY_VALUE } from '@shared/constants/constants';

export const AssigneesSuggestion = ({
  data,
  selectedUser,
  recommendedAssignees,
  onItemClick,
}: T.Props) => {
  /* State variables */
  const [, setIsLoading] = useState<boolean>(false);

  /* Constants */
  const { t } = useTranslation();

  /* Functions */
  const isSuggested = (userId: string) => {
    return recommendedAssignees.find((item) => item.userId === userId);
  };

  const isSelected = (userId: string) => {
    if (selectedUser) {
      return selectedUser.userId === userId;
    }

    return false;
  };

  const handleSuggestionClick = (user: UserSearchSchemaType) => {
    setIsLoading(true);

    onItemClick?.(user);

    setTimeout(() => {
      setIsLoading(false); // fake the delay to reset state
    }, 1);
  };

  return (
    <View direction="column" padding={[2, 0]} className={styles['assignees-suggestion__container']}>
      {selectedUser && (
        <View.Item>
          <View direction="column" className={styles['assignees-suggestion__section']}>
            <View.Item>
              <View className={styles['assignees-suggestion__section__title']}>
                <Text>{t('PODashboard.Assignees.Selected')} (1)</Text>
              </View>
            </View.Item>
            <View.Item>
              <View className={styles['assignees-suggestion__section__items']}>
                <View
                  key={t('PODashboard.ItemKey', {
                    item: 'selected',
                    key: selectedUser?.userId,
                  })}
                  direction="row"
                  className={styles['assignees-suggestion__item']}
                >
                  <View
                    direction="row"
                    justify="center"
                    align="center"
                    className={styles['assignees-suggestion__item__checkbox__label']}
                  >
                    <View.Item grow>
                      <Avatar
                        name={
                          selectedUser?.firstName && selectedUser?.lastName
                            ? `${selectedUser.firstName} ${selectedUser.lastName}`
                            : EMPTY_VALUE
                        }
                        size="large"
                        variant="assignee"
                        showText={true}
                      />
                    </View.Item>

                    {isSuggested(selectedUser?.userId) && (
                      <View.Item>
                        <View
                          direction="row"
                          justify="center"
                          align="center"
                          min-height="var(--st-unit-6)"
                          width="fit-content"
                          borderRadius="small"
                          className={styles[`assignees-suggestion__item__badge`]}
                        >
                          <Text
                            size="075"
                            weight="bold"
                            className={styles[`assignees-suggestion__item__badge--text`]}
                          >
                            {t('PODashboard.Assignees.Suggested')}
                          </Text>
                        </View>
                      </View.Item>
                    )}
                  </View>
                </View>
              </View>
            </View.Item>
          </View>
        </View.Item>
      )}

      <View.Item>
        <View direction="column" className={styles['assignees-suggestion__section']}>
          <View.Item>
            <View className={styles['assignees-suggestion__section__title']}>
              <Text size="087" weight="medium" lineHeight="140">
                {t('PODashboard.Assignees.AllAutoZoners')} (
                {data?.filter((assignee) => !isSelected(assignee.userId))?.length})
              </Text>
            </View>
          </View.Item>
          <View.Item>
            <View className={styles['assignees-suggestion__section__items']}>
              {data
                ?.filter((assignee) => !isSelected(assignee.userId))
                .map((dataItem: UserSearchSchemaType) => (
                  <Actionable
                    key={t('PODashboard.ItemKey', {
                      item: 'assignees',
                      key: dataItem.userId,
                    })}
                    fullWidth
                    onClick={() => handleSuggestionClick(dataItem)}
                  >
                    <View direction="row" className={styles['assignees-suggestion__item']}>
                      <View
                        direction="row"
                        justify="center"
                        align="center"
                        className={styles['assignees-suggestion__item__checkbox__label']}
                      >
                        <View.Item grow>
                          <Avatar
                            name={`${dataItem.firstName} ${dataItem.lastName}`}
                            size="large"
                            variant="assignee"
                            showText={true}
                          />
                        </View.Item>

                        {isSuggested(dataItem.userId) && (
                          <View.Item>
                            <View
                              direction="row"
                              justify="center"
                              align="center"
                              min-height="var(--st-unit-6)"
                              width="fit-content"
                              borderRadius="small"
                              className={styles[`assignees-suggestion__item__badge`]}
                            >
                              <Text
                                size="075"
                                weight="bold"
                                className={styles[`assignees-suggestion__item__badge--text`]}
                              >
                                {t('PODashboard.Assignees.Suggested')}
                              </Text>
                            </View>
                          </View.Item>
                        )}
                      </View>
                    </View>
                  </Actionable>
                ))}
            </View>
          </View.Item>
        </View>
      </View.Item>
    </View>
  );
};
