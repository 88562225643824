/**
 * Copyright 2025 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const ReplenishmentDashboardMetricsSchema = z.object({
  countByPriority: z
    .array(
      z.object({
        key: z.string(),
        value: z.string(),
      })
    )
    .nullable(),
  countByUserAssignment: z
    .array(
      z.object({
        key: z.string(),
        value: z.string(),
      })
    )
    .nullable(),
  metricByEffectiveTs: z
    .array(
      z.object({
        key: z.string(),
        value: z.string(),
      })
    )
    .nullable(),
});
