/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { ColumnParam as STARCColumParam } from '@az/starc-ui/dist/components/table/Table.types';
import type * as T from '../StatusBadge/StatusBadge.types';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';
import { TableStylingVariants } from '@shared/components/Table/tableConstants';
import { ReactNode } from 'react';
import { ProductType } from '@ofm/types/types';
import { StatusVariants } from '../StatusBadge/StatusBadge.types';
import { User } from '../AvatarGroup/AvatarGroup.types';

export type ErrorRowTypes = {
  storeId: string;
  errorStatus: string;
  attempts: number;
  created: Date;
  lastAttempted: Date;
  link: string;
};

export type OrderDetailsRowTypes = {
  sku: string;
  partNumber: string;
  planogramId: string;
  description: string;
  pack: number;
  qoh: number;
  quantity: number;
  isRowCredited: boolean;
};

export interface WarehouseDetails extends SelectOption {
  warehouseId: string;
  quantityOnHand: number;
  minimumPack: number;
}

export type WillCallRowTypes = ProductType & {
  warehouseDetails: WarehouseDetails[];
  selectedWarehouse: WarehouseDetails;
  status: string;
  quantityCurrent: number;
  numberOfPacksCurrent: number;
  isRowBeingEdited: boolean;
};

export type StoreDetailsRowTypes = {
  orderId: string;
  invoiceId: string;
  requestBy?: Date;
  orderType: string;
  badgeText: string;
  badgeVariant: T.StatusVariants;
  requestedAt: Date;
  billedAt?: Date;
  lines: number;
  pieces: number;
  hasComments: boolean;
};

export type WarehouseConfigurationRowTypes = {
  id: number;
  dcNumber: number;
  address: string;
  description: string;
  contactNumber: string;
};

export interface StoreStatus {
  value: string;
  label: string;
  variant: StatusVariants;
}

export type OrderReleaseStoreOrderRowTypes = {
  storeNumber: string;
  pallets: number;
  pieces?: number;
  comments?: string;
  pallet?: string;
  route?: string;
  dispatchTime?: Date;
  activity?: string;
  status?: StoreStatus;
  willCall?: boolean;
  orderNumber?: string;
};

export type OrderReleaseNewStoreOrderRowTypes = {
  subzone: string;
  sku: number;
  pallets: number;
  status: StoreStatus;
  pieces: number;
};

export type OrderDetailsDrawerRowTypes = {
  sku: string;
  part: string;
  planogramId: string;
  subzone: string;
  description: string;
  pack: number;
  qoh: number;
  quantity: number;
  users?: User[];
};

export type SortRowsParam = {
  dividerLabel?: string;
  id: string;
  isDisabled?: boolean;
  isRowExpandable?: boolean;
  cells: { value: ReactNode; sortValue?: string | number | boolean | null }[];
  inGroup?: boolean;
  inLaneConfirmation?: boolean;
  isStoreGroupParent?: boolean;
};

export interface ColumnParam extends STARCColumParam {
  isSorted?: boolean;
  isCheckbox?: boolean;
}

export type Props = {
  columns: ColumnParam[];
  rows: SortRowsParam[];
  expandableTableView?: ReactNode;
  isPaginated: boolean;
  pageSize: number;
  defaultPage: number;
  isCheckboxTable: boolean;
  isCheckboxDisabled?: boolean;
  showDeselectCheckbox?: boolean;
  isLoading?: boolean;
  isApiLoadedData?: boolean;
  isCreditItem: boolean;
  styleVariant:
    | TableStylingVariants.DETAILS
    | TableStylingVariants.ERROR
    | TableStylingVariants.MODAL
    | TableStylingVariants.DEFAULT
    | TableStylingVariants.FILLED
    | TableStylingVariants.SIMPLE
    | TableStylingVariants.DRAWER
    | TableStylingVariants.EXPANDABLE;
  totalPages: number;
  onClick?: (selectedRows: string[]) => void;
  onSort: (sorting: TableSorting[], columnID: string) => void;
  onPageChange?: (pageNumber: number) => void;
  showTotalRows?: boolean;
  onRowAction?: (row: SortRowsParam) => void;
  isFullHeight?: boolean;
  showEmptyState?: boolean;
  emptyStateSubtitle?: string;
  emptyStateText?: string;
};

export enum DIRECTION {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
  INACTIVE = 'inactive',
}

export type TableSorting = { direction: DIRECTION; id: string };

export type ReleaseToPaperRowTypes = {
  subzone: string;
  sku: number;
  pallets: number;
  pieces: number;
};

export type ClosingPaperRowTypes = {
  label: string;
  sku: string;
  part: string;
  description: string;
  pack: number;
  qoh: number;
  quantity: number;
  locations: {
    locationId: string;
    quantity: number;
  }[];
};
