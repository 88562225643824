/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { lazy, useState, startTransition } from 'react';
import { View, Text, Link } from '@az/starc-ui';
import { t } from 'i18next';
import { useQuery } from '@tanstack/react-query';

import { TopVariancesParams } from '@inventory/types/service.types';
import { topVarianceService } from '@inventory/services/topVarianceService';

import { List } from './List';
import * as T from './TopVariancesSection.types';

const LazyLoadTopVarianceProductCategoryModal = lazy(() =>
  import(
    '@inventory/pages/CycleCounts/components/TopVarianceProductCategory/TopVarianceProductCategory'
  ).then((module) => ({ default: module.TopVarianceProductCategory }))
);

export const TopVariancesSection = ({
  dollarPayload,
  piecePayload,
  selectedFilter,
  isRBCCFlow = false,
}: T.Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedPayload, setSelectedPayload] = useState<TopVariancesParams | null>(null);

  const {
    data: dollarData,
    isLoading: dollarLoading,
    error: dollarError,
  } = useQuery(
    ['dollarVarianceData', dollarPayload.payload],
    () => topVarianceService.getVariances(dollarPayload.payload),
    {
      enabled: !!dollarPayload.payload,
    }
  );

  const {
    data: pieceData,
    isLoading: pieceLoading,
    error: pieceError,
  } = useQuery(
    ['pieceVarianceData', piecePayload.payload],
    () => topVarianceService.getVariances(piecePayload.payload),
    {
      enabled: !!piecePayload.payload,
    }
  );

  const openViewAllModal = (payload: TopVariancesParams) => {
    startTransition(() => {
      setSelectedPayload(payload);
      setIsOpen(true); // Open modal
    });
  };

  const SectionHeader = ({ title, payload }: T.SectionHeaderProps) => (
    <View direction="row" align="center" justify="space-between" width="auto">
      <Text weight="bold" color="500" size="075" textCase="uppercase">
        {title}
      </Text>
      <Link onClick={() => openViewAllModal(payload)}>
        <Text weight="bold" color="500" size="087">
          {t(`ViewAll`)}
        </Text>
      </Link>
    </View>
  );

  return (
    <View direction="column" gap={4}>
      <View direction="column" gap={2}>
        {/* Dollar variance section */}
        <SectionHeader
          title={t(`TopVariances.ProductCategory.DollarVariances`)}
          payload={dollarPayload.payload}
        />
        <List
          data={dollarData?.content}
          isLoading={dollarLoading}
          isError={dollarError}
          isDollarVariance
        />
      </View>
      <View direction="column" gap={2}>
        {/* Piece Adjustment section */}
        <SectionHeader
          title={t(`TopVariances.ProductCategory.PieceAdjustments`)}
          payload={piecePayload.payload}
        />
        <List data={pieceData?.content} isLoading={pieceLoading} isError={pieceError} />
      </View>
      {isOpen && (
        <LazyLoadTopVarianceProductCategoryModal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          payload={selectedPayload as TopVariancesParams}
          selectedFilter={selectedFilter}
          isRBCCFlow={isRBCCFlow}
        />
      )}
    </View>
  );
};
